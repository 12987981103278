import React, { useEffect, useCallback, useState, useContext } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  LinearProgress,
  Badge,
  Alert,
} from '@mui/material';
import {
  FixedSizeList as List,
} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { useChats } from '../../hooks/useChats';
import { truncate, debounce } from 'lodash';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  AssignmentIndTwoTone as ProfileIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { UserDialogContext } from 'features/context/userDialogContext';

export default function ChatList() {
  const { t } = useTranslation();
  const {
    chats,
    getChats,
    fetchMore: loadMoreChats,
    total: totalChats,
    setSearchText,
    selectedChatId,
    setSelectedChatId,
    isIdle,
  } = useChats();
  const [localSearchText, setLocalSearchText] = useState('');
  const { onOpen: onUserDialogOpen } = useContext(UserDialogContext);

  const onUsernameClicked = useCallback(
    (username) => (event) => {
      event?.preventDefault();
      onUserDialogOpen(username);
      event?.stopPropagation();
    }, [onUserDialogOpen]
  );

  useEffect(() => {
    getChats();
  }, [getChats]);

  const Row = useCallback(
    ({ data, index, style }) => {
      const chat = data[index];

      const { name: chatName, lastMessage, _id: chatId, unreadCount, fromMe } = chat;
      const { text = '', type: lastMessageType, from } = lastMessage || {};
      const { name: fromName = 'User' } = from || {};

      let lastMessageText;

      if (!lastMessage) {
        lastMessageText = t('No messages');
      } else {
        lastMessageText = fromMe ? `${t('You')}: ` : `${fromName}: `;
        lastMessageText += lastMessageType === 'text' ? text : lastMessageType;
      }

      const truncatedLastMessageText = truncate(lastMessageText, {
        length: 20,
        omission: '...',
      });

      let createdAtFormatted = '';

      if (lastMessage?.createdAt) {
        createdAtFormatted = dayjs(lastMessage.createdAt).format('DD/MM/YYYY HH:mm');
      }

      return (
        <Box style={style}>
          <Box
            sx={{
              border: '1px solid #ccc',
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              bgcolor: selectedChatId === chatId ? 'primary.dark' : 'transparent',
              color: selectedChatId === chatId ? 'primary.contrastText' : 'inherit',
            }}
            onClick={(event) => {
              event.preventDefault();
              setSelectedChatId(chatId);
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Typography variant="h6">
                  {chatName}

                </Typography>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={onUsernameClicked(chatName)}
                >
                  <ProfileIcon />
                </IconButton>
              </Box>
              <Typography variant="caption">
                {createdAtFormatted}
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Typography
                variant="body2"
                fontStyle={lastMessageType === 'text' ? 'inherit' : 'italic'}
              >
                {truncatedLastMessageText}
              </Typography>
              {
                unreadCount > 0 && (
                  <Badge
                    sx={{
                      mr: 2
                    }}
                    badgeContent={unreadCount}
                    color="secondary"
                  />
                )
              }
            </Box>
          </Box>
        </Box>
      );
    }, [selectedChatId, setSelectedChatId, t, onUsernameClicked]
  );

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
    }}>
      {
        !isIdle && (
          <LinearProgress />
        )
      }
      <Box sx={{
        height: theme => theme.mixins.toolbar.minHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <TextField
          variant="standard"
          fullWidth
          placeholder={t('Search')}
          value={localSearchText}
          onChange={(e) => {
            e.preventDefault();
            setLocalSearchText(e.target.value);
            debounce(() => {
              setSearchText(e.target.value);
            }, 500)();
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setLocalSearchText('');
                    setSearchText('');
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      {
        chats.length === 0 && (
          <Alert severity="info">
            {t('No chats found')}
          </Alert>
        )
      }
      <Box sx={{
        height: theme => `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
      }}>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={index => index < chats.length}
              itemCount={totalChats}
              loadMoreItems={loadMoreChats}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={height}
                  itemCount={chats.length}
                  itemSize={72}
                  itemData={chats}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                >
                  {
                    Row
                  }
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
}