import React, { useState, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase, each, startsWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AbilityContext } from 'casl/Can';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CommonContext from 'features/context/commonContext';
import axios from 'axios';
import InputAdornment from '@mui/material/FormControl';
import IconButton from '@mui/material/FormControl';
import FetchIcon from '@mui/icons-material/GetAppTwoTone';
import ClearIcon from '@mui/icons-material/ClearTwoTone';
import { transformSavedData } from 'utils/form-utils';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const _NEWID = '@NewBankBot';
const _RNAME = 'bankBots';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const { bankBotLookup } = useContext(CommonContext);
  const [ accountOpts, setAccountOpts ] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const bankBotOptions = useMemo(
    () => {
      let ret = [];
      each(bankBotLookup, (value, key) => {
        ret.push({ key, value });
      });
      return ret;
    }, [bankBotLookup]
  );

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        autoApproval: false,
        isEnabled: true
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    botType: Yup.string().required(t("Required")),
    username: Yup.string()
      .when('botType', {
        is: (val) => (startsWith(val, 'my_')) ,
        then: (schema) => schema.required(t("Required")),
        otherwise: (schema) => schema.nullable()
      }),
    password: Yup.string().nullable(),
    apiKey: Yup.string()
      .when('botType', {
        is: (val) => (startsWith(val, 'au_')) ,
        then: (schema) => schema.required(t("Required")),
        otherwise: (schema) => schema.nullable()
      }),
    accountId: Yup.string()
      .when('botType', {
        is: (val) => (startsWith(val, 'au_')) ,
        then: (schema) => schema.required(t("Required")),
        otherwise: (schema) => schema.nullable()
      }),
    accountNumber: Yup.string().required(t("Required")),
    autoApproval: Yup.bool().required(t("Required")),
    socksProxyUri: Yup.string().nullable(),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const flattenVal = flatten(values);

        if (!flattenVal?.password) delete flattenVal['password'];

        if (_id === _NEWID) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const memoizedBotType = useMemo(() => {
    return formik.values?.botType || '';
  }, [formik.values]);

  const memoizedApiKey = useMemo(() => {
    return formik.values?.apiKey || '';
  }, [formik.values]);

  const canFetchAccountOpts = useMemo(() => {
    if (!!accountOpts.length) return false;
    if (memoizedBotType === 'au_upb_bsb' && memoizedApiKey) return true;
    return false;
  }, [memoizedBotType, memoizedApiKey, accountOpts]);

  const hasAccountOpts = useMemo(() => {
    return !!accountOpts?.length;
  }, [accountOpts])

  const fetchAccountOpts = useCallback(
    async (event) => {
      try {
        event?.preventDefault();
        setStatus('submitting');

        if (memoizedBotType === 'au_upb_bsb') {
/*          const response = {
            data: {
              "data":[{
                "type": "accounts",
                "id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
                "attributes":
                {
                  "displayName": "Spending",
                  "accountType": "TRANSACTIONAL",
                  "ownershipType": "INDIVIDUAL",
                  "balance":
                  {
                    "currencyCode": "AUD",
                    "value": "100.00",
                    "valueInBaseUnits": 10000
                  }
                },
              }]
            }
          };*/
          const response = await axios({
            method: 'get',
            url: 'https://api.up.com.au/api/v1/accounts',
            headers: {
              'Authorization': `Bearer ${memoizedApiKey}`
            },
            params: {
              'page[size]': 30
            }
          });

          const accounts = response.data?.data || [];
          const formattedAccounts = accounts.map((account) => {
            const displayName = get(account, 'attributes.displayName', 'Unknown');
            const balance = get(account, 'attributes.balance.value', '0.00');
            const accountId = get(account, 'id', '');
            return {
              name: `${displayName} $${balance}`,
              id: accountId,
            }
          });
          setAccountOpts(formattedAccounts);
        }
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        setStatus('idle');
      }
    }, [memoizedBotType, memoizedApiKey, setGlobalErrorMessage]
  );

  const clearAccountOpts = useCallback(
    (event) => {
      event?.preventDefault();
      setAccountOpts([]);
    }, []
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Bank Bot')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.botType', false) && Boolean(get(formik, 'errors.botType'))}
              >
                <InputLabel id='bot-type-select-label'>{t('Bot Type')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('botType')}
                  labelId='bot-type-select-label'
                  id='botType'
                  name='botType'
                  value={get(formik, 'values.botType', 'None')}
                  label={t('Bot Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    bankBotOptions.map(b => (
                      <MenuItem key={b.key} value={b.key}>
                        {b.value}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.botType', false) && get(formik, 'errors.botType')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('username')}
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                id='password'
                name='password'
                label='Password'
                value={get(formik, 'values.password', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.password`, false) && Boolean(get(formik, `errors.password`))}
                helperText={get(formik, `touched.password`, false) && get(formik, `errors.password`)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={toggleShowPassword}
                        onMouseDown={toggleShowPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('apiKey')}
                id='apiKey'
                name='apiKey'
                label={t('API Key')}
                value={get(formik, 'values.apiKey', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.apiKey`, false) && Boolean(get(formik, `errors.apiKey`))}
                helperText={get(formik, `touched.apiKey`, false) && get(formik, `errors.apiKey`)}
              />
            </Grid>
            <Grid item xs={12}>
              {
                !hasAccountOpts ?
                 <TextField
                  fullWidth
                  disabled={disableInput('accountId')}
                  id='accountId'
                  name='accountId'
                  label={t('Account ID')}
                  value={get(formik, 'values.accountId', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, `touched.accountId`, false) && Boolean(get(formik, `errors.accountId`))}
                  helperText={get(formik, `touched.accountId`, false) && get(formik, `errors.accountId`)}
                  InputProps={{
                    ...(
                      canFetchAccountOpts && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="fetch account opts" onClick={fetchAccountOpts}>
                              <FetchIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    ),
                  }}
                /> :
                <FormControl
                  fullWidth
                  error={get(formik, 'touched.accountId', false) && Boolean(get(formik, 'errors.accountId'))}
                >
                  <InputLabel id='bot-type-select-label'>{t('Account ID')}</InputLabel>
                  <Select
                    autoWidth
                    disabled={disableInput('accountId')}
                    labelId='bot-type-select-label'
                    id='accountId'
                    name='accountId'
                    value={get(formik, 'values.accountId', 'None')}
                    label={t('Account ID')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton aria-label="clear account id" onClick={clearAccountOpts}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                    {
                      accountOpts.map(account => (
                        <MenuItem key={account.id} value={account.id}>
                          <Box>
                            <Typography variant="subtitle2">
                              {account.name}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {account.id}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText>{get(formik, 'touched.accountId', false) && get(formik, 'errors.accountId')}</FormHelperText>
                </FormControl>
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('accountNumber')}
                id='accountNumber'
                name='accountNumber'
                label={t('Account Number')}
                value={get(formik, 'values.accountNumber', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.accountNumber`, false) && Boolean(get(formik, `errors.accountNumber`))}
                helperText={get(formik, `touched.accountNumber`, false) && get(formik, `errors.accountNumber`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('socksProxyUri')}
                id='socksProxyUri'
                name='socksProxyUri'
                label={t('Socks Proxy URI')}
                value={get(formik, 'values.socksProxyUri', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.socksProxyUri`, false) && Boolean(get(formik, `errors.socksProxyUri`))}
                helperText={get(formik, `touched.socksProxyUri`, false) && get(formik, `errors.socksProxyUri`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('autoApproval')} checked={formik.values.autoApproval} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('autoApproval', isChecked)
                  }} />
                }
                label={t('Auto Approval')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isEnabled', isChecked)
                  }} />
                }
                label={t('Enabled')}
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
