import React, { useContext, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  Avatar,
  Box,
  IconButton,
  FormControlLabel,
} from '@mui/material';
import {
  Downloading as DownloadingIcon,
  AddPhotoAlternate as AddPhotoIcon
} from '@mui/icons-material';
import useImageAssetDownloader from 'hooks/useImageAssetDownloader';
import { AbilityContext } from 'casl/Can';
import AssetContext from 'features/context/assetContext';
import AssetExplorerDialog from 'features/assets/AssetExplorerDialog';
import {
  Cancel as DeleteIcon,
} from '@mui/icons-material';

export default function FormikAvatar(props) {
  const [ field, meta, helpers ] = useField(props);
  const { initialValues } = useFormikContext();
  const ability = useContext(AbilityContext);
  const { caslSubject = '', _id } = initialValues;
  const { name: fieldName, value: imageId } = field;
  const { openExplorer } = useContext(AssetContext);

  const { image, isLoaded: imageReady } = useImageAssetDownloader({ assetId: imageId });

  const action = _id === undefined ? 'create' : 'update';
  const isAllowed = ability.can(action, caslSubject, fieldName);

  const { sx: sxProp = {}, onChange = undefined, allowedMimeTypes = [], ...rest } = props;
  const hasError = meta.touched && Boolean(meta.error);

  const handleOnClick = useCallback(async (event) => {
    event.preventDefault();
    if (!isAllowed) return;

    async function showExplorer() {
      try {
        const result = await openExplorer({
          singleSelect: true,
          allowedMimeTypes,
        });
        const firstImage = result?.[0] || null;
        if (firstImage) {
          helpers.setValue(firstImage);
          if (onChange) onChange(firstImage);
        }
      } catch (err) {
        console.error(err);
      }
    }

    showExplorer();
    event.stopPropagation();
  }, [isAllowed, openExplorer, allowedMimeTypes, helpers, onChange]);

  const handleClearImage = () => {
    helpers.setValue(null);
    if (onChange) onChange(null);
  };

  return (
    <>
      <AssetExplorerDialog />
      <FormControlLabel
        sx={{
          color: hasError ? 'error.main' : 'text.secondary',
          '& .MuiFormControlLabel-label': {
            fontSize: theme => theme.typography.caption.fontSize,
            lineHeight: theme => theme.typography.caption.lineHeight,
          },
        }}
        control={
          <Box sx={{
            position: 'relative',
            display: 'inline-block',
          }}>
            <Avatar
              onClick={handleOnClick}
              {...rest}
              sx={{
                ...sxProp,
                cursor: isAllowed ? 'pointer' : 'inherit',
                border: 1,
                borderStyle: hasError ? 'dashed' : 'solid',
                borderColor: hasError ? 'error.main' : 'text.disabled'
              }}
              {
                ...(imageId && imageReady && {
                  alt: imageId,
                  src: image
                })
              }
            >
              {
                !imageId && <AddPhotoIcon />
              }
              {
                imageId && !imageReady && <DownloadingIcon />
              }
            </Avatar>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: -15,
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                {
                  imageId && isAllowed &&
                  <IconButton
                    onClick={handleClearImage}
                    size="small"
                    color='error'
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              </Box>
          </Box>
        }
        label={props?.label || ''}
        labelPlacement={'top'}
      />
    </>
  );
}
