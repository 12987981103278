import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import BootstrapIcon from '@mui/icons-material/StorageTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import BootstrapDialog from 'features/bootstrapDialog/BootstrapDialog';
import { subject } from '@casl/ability';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'companySettings';
const table = createColumnHelper();

export default function WalletAdjustments() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const canUpdate = ability.can('update', _RNAME);
  const canDelete = ability.can('delete', _RNAME);
  const canBootstrap = ability.can('update', subject(_RNAME, {}), 'lastBootstrappedAt');
  const [ bootstrapId, setBootstrapId ] = useState(null);

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor('companyId', {
          id: `companyId`,
          cell: info => info.getValue(),
          header: () => t('Company ID'),
          footer: props => props.column.id,
        }),
        table.accessor('groupName', {
          id: `groupName`,
          cell: info => info.getValue()?.join(', ') || '',
          header: () => t('Group Name'),
          footer: props => props.column.id,
        }),
        table.accessor('country', {
          id: `country`,
          cell: info => t(`country-${info.getValue()}`),
          header: () => t('Country'),
          footer: props => props.column.id,
        }),
        table.accessor('supportedCountryCodes', {
          id: `supportedCountryCodes`,
          cell: info => info.getValue().join(', '),
          header: () => t('Supported Country Codes'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
            const chatIds = get(row, 'telegramNotification.chatIds', []);
            return chatIds.join(', ');
          }, {
          id: `telegramNotification.chatIds`,
          cell: info => info.getValue(),
          header: () => t('Telegram Notification Chat IDs'),
          footer: props => props.column.id,
        }),
        table.accessor('walletConfig', {
          id: 'walletConfig.walletHost',
          cell: info => info.getValue()?.walletHost || '',
          header: () => t('Wallet Host'),
          footer: props => props.column.id,
        }),
        table.accessor('socialChannels', {
          id: 'socialChannels.directChatLink',
          cell: info => info.getValue()?.directChatLink || '',
          header: () => t('Direct Chat Link'),
          footer: props => props.column.id,
        }),
        table.accessor('socialChannels', {
          id: 'socialChannels.facebookLink',
          cell: info => info.getValue()?.facebookLink || '',
          header: () => t('Facebook Link'),
          footer: props => props.column.id,
        }),
        table.accessor('socialChannels', {
          id: 'socialChannels.telegramLink',
          cell: info => info.getValue()?.telegramLink || '',
          header: () => t('Telegram Link'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowEdit = (data) => (event) => {
    event?.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleRowDelete = (rowId) => (event) => {
    event?.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const handleRowBootstrap = (rowId) => (event) => {
    event?.preventDefault();
    setBootstrapId(rowId);
  };

  const handleBootstrapClose = (event) => {
    event?.preventDefault();
    setBootstrapId(null);
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')} placement="top">
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Edit')} placement="top">
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        {canBootstrap && (
          <Tooltip title={t('Bootstrap')} placement="top">
            <span>
              <IconButton onClick={handleRowBootstrap(rowId)} color='primary'>
                <BootstrapIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Company')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <BootstrapDialog _id={bootstrapId} onClose={handleBootstrapClose} />
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      <Table
        name={t('Companies')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={canCreate}
        onCreateClick={handleOnCreateClick}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          'telegramNotification.chatIds': false,
          'walletConfig.walletHost': false,
          'socialChannels.directChatLink': false,
          'socialChannels.facebookLink': false,
          'socialChannels.telegramLink': false,
        }}
      />
    </Box>
  );
}
