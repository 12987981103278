import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './i18n';
import { ProvideAuth } from 'hooks/useAuth';
import { ProvideUserConfig } from 'hooks/useUserConfig';
import { AbilityContext } from './casl/Can';
import ability from './casl/ability';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProvideAuth>
        <ProvideUserConfig>
          <AbilityContext.Provider value={ability}>
            <App />
          </AbilityContext.Provider>
        </ProvideUserConfig>
      </ProvideAuth>
    </Provider>
  </React.StrictMode>
);

// If you want to troubleshoot production behavior
// change the StrictMode to Fragment

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
