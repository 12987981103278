import React, { useMemo, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line } from 'recharts';
import { get } from 'lodash';
import Decimal from 'decimal.js';
import { green, red, blue, orange, cyan, purple } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

export default function RecentPointTransactions({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [ unit, setUnit ] = useState('month');

  const isDarkPalette = useMemo(
    () => {
      return get(theme, 'palette.mode', 'light') === 'dark' ? true : false;
    }, [theme]
  );

  const graphColorTone = useMemo(
    () => {
      return isDarkPalette ? 300 : 600;
    }, [isDarkPalette]
  );

  const startDate = useMemo(
    () => {
      switch (unit) {
        case 'day':
          return dayjs().subtract(14, 'd').startOf('d');

        case 'week':
          return dayjs().subtract(6, 'w').startOf('w');

        case 'month':
          return dayjs().subtract(6, 'M').startOf('M');

        case 'year':
          return dayjs().subtract(6, 'y').startOf('y');

        default:
          return dayjs().subtract(6, 'y').startOf('y');
      }
    }, [unit]
  );

  const dateFormat = useMemo(
    () => {
      switch (unit) {
        case 'day':
          return 'MM-DD';

        case 'week':
          return 'w';

        case 'month':
          return 'MMM';

        case 'year':
          return 'YYYY';

        default:
          return 'MM-DD';
      }
    }, [unit]
  );

  const chartData = useMemo(() => {
    const { timezone: companyTimezone = 'UTC' } = data || {};

    const recentDataKey = [
      'recentBonus.pointRedemptions',
      'recentPointIssued.referralRewards',
      'recentPointIssued.tierRewards',
      'recentPointIssued.boosterRewards',
      'recentPointIssued.luckyDrawRewards',
      'recentPointIssued.vipRewards',
      'recentPointIssued.walletAdjustments',
      'recentPointRedeemed.pointRedemptions',
      'recentPointRedeemed.walletAdjustments',
    ];

    const defaultData = Object.fromEntries(recentDataKey.map(key => [`${key}Amount`, 0]));

    const transformedData = recentDataKey.reduce((all, key) => {
      const recentData = get(data, `${key}.${unit}`, []);
      const items = recentData
        .filter(({ date }) => dayjs(date).utc().tz(companyTimezone, true).isAfter(startDate))
        .map(({ date, amount: { $numberDecimal = '0' } = {} }) => {
          const adjustedDate = dayjs(date).utc().tz(companyTimezone, true);
          return {
            unixTimestamp: adjustedDate.unix(),
            date: adjustedDate.format(dateFormat),
            [`${key}Amount`]: new Decimal($numberDecimal),
          };
        });

      return items.reduce((acc, item) => {
        const index = acc.findIndex(({ date }) => date === item.date);

        if (index === -1) {
          return [...acc, { ...defaultData, ...item }];
        }

        acc[index] = { ...acc[index], ...item };

        return acc;
      }, all);
    }, []);

    if (!transformedData) return defaultData;

    const sortedData = transformedData.slice().sort((a, b) => a.unixTimestamp - b.unixTimestamp);

    const dataWithTotalAmount = sortedData.map((item) => {
      const pointIssuedKeys = [
        'recentPointIssued.referralRewardsAmount',
        'recentPointIssued.tierRewardsAmount',
        'recentPointIssued.boosterRewardsAmount',
        'recentPointIssued.luckyDrawRewardsAmount',
        'recentPointIssued.vipRewardsAmount',
        //'recentPointIssued.walletAdjustmentsAmount',
      ];

      const totalAmount = Object.keys(item)
        .filter(key => pointIssuedKeys.includes(key))
        .reduce((sum, key) => sum.add(new Decimal(item[key])), new Decimal(0));

      const bonusAmount = new Decimal(item['recentBonus.pointRedemptionsAmount'] || 0);
      const pointRedeemedAmount = new Decimal(item['recentPointRedeemed.pointRedemptionsAmount'] || 0);
      const cashToPointRatio = pointRedeemedAmount.gt(0)
        ? bonusAmount.dividedBy(pointRedeemedAmount) : new Decimal(0);

      return {
        ...item,
        'recentPointIssued.totalAmount': totalAmount,
        cashToPointRatio
      };
    });

    return dataWithTotalAmount;

  }, [data, unit, startDate, dateFormat]);


  const unitButtonVariant = useCallback(
    (u) => {
      return (unit === u) ? 'contained' : 'outlined';
    }, [unit]
  );

  const handleChartUnitChanged = (unit) => (event) => {
    event?.preventDefault();
    setUnit(unit);
  };

  const amountFormatter = (value, name) => {
    const translatedCTPRName = t('Cash to Point Ratio');
    if (name === translatedCTPRName) {
      return value.toFixed(4);
    }
    return value.toFixed(2);
  };

  return (
     <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
        {t('Recent Point Transactions')}
      </Typography>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup size='small'>
          <Button onClick={handleChartUnitChanged('day')} variant={unitButtonVariant('day')}>{t('Day')}</Button>
          <Button onClick={handleChartUnitChanged('week')} variant={unitButtonVariant('week')}>{t('Week')}</Button>
          <Button onClick={handleChartUnitChanged('month')} variant={unitButtonVariant('month')}>{t('Month')}</Button>
          <Button onClick={handleChartUnitChanged('year')} variant={unitButtonVariant('year')}>{t('Year')}</Button>
        </ButtonGroup>
      </Box>
      <Box sx={{ width: '100%', height: 280 }}>
        <ResponsiveContainer width="100%" height="100%">
         <ComposedChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke={theme.palette.divider} strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              formatter={amountFormatter}
              contentStyle={{
                borderRadius: '5px',
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary
              }}
            />
            <Legend />
            <Bar name={t('Point Issuance')} dataKey="recentPointIssued.totalAmount" stackId="issued" barSize={10} fill={green[graphColorTone]} />
            <Bar name={t('Point Redemption')} dataKey="recentPointRedeemed.pointRedemptionsAmount" stackId="redeemed" barSize={10} fill={red[graphColorTone]} />
            <Bar name={t('Point Adjustment +')} dataKey="recentPointIssued.walletAdjustmentsAmount" stackId="issued" barSize={10} fill={blue[graphColorTone]} />
            <Bar name={t('Point Adjustment -')} dataKey="recentPointRedeemed.walletAdjustmentsAmount" stackId="redeemed" barSize={10} fill={cyan[graphColorTone]} />
            <Line name={t('Redeemed Cash')} dataKey="recentBonus.pointRedemptionsAmount" stroke={orange[graphColorTone]} strokeWidth={2} />
            <Line name={t('Cash to Point Ratio')} dataKey="cashToPointRatio" stroke={purple[graphColorTone]} strokeWidth={2} />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
}
