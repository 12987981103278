//import { createSelector } from 'reselect';
import { get } from 'lodash';
import { initialState } from './slice';

export const getData = name => state => get(state, `${name}.data`, initialState.data);
export const getPage = name => state => get(state, `${name}.page`, initialState.page);
export const getLimit = name => state => get(state, `${name}.limit`, initialState.limit);
export const getSort = name => state => get(state, `${name}.sort`, []);
export const getColumnVisibility = name => state => get(state, `${name}.columnVisibility`, initialState.columnVisibility);
export const getTotal = name => state => get(state, `${name}.total`, initialState.total);
export const getStatus = name => state => get(state, `${name}.status`, initialState.status);
export const getTags = name => state => get(state, `${name}.tags`, initialState.tags);
export const getRemarks = name => state => get(state, `${name}.remarks`, initialState.remarks);
export const getFilter = name => state => get(state, `${name}.filter`, initialState.filter);
export const getPopulate = name => state => get(state, `${name}.populate`, initialState.populate);
export const getTextSearch = name => state => get(state, `${name}.textSearch`, initialState.textSearch);
export const getErrorMessage = name => state => get(state, `${name}.errorMessage`, initialState.errorMessage);
export const getTotalPage = name => state => {
  const limit = get(state, `${name}.limit`, initialState.limit);
  const total = get(state, `${name}.total`, initialState.total);
  return Math.ceil(total / limit);
};
export const getIsFiltered = name => state => {
  const f = get(state, `${name}.filter`, initialState.filter);
  return (f === null || !Object.keys(f).length ) ? false : true;
};
export const getFormattedSort = name => state => {
  const sort = get(state, `${name}.sort`, initialState.sort);
  const formattedSort = sort.map(arr => {
    const id = arr[0];
    const desc = arr[1] === -1 ? true : false;

    return { id, desc };
  });
  return formattedSort;
}
