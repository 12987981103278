import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
  PauseCircleTwoTone as PauseIcon,
  PlayCircleFilledTwoTone as PlayIcon,
  ToggleOnTwoTone as ToggleOnIcon,
  ToggleOffTwoTone as ToggleOffIcon,
} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/RefreshTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import useKioskApiResponse from 'hooks/useKioskApiResponse';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import Decimal from 'decimal.js';
import { lookupsKiosk as lookupKiosks } from 'lookups/games';
import { lookups as lookupCountries } from 'lookups/countries';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'kiosks';
const table = createColumnHelper();

export default function WalletAdjustments() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const canUpdate = ability.can('update', _RNAME);
  const canDelete = ability.can('delete', _RNAME);
  const kioskApiResponse = useKioskApiResponse();

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  useEffect(() => {
    if (!kioskApiResponse) return;

    const err = kioskApiResponse?.error;

    if (err) {
      setGlobalErrorMessage({ err });
      return;
    }
    setGlobalMessage({
      message: t(`Executed`),
      severity: 'success'
    });
  }, [kioskApiResponse, setGlobalMessage, setGlobalErrorMessage, t]);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const t = get(row, 'type');
          const lookup = get(lookupKiosks, t, '');
          return lookup;
        }, {
          id: 'type',
          cell: info => info.getValue(),
          header: () => t('Type'),
          footer: props => props.column.id,
        }),
        table.accessor('url', {
          id: 'url',
          cell: info => info.getValue(),
          header: () => t('URL'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const t = get(row, 'country');
          const lookup = get(lookupCountries, t, '');
          return lookup;
        }, {
          id: 'country',
          cell: info => info.getValue(),
          header: () => t('Country'),
          footer: props => props.column.id,
        }),
        table.accessor('username', {
          id: 'username',
          cell: info => info.getValue(),
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor('password', {
          id: 'password',
          cell: info => info.getValue(),
          header: () => t('Password'),
          footer: props => props.column.id,
        }),
        table.accessor('secondPassword', {
          id: 'secondPassword',
          cell: info => info.getValue(),
          header: () => t('Second Password'),
          footer: props => props.column.id,
        }),
        table.accessor('usernamePrefix', {
          id: 'usernamePrefix',
          cell: info => info.getValue(),
          header: () => t('Username Prefix'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const percentage = new Decimal(get(row, `percentage.$numberDecimal`, '0')).toFixed(2);
          return percentage;
        }, {
          id: 'percentage',
          cell: info => info.getValue(),
          header: () => t('Percentage'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const balance = new Decimal(get(row, `balance.$numberDecimal`, '0')).toFixed(2);
          return balance;
        }, {
          id: 'balance',
          cell: info => info.getValue(),
          header: () => t('Balance'),
          footer: props => props.column.id,
        }),
        table.accessor('balanceUpdatedAt', {
          id: 'balanceUpdatedAt',
          cell: info => {
            const val = info.getValue();
            if (!val) return '';
            return dayjs(val).locale(lang).format('YYYY-MM-DD HH:mm:ss');
          },
          header: () => t('Balance Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const minBalanceStr = get(row, `minBalanceAlert.$numberDecimal`);
          if (!minBalanceStr) return '';
          return new Decimal(minBalanceStr).toFixed(2);
        }, {
          id: 'minBalanceAlert',
          cell: info => info.getValue(),
          header: () => t('Min Balance Alert'),
          footer: props => props.column.id,
        }),
        table.accessor('isPaused', {
          id: 'isPaused',
          cell: info => {
            const val = info.getValue();
            const color = val === false ? 'success.main' : 'error.main';
            const fontWeight = val === false ? '700' : 'inherit';
            const text = val === false ? 'Started' : 'Stopped';
            return <Box sx={{ fontWeight, color }} element='span'>{t(text)}</Box>
          },
          header: () => t('Queue Status'),
          footer: props => props.column.id,
        }),
        table.accessor('isEnabled', {
          id: 'isEnabled',
          cell: info => {
            const val = info.getValue();
            const color = val === false ? 'error.main' : 'inherit';
            const fontWeight = val === false ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('Enabled'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const handleBalanceRefresh = (rowId) => async (event) => {
    try {
      event.preventDefault();
      await feathers.service(serviceName).patch(rowId, {
        lastBalanceCheckRequestAt: new Date(),
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleQueuePause = (data) => async (event) => {
    event.preventDefault();
    const rowId = get(data, '_id');
    const isPaused = get(data, 'isPaused', false);
    try {
      const updated = await feathers.service(serviceName).patch(rowId, {
        isPaused: !isPaused
      });
      const updatedIsPaused = get(updated, 'isPaused', false);
      setGlobalMessage({
        message: t(`Queue ${updatedIsPaused ? 'Paused' : 'Resumed' }`),
        severity: 'success'
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  const handleIsEnabledToggle = useCallback((data) => async (event) => {
    event?.preventDefault();
    const { _id: rowId, isEnabled = true } = data;

    try {
      await feathers.service(serviceName).patch(rowId, {
        isEnabled: !isEnabled,
      });
      setGlobalMessage({
        message: t('Saved'),
        severity: 'success'
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [serviceName, setGlobalMessage, setGlobalErrorMessage, t]);

  function RowActions(props) {
    const data = get(props, 'row.original');
    const { _id: rowId, isEnabled, isPaused } = data;
    const canControlQueue = ability.can('update', _RNAME, 'isPaused');
    const canUpdateIsEnabled = ability.can('update', _RNAME, 'isEnabled');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t("Delete")}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t("Refresh Balance")}>
          <span>
            <IconButton onClick={handleBalanceRefresh(data)} color='primary'>
              <RefreshIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={isPaused ? t("Resume Queue") : t("Pause Queue")}>
          <span>
            <IconButton disabled={!canControlQueue} onClick={handleQueuePause(data)} color='primary'>
              {
                isPaused ? <PlayIcon /> : <PauseIcon />
              }
            </IconButton>
          </span>
        </Tooltip>
        {
          !!canUpdate &&
          <Tooltip title={t("Edit")}>
            <span>
              <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
        <Tooltip title={isEnabled ? t("Disable") : t("Enable")}>
          <span>
           <IconButton disabled={!canUpdateIsEnabled} onClick={handleIsEnabledToggle(data)} color='primary'>
              {
                !isEnabled ? <ToggleOffIcon color='error' /> : <ToggleOnIcon color='success' />
              }
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Kiosk')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      <Table
        name={t('Kiosks')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={canCreate}
        onCreateClick={handleOnCreateClick}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          country: false,
          usernamePrefix: false,
          percentage: false,
          minBalanceAlert: false,
          updatedAt: false,
          createdAt: false,
        }}
      />
    </Box>
  );
}
