import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { nanoid } from 'nanoid';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import CommonContext from 'features/context/commonContext';
import { transformSavedData, unsetEmptyValues } from 'utils/form-utils';
import { Tab, Tabs } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { lookups as lookupLocales } from 'lookups/locales';

const _NEWID = '@NewUser';
const _RNAME = 'users';

const _TAB_INDEX = {
  'general': 0,
  'referral': 1,
  'socialUid': 2,
  'company': 3,
};

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const { companySettings } = useContext(CommonContext);
  const [ tabIndex, setTabIndex ] = useState(0);
  const { user: { supportedLocales = ['en'], role: userRole } = {} } = useAuth();

  const defaultRoleForNewUser = useMemo(() => {
    switch (userRole) {
      case 'root':
        return 'admin';
      case 'superadmin':
        return 'admin';
      case 'admin':
        return 'account';
      case 'superaccount':
        return 'account';
      case 'account':
        return 'user';
      default:
        return '';
    }
  }, [userRole]);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }
      return {
        _id: _NEWID,
        password: nanoid(16),
        role: defaultRoleForNewUser,
        lang: 'en',
        referralReward: {
          type: 'point',
          pointMultiplier: 0,
          cashMultiplier: 0
        },
        status: 'initing',
      }
    }, [propData, savedData, defaultRoleForNewUser]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    companyId: Yup.string().nullable(),
    groupName: Yup.array().of(Yup.string()).nullable(),
    username: Yup.string()
      .required(t("Required"))
      .matches(/^[a-zA-Z0-9]{3,}$/, t("Three or more alphanumeric")),
    password: Yup.string().nullable(),
    name: Yup.string().nullable(),
    nickname: Yup.string().nullable(),
    role: Yup.string().matches(/(user|account|admin|superadmin|root)/),
    status: Yup.string().matches(/(initing|active|blocked)/),
    language: Yup.string().matches(new RegExp(`(${supportedLocales.join('|')})`)),
    referralReward: Yup.object().when('role', {
      is: 'user',
      then: Yup.object().shape({
        type: Yup.string().matches(/(point|cash|both)/),
        pointMultiplier: Yup.number().required(t("Required")).min(0).max(1),
        cashMultiplier: Yup.number().required(t("Required")).min(0).max(1),
      }),
      otherwise: Yup.object().nullable(),
    }),
    telegramUserId: Yup.string().nullable(),
    lineUserId: Yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const role = get(values, 'role');

        const flattenVal = flatten(values, { safe: true });
        unsetEmptyValues(flattenVal, ['telegramUserId', 'lineUserId']);

        if (role !== 'user') {
          delete flattenVal.referralReward;
        }

        if (_id === _NEWID) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
        //setOpen(false);
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const isUser = get(formik, 'values.role') === 'user';

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  const handleRandomPassword = (event) => {
    event?.preventDefault();
    formik.setFieldValue('password', nanoid(16));
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';

    if (action === 'update') {
      return ability.can('update', subject(_RNAME, { ...data }), fieldName) ? false : true;
    } else {
      return ability.can(action, _RNAME, fieldName) ? false : true;
    }
  }

  function hideRole(role) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    if (action === 'update') return false;

    return ability.can('create', subject(_RNAME, { role })) ? false : true;
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('User')}</DialogTitle>
      <DialogContent dividers>
      <Tabs value={tabIndex} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'>
        <Tab label={t('General')} />
        <Tab label={t('Referral')} disabled={!isUser} />
        <Tab label={t('Social UID')} disabled={!isUser} />
        <Tab label={t('Company')} />
      </Tabs>
        {
          tabIndex === _TAB_INDEX.general &&
          <Paper sx={{ p: 2 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('username')}
                  id='username'
                  name='username'
                  label={t('Username')}
                  value={get(formik, 'values.username', '')}
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    const regex = /^[a-zA-Z0-9]*$/;
                    if (regex.test(event.target.value)) {
                      formik.handleChange(event);
                    }
                  }}
                  error={get(formik, 'touched.username', false) && Boolean(get(formik, 'errors.username'))}
                  helperText={get(formik, 'touched.username', false) && get(formik, 'errors.username')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('password')}
                  id='password'
                  name='password'
                  label={t('Password')}
                  value={get(formik, 'values.password', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, 'touched.password', false) && Boolean(get(formik, 'errors.password'))}
                  helperText={get(formik, 'touched.password', false) && get(formik, 'errors.password')}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleRandomPassword}
                          edge='end'
                        >
                          <RefreshIcon />
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('name')}
                  id='name'
                  name='name'
                  label={t('Name')}
                  value={get(formik, 'values.name', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, 'touched.name', false) && Boolean(get(formik, 'errors.name'))}
                  helperText={get(formik, 'touched.name', false) && get(formik, 'errors.name')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('nickname')}
                  id='nickname'
                  name='nickname'
                  label={t('Nickname')}
                  value={get(formik, 'values.nickname', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, 'touched.nickname', false) && Boolean(get(formik, 'errors.nickname'))}
                  helperText={get(formik, 'touched.nickname', false) && get(formik, 'errors.nickname')}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={get(formik, 'touched.role', false) && Boolean(get(formik, 'errors.role'))}
                >
                  <InputLabel id='role-select-label'>{t('Role')}</InputLabel>
                  <Select
                    autoWidth
                    disabled={disableInput('role')}
                    labelId='role-select-label'
                    id='role'
                    name='role'
                    value={formik.values.role}
                    label={t('Role')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    { !hideRole('user') && <MenuItem value='user'>{t('user')}</MenuItem> }
                    { !hideRole('account') && <MenuItem value='account'>{t('account')}</MenuItem> }
                    { !hideRole('superaccount') && <MenuItem value='superaccount'>{t('superaccount')}</MenuItem> }
                    { !hideRole('admin') && <MenuItem value='admin'>{t('admin')}</MenuItem> }
                    { !hideRole('superadmin') && <MenuItem value='superadmin'>{t('superadmin')}</MenuItem> }
                    { !hideRole('root') && <MenuItem value='root'>{t('root')}</MenuItem> }
                  </Select>
                  <FormHelperText>{get(formik, 'touched.role', false) && get(formik, 'errors.role')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={get(formik, 'touched.lang', false) && Boolean(get(formik, 'errors.lang'))}
                >
                  <InputLabel id='language-select-label'>{t('Language')}</InputLabel>
                  <Select
                    autoWidth
                    disabled={disableInput('lang')}
                    labelId='language-select-label'
                    id='lang'
                    name='lang'
                    value={formik.values.lang}
                    label={t('Language')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {
                      supportedLocales.map(function(locale) {
                        const label = lookupLocales[locale] ?? locale;
                        return <MenuItem key={locale} value={locale}>{label}</MenuItem>
                      })
                    }
                  </Select>
                  <FormHelperText>{get(formik, 'touched.lang', false) && get(formik, 'errors.lang')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={get(formik, 'touched.status', false) && Boolean(get(formik, 'errors.status'))}
                >
                  <InputLabel id='status-select-label'>{t('Status')}</InputLabel>
                  <Select
                    autoWidth
                    disabled={disableInput('status')}
                    labelId='status-select-label'
                    id='status'
                    name='status'
                    value={formik.values.status}
                    label={t('Status')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value='initing'>{t('initing')}</MenuItem>
                    <MenuItem value='active'>{t('active')}</MenuItem>
                    <MenuItem value='blocked'>{t('blocked')}</MenuItem>
                  </Select>
                  <FormHelperText>{get(formik, 'touched.status', false) && get(formik, 'errors.status')}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        }
        {
          tabIndex === _TAB_INDEX.referral &&
          <Paper sx={{ p: 2 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={get(formik, 'touched.referralReward.type', false) && Boolean(get(formik, 'errors.referralReward.type'))}
                >
                  <InputLabel id='referral-reward-type-select-label'>{t('Referral Reward Type')}</InputLabel>
                  <Select
                    autoWidth
                    disabled={disableInput('referralReward.type')}
                    labelId='referral-reward-type-select-label'
                    id='referralReward.type'
                    name='referralReward.type'
                    value={get(formik, 'values.referralReward.type', 'point')}
                    label={t('Referral Reward Type')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value='point'>{t('Point')}</MenuItem>
                    <MenuItem value='cash'>{t('Cash')}</MenuItem>
                    <MenuItem value='both'>{t('Both')}</MenuItem>
                  </Select>
                  <FormHelperText>{get(formik, 'touched.referralReward.type', false) && get(formik, 'errors.referralReward.type')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('referralReward.pointMultiplier')}
                  id='referralReward.pointMultiplier'
                  name='referralReward.pointMultiplier'
                  label={t('Referral Reward Point Multiplier')}
                  value={get(formik, 'values.referralReward.pointMultiplier', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, `touched.referralReward.pointMultiplier`, false) && Boolean(get(formik, `errors.referralReward.pointMultiplier`))}
                  helperText={get(formik, `touched.referralReward.pointMultiplier`, false) && get(formik, `errors.referralReward.pointMultiplier`)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('referralReward.cashMultiplier')}
                  id='referralReward.cashMultiplier'
                  name='referralReward.cashMultiplier'
                  label={t('Referral Reward Cash Multiplier')}
                  value={get(formik, 'values.referralReward.cashMultiplier', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, `touched.referralReward.cashMultiplier`, false) && Boolean(get(formik, `errors.referralReward.cashMultiplier`))}
                  helperText={get(formik, `touched.referralReward.cashMultiplier`, false) && get(formik, `errors.referralReward.cashMultiplier`)}
                />
              </Grid>
            </Grid>
          </Paper>
        }
        {
          tabIndex === _TAB_INDEX.socialUid &&
          <Paper sx={{ p: 2 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('telegramUserId')}
                  id='telegramUserId'
                  name='telegramUserId'
                  label={t('Telegram User ID')}
                  value={get(formik, 'values.telegramUserId', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, 'touched.telegramUserId', false) && Boolean(get(formik, 'errors.telegramUserId'))}
                  helperText={get(formik, 'touched.telegramUserId', false) && get(formik, 'errors.telegramUserId')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('lineUserId')}
                  id='lineUserId'
                  name='lineUserId'
                  label={t('Line User ID')}
                  value={get(formik, 'values.lineUserId', '')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={get(formik, 'touched.lineUserId', false) && Boolean(get(formik, 'errors.lineUserId'))}
                  helperText={get(formik, 'touched.lineUserId', false) && get(formik, 'errors.lineUserId')}
                />
              </Grid>
            </Grid>
          </Paper>
        }
        {
          tabIndex === _TAB_INDEX.company &&
          <Paper sx={{ p: 2 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={get(formik, 'touched.companyId', false) && Boolean(get(formik, 'errors.companyId'))}
                >
                  <InputLabel id='company-id-select-label'>{t('Company ID')}</InputLabel>
                  <Select
                    autoWidth
                    disabled={disableInput('companyId')}
                    labelId='company-id-select-label'
                    id='companyId'
                    name='companyId'
                    value={get(formik, 'values.companyId', '')}
                    label={t('Company ID')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={'*'}>*</MenuItem>
                    {
                      companySettings.map(function(cs) {
                        const companyId = cs?.companyId ?? null;
                        if (!companyId) return null;
                        return <MenuItem key={companyId} value={companyId}>{companyId}</MenuItem>
                      })
                    }
                  </Select>
                  <FormHelperText>{get(formik, 'touched.companyId', false) && get(formik, 'errors.companyId')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disableInput('groupName')}
                  id='groupName'
                  name='groupName'
                  label={t('Group Name')}
                  multiline
                  rows={3}
                  value={get(formik, 'values.groupName', []).join('\n')}  // Join the array of strings with a newline character
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    const value = event.target.value;
                    const valuesArray = value === '' ? [] : value
                      .split('\n')
                      .map((val) => val.trim());
                    formik.handleChange({
                      target: {
                        name: 'groupName',
                        value: valuesArray,
                      },
                    });
                  }}
                  error={get(formik, `touched.groupName`, false) && Boolean(get(formik, `errors.groupName`))}
                  helperText={get(formik, `touched.groupName`, false) && get(formik, `errors.groupName`)}
                />
              </Grid>
            </Grid>
          </Paper>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
