import React, { useMemo } from 'react';
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  CssBaseline,
  IconButton,
  LinearProgress,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Menu as MenuIcon,
  FavoriteTwoTone as FavoriteIcon,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function ThemePreview(prop) {
  const { data: dataString, fontImportUrls = [] } = prop;

  const data = useMemo(() => {
    try {
      const ret = JSON.parse(dataString);

      const test = testTheme(ret);

      if (!test) {
        throw new Error('Invalid theme data');
      }

      return ret;
    } catch (e) {
      return null;
    }
  }, [dataString]);

  function testTheme(data) {
    try {
      const theme = createTheme(data);

      if (!['light', 'dark'].includes(theme.palette.mode)) {
        return false;
      }

      return !!(<ThemeProvider theme={theme} />);
    } catch (e) {
      return false;
    }
  }

  const theme = useMemo(() => {
    if (!data) {
      return null;
    }

    return createTheme(data);
  }, [data]);

  if (!theme || !data) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style>
        {
          fontImportUrls.map((url) => `@import url(${url});`).join('\n')
        }
      </style>
      <Container component='main'>
        <Box component='body'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
          }}>
            <Paper sx={{ p: 1 }}>
              <Typography variant="h6" component="div">
                { `Hello, ${data.palette.mode} paper` }
              </Typography>
              <Box sx={{
                p: 1,
              }}>
                <LinearProgress sx={{
                  height: 14,
                }} />
              </Box>
            </Paper>
            <Card>
              <CardHeader title="Theme Preview" />
              <CardContent>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 2,
                }}>
                  <Chip label="Primary" color="primary" size="small" />
                  <Chip label="Secondary" color="secondary" size="small" />
                  <Chip label="Error" color="error" size="small" />
                  <Chip label="Warning" color="warning" size="small" />
                  <Chip label="Info" color="info" size="small" />
                  <Chip label="Success" color="success" size="small" />
                </Box>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary">
                  Primary
                </Button>
                <Button variant="contained" color="secondary">
                  Secondary
                </Button>
              </CardActions>
            </Card>
          </Box>
          <BottomNavigation showLabels={true}>
            <BottomNavigationAction label="1" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="2" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="3" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="4" icon={<FavoriteIcon />} />
            <BottomNavigationAction label="5" icon={<FavoriteIcon />} />
          </BottomNavigation>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
