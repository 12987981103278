import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import CommonContext from 'features/context/commonContext';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';
import { enums as enumLocales } from 'lookups/locales';

const _RNAME = 'users';

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));
  const ability = useContext(AbilityContext);
  const canManageCompany = ability.can('create', 'companySettings');
  const { companySettings } = useContext(CommonContext);

  const roleOptions = useMemo(
    () => {
      const allowedRoles = ['root', 'superadmin', 'admin', 'account', 'user'].filter(role => {
        const canFind = ability.can('create', subject('users', { role }));
        return canFind;
      });
      return allowedRoles;
    }, [ability]
  );

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
        username: '',
        ...(roleOptions.length === 1 && {
          role: roleOptions[0]
        })
      };
    }, [filter, roleOptions]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    companyId: Yup.string().nullable(),
    username: Yup.string().nullable(),
    role: Yup.object().shape({
      $in: Yup.array().of(Yup.string()).nullable(),
    }),
    lang: Yup.object().shape({
      $in: Yup.array().of(Yup.string()).nullable(),
    }),
    status: Yup.object().shape({
      $in: Yup.array().of(Yup.string()).nullable(),
    }),
    referralReward: Yup.object().shape({
      type: Yup.string().nullable()
    }),
    updatedAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
    createdAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            {
              !!canManageCompany &&
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={get(formik, 'touched.companyId', false) && Boolean(get(formik, 'errors.companyId'))}
                >
                  <InputLabel id='company-id-select-label'>{t('Company ID')}</InputLabel>
                  <Select
                    autoWidth
                    labelId='company-id-select-label'
                    id='companyId'
                    name='companyId'
                    value={get(formik, 'values.companyId', 'point')}
                    label={t('Company ID')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={'*'}>*</MenuItem>
                    {
                      companySettings.map(function(cs) {
                        const companyId = cs?.companyId ?? null;
                        if (!companyId) return null;
                        return <MenuItem key={companyId} value={companyId}>{companyId}</MenuItem>
                      })
                    }
                  </Select>
                  <FormHelperText>{get(formik, 'touched.companyId', false) && get(formik, 'errors.companyId')}</FormHelperText>
                </FormControl>
              </Grid>
            }
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            {
              roleOptions.length > 1 &&
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={get(formik, 'touched.role.$in', false) && Boolean(get(formik, 'errors.role.$in'))}
                >
                  <InputLabel id='role-select-label'>{t('Role')}</InputLabel>
                  <Select
                    autoWidth
                    labelId='role-select-label'
                    id='role.$in'
                    name='role.$in'
                    value={get(formik, 'values.role.$in', [])}
                    label={t('Role')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    multiple
                  >
                    <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                    {
                      roleOptions.map(r => (
                        <MenuItem key={r} value={r}>
                          {t(r)}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText>{get(formik, 'touched.role.$in', false) && get(formik, 'errors.role.$in')}</FormHelperText>
                </FormControl>
              </Grid>
            }
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.lang.$in', false) && Boolean(get(formik, 'errors.lang.$in'))}
              >
                <InputLabel id='lang-select-label'>{t('Language')}</InputLabel>
                <Select
                  autoWidth
                  labelId='lang-select-label'
                  id='lang.$in'
                  name='lang.$in'
                  value={get(formik, 'values.lang.$in', [])}
                  label={t('Language')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    enumLocales.map(locale => (
                      <MenuItem key={locale} value={locale}>
                        {t(`language.${locale}`)}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.lang.$in', false) && get(formik, 'errors.lang.$in')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.status.$in', false) && Boolean(get(formik, 'errors.status.$in'))}
              >
                <InputLabel id='status-select-label'>{t('Status')}</InputLabel>
                <Select
                  autoWidth
                  labelId='status-select-label'
                  id='status.$in'
                  name='status.$in'
                  value={get(formik, 'values.status.$in', [])}
                  label={t('Status')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  <MenuItem value={'initing'}>{t('initing')}</MenuItem>
                  <MenuItem value={'active'}>{t('active')}</MenuItem>
                  <MenuItem value={'blocked'}>{t('blocked')}</MenuItem>
                </Select>
                <FormHelperText>{get(formik, 'touched.status.$in', false) && get(formik, 'errors.status.$in')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.referralReward.type', false) && Boolean(get(formik, 'errors.referralReward.type'))}
              >
                <InputLabel id='referral-reward-type-select-label'>{t('Referral Reward Type')}</InputLabel>
                <Select
                  autoWidth
                  labelId='referral-reward-type-select-label'
                  id='referralReward.type'
                  name='referralReward.type'
                  value={get(formik, 'values.referralReward.type', 'None')}
                  label={t('Referral Reward Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  <MenuItem value={'point'}>{t('Point')}</MenuItem>
                  <MenuItem value={'cash'}>{t('Cash')}</MenuItem>
                  <MenuItem value={'both'}>{t('Both')}</MenuItem>
                </Select>
                <FormHelperText>{get(formik, 'touched.referralReward.type', false) && get(formik, 'errors.referralReward.type')}</FormHelperText>
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$gte'
                  label={t('Updated From')}
                  value={get(formik, 'values.updatedAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$gte', '');
                    else formik.setFieldValue('updatedAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$gte', false) && Boolean(get(formik, 'errors.updatedAt.$gte'))}
                  helperText={get(formik, 'touched.updatedAt.$gte', false) && get(formik, 'errors.updatedAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$lt'
                  label={t('Updated To')}
                  value={get(formik, 'values.updatedAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$lt', '');
                    else formik.setFieldValue('updatedAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$lt', false) && Boolean(get(formik, 'errors.updatedAt.$lt'))}
                  helperText={get(formik, 'touched.updatedAt.$lt', false) && get(formik, 'errors.updatedAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleSave}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
