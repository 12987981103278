import React, { useEffect, useState, useCallback } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import UnverifiedIcon from '@mui/icons-material/Help';
import SuspiciousIcon from '@mui/icons-material/Error';
import BlockedIcon from '@mui/icons-material/RemoveCircle';
import VerifiedIcon from '@mui/icons-material/VerifiedUser';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
  ))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        //color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function StatusControl(props) {
  const { accountNumber } = props;
  const [ bankStatus, setBankStatus ] = useState('');
  const [ statusToBePatched, setStatusToBePatched ] = useState(null);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ id, setId ] = useState(null);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const service = feathers.service('client-banks');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChanged = (status) => (event) => {
    if (event) event.preventDefault();
    setAnchorEl(null);
    setStatusToBePatched(status);
  };

  const onPatched = useCallback(
    (data) => {
      if (!accountNumber) return;
      if (get(data, 'accountNumber') !== accountNumber) return;
      const status = get(data, 'status');
      setBankStatus(status);
    },
    [accountNumber]
  );

  useEffect(() => {
    if (!accountNumber) return;
    let isMounted = true;
    async function fetch() {
      try {
        const find = await service.find({ query: { accountNumber, $limit: 1 } });
        if (!find.total) return;

        const _id = get(find, 'data[0]._id');
        const status = get(find, 'data[0].status');
        setId(_id);
        if (isMounted) setBankStatus(status);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    fetch();

    return () => {
      isMounted = false;
    };
  }, [accountNumber, service, setGlobalErrorMessage]);

  useEffect(() => {
    if (!id || !statusToBePatched) return;

    async function patch() {
      try {
        await service.patch(id, { status: statusToBePatched });
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    patch();
  }, [statusToBePatched, id, service, t, setGlobalMessage, setGlobalErrorMessage]);

  useEffect(() => {
    service.on('updated', onPatched);
    service.on('patched', onPatched);

    return () => {
      service.removeListener('updated', onPatched);
      service.removeListener('patched', onPatched);
    };
  }, [service, onPatched]);

  function getIcon(status) {
    if (status === 'unverified') return <UnverifiedIcon />;
    if (status === 'suspicious') return <SuspiciousIcon />;
    if (status === 'blocked') return <BlockedIcon />;
    return <VerifiedIcon />;
  }

  function getColor(status) {
    if (status === 'unverified') return 'info';
    if (status === 'suspicious') return 'warning';
    if (status === 'blocked') return 'error';
    return 'success';
  }

  if (!bankStatus) return null;

  return (
    <Box>
      <Button
        size='small'
        color={getColor(bankStatus)}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={getIcon(bankStatus)}
      >
        {t(bankStatus)}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleStatusChanged('verified')} disableRipple>
          <VerifiedIcon color='success' />
          {t('verified')}
        </MenuItem>
        <MenuItem onClick={handleStatusChanged('blocked')} disableRipple>
          <BlockedIcon color='error' />
          {t('blocked')}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleStatusChanged('suspicious')} disableRipple>
          <SuspiciousIcon color='warning' />
          {t('suspicious')}
        </MenuItem>
        <MenuItem onClick={handleStatusChanged('unverified')} disableRipple>
          <UnverifiedIcon color='info' />
          {t('unverified')}
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}
