import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';

const _RNAME = 'deviceFingerprints';

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
      };
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    'fingerprint': Yup.string().nullable(),
    'deviceType': Yup.string().nullable(),
    'osName': Yup.string().nullable(),
    'osVersion': Yup.string().nullable(),
    'browserName': Yup.string().nullable(),
    'fullBrowserVersion': Yup.string().nullable(),
    'mobileVendor': Yup.string().nullable(),
    'mobileModel': Yup.string().nullable(),
    updatedAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
    createdAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleFilter = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='fingerprint'
                name='fingerprint'
                label={t('Fingerprint')}
                value={get(formik, 'values.fingerprint', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.fingerprint`, false) && Boolean(get(formik, `errors.fingerprint`))}
                helperText={get(formik, `touched.fingerprint`, false) && get(formik, `errors.fingerprint`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceType'
                name='deviceType'
                label={t('Device Type')}
                value={get(formik, 'values.deviceType', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceType`, false) && Boolean(get(formik, `errors.deviceType`))}
                helperText={get(formik, `touched.deviceType`, false) && get(formik, `errors.deviceType`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='osName'
                name='osName'
                label={t('OS Name')}
                value={get(formik, 'values.osName', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.osName`, false) && Boolean(get(formik, `errors.osName`))}
                helperText={get(formik, `touched.osName`, false) && get(formik, `errors.osName`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='osVersion'
                name='osVersion'
                label={t('OS Version')}
                value={get(formik, 'values.osVersion', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.osVersion`, false) && Boolean(get(formik, `errors.osVersion`))}
                helperText={get(formik, `touched.osVersion`, false) && get(formik, `errors.osVersion`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='browserName'
                name='browserName'
                label={t('Browser Name')}
                value={get(formik, 'values.browserName', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.browserName`, false) && Boolean(get(formik, `errors.browserName`))}
                helperText={get(formik, `touched.browserName`, false) && get(formik, `errors.browserName`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='fullBrowserVersion'
                name='fullBrowserVersion'
                label={t('Full Browser Version')}
                value={get(formik, 'values.fullBrowserVersion', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.fullBrowserVersion`, false) && Boolean(get(formik, `errors.fullBrowserVersion`))}
                helperText={get(formik, `touched.fullBrowserVersion`, false) && get(formik, `errors.fullBrowserVersion`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='mobileVendor'
                name='mobileVendor'
                label={t('Mobile Vendor')}
                value={get(formik, 'values.mobileVendor', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.mobileVendor`, false) && Boolean(get(formik, `errors.mobileVendor`))}
                helperText={get(formik, `touched.mobileVendor`, false) && get(formik, `errors.mobileVendor`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='mobileModel'
                name='mobileModel'
                label={t('Mobile Model')}
                value={get(formik, 'values.mobileModel', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.mobileModel`, false) && Boolean(get(formik, `errors.mobileModel`))}
                helperText={get(formik, `touched.mobileModel`, false) && get(formik, `errors.mobileModel`)}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$gte'
                  label={t('Updated From')}
                  value={get(formik, 'values.updatedAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$gte', '');
                    else formik.setFieldValue('updatedAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$gte', false) && Boolean(get(formik, 'errors.updatedAt.$gte'))}
                  helperText={get(formik, 'touched.updatedAt.$gte', false) && get(formik, 'errors.updatedAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$lt'
                  label={t('Updated To')}
                  value={get(formik, 'values.updatedAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$lt', '');
                    else formik.setFieldValue('updatedAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$lt', false) && Boolean(get(formik, 'errors.updatedAt.$lt'))}
                  helperText={get(formik, 'touched.updatedAt.$lt', false) && get(formik, 'errors.updatedAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleFilter}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
