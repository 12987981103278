import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import UnlockIcon from '@mui/icons-material/LockOpenTwoTone';
import LockIcon from '@mui/icons-material/LockTwoTone';
import KeyIcon from '@mui/icons-material/Password';
import ExportIcon from '@mui/icons-material/DownloadTwoTone';
import UnblockIcon from '@mui/icons-material/KeyTwoTone';
import { get, kebabCase } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import Decimal from 'decimal.js';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'features/copyToClipboard/CopyToClipboard';
import CommonContext from 'features/context/commonContext';
import { UserDialogContext } from 'features/context/userDialogContext';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { lookups as lookupLocales } from 'lookups/locales';
import copyToClipboard from 'utils/copy-to-clipboard';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'users';
const table = createColumnHelper();

export default function Users() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const canUpdate = ability.can('update', _RNAME);
  const canDelete = ability.can('delete', _RNAME);
  const canManageCompany = ability.can('create', 'companySettings');
  const canCreateIPUnblock = ability.can('create', 'ipUnblock');
  const canExport = ability.can('find', 'exportUsers');
  const { walletHost } = useContext(CommonContext);
  const { onOpen: onUserDialogOpen } = useContext(UserDialogContext);
  const [ipUnblockCode, setIpUnblockCode] = useState('');

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  useEffect(() => {
    let timer;

    if (ipUnblockCode !== '') {
      timer = setTimeout(() => {
        setIpUnblockCode('');
      }, 30000);
    }

    return () => clearTimeout(timer);
  }, [ipUnblockCode]);

  const onUsernameClicked = useCallback(
    (username) => (event) => {
      event?.preventDefault();
      onUserDialogOpen(username);
    }, [onUserDialogOpen]
  );

  const onExportClick = useCallback(
    (event) => {
      event.preventDefault();

      async function exportUsers() {
        try {
          const response = await feathers.service('/export-users').find();
          const blob = new Blob([response], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `users-${dayjs().format('YYYY-MM-DD')}.csv`;
          a.click();
          window.URL.revokeObjectURL(url);

          setGlobalMessage({
            message: t('Success'),
            severity: 'success'
          });
        } catch (err) {
          setGlobalErrorMessage({ err });
        }
      }
      exportUsers();
    }, [t, setGlobalMessage, setGlobalErrorMessage]
  );

  const defaultColumns = useMemo(
    () => {
      if (walletHost === null) return [];
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { status, hasPassword = false } = row;
          return { status, hasPassword };
        }, {
          id: 'status',
          cell: info => {
            const { status, hasPassword } = info.getValue();
            const isBlocked = status === 'blocked' ? true : false;
            return (
              <Box sx={{
                color: isBlocked ? theme.palette.error.main : 'inherit',
                fontWeight: isBlocked ? 700 : 'inherit'
              }}
              component='span'>
                {
                  t(status)
                }
                {
                  hasPassword &&
                  <Typography sx={{ color: 'info.main', ml: 1 }} variant='subtitle2' component='span'>
                    {'*'}
                  </Typography>
                }
              </Box>
            );
          },
          header: () => t('Status'),
          footer: props => props.column.id,
        }),
        table.accessor('groupName', {
          id: `groupName`,
          cell: info => info.getValue()?.join(', ') || '',
          header: () => t('Group Name'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const username = get(row, 'username', '');
          const role = get(row, 'role', '');
          const companyId = get(row, 'companyId', '');
          return { username, role, companyId };
        }, {
          id: 'username',
          cell: info => {
            const { username, role, companyId } = info.getValue();

            if (canManageCompany) {
              return <span>{`${username}@${companyId}`}</span>;
            }

            if (role === 'user') {
              return (
                <Box sx={{
                  textDecoration: 'underline',
                  textDecorationColor: theme => (theme.palette.info.main),
                  textDecorationThickness: 2,
                  cursor: 'pointer',
                }}
                onClick={onUsernameClicked(username)}
                component='span'>
                  {username}
                </Box>
              );
            }
            return <span>{username}</span>;
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `name`, ''), {
          id: 'name',
          cell: info => info.getValue(),
          header: () => t('Name'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `nickname`, ''), {
          id: 'nickname',
          cell: info => info.getValue(),
          header: () => t('Nickname'),
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `role`, ''), {
          id: 'role',
          cell: info => t(info?.getValue()),
          header: () => t('Role'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const { lang } = row;
          if (!lang) return '';
          return lookupLocales[lang] || lang;
        }, {
          id: 'lang',
          cell: info => info?.getValue(),
          header: () => t('Language'),
          footer: props => props.column.id,
        }),
        table.accessor('lastLogon', {
          id: 'lastLogon',
          cell: info => {
            const lastLogon = info.getValue();
            if (!lastLogon) return '';
            return dayjs(lastLogon).locale(lang).fromNow();
          },
          header: () => t('Last Logon'),
          footer: props => props.column.id,
        }),
        table.accessor('fingerprint', {
          id: 'fingerprint',
          cell: info => {
            const fingerprint = info.getValue() || '';
            return (
              <Link to={`/device-fingerprints?textSearch=${fingerprint}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography component='span' variant='body2' gutterBottom
                  sx={{
                    textDecoration: 'underline',
                    textDecorationColor: theme => (theme.palette.error.main),
                    textDecorationThickness: 2,
                    cursor: 'pointer',
                  }}
                >
                  {fingerprint}
                </Typography>
              </Link>
            );
          },
          header: () => t('Fingerprint'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => {
          const cashBalanceRaw = get(row, `wallet.cashBalance.$numberDecimal`);
          if (!cashBalanceRaw) return '';
          const cashBalance = new Decimal(cashBalanceRaw).toFixed(2);
          return cashBalance;
        }, {
          id: 'cashBalance',
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right'
              }}
              element='span'>
              {val}
            </Box>
          },
          header: () => t('Cash Balance'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => {
          const pointBalanceRaw = get(row, `wallet.pointBalance.$numberDecimal`);
          if (!pointBalanceRaw) return '';
          const pointBalance = new Decimal(pointBalanceRaw).toFixed(2);
          return pointBalance;
        }, {
          id: 'pointBalance',
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right'
              }}
              element='span'>
              {val}
            </Box>
          },
          header: () => t('Point Balance'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang, theme, onUsernameClicked, canManageCompany, walletHost]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleGetLoginTokenAsync = useCallback(
    async (data) => {
      const { _id: userId } = data;
      try {
        const res = await feathers.service('login-tokens').get(userId, {
          query: {
            isUserId: true
          }
        });

        const { token } = res;
        const loginLink = walletHost ? `${walletHost}/login/${token}` : token;

        copyToClipboard(loginLink);

        setGlobalMessage({
          message: t('Success'),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [setGlobalMessage, setGlobalErrorMessage, t, walletHost]
  );

  const handleGetLoginToken = (data) => (event) => {
    event.preventDefault();
    handleGetLoginTokenAsync(data);
  };

  const handleRowLock = (data) => async (event) => {
    event.preventDefault();
    const rowId = get(data, '_id');
    const status = get(data, 'status');
    if (status !== 'active' && status !== 'blocked') return;
    const updateStatus = status === 'active' ? 'blocked' : 'active';
    try {
      await feathers.service(serviceName).patch(rowId, {
        status: updateStatus
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  const handleGenerateUnblockLink = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const res = await feathers.service('ip-unblock').create({});

        const { ipUnblockCode } = res;
        const formattedIPUnblockCode = `${walletHost && `${walletHost}/unblock/`}${ipUnblockCode}`;

        setIpUnblockCode(formattedIPUnblockCode);

        setGlobalMessage({
          message: t('Success'),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [setGlobalMessage, setGlobalErrorMessage, t, walletHost]
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');
    const status = get(data, 'status');
    const { role } = data || {};
    const isUserRole = role === 'user';

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={status === 'blocked' ? t('Unlock') : t('Lock')}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowLock(data)} color='primary'>
              {
                status === 'blocked' ? <UnlockIcon /> : <LockIcon />
              }
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Edit')}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Copy login token to clipboard')}>
          <span>
            <IconButton disabled={!isUserRole} onClick={handleGetLoginToken(data)} color='warning'>
              <KeyIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete User')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      {
        !!defaultColumns.length &&
        <Table populate={
            [{
              path: 'wallet',
              //select: ['cashBalance', 'pointBalance']
            }]
          }
          name={t('Users')}
          rname={_RNAME}
          defaultColumns={defaultColumns}
          canCreate={canCreate}
          onCreateClick={handleOnCreateClick}
          onFilterClick={handleOnFilterClick}
          defaultColumnVisibility={{
            _id: false,
            groupName: false,
            name: false,
            updatedAt: false,
          }}
        />
      }
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        gap: 1,
      }}>
        {
          canExport &&
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            <Button size='small' color='primary' variant='contained' onClick={onExportClick}>
              <ExportIcon sx={{ mr: 1 }} />
              {t('Export')}
            </Button>
          </Box>
        }
        {
          canCreateIPUnblock &&
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            {
              ipUnblockCode ?
              <Alert severity='error' icon={false}>
                <Box display='flex' alignItems='center'>
                  <Box sx={{ mr: 1 }}>
                    <Typography variant='subtitle2'>
                      {ipUnblockCode}
                    </Typography>
                  </Box>
                  <CopyToClipboard text={ipUnblockCode} copiedText={t('IP unblock code copied')}>
                    <IconButton color='inherit' size='small'>
                      <FileCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                </Box>
              </Alert> :
              <Button color='error' variant='contained' onClick={handleGenerateUnblockLink}>
                <UnblockIcon sx={{ mr: 1 }} />
                {t('Generate IP Unblock Code')}
              </Button>
            }
          </Box>
        }
      </Box>
    </Box>
  );
}
