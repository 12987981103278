import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase, each, includes, unset } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AbilityContext } from 'casl/Can';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { lookupsKiosk as lookupKiosks } from 'lookups/games';
import { lookups as lookupCountries } from 'lookups/countries';
import { transformSavedData } from 'utils/form-utils';

const _NEWID = '@NewKiosk';
const _RNAME = 'kiosks';

const _REQ_SPASSWORD = [
  '1slot',
  '918kaya',
  '918kiss',
  '918kissh5',
  'apollo777',
  'jili',
  'scr2',
  'scr888',
  'xe88',
];

const _REQ_USNPREFIX = [
  'csc',
  'gw99',
  'king855',
  'playboy',
];

// eslint-disable-next-line
//const _URL_REGEX = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&=\/]*)(?<!\/)$/;

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);

  const gameOptions = useMemo(
    () => {
      let ret = [];
      each(lookupKiosks, (value, key) => {
        ret.push({ key, value });
      });
      return ret;
    }, []
  );

  const countryOptions = useMemo(
    () => {
      let ret = [];
      each(lookupCountries, (value, key) => {
        ret.push({ key, value});
      });
      return ret;
    }, []
  );

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        country: 'my',
        percentage: 80,
        minBalanceAlert: 5000,
        isEnabled: true,
        propagateUrl: false,
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    type: Yup.string().required(t("Required")),
    //url: Yup.string().matches(_URL_REGEX, t('Invalid URL')).required(t("Required")),
    url: Yup.string().url().required(t("Required")),
    country: Yup.string().nullable().matches(/(my|sg|cn|au)/),
    username: Yup.string().required(t("Required")),
    password: Yup.string().required(t("Required")),
    secondPassword: Yup.string().when('type', {
      is: type => includes(_REQ_SPASSWORD, type),
      then: Yup.string().required(t("Required"))
    }),
    usernamePrefix: Yup.string().when('type', {
      is: type => includes(_REQ_USNPREFIX, type),
      then: Yup.string().required(t("Required"))
    }),
    percentage: Yup.number().required(),
    minBalanceAlert: Yup.number().required().integer(),
    isEnabled: Yup.bool().required(t("Required")),
    propagateUrl: Yup.bool().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const flattenVal = flatten(values);

        // Remove unneeded fields
        unset(flattenVal, 'isPaused');
        unset(flattenVal, 'lastBalanceCheckRequestAt');

        if (_id === _NEWID) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Kiosk')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.type', false) && Boolean(get(formik, 'errors.type'))}
              >
                <InputLabel id='type-select-label'>{t('Type')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('type')}
                  labelId='type-select-label'
                  id='type'
                  name='type'
                  value={get(formik, 'values.type', 'None')}
                  label={t('Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    gameOptions.map(g => (
                      <MenuItem key={g.key} value={g.key}>
                        {g.value}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.type', false) && get(formik, 'errors.type')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('url')}
                id='url'
                name='url'
                label={t('URL')}
                value={get(formik, 'values.url', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.url`, false) && Boolean(get(formik, `errors.url`))}
                helperText={get(formik, `touched.url`, false) && get(formik, `errors.url`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.country', false) && Boolean(get(formik, 'errors.country'))}
              >
                <InputLabel id='country-select-label'>{t('Country')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('country')}
                  labelId='country-select-label'
                  id='country'
                  name='country'
                  value={get(formik, 'values.country', 'None')}
                  label={t('Country')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    countryOptions.map(c => (
                      <MenuItem key={c.key} value={c.key}>
                        {c.value}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.country', false) && get(formik, 'errors.country')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('username')}
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('password')}
                id='password'
                name='password'
                label={t('Password')}
                value={get(formik, 'values.password', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.password`, false) && Boolean(get(formik, `errors.password`))}
                helperText={get(formik, `touched.password`, false) && get(formik, `errors.password`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('secondPassword')}
                id='secondPassword'
                name='secondPassword'
                label={t('Second Password')}
                value={get(formik, 'values.secondPassword', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.secondPassword`, false) && Boolean(get(formik, `errors.secondPassword`))}
                helperText={get(formik, `touched.secondPassword`, false) && get(formik, `errors.secondPassword`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('usernamePrefix')}
                id='usernamePrefix'
                name='usernamePrefix'
                label={t('Username Prefix')}
                value={get(formik, 'values.usernamePrefix', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.usernamePrefix`, false) && Boolean(get(formik, `errors.usernamePrefix`))}
                helperText={get(formik, `touched.usernamePrefix`, false) && get(formik, `errors.usernamePrefix`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('percentage')}
                id='percentage'
                name='percentage'
                label={t('Percentage')}
                value={get(formik, 'values.percentage', '0')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.percentage`, false) && Boolean(get(formik, `errors.percentage`))}
                helperText={get(formik, `touched.percentage`, false) && get(formik, `errors.percentage`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('minBalanceAlert')}
                id='minBalanceAlert'
                name='minBalanceAlert'
                label={t('Min Balance Alert')}
                value={get(formik, 'values.minBalanceAlert', '0')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.minBalanceAlert`, false) && Boolean(get(formik, `errors.minBalanceAlert`))}
                helperText={get(formik, `touched.minBalanceAlert`, false) && get(formik, `errors.minBalanceAlert`)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isEnabled', isChecked)
                  }} />
                }
                label={t('Enabled')}
              />
            </Grid>
            {
              !disableInput('propagateUrl') && <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch color='error' checked={get(formik, 'values.propagateUrl', false)} onChange={(event) => {
                      const isChecked = get(event, 'target.checked', false);
                      formik.setFieldValue('propagateUrl', isChecked)
                    }} />
                  }
                  label={t('Propagate URL')}
                />
              </Grid>
            }
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
