import React, { useEffect, useState, useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import {
  useLocation,
  Navigate,
} from "react-router-dom";
import { Formik } from 'formik';
import useQuery from 'hooks/useQuery';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useUserConfig } from 'hooks/useUserConfig';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Loader from 'features/loader/Loader';
import Relogin from 'features/login/Relogin';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

function Copyright(props) {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('Copyright © ')}
      <Link color="inherit" href={t("branding:homeUrl")}>
        {t("branding:name")}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function PasswordAuthentication() {
  const auth = useAuth();
  const { t } = useTranslation();
  const [ showPassword, setShowPassword ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const validationSchema = Yup.object({
    username: Yup.string().required(t("Required")),
    password: Yup.string().required(t("Required"))
  });

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const { username, password } = values;
        try {
          await auth.login(username, password);
        } catch (err) {
          setGlobalErrorMessage({ err });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Box
          component="form" noValidate onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
         <FormControl fullWidth variant='standard'>
            <InputLabel htmlFor="username">{t('Username')}</InputLabel>
            <Input
              id="username"
              name="username"
              type="text"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.username && Boolean(errors.username)}
              disabled={isSubmitting}
              fullWidth
              autoFocus
            />
            <FormHelperText error>{touched.username && errors.username}</FormHelperText>
          </FormControl>
          <FormControl fullWidth variant='standard'>
            <InputLabel htmlFor="password">{t('Password')}</InputLabel>
            <Input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              disabled={isSubmitting}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('Toggle password visibility')}
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{touched.password && errors.password}</FormHelperText>
          </FormControl>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {t('Login')}
          </LoadingButton>
        </Box>
      )}
    </Formik>
  );
}

function TotpAuthentication() {
  const auth = useAuth();
  const { t } = useTranslation();
  const [ showTotp, setShowTotp ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const totpSessionToken = auth.totpSessionToken;

  const validationSchema = Yup.object({
    totpCode: Yup.string().required(t("Required")),
  });

  return (
    <Formik
      initialValues={{
        totpCode: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const { totpCode } = values;
        try {
          await auth.totpAuthenticate(totpSessionToken, totpCode);
        } catch (err) {
          setGlobalErrorMessage({ err });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Box
          component="form" noValidate onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <FormControl fullWidth variant='standard'>
            <InputLabel htmlFor="totpCode">{t('TOTP Code')}</InputLabel>
            <Input
              id="totpCode"
              name="totpCode"
              type={showTotp ? 'text' : 'password'}
              value={values.totpCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.totpCode && Boolean(errors.totpCode)}
              disabled={isSubmitting}
              fullWidth
              autoFocus
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('Toggle TOTP visibility')}
                    onClick={() => setShowTotp(!showTotp)}
                    edge="end"
                  >
                    {showTotp ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{touched.totpCode && errors.totpCode}</FormHelperText>
          </FormControl>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {t('Authenticate')}
          </LoadingButton>
        </Box>
      )}
    </Formik>
  );
}

export default function Login() {
  const { t } = useTranslation();
  const query = useQuery();
  const lang = query.get('lang') || 'en';
  const darkMode = query.get('darkMode', 'false') === 'true' ? true : false;
  const location = useLocation();
  const auth = useAuth();
  const { setLang, setDarkMode } = useUserConfig();
  /*
    If the previous location is '/logout', then redirect to the home page.
  */
  const from = location.state?.from?.pathname === '/logout' ? '/' : location.state?.from || '/';

  useEffect(() => {
    if (auth.user) return;
    setLang(lang);
  }, [lang, auth, setLang]);

  useEffect(() => {
    if (auth.user) return;
    setDarkMode(darkMode);
  }, [darkMode, auth, setDarkMode]);

  const isAuthenticated = useMemo(
    () => {
      return !!auth?.user;
    }, [auth]
  );

  const hasToken = useMemo(
    () => {
      return !!localStorage.getItem('feathers-jwt');
    }, []
  );

  if (isAuthenticated) {
    return (<Navigate to={from} replace={true} />);
  }

  if (hasToken) {
    return (<Relogin from={from} />);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Loader open={!auth.isIdle} />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar variant='rounded' alt={t("branding:name")} sx={{ my: 1, width: 88, height: 88 }} src='/logo512.png'/>
        <Typography component="h1" variant="h5">
          {t("branding:name")}
        </Typography>
        <Typography variant="overline" display="block">
          {t("branding:tagline")}
        </Typography>
        {
          auth.totpSessionToken ? (
            <TotpAuthentication />
          ) : (
            <PasswordAuthentication />
          )
        }
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}