import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { LuckyWheel } from '@lucky-canvas/react'; // For previewing the lucky wheel
import {
  Box,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function LuckyWheelPreview({ data }) {
  const {
    background,
    backgroundImage,
    prize,
    blocks,
    config
  } = data || {};
  const wheelRef = useRef(null);
  const { t } = useTranslation();
  const [ hideWheel, setHideWheel ] = useState(false);
  const [ lastInit, setLastInit ] = useState(null);
  const [ prizeDigit, setPrizeDigit ] = useState(2);

  const samplePrizes = useMemo(() => {
    if (!prize) return [];

    const eightPrizes = Array.from({ length: 8 }, (_, index) => {
      return (index + 1) * Math.pow(10, prizeDigit);
    });
    const { top, fontColors, fontSize, fontWeight } = prize;

    const mappedPrizes = eightPrizes.map((prize, index) => {
      const fonts = [{
        text: `${prize}`,
        top,
        fontSize,
        fontColor: fontColors[index % fontColors.length],
        fontWeight,
      }];

      return {
        fonts
      };
    });

    return mappedPrizes;
  }, [prize, prizeDigit]);

  const safeBlocks = useMemo(() => {
    if (!blocks) return [];

    const clonedBlocks = JSON.parse(JSON.stringify(blocks));

    const ret = clonedBlocks.map(block => {
      block.imgs = block.imgs.map(img => {
        if (img?.src?.startsWith('http')) return img;
        img.src = `/asset/${img.src}`;
        return img;
      });
      return block;
    });

    return ret;
  }, [blocks]);

  const safeBackgroundImage = useMemo(() => {
    if (!backgroundImage) return null;
    return `/asset/${backgroundImage}`;
  }, [backgroundImage]);

  const safeConfig = useMemo(() => {
    if (!config) return {};

    const ret = {
      offsetDegree: Number(config.offsetDegree),
      stopRange: Number(config.stopRange),
      speed: Number(config.speed),
      accelerationTime: Number(config.accelerationTime),
      decelerationTime: Number(config.decelerationTime),
      duration: Number(config.duration),
    };

    return ret;
  }, [config]);

  const handleSpin = useCallback((event) => {
    event.preventDefault();

    if (wheelRef.current) {
      wheelRef.current.play();
    }

    const { duration = 4000 } = config;

    const timer = setTimeout(() => {
      if (wheelRef.current) {
        wheelRef.current.stop(Math.floor(Math.random() * 8));
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [wheelRef, config]);

  const handleInit = useCallback((event) => {
    event.preventDefault();

    if (wheelRef.current) {
      wheelRef.current.init();
    }

    setPrizeDigit(1);
    setLastInit(new Date().getTime());
  }, [wheelRef]);

  useEffect(() => {
    if (!lastInit) return;

    setHideWheel(true);

    const timer = setTimeout(() => {
      setHideWheel(false);
    }, 100);

    return () => clearTimeout(timer);
  }, [lastInit]);

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}>
      <Box sx={{
        width: '100%',
        py: 2,
        ...(safeBackgroundImage && {
          backgroundImage: `url("${safeBackgroundImage}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }),
        ...(background && {
          background: background,
        }),
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '300px',
          height: '300px',
          mx: 'auto',
        }}>
          {
            hideWheel ?
            <Box sx={{
              width: '100%',
              height: '100%',
            }}></Box> :
            <LuckyWheel
              ref={wheelRef}
              width="300px"
              height="300px"
              blocks={safeBlocks}
              prizes={samplePrizes}
              defaultConfig={safeConfig}
            />
          }
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            visibility: 'none',
            width: '2rem',
            height: '2rem'
          }} onClick={handleSpin}>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 1,
      }}>
        <Button variant="contained" onClick={handleInit}>
          {t('Init')}
        </Button>
        <Button variant="contained" onClick={handleSpin}>
          {t('Spin')}
        </Button>
        <Button variant="outlined" onClick={() => setPrizeDigit(prizeDigit + 1)}>
          {t('Higher Prize')}
        </Button>
        <Button variant="outlined" onClick={() => setPrizeDigit(prizeDigit - 1)}>
          {t('Lower Prize')}
        </Button>
      </Box>
    </Box>
  );
}
