import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { AbilityContext } from 'casl/Can';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';
import { transformSavedData } from 'utils/form-utils';
import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject } from 'utils/yup-helper';

const _NEWID = '@NewAnnouncement';
const _RNAME = 'announcements';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      const defaultData = {
        startsAt: new Date(),
        daysAllowed: [1, 2, 3, 4, 5, 6, 7],
        speed: 20,
        delay: 0,
        loop: 0,
        isPlay: true,
        isPrivate: true,
        isEnabled: true
      };

      // for template
      if (propData && propData._id === '__template__') {
        return {
          ...propData,
          _id: _NEWID,
          ...defaultData,
        }
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        ...defaultData,
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    text: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t('Required'))),
    startsAt: Yup.date().required(t("Required")),
    endsAt: Yup.date().required(t("Required")),
    daysAllowed: Yup.array().of(Yup.number().integer()),
    speed: Yup.number().min(0).required(t("Required")),
    delay: Yup.number().min(0).required(t("Required")),
    loop: Yup.number().min(0).required(t("Required")),
    isPlay: Yup.bool().required(t("Required")),
    isPrivate: Yup.bool().required(t("Required")),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');

        if (_id === _NEWID) {
          delete values._id;
          const saved = await feathers.service(serviceName).create(values);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, {
            $set: values
          });
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Announcement')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            {
              supportedLocales.map((locale) => (
                <Grid key={locale} item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput(`text.${locale}`)}
                    id={`text.${locale}`}
                    name={`text.${locale}`}
                    label={t(`text.${locale}`)}
                    value={get(formik, `values.text.${locale}`, '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.text.${locale}`, false) && Boolean(get(formik, `errors.text.${locale}`))}
                    helperText={get(formik, `touched.text.${locale}`, false) && get(formik, `errors.text.${locale}`)}
                  />
                </Grid>
              ))
            }
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  disabled={disableInput('startsAt')}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='startsAt'
                  label={t('Starts At')}
                  value={get(formik, 'values.startsAt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('startsAt', '');
                    else formik.setFieldValue('startsAt', date.toDate());
                  }}
                  error={get(formik, 'touched.startsAt', false) && Boolean(get(formik, 'errors.startsAt'))}
                  helperText={get(formik, 'touched.startsAt', false) && get(formik, 'errors.startsAt')}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  disabled={disableInput('endsAt')}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='endsAt'
                  label={t('Ends At')}
                  value={get(formik, 'values.endsAt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('endsAt', '');
                    else formik.setFieldValue('endsAt', date.toDate());
                  }}
                  error={get(formik, 'touched.endsAt', false) && Boolean(get(formik, 'errors.endsAt'))}
                  helperText={get(formik, 'touched.endsAt', false) && get(formik, 'errors.endsAt')}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                disabled={disableInput('daysAllowed')}
                error={get(formik, 'touched.daysAllowed', false) && Boolean(get(formik, 'errors.daysAllowed'))}
              >
                <InputLabel id='state-select-label'>{t('Days Allowed')}</InputLabel>
                <Select
                  autoWidth
                  labelId='state-select-label'
                  id='daysAllowed'
                  name='daysAllowed'
                  value={get(formik, 'values.daysAllowed', [])}
                  label={t('Days Allowed')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    [1, 2, 3, 4, 5, 6, 7].map((day) => (
                    <MenuItem key={day} value={day}>
                      {t(`day_${day}`)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{get(formik, 'touched.daysAllowed', false) && get(formik, 'errors.daysAllowed')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('speed')}
                id='speed'
                name='speed'
                label={t('Speed')}
                value={get(formik, 'values.speed', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.speed`, false) && Boolean(get(formik, `errors.speed`))}
                helperText={get(formik, `touched.speed`, false) && get(formik, `errors.speed`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('delay')}
                id='delay'
                name='delay'
                label={t('Delay In Sec')}
                value={get(formik, 'values.delay', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.delay`, false) && Boolean(get(formik, `errors.delay`))}
                helperText={get(formik, `touched.delay`, false) && get(formik, `errors.delay`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('loop')}
                id='loop'
                name='loop'
                label={t('Loop')}
                value={get(formik, 'values.loop', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.loop`, false) && Boolean(get(formik, `errors.loop`))}
                helperText={get(formik, `touched.loop`, false) && get(formik, `errors.loop`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isPlay')} checked={formik.values.isPlay} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isPlay', isChecked)
                  }} />
                }
                label={t('Is Play')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isPrivate')} checked={formik.values.isPrivate} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isPrivate', isChecked)
                  }} />
                }
                label={t('Is Private')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isEnabled', isChecked)
                  }} />
                }
                label={t('Enabled')}
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
