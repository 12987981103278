import { io } from 'socket.io-client';
import { feathers } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';
import {
  deviceType,
  osName,
  osVersion,
  browserName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel
} from 'react-device-detect';
import { ClientJS } from 'clientjs';

const client = new ClientJS();

const generateDeviceInfo = () => {
  const fingerprint = client.getFingerprint();
  const deviceInfo = {
    fingerprint,
    deviceType,
    osName,
    osVersion,
    browserName,
    fullBrowserVersion,
    mobileVendor,
    mobileModel
  };
  return JSON.stringify(deviceInfo);
};

const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST || 'localhost:3030';
const socket = io(SOCKET_HOST, {
  query: { deviceInfo: generateDeviceInfo() },
  transports: ['websocket'],
  upgrade: false
});

const app = feathers();

app.configure(socketio(socket, {
  timeout: 30000
}));

app.configure(authentication({
  storage: window.localStorage
}));

export default app;
export const socketClient = socket;
