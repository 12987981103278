import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  Switch,
  FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

const BOOTSTRAP_SELECTIONS = [
  'announcements',
  'assets',
  'gameUrls',
  'games',
  'kiosks',
  'memberTiers',
  'news',
  'pointRedemptionDeals',
  'pointBoosters',
  'luckyDrawSettings',
  'luckyWheels',
  'themes',
];

function BootstrapDialog({ onClose, _id }) {
  const { t } = useTranslation();
  const [checkboxState, setCheckboxState] = useState(0);
  const [variant, setVariant] = useState('');
  const [ isWildcardBootstrap, setIsWildcardBootstrap ] = useState(false);
  const open = _id != null;
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const handleCheckboxChange = (event) => {
    const checkboxValue = parseInt(event.target.value, 10);
    if (event.target.checked) {
      setCheckboxState(checkboxState | checkboxValue);
    } else {
      setCheckboxState(checkboxState & ~checkboxValue);
    }
  };

  const handleBootstrap = async () => {
    setCheckboxState(0);
    setVariant('');
    try {
      await feathers.service('company-settings').patch(_id, {
        lastBootstrappedAt: new Date(),
      }, {
        query: {
          variant: variant,
          selectedOptions: checkboxState,
          isWildcardBootstrap,
        }
      });
      setGlobalMessage({
        message: t(`Executed`),
        severity: 'success'
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const availableSelections = useMemo(() => {
    if (isWildcardBootstrap) {
      return BOOTSTRAP_SELECTIONS.map((selection) => {
        if (['assets', 'luckyWheels', 'themes'].includes(selection)) {
          return 1;
        }
        return 0;
      });
    } else {
      // luckyWheels is not available
      return BOOTSTRAP_SELECTIONS.map((selection) => {
        if (['luckyWheels', 'themes'].includes(selection)) {
          return 0;
        }
        return 1;
      });
    }
  }, [isWildcardBootstrap]);

  useEffect(() => {
    setCheckboxState(0);
  }, [isWildcardBootstrap]);

  const handleClose = () => {
    onClose();
  };

  const handleVariantChange = (event) => {
    setVariant(event.target.value);
  };

  const handleSelectAll = () => {
    const allSelectedValue = BOOTSTRAP_SELECTIONS.reduce((acc, _, index) => acc + 2 ** index, 0);
    setCheckboxState((prevState) => (prevState === 0 ? allSelectedValue : 0));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('bootstrapDialog.title')}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Switch
                checked={isWildcardBootstrap}
                onChange={(event) => setIsWildcardBootstrap(event.target.checked)}
              />
            }
            label={t('bootstrapDialog.wildcardBootstrap')}
            labelPlacement="end"
          />
        </FormControl>
        <TextField
          value={variant}
          onChange={handleVariantChange}
          fullWidth
          label={t('bootstrapDialog.variant')}
          margin="dense"
        />
        {
          BOOTSTRAP_SELECTIONS.map((selection, index) => {
            const selectionValue = 2 ** index;

            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={(checkboxState & selectionValue) === selectionValue}
                    onChange={handleCheckboxChange}
                    value={selectionValue}
                  />}
                label={t(`bootstrapDialog.${selection}`)}
                disabled={availableSelections[index] === 0}
              />
            );
          })
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('bootstrapDialog.close')}</Button>
        <Button onClick={handleSelectAll}>
          {checkboxState === 1023 ? t('bootstrapDialog.unselectAll') : t('bootstrapDialog.selectAll')}
        </Button>
        <Button onClick={handleBootstrap} disabled={checkboxState === 0}>{t('bootstrapDialog.startBootstrap')}</Button>
      </DialogActions>
    </Dialog>
  );
}

BootstrapDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  _id: PropTypes.string,
};

export default BootstrapDialog;
