import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { teal, grey, purple, amber, green } from '@mui/material/colors';
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Line } from 'recharts';
import { amountFormatter } from './common';
import {
  BarChart as ChartIcon,
  TableView as TableIcon,
} from '@mui/icons-material';
import EnhancedTableHead, { getComparator } from './EnhancedTableHead';

const GameTransactionAmount = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [ tableMode, setTableMode ] = useState(false);
  const [ order, setOrder ] = useState('asc');
  const [ orderBy, setOrderBy ] = useState('date');

  const isDarkPalette = useMemo(
    () => {
      const { palette: { mode = 'light' } = {} } = theme || {};
      return mode === 'dark' ? true : false;
    }, [theme]
  );

  const graphColorTone = useMemo(
    () => {
      return isDarkPalette ? 300 : 600;
    }, [isDarkPalette]
  );

  const headCells = useMemo(
    () => [
      { id: 'date', numeric: false, disablePadding: false, label: t('Date') },
      { id: 'dpAmt', numeric: true, disablePadding: false, label: t('DP') },
      { id: 'wdAmt', numeric: true, disablePadding: false, label: t('WD') },
      { id: 'grossWinLossAmt', numeric: true, disablePadding: false, label: t('Gross Win Loss') },
      { id: 'percentage', numeric: true, disablePadding: false, label: '%' },
      { id: 'commissionAmt', numeric: true, disablePadding: false, label: t('Commission') },
      { id: 'netWinLossAmt', numeric: true, disablePadding: false, label: t('Net Win Loss') },
    ], [t]
  );

  const onRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }, [order, orderBy]
  );

  if (!data || !data.gameType) {
    return null;
  }

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Box>
          <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
            {t('Game Txn')}
            {' '}
            <Box sx={{ fontWeight: 300, color: teal[700] }} component='span'>
              {t('Amount')}
            </Box>
          </Typography>
        </Box>
        <Box>
          <ToggleButtonGroup value={tableMode} exclusive onChange={(e, value) => setTableMode(value)} size='small'>
            <ToggleButton value={false} aria-label="chart">
              <ChartIcon />
            </ToggleButton>
            <ToggleButton value={true} aria-label="table">
              <TableIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      {
        !tableMode && <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            data={data.date}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }}
          >
            <CartesianGrid stroke={theme.palette.divider} />
            <XAxis dataKey='date' />
            <YAxis />
            <Tooltip
              formatter={amountFormatter}
              contentStyle={{
                borderRadius: '5px',
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
            />
            <Legend />
            <Bar name={t('DP')} stackId="dp" dataKey="dpAmt" barSize={15} fill={teal[graphColorTone]} />
            <Bar name={t('WD')} stackId="wd" dataKey="wdAmt" barSize={15} fill={grey[graphColorTone]} />
            <Line type="monotone" name={t('Gross Win Loss')} dataKey="grossWinLossAmt" stroke={purple[graphColorTone]} strokeWidth={3} />
            <Line type="monotone" name={t('Commission')} dataKey="commissionAmt" stroke={amber[graphColorTone]} strokeWidth={3} />
            <Line type="monotone" name={t('Net Win Loss')} dataKey="netWinLossAmt" stroke={green[graphColorTone]} strokeWidth={3} />
          </ComposedChart>
        </ResponsiveContainer>
      }
      {
        tableMode && <TableContainer>
          <Table>
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
            />
            <TableBody>
              {data.date.sort(getComparator(order, orderBy)).map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell component="th" scope="row" padding="none">
                    {row.date}
                  </TableCell>
                  <TableCell align="right">{amountFormatter(row.dpAmt)}</TableCell>
                  <TableCell align="right">{amountFormatter(row.wdAmt)}</TableCell>
                  <TableCell align="right">{amountFormatter(row.grossWinLossAmt)}</TableCell>
                  <TableCell align="right">{amountFormatter(row.percentage)}</TableCell>
                  <TableCell align="right">{amountFormatter(row.commissionAmt)}</TableCell>
                  <TableCell align="right">{amountFormatter(row.netWinLossAmt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Paper>
  );
};

const GameTransactionCount = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!data || !data.gameType) {
    return null;
  }

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
        {t('Game Txn')}
        {' '}
        <Box sx={{ fontWeight: 300, color: teal[700] }} component='span'>
          {t('Count')}
        </Box>
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={data.date}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid stroke={theme.palette.divider} />
          <XAxis dataKey='date' />
          <YAxis />
          <Tooltip
            contentStyle={{
              borderRadius: '5px',
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}
          />
          <Legend />
          <Bar name={t('DP')} stackId="dp" dataKey="dpCnt" barSize={15} fill={teal[600]} />
          <Bar name={t('WD')} stackId="wd" dataKey="wdCnt" barSize={15} fill={grey[600]} />
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
}

export { GameTransactionAmount, GameTransactionCount };