import ContentContext from './commonContext';
import React, { useEffect, useState } from 'react';
import useFeathers from 'hooks/useFeathers';
import { getLookupsByCountry as getBankLookupsByCountry } from 'lookups/banks';
import { getLookupsByCountry as getBankBotLookupsByCountry } from 'lookups/bank-bots';

import { useAuth } from 'hooks/useAuth';

function CommonProvider({ children }) {
  const queryFetchAll = {
    query: {
      $limit: -1
    }
  };

/*  const queryFetchOne = {
    query: {
      $limit: 1
    }
  };*/

  const { data: companySettings, ready: companySettingsReady } = useFeathers('company-settings', queryFetchAll);
  const { data: memberTiers, ready: memberTiersReady } = useFeathers('member-tiers', queryFetchAll);
  const [ bankLookup, setBankLookup ] = useState([]);
  const [ bankBotLookup, setBankBotLookup ] = useState([]);
  const [ lookupReady, setLookupReady] = useState(false);
  const [ walletHost, setWalletHost] = useState(null);
  const [ enableNotificationSound, setEnableNotificationSound ] = useState(false);
  const [ appBarHeight, setAppBarHeight ] = useState(0);
  const { user } = useAuth();
  const companyId = user?.companyId || '';
  const country = user?.country || '';

  useEffect(
    () => {
      const lookupBanks = getBankLookupsByCountry(country);
      const lookupBankBots = getBankBotLookupsByCountry(country);
      setBankLookup(lookupBanks);
      setBankBotLookup(lookupBankBots);
      setLookupReady(true);
    }, [country]
  );

  useEffect(
    () => {
      if (!companySettingsReady) return;

      const result = companySettings.find(item => item.companyId === companyId);
      const {
        walletConfig: {
          walletHost: _walletHost = '',
        } = {},
        backofficeConfig: {
          enableNotificationSound: _enableNotificationSound = false
        } = {}
      } = result || {};

      setWalletHost(_walletHost);
      setEnableNotificationSound(_enableNotificationSound);
    }, [companySettingsReady, companySettings, companyId]
  );

  return (
    <ContentContext.Provider value={{
      companySettings,
      companySettingsReady,
      memberTiers,
      memberTiersReady,
      bankLookup: bankLookup,
      bankBotLookup: bankBotLookup,
      lookupReady,
      walletHost,
      enableNotificationSound,
      appBarHeight,
      setAppBarHeight,
    }}>
      {children}
    </ContentContext.Provider>
  );
}

export default CommonProvider;
