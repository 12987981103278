import React, { useContext, useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useAuth } from 'hooks/useAuth';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import feathers from 'services/feathers';
import { useNavigate } from "react-router-dom";
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function CompanySwitch() {
  const { user } = useAuth();
  const {
    _id: userId,
    companyId = '',
    manageableCompanyIds = [],
  } = user;

  const ability = useContext(AbilityContext);
  const canUpdateCompanyId = ability.can('update', subject('users', user), 'companyId')
  const navigate = useNavigate();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { t } = useTranslation();

  const onChangeCompany = useCallback(
    (companyId) => async (event) => {
      try {
        await feathers.service('users').patch(userId, {
          companyId
        });
        navigate(0);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [navigate, setGlobalErrorMessage, userId]
  );

  if (!canUpdateCompanyId || !manageableCompanyIds.length || manageableCompanyIds.length <= 1) {
    return <Chip size='small' label={companyId} variant='contained' color='success' />;
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Tooltip title={t('Switch Company')}>
            <Button
              variant="contained"
              size='small'
              color='success'
              {...bindTrigger(popupState)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {companyId}
            </Button>
          </Tooltip>
          <Menu {...bindMenu(popupState)}>
            {/*<MenuItem onClick={onChangeCompany('na')}>N/A</MenuItem>*/}
            {
              manageableCompanyIds.map(function(companyId) {
                if (!companyId) return null;
                return <MenuItem key={companyId} onClick={onChangeCompany(companyId)}>{companyId}</MenuItem>
              })
            }
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}