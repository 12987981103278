import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { get } from 'lodash';

export default function BoolToggle(props) {
  const { onChange } = props;
  const value = get(props, 'value', null);

  const handleOnChange = (event, newValue) => {
    onChange(event, newValue);
  }

  return (
    <Box sx={{ mx: 1.5 }}>
      <ToggleButtonGroup
        value={value}
        onChange={handleOnChange}
        exclusive
      >
        <ToggleButton color='error' value={false}>
          <HighlightOffIcon />
        </ToggleButton>
        <ToggleButton color='success' value={true}>
          <CheckCircleOutlineIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

BoolToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
};
