import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { transformSavedData } from 'utils/form-utils';
import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject } from 'utils/yup-helper';
import {
  FormikTextField,
  FormikAvatar,
} from 'features/formikControl';

const CASL_SUBJECT = 'memberTiers';
const SERVICE_NAME = 'member-tiers';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
      }
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    name: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t("Required"))),
    photo: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t("Required"))),
    minLevel: Yup.number().required(t("Required")).integer().min(0),
    pointMultiplier: Yup.number().required(t("Required")).min(0).max(1),
    cashOutLimit: Yup.object().shape({
      day: Yup.object().shape({
        count: Yup.number().required(t("Required")).integer().min(0),
        amount: Yup.number().required(t("Required")).min(0),
      }),
      month: Yup.object().shape({
        count: Yup.number().required(t("Required")).integer().min(0),
        amount: Yup.number().required(t("Required")).min(0),
      })
    })
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Member Tier')}</DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const { _id } = values;

            if (!_id) {
              const { _id, ...newValues } = values;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              await feathers.service(SERVICE_NAME).patch(_id, {
                $set: values
              });
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
                <Paper sx={{ p: 2 }} elevation={0}>
                  <Grid container spacing={2}>
                    {
                      supportedLocales.map(locale => {
                        const nameKey = `name.${locale}`;
                        const photoKey = `photo.${locale}`;
                        return (
                          <React.Fragment key={locale}>
                            <Grid item xs={10}>
                              <FormikTextField
                                fullWidth
                                id={nameKey}
                                name={nameKey}
                                label={t(nameKey)}
                                value={get(values, nameKey, '')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FormikAvatar
                                id={photoKey}
                                name={photoKey}
                                value={get(values, photoKey, '')}
                                variant='rounded'
                                sx={{ mx: 'auto', my: 1 }}
                                onChange={(imageId) => {
                                  if (locale === supportedLocales[0]) {
                                    supportedLocales.forEach(l => {
                                      setFieldValue(`photo.${l}`, imageId);
                                    });
                                  }
                                }}
                              />
                            </Grid>
                          </React.Fragment>
                        );
                      })
                    }
                    <Grid item xs={12}>
                      <FormikTextField
                        fullWidth
                        id='minLevel'
                        name='minLevel'
                        label={t('Min Level')}
                        value={get(values, 'minLevel', '')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        fullWidth
                        id='pointMultiplier'
                        name='pointMultiplier'
                        label={t('Point Multiplier')}
                        value={get(values, 'pointMultiplier', '')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                          <Typography variant='body2' sx={{ fontWeight: 700, pt: 2, color: 'text.secondary' }}>
                            {t('Cash Out Limit')}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='cashOutLimit.day.count'
                            name='cashOutLimit.day.count'
                            label={t('Daily Count')}
                            value={get(values, 'cashOutLimit.day.count', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='cashOutLimit.day.amount'
                            name='cashOutLimit.day.amount'
                            label={t('Daily Amount')}
                            value={get(values, 'cashOutLimit.day.amount', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='cashOutLimit.month.count'
                            name='cashOutLimit.month.count'
                            label={t('Monthly Count')}
                            value={get(values, 'cashOutLimit.month.count', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='cashOutLimit.month.amount'
                            name='cashOutLimit.month.amount'
                            label={t('Monthly Amount')}
                            value={get(values, 'cashOutLimit.month.amount', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
