import React, { useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import Editor from 'features/slate/Editor';
import { useTranslation } from 'react-i18next';
import TagsInput from 'features/tagsInput/TagsInput';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import { transformSavedData } from 'utils/form-utils';
import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject, getLocaleYupArray } from 'utils/yup-helper';
import { enums as enumLocales } from 'lookups/locales';
import {
  FullscreenTwoTone as FullscreenIcon,
  FullscreenExitTwoTone as FullscreenExitIcon,
} from '@mui/icons-material';
import {
  FormControlLabel,
  IconButton,
  Switch,
} from '@mui/material';
import { AbilityContext } from 'casl/Can';

const _NEWID = '@NewNews';
const _SERVICENAME = 'news';
const EMPTY_SLATE = "%5B%7B%22type%22%3A%22paragraph%22%2C%22children%22%3A%5B%7B%22text%22%3A%22%22%7D%5D%7D%5D";

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();
  const [ selectedLang, setSelectedLang ] = useState(supportedLocales[0]);
  const [ isFullscreen, setIsFullscreen ] = useState(false);
  const ability = useContext(AbilityContext);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      const dateNow = dayjs().format('YYMMDDHHmmss');
      return {
        _id: _NEWID,
        title: supportedLocales.reduce((acc, locale) => {
          const untitledKey = `untitled.${locale}`;
          return {
            ...acc,
            [locale]: `${t(untitledKey)} ${dateNow}`,
          }
        }, {}),
        content: supportedLocales.reduce((acc, locale) => {
          return {
            ...acc,
            [locale]: EMPTY_SLATE,
          }
        }, {}),
        publishedAt: new Date()
      }
    }, [propData, savedData, supportedLocales, t]
  );

  const storageKeys = useMemo(
    () => {
      return supportedLocales.reduce((acc, locale) => {
        return {
          ...acc,
          [locale]: `slate_news_${data?._id}_${locale}`,
        }
      }, {});
    }, [data, supportedLocales]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    title: Yup.object(getLocaleYupObject(supportedLocales, true, t("Required"))),
    tags: Yup.object(getLocaleYupArray(supportedLocales)),
    publishedAt: Yup.date().required(t("Required")),
    unpublishedAt: Yup.date().nullable(),
    propagateContent: Yup.boolean().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const dataId = get(data, '_id');

        // prevent saving isPublished
        delete values.isPublished;

        if (dataId === _NEWID) {
          delete values._id;
          const saved = await feathers.service(_SERVICENAME).create(values);
          setSavedData(saved);
        } else {
          await feathers.service(_SERVICENAME).patch(dataId, {
            $set: values
          });
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
        //setOpen(false);
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  useEffect(() => {
    if (open) return;

    const removeKeys = Object.values(storageKeys);
    removeKeys.forEach(key => {
      localStorage.removeItem(key);
    });
  }, [open, storageKeys]);

  const canPropagateContent = useMemo(
    () => {
      return ability.can('update', 'news', 'propagateContent');
    }, [ability]
  );

  const onLangChanged = (lang) => (event) => {
    event?.preventDefault();
    setSelectedLang(lang);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();

    enumLocales.forEach(locale => {
      const storageKey = get(storageKeys, locale);
      const savedContent = localStorage.getItem(storageKey);

      if (!savedContent) return;
      formik.setFieldValue(`content.${locale}`, savedContent);
    });

    formik.handleSubmit();
  };

  return (
    <Dialog fullScreen={isFullscreen} open={open} onClose={handleClose} fullWidth maxWidth='xl'>
      <DialogTitle>{t('News')}</DialogTitle>
      <IconButton
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          m: 1
        }}
        onClick={() => setIsFullscreen(!isFullscreen)}
      >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
      <DialogContent dividers>
        <Paper elevation={8} sx={{ p: 2, my: 2 }} >
          <Grid container spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={3} sm={4}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='publishedAt'
                  label={t('Published At')}
                  value={get(formik, 'values.publishedAt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('publishedAt', '');
                    else formik.setFieldValue('publishedAt', date.toDate());
                  }}
                  error={get(formik, 'touched.publishedAt', false) && Boolean(get(formik, 'errors.publishedAt'))}
                  helperText={get(formik, 'touched.publishedAt', false) && get(formik, 'errors.publishedAt')}
                />
              </Grid>
              <Grid item xs={12} md={3} sm={4}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='unpublishedAt'
                  label={t('Unpublished At')}
                  value={get(formik, 'values.unpublishedAt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('unpublishedAt', '');
                    else formik.setFieldValue('unpublishedAt', date.toDate());
                  }}
                  error={get(formik, 'touched.unpublishedAt', false) && Boolean(get(formik, 'errors.unpublishedAt'))}
                  helperText={get(formik, 'touched.unpublishedAt', false) && get(formik, 'errors.unpublishedAt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
        <Paper elevation={8} sx={{ p: 2, my: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonGroup variant='contained'>
                {
                  supportedLocales.map(locale => (
                    <Button
                      key={locale}
                      variant={selectedLang === locale ? 'contained' : 'outlined'}
                      onClick={onLangChanged(locale)}>
                        {t(`language.${locale}`)}
                    </Button>
                  ))
                }
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} md={3} sm={4}>
              <TextField
                fullWidth
                margin='normal'
                id={`title.${selectedLang}`}
                name={`title.${selectedLang}`}
                label={t('Title')}
                value={get(formik, `values.title.${selectedLang}`, '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.title.${selectedLang}`, false) && Boolean(get(formik, `errors.title.${selectedLang}`))}
                helperText={get(formik, `touched.title.${selectedLang}`, false) && get(formik, `errors.title.${selectedLang}`)}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={4}>
              <TagsInput
                fullWidth
                margin='normal'
                id={`tags.${selectedLang}`}
                name={`tags.${selectedLang}`}
                label={t('Tags')}
                helperText={t('Press enter to add')}
                tags={get(formik, `values.tags.${selectedLang}`, [])}
                selectedTags={(newTags) => {
                  formik.setFieldValue(`tags.${selectedLang}`, newTags);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <Editor
          storageKey={get(storageKeys, selectedLang)}
          value={get(formik, `values.content.${selectedLang}`)}
        />
      </DialogContent>
      <DialogActions>
        {
          canPropagateContent &&
          <FormControlLabel
            control={
              <Switch color='error' checked={get(formik, 'values.propagateContent', false)} onChange={(event) => {
                const isChecked = get(event, 'target.checked', false);
                formik.setFieldValue('propagateContent', isChecked)
              }} />
            }
            label={t('Propagate Content')}
          />
        }
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton variant='contained' loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
