/*
 supportedLocales.reduce((acc, locale) => {
        return {
          ...acc,
          [locale]: Yup.string().required(t("Required")),
        }
      }, {})
*/

const Yup = require('yup');

export function getLocaleYupObject(locales, required = false, requiredMessage = 'Required') {
  if (required) {
    return locales.reduce((acc, locale) => {
      return {
        ...acc,
        [locale]: Yup.string().required(requiredMessage),
      }
    }, {})
  } else {
    return locales.reduce((acc, locale) => {
      return {
        ...acc,
        [locale]: Yup.string().nullable(),
      }
    }, {})
  }
}

export function getLocaleYupArray(locales, required = false, requiredMessage = 'Required') {
  if (required) {
    return locales.reduce((acc, locale) => {
      return {
        ...acc,
        [locale]: Yup.array().of(Yup.string()).required(requiredMessage),
      }
    }, {})
  } else {
    return locales.reduce((acc, locale) => {
      return {
        ...acc,
        [locale]: Yup.array().of(Yup.string()).nullable(),
      }
    }, {})
  }
}