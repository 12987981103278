import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
  ToggleOnTwoTone as ToggleOnIcon,
  ToggleOffTwoTone as ToggleOffIcon,
} from '@mui/icons-material';
import { get, kebabCase, map } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import Decimal from 'decimal.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'pointRedemptionDeals';
const table = createColumnHelper();

export default function WalletAdjustments() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const canUpdate = ability.can('update', _RNAME);
  const canDelete = ability.can('delete', _RNAME);

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `name.${lang}`, ''), {
          id: `name.${lang}`,
          cell: info => info.getValue(),
          header: () => t('Name'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const point = new Decimal(get(row, `point.$numberDecimal`, '0')).toFixed(2);
          return point;
        }, {
          id: 'point',
          cell: info => info.getValue(),
          header: () => t('Point Needed'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const cashRewards = get(row, `cashRewards`, []);
          const mappedCashRewards = map(cashRewards, function (cr) {
            return new Decimal(cr?.$numberDecimal || '0').toNumber();
          });
          return mappedCashRewards.join(', ');
        }, {
          id: 'cashRewards',
          cell: info => info.getValue(),
          header: () => t('Cash Rewards'),
          footer: props => props.column.id,
        }),
        table.accessor('isEnabled', {
          id: 'isEnabled',
          cell: info => {
            const val = info.getValue();
            const color = val === false ? 'error.main' : 'inherit';
            const fontWeight = val === false ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('Enabled'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  const handleIsEnabledToggle = useCallback((data) => async (event) => {
    event?.preventDefault();
    const { _id: rowId, isEnabled = true } = data;

    try {
      await feathers.service(serviceName).patch(rowId, {
        isEnabled: !isEnabled,
      });
      setGlobalMessage({
        message: t('Saved'),
        severity: 'success'
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [serviceName, setGlobalMessage, setGlobalErrorMessage, t]);

  function RowActions(props) {
    const data = get(props, 'row.original');
    const { _id: rowId, isEnabled } = data;
    const canUpdateIsEnabled = ability.can('update', _RNAME, 'isEnabled');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Edit')}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={isEnabled ? t("Disable") : t("Enable")}>
          <span>
           <IconButton disabled={!canUpdateIsEnabled} onClick={handleIsEnabledToggle(data)} color='primary'>
              {
                !isEnabled ? <ToggleOffIcon color='error' /> : <ToggleOnIcon color='success' />
              }
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Redemption Deal')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      <Table
        name={t('Redemption Deals')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={canCreate}
        onCreateClick={handleOnCreateClick}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
        }}
      />
    </Box>
  );
}
