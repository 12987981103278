import React, { useState, useContext, createContext, useEffect, useMemo, useCallback } from 'react';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

const chatContext = createContext();

export function ProvideChats({ children }) {
  const chats = useProvideChats();
  return <chatContext.Provider value={chats}>{children}</chatContext.Provider>;
}

export const useChats = () => {
  return useContext(chatContext);
};

function useProvideChats() {
  const [ total, setTotal ] = useState(0);
  const [ limit ] = useState(30);
  const [ skip, setSkip ] = useState(0);
  const [ chats, setChats ] = useState([]);
  const [ selectedChatId, setSelectedChatId ] = useState(null);
  const [ searchText, setSearchText ] = useState('');
  const [ status, setStatus ] = useState('idle');
  const [ distributionDomain, setDistributionDomain ] = useState(null);
  const [ selectedChatSendChannel, setSelectedChatSendChannel ] = useState();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const params = useMemo(() => ({
    query: {
      $sort: { 'lastMessage.createdAt': -1 },
      $limit: limit,
      $skip: skip,
      ...(searchText && { $text: { $search: searchText } }),
    },
  }), [limit, skip, searchText]);

  useEffect(() => {
    setSkip(0);
    setTotal(0);
    setChats([]);
  }, [searchText]);

  const chatService = feathers.service('chats');

  useEffect(() => {
    chatService.on('created', (chat) => {
      setChats((prevChats) => {
        if (prevChats.some((prevChat) => prevChat._id === chat._id)) {
          return prevChats;
        }
        return [chat, ...prevChats];
      });
    });

    chatService.on('patched', (chat) => {
      setChats((prevChats) => {
        const prevChat = prevChats.find((prevChat) => prevChat._id === chat._id);
        const { lastMessage: prevLastMessage = {} } = prevChat || {};

        if (prevLastMessage.createdAt === chat.lastMessage.createdAt) {
          return prevChats.map((prevChat) => {
            if (prevChat._id === chat._id) {
              return chat;
            }
            return prevChat;
          });
        }
        return [chat, ...prevChats.filter((prevChat) => prevChat._id !== chat._id)];
      });
    });

    return () => {
      chatService.removeListener('created');
      chatService.removeListener('patched');
    };
  }, [chatService]);

  const getChats = useCallback(async () => {
    try {
      setStatus('loading');
      const res = await chatService.find(params);
      const { data, total, distributionDomain } = res;
      setChats((prevChats) => {
        const newChats = data.filter((chat) => !prevChats.some((prevChat) => prevChat._id === chat._id));
        return [...prevChats, ...newChats];
      });

      setTotal(total);

      if (distributionDomain) {
        setDistributionDomain(distributionDomain);
      }
    } catch (err) {
      setGlobalErrorMessage({ err });
      throw err;
    } finally {
      setStatus('idle');
    }
  }, [chatService, params, setGlobalErrorMessage]);

  const fetchMore = useCallback(() => {
    if (skip + limit >= total) return;
    setSkip((prevSkip) => prevSkip + limit);
  }, [limit, total, skip]);

  const formattedChats = useMemo(() => {
    return chats.map((chat) => {
      const { companyId, participants = [], name, lastMessage } = chat;
      const { from } = lastMessage || {};
      const systemId = companyId;
      const systemIdType = 'system';

      const systemParticipant = participants.find(
        (participant) => participant.id === systemId && participant.type === systemIdType
      );

      const { unreadCount = 0 } = systemParticipant || {};

      let formattedName = name;

      if (!formattedName) {
        const participantNames = participants
          .filter((participant) => participant.type !== 'system')
          .map((participant) => participant.id)
          .join(', ');

        formattedName = participantNames;
      }

      const fromMe = from?.id === systemId && from?.type === systemIdType;

      return {
        ...chat,
        name: formattedName,
        unreadCount,
        fromMe,
      };
    });
  }, [chats]);

  const selectedChatObject = useMemo(() => {
    const find = formattedChats.find((chat) => chat._id === selectedChatId);
    return find;
  }, [formattedChats, selectedChatId]);

  const selectedChatUnreadCount = useMemo(() => {
    const { unreadCount } = selectedChatObject || {};
    return unreadCount;
  }, [selectedChatObject]);

  useEffect(() => {
    if (!selectedChatObject) return;
    const { lastMessage } = selectedChatObject;
    const { origin } = lastMessage || {};

    if (origin) {
      setSelectedChatSendChannel(origin);
    } else {
      setSelectedChatSendChannel('local');
    }
  }, [selectedChatObject]);

  const selectedChatAvailableSendChannels = useMemo(() => {
    const {
      lastTelegramBot,
      lastWhatsAppBot,
      lastLineBot,
    } = selectedChatObject || {};

    const availableSendChannels = ['local'];

    if (lastTelegramBot) {
      availableSendChannels.push('telegram');
    }

    if (lastWhatsAppBot) {
      availableSendChannels.push('whatsapp');
    }

    if (lastLineBot) {
      availableSendChannels.push('line');
    }

    return availableSendChannels;
  }, [selectedChatObject]);

  const sendTextMessage = useCallback(async (text) => {
    if (!selectedChatObject) return;

    try {
      const {
        _id: selectedChatId,
        lastTelegramBot,
        lastWhatsAppBot,
        lastLineBot,
      } = selectedChatObject;

      let botObject = null;

      if (selectedChatSendChannel === 'telegram' && lastTelegramBot) {
        botObject = lastTelegramBot;
      } else if (selectedChatSendChannel === 'whatsapp' && lastWhatsAppBot) {
        botObject = lastWhatsAppBot;
      } else if (selectedChatSendChannel === 'line' && lastLineBot) {
        botObject = lastLineBot;
      }

      const sender = getSender(selectedChatObject);
      const recipients = getRecipients(selectedChatObject);

      if (!sender || !recipients.length) {
        return;
      }

      for (const recipient of recipients) {
        const originUserId = getOriginUserId(recipient, selectedChatSendChannel);
        const requestData = {
          chatId: selectedChatId,
          from: sender,
          to: recipient,
          type: 'text',
          text,
          origin: selectedChatSendChannel,
          ...(originUserId && { originUserId }),
          ...(botObject && {
            botId: botObject.id,
            botUsername: botObject.username,
          })
        };

        await feathers.service('chat-messages').create(requestData);
      }
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [selectedChatObject, selectedChatSendChannel, setGlobalErrorMessage]);

  const sendMediaMessage = useCallback(async (media, caption, thumbnail) => {
    if (!selectedChatObject) return;

    try {
      const {
        _id: selectedChatId,
        lastTelegramBot,
        lastWhatsAppBot,
        lastLineBot,
      } = selectedChatObject;

      let botObject = null;

      if (selectedChatSendChannel === 'telegram' && lastTelegramBot) {
        botObject = lastTelegramBot;
      } else if (selectedChatSendChannel === 'whatsapp' && lastWhatsAppBot) {
        botObject = lastWhatsAppBot;
      } else if (selectedChatSendChannel === 'line' && lastLineBot) {
        botObject = lastLineBot;
      }

      const sender = getSender(selectedChatObject);
      const recipients = getRecipients(selectedChatObject);

      if (!sender || !recipients.length) {
        return;
      }

      /*
      * For now, we are only supporting text, image, video and audio
      */
      const { mimeType } = media;
      const chatMessageType = mimeType.split('/')[0];

      for (const recipient of recipients) {
        const originUserId = getOriginUserId(recipient, selectedChatSendChannel);
        const requestData = {
          chatId: selectedChatId,
          from: sender,
          to: recipient,
          type: chatMessageType,
          text: caption,
          media,
          ...(!!thumbnail && { thumbnail }),
          origin: selectedChatSendChannel,
          ...(originUserId && { originUserId }),
          ...(botObject && {
            botId: botObject.id,
            botUsername: botObject.username,
          })
        };

        await feathers.service('chat-messages').create(requestData);
      }
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [selectedChatObject, selectedChatSendChannel, setGlobalErrorMessage]);

  const hasUnreadChats = useMemo(() => {
    return formattedChats.some((chat) => chat.unreadCount > 0);
  }, [formattedChats]);

  return {
    chats: formattedChats,
    getChats,
    fetchMore,
    total,
    selectedChatId,
    setSelectedChatId,
    searchText,
    setSearchText,
    isIdle: status === 'idle',
    selectedChatUnreadCount,
    selectedChatSendChannel,
    setSelectedChatSendChannel,
    selectedChatAvailableSendChannels,
    distributionDomain,
    sendTextMessage,
    sendMediaMessage,
    hasUnreadChats,
  };
}

function getSender(chatObject) {
  const { participants = [], companyId } = chatObject;
  const systemId = companyId;
  const systemIdType = 'system';

  const systemParticipant = participants.find(
    (participant) => participant.id === systemId && participant.type === systemIdType
  );

  const { id, type } = systemParticipant || {};

  return {
    id,
    type,
  };
}

function getRecipients(chatObject) {
  const { participants = [], companyId } = chatObject;
  const systemId = companyId;
  const systemIdType = 'system';

  const recipients = participants.filter(
    (participant) => participant.id !== systemId || participant.type !== systemIdType
  );

  const mappedRecipients = recipients.map((recipient) => {
    const { id, type, externalUserId } = recipient;
    return {
      id,
      type,
      externalUserId,
    };
  });

  return mappedRecipients;
}

function getOriginUserId(participant, selectedChatSendChannel) {
  const { externalUserId } = participant;
  const { telegram, whatsapp, line } = externalUserId || {};

  if (selectedChatSendChannel === 'telegram' && telegram) {
    return telegram;
  }

  if (selectedChatSendChannel === 'whatsapp' && whatsapp) {
    return whatsapp;
  }

  if (selectedChatSendChannel === 'line' && line) {
    return line;
  }

  return null;
}