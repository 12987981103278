import BankBotContext from './bankBotContext';
import React from 'react';
import useFeathers from 'hooks/useFeathers';

function BankBotProvider({ children }) {
  const queryFetchAll = {
    query: {
      $limit: -1
    }
  };

  const { data: bankBots, ready: bankBotsReady } = useFeathers('bank-bots', queryFetchAll);

  return (
    <BankBotContext.Provider value={{
      bankBots,
      bankBotsReady,
      }}>
      {children}
    </BankBotContext.Provider>
  );
}

export default BankBotProvider;
