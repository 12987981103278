import isUrl from 'is-url';
import { Transforms } from 'slate'

export default function withImages(editor) {
  const { insertData, isVoid } = editor;

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');
    //const { files } = data;

    if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const isImageUrl = url => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  return true;
};

const createImageNode = (url) => ({
  type: "image",
  url: url,
  children: [{ text: '' }],
});

const createImageIdNode = (imageId) => ({
  type: "image",
  id: imageId,
  children: [{ text: '' }],
});

export const insertImage = (editor, url) => {
  const imageNode = createImageNode(url);
  Transforms.insertNodes(editor, imageNode);
};

export const insertImageId = (editor, imageId) => {
  const imageNode = createImageIdNode(imageId);
  Transforms.insertNodes(editor, imageNode);
};
