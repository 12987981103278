import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { sortBy, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Decimal from 'decimal.js';
import { mergeDataItems } from './utils';

const HOURLY_GAME_TYPES = [
  'crown128',
  'lc88',
  'lpe88',
  'ntc33',
  'rollex11',
];

export default function GameDialog(props) {
  const { t } = useTranslation();
  const { onClose, open, data = [], gameType } = props;

  const isHourlyType = useMemo(
    () => {
      return HOURLY_GAME_TYPES.includes(gameType);
    }, [gameType]
  );

  const handleClose = () => {
    onClose();
  };

  const [checked, setChecked] = useState([]);

  const logs = useMemo(() => {
    if (!data?.length) return [];

    const mergedData = mergeDataItems(data, {
      isHourlyType
    });
    return sortBy(mergedData, 'gameName');
  }, [data, isHourlyType]);

  useEffect(() => {
    if (!logs?.length) return;
    setChecked([...Array(logs.length).keys()]);
  }, [logs]);

  const sumAmount = useMemo(
    () => {
      let sumTurnover = new Decimal(0);
      let sumWinLose = new Decimal(0);
      let sumCount = new Decimal(0);

      for (let i = 0; i < checked.length; i++) {
        if (!logs?.length) break;

        const selectedIndex = checked[i];
        const log = get(logs, `[${selectedIndex}]`);

        const decBetAmount = new Decimal(get(log, 'totalBet', '0'));
        const decTotalWinLose = new Decimal(get(log, 'totalWinLose', '0'));
        const decCount = new Decimal(get(log, 'count', '0'));
        sumTurnover = sumTurnover.plus(decBetAmount);
        sumWinLose = sumWinLose.plus(decTotalWinLose);
        sumCount = sumCount.plus(decCount);
      }

      const average = sumCount.gt(0) ? sumTurnover.dividedBy(sumCount).toFixed(2) : '0.00';

      return {
        turnover: sumTurnover.toFixed(2),
        winLose: sumWinLose.toFixed(2),
        count: sumCount.toNumber(),
        average
      }
    }, [checked, logs]
  );

  const isAllSelected = useMemo(
    () => {
      return checked?.length === logs?.length;
    }, [checked, logs]
  );

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = (event) => {
    event?.preventDefault();
    setChecked([...Array(logs.length).keys()]);
  };

  const handleSelectNone = (event) => {
    event?.preventDefault();
    setChecked([]);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t('Game Details')}</DialogTitle>
      <DialogContent dividers>
        <Paper elevation={8} sx={{ p: 1, mb: 2 }}>
          <Grid container sx={{ textAlign: 'center' }}>
            <Grid item xs={6}>
              <Typography variant='subtitle2' gutterBottom>
                {t('Turnover')}
              </Typography>
              <Typography variant='h4' gutterBottom>
                {sumAmount.turnover}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle2' gutterBottom>
                {t('W/L')}
              </Typography>
              <Typography variant='h4' gutterBottom>
                {sumAmount.winLose}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle2' gutterBottom>
                {t('Game Count')}
              </Typography>
              <Typography variant='h4' gutterBottom>
                {sumAmount.count}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle2' gutterBottom>
                {t('Average / Game')}
              </Typography>
              <Typography variant='h4' gutterBottom>
                {sumAmount.average}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <Stack direction='row' spacing={1}>
            <Chip size='small' label={t('Count')} color='secondary' />
            <Chip size='small' label={t('Turnover')} color='info' />
            <Chip size='small' label={t('We Lose')} color='error' />
            <Chip size='small' label={t('We Win')} color='success' />
          </Stack>
        </Box>
        <Paper variant='outlined' sx={{ p: 1, display: logs?.length ? 'block' : 'none' }}>
          <List sx={{ pt: 0 }}>
            {
              logs.map((game, index) => {
                const { category, gameName, totalBet, totalWinLose, count, gameCategory } = game;
                const displayCategory = gameCategory ? t(`gameCategories.${gameCategory}`) : category;
                const totalBetStr = new Decimal(totalBet).toFixed(2);
                const decTotalWinLose = new Decimal(totalWinLose);
                return (
                  <ListItem
                    key={game?.gameName}
                    disablePadding
                  >
                    <ListItemButton dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(index) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': gameName }}
                          onClick={handleToggle(index)}
                        />
                      </ListItemIcon>
                      <ListItemText sx={{ userSelect: 'text' }} primary={`${gameName}`} secondary={displayCategory} />
                    </ListItemButton>
                    <Stack direction='row' spacing={1}>
                      <Chip size='small' label={count} color='secondary' />
                      <Chip size='small' label={totalBetStr} color='info' />
                      <Chip size='small' label={decTotalWinLose.toFixed(2)} color={decTotalWinLose.gt(0) ? 'error' : 'success'} />
                    </Stack>
                  </ListItem>
                );
              })
            }
          </List>
        </Paper>
      </DialogContent>
      <DialogActions>
        {
          isAllSelected ?
          <Button onClick={handleSelectNone}>
            {t('Select None')}
          </Button> :
          <Button onClick={handleSelectAll}>
            {t('Select All')}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

GameDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
