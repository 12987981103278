import React, { useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { AbilityContext } from 'casl/Can';
import {
  FormControl,
  InputLabel,
  Select
} from '@mui/material';

export default function FormikSelect(props) {
  const [ field, meta ] = useField(props);
  const { initialValues } = useFormikContext();
  const ability = useContext(AbilityContext);

  const { caslSubject = '', _id } = initialValues;
  const { name: fieldName } = field;

  const action = _id === undefined ? 'create' : 'update';
  const isAllowed = ability.can(action, caslSubject, fieldName);
  const hasError = meta.touched && Boolean(meta.error);
  const labelId = `${fieldName}-select-label`;

  const { fullWidth = false, margin, ...rest } = props;

  return (
    <FormControl
      fullWidth={Boolean(fullWidth)}
      {
        ...(margin !== undefined && { margin })
      }
      error={hasError}
    >
      <InputLabel id={labelId}>{props.label}</InputLabel>
      <Select
        {...field}
        {...rest}
        disabled={isAllowed === false}
        labelId={labelId}
        label={props.label}
      />
    </FormControl>
  );
}