import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';

const _RNAME = 'companySettings';

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
      };
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    companyId: Yup.string().nullable(),
    country: Yup.string().nullable(),
    supportedCountryCodes: Yup.object().shape({
      $in: Yup.array().of(Yup.string().matches(/(60|65|61|64|66)/)).nullable(),
    }),
    telegramNotification: Yup.object().shape({
      token: Yup.string().nullable(),
      chatIds: Yup.string().nullable()
    }),
    walletConfig: Yup.object().shape({
      walletHost: Yup.string().nullable(),
    }),
    socialChannels: Yup.object().shape({
      facebookLink: Yup.string().nullable(),
      telegramLink: Yup.string().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='companyId'
                name='companyId'
                label={t('Company ID')}
                value={get(formik, 'values.companyId', 'None')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.companyId`, false) && Boolean(get(formik, `errors.companyId`))}
                helperText={get(formik, `touched.companyId`, false) && get(formik, `errors.companyId`)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                  fullWidth
                  error={get(formik, 'touched.country', false) && Boolean(get(formik, 'errors.country'))}
                >
                  <InputLabel id='country-select-label'>{t('Country')}</InputLabel>
                  <Select
                    autoWidth
                    labelId='country-select-label'
                    id='country'
                    name='country'
                    value={get(formik, 'values.country', ['None'])}
                    label={t('Country')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                    {
                      ['my', 'sg', 'au', 'nz', 'th'].map(key => (
                        <MenuItem key={key} value={key}>
                          {t(`country-${key}`)}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText>{get(formik, 'touched.country', false) && get(formik, 'errors.country')}</FormHelperText>
                </FormControl>
            </Grid>
           <Grid item xs={6}>
              <FormControl
                  fullWidth
                  error={get(formik, 'touched.supportedCountryCodes.$in', false) && Boolean(get(formik, 'errors.supportedCountryCodes.$in'))}
                >
                  <InputLabel id='supported-country-codes-select-label'>{t('Supported Country Codes')}</InputLabel>
                  <Select
                    autoWidth
                    labelId='supported-country-codes-select-label'
                    multiple
                    id='supportedCountryCodes.$in'
                    name='supportedCountryCodes.$in'
                    value={get(formik, 'values.supportedCountryCodes.$in', [])}
                    label={t('Supported Country Codes')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                    {
                      ['60', '61', '64', '65', '66'].map(key => (
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText>{get(formik, 'touched.supportedCountryCodes.$in', false) && get(formik, 'errors.supportedCountryCodes.$in')}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='telegramNotification.token'
                name='telegramNotification.token'
                label={t('Telegram Notification Token')}
                value={get(formik, 'values.telegramNotification.token', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.telegramNotification.token`, false) && Boolean(get(formik, `errors.telegramNotification.token`))}
                helperText={get(formik, `touched.telegramNotification.token`, false) && get(formik, `errors.telegramNotification.token`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='telegramNotification.chatIds'
                name='telegramNotification.chatIds'
                label={t('Telegram Notification Chat IDs')}
                value={get(formik, 'values.telegramNotification.chatIds', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.telegramNotification.chatIds`, false) && Boolean(get(formik, `errors.telegramNotification.chatIds`))}
                helperText={get(formik, `touched.telegramNotification.chatIds`, false) && get(formik, `errors.telegramNotification.chatIds`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='walletConfig.walletHost'
                name='walletConfig.walletHost'
                label={t('Wallet Host')}
                value={get(formik, 'values.walletConfig.walletHost', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.walletConfig.walletHost`, false) && Boolean(get(formik, `errors.walletConfig.walletHost`))}
                helperText={get(formik, `touched.walletConfig.walletHost`, false) && get(formik, `errors.walletConfig.walletHost`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='socialChannels.facebookLink'
                name='socialChannels.facebookLink'
                label={t('Facebook Link')}
                value={get(formik, 'values.socialChannels.facebookLink', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, 'touched.socialChannels.facebookLink', false) && Boolean(get(formik, 'errors.socialChannels.facebookLink'))}
                helperText={get(formik, 'touched.socialChannels.facebookLink', false) && get(formik, 'errors.socialChannels.facebookLink')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id='socialChannels.telegramLink'
                name='socialChannels.telegramLink'
                label={t('Telegram Link')}
                value={get(formik, 'values.socialChannels.telegramLink', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, 'touched.socialChannels.telegramLink', false) && Boolean(get(formik, 'errors.socialChannels.telegramLink'))}
                helperText={get(formik, 'touched.socialChannels.telegramLink', false) && get(formik, 'errors.socialChannels.telegramLink')}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$gte'
                  label={t('Updated From')}
                  value={get(formik, 'values.updatedAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$gte', '');
                    else formik.setFieldValue('updatedAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$gte', false) && Boolean(get(formik, 'errors.updatedAt.$gte'))}
                  helperText={get(formik, 'touched.updatedAt.$gte', false) && get(formik, 'errors.updatedAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$lt'
                  label={t('Updated To')}
                  value={get(formik, 'values.updatedAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$lt', '');
                    else formik.setFieldValue('updatedAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$lt', false) && Boolean(get(formik, 'errors.updatedAt.$lt'))}
                  helperText={get(formik, 'touched.updatedAt.$lt', false) && get(formik, 'errors.updatedAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleSave}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
