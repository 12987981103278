import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { transformSavedData } from 'utils/form-utils';
import {
  FormikTextField,
  FormikSwitch,
} from 'features/formikControl';

const CASL_SUBJECT = 'corsOrigins';
const SERVICE_NAME = 'cors-origins';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        isEnabled: true,
      }
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    origin: Yup.string().required(t('Required')),
    description: Yup.string().nullable(),
    isEnabled: Yup.boolean().required(t('Required')),
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('CORS Origin')}</DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const _id = get(data, '_id');

            if (!_id) {
              const { _id, ...newValues } = values;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              await feathers.service(SERVICE_NAME).patch(_id, {
                $set: values
              });
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
                <Paper sx={{ p: 2 }} elevation={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormikTextField
                        fullWidth
                        label={t('Origin')}
                        name="origin"
                        value={values.origin || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.origin && touched.origin}
                        helperText={errors.origin && touched.origin && errors.origin}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        fullWidth
                        label={t('Description')}
                        name="description"
                        value={values.description || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.description && touched.description}
                        helperText={errors.description && touched.description && errors.description}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikSwitch
                        label={t('Enabled')}
                        name="isEnabled"
                        value={values.isEnabled || false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.isEnabled && touched.isEnabled}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
