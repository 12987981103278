import { useEffect, useState, useCallback } from 'react';
import feathers from 'services/feathers';
import { uniqBy } from 'lodash';
import dayjs from 'dayjs';

export default function useCaptcha() {
  const service = feathers.service(`captcha`);
  const [ requests, setRequests ] = useState([]);

  function isExpired(request) {
    const createdAt = dayjs(request?.createdAt).isValid() ? dayjs(request.createdAt) : dayjs().minus(1, 'hour');
    return dayjs().diff(createdAt, 's') >= 15 ? true : false;
  }

  const removeRequest = useCallback(
    (captchaId) => {
      setRequests(prev => {
        return prev.filter(req => {
          return (!isExpired(req) && req?.captchaId !== captchaId)
        });
      });
    }, []
  );

  useEffect(() => {
    const onCaptchaRequest = (data) => {
      setRequests(prev => {
        const activeRequest = prev.filter(req => !isExpired(req));
        return uniqBy([...activeRequest, data], 'captchaId');
      });
    };

    const onCaptchaResponse = (data) => {
      removeRequest(data?.captchaId);
    };

    service.on('request', onCaptchaRequest);
    service.on('response', onCaptchaResponse);

    return () => {
      service.removeListener('request', onCaptchaRequest);
      service.removeListener('response', onCaptchaResponse);
    };
  }, [service, removeRequest]);

  return {
    requests,
    requestCount: requests?.length || 0
  };
}
