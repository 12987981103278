import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {
  DeleteTwoTone as DeleteIcon,
  EditTwoTone as EditIcon,
  FileCopyTwoTone as CopyIcon,
  PreviewTwoTone as PreviewIcon,
  ToggleOffTwoTone as ToggleOffIcon,
  ToggleOnTwoTone as ToggleOnIcon,
} from '@mui/icons-material';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import LuckyWheelPreview from './LuckyWheelPreview';
import PromptContext from 'features/context/promptContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'luckyWheels';
const table = createColumnHelper();

export default function LuckyWheels() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ previewData, setPreviewData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const { openPrompt } = useContext(PromptContext);

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor('name', {
          id: 'name',
          cell: info => info.getValue(),
          header: () => t('Name'),
          footer: props => props.column.id,
        }),
        table.accessor('isEnabled', {
          id: 'isEnabled',
          cell: info => {
            const val = info.getValue();
            const color = val === false ? 'error.main' : 'inherit';
            const fontWeight = val === false ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('Enabled'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleRowCopy = (data) => (event) => {
    event.preventDefault();
    const { _id, name, ...rest } = data;
    setEditData({
      ...rest,
      name: `${name} - ${t('Copy')} #${dayjs().unix()}`,
    });
    setOpenForm(true);
  }

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();

    async function deleteRow() {
      try {
        await feathers.service(serviceName).remove(rowId);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    async function confirmationPrompt() {
      const result = await openPrompt({
        title: t('Delete Lucky Wheel'),
        message: t('Confirm to delete?'),
        confirmColor: 'error',
        cancelColor: 'inherit',
      });

      if (!result) return;

      deleteRow();
    }

    confirmationPrompt();
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  const handleIsEnabledToggle = useCallback((data) => async (event) => {
    event?.preventDefault();
    const { _id: rowId, isEnabled = true } = data;

    try {
      await feathers.service(serviceName).patch(rowId, {
        isEnabled: !isEnabled,
      });
      setGlobalMessage({
        message: t('Saved'),
        severity: 'success'
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [serviceName, setGlobalMessage, setGlobalErrorMessage, t]);

  function RowActions(props) {
    const data = get(props, 'row.original');
    const { _id: rowId, isEnabled } = data;
    const canUpdateIsEnabled = ability.can('update', subject(_RNAME, { ...data }), 'isEnabled');
    const canUpdate = ability.can('update', subject(_RNAME, { ...data }));
    const canDelete = ability.can('delete', subject(_RNAME, { ...data }));

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Edit')}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Copy')}>
          <span>
            <IconButton disabled={!canCreate} onClick={handleRowCopy(data)} color='primary'>
              <CopyIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={isEnabled ? t("Disable") : t("Enable")}>
          <span>
           <IconButton disabled={!canUpdateIsEnabled} onClick={handleIsEnabledToggle(data)} color='primary'>
              {
                canUpdateIsEnabled ? (
                  isEnabled ? <ToggleOnIcon color='success' /> : <ToggleOffIcon color='error' />
                ) : (
                  isEnabled ? <ToggleOnIcon /> : <ToggleOffIcon />
                )
              }
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Preview')}>
          <span>
            <IconButton onClick={() => setPreviewData({
              background: data.background,
              backgroundImage: data.backgroundImage,
              blocks: data.blocks,
              prize: data.prize,
              config: data.config,
            })} color='primary'>
              <PreviewIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <Dialog open={!!previewData} onClose={() => setPreviewData(null)}>
        <DialogTitle>{t('Preview')}</DialogTitle>
        <DialogContent>
          <LuckyWheelPreview data={previewData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewData(null)} color='primary'>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      {
        !!defaultColumns.length &&
        <Table
          name={t('Lucky Wheels')}
          rname={_RNAME}
          defaultColumns={defaultColumns}
          canCreate={canCreate}
          onCreateClick={handleOnCreateClick}
          onFilterClick={handleOnFilterClick}
          defaultColumnVisibility={{
            _id: false,
          }}
        />
      }
    </Box>
  );
}
