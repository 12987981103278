import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { transformSavedData, formatValuesBeforeSave } from 'utils/form-utils';
import { Tab, Tabs } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject } from 'utils/yup-helper';
import {
  FormikTextField,
  FormikAvatar,
  FormikTimePicker,
  FormikSwitch,
  FormikSelect,
} from 'features/formikControl';

const CASL_SUBJECT = 'customerServices';
const SERVICE_NAME = 'customer-services';

const _TAB_INDEX = {
  'general': 0,
  'shift': 1,
};

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const [ tabIndex, setTabIndex ] = useState(0);
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        isEnabled: true,
        shift: {
          days: [1, 2, 3, 4, 5, 6, 7]
        },
        onShift: false,
      }
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    name: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t('Required'))),
    status: Yup.object().shape(getLocaleYupObject(supportedLocales)),
    avatar: Yup.string().nullable(),
    phoneNumber: Yup.string().matches(/^[0-9]{11,12}$/, t('Phone number with country code')).nullable(),
    whatsapp: Yup.string().matches(/^[0-9]{11,12}$/, t('Phone number with country code')).nullable(),
    telegram: Yup.string().nullable(),
    line: Yup.string().nullable(),
    isEnabled: Yup.bool().required(t("Required")),
    shift: Yup.object().shape({
      startsAt: Yup.date().nullable(),
      endsAt: Yup.date().nullable(),
      days: Yup.array().of(Yup.number().integer()),
    }),
    onShift: Yup.bool().required(t("Required")),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Customer Service')}</DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const _id = get(data, '_id');

            if (!_id) {
              const { _id, ...newValues } = values;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              const formattedSaveValues = formatValuesBeforeSave(values, {
                unsetKeysIfEmpty: [
                  'avatar',
                  'phoneNumber',
                  'whatsapp',
                  'telegram',
                  'line',
                ],
              });

              await feathers.service(SERVICE_NAME).patch(_id, formattedSaveValues);
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label={t('General')} />
                  <Tab label={t('Shift')} />
                </Tabs>
                {
                  tabIndex === _TAB_INDEX.general &&
                  <Paper sx={{ p: 2 }} elevation={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <FormikAvatar
                          id='avatar'
                          name='avatar'
                          label={t('Avatar')}
                          value={get(values, 'avatar', '')}
                          //variant='rounded'
                          sx={{ width: '5rem', height: '5rem', my: 1 }}
                        />
                      </Grid>
                      {
                        supportedLocales.map(locale => {
                          const nameKey = `name.${locale}`;
                          const statusKey = `status.${locale}`;

                          return (
                            <React.Fragment key={locale}>
                              <Grid item xs={6}>
                                <FormikTextField
                                  fullWidth
                                  id={nameKey}
                                  name={nameKey}
                                  label={t(nameKey)}
                                  value={get(values, nameKey, '')}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormikTextField
                                  fullWidth
                                  id={statusKey}
                                  name={statusKey}
                                  label={t(statusKey)}
                                  value={get(values, statusKey, '')}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </React.Fragment>
                          )
                        })
                      }
                      <Grid item xs={12}>
                        <FormikTextField
                          fullWidth
                          type='text'
                          inputProps={{ inputMode: 'numeric' }}
                          inputMode='numeric'
                          id='phoneNumber'
                          name='phoneNumber'
                          label={t('Phone Number')}
                          value={get(values, 'phoneNumber', '')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='60123456789'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          fullWidth
                          type='text'
                          inputProps={{ inputMode: 'numeric' }}
                          inputMode='numeric'
                          id='whatsapp'
                          name='whatsapp'
                          label={t('WhatsApp')}
                          value={get(values, 'whatsapp', '')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder='60123456789'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          fullWidth
                          id='telegram'
                          name='telegram'
                          label={t('Telegram')}
                          value={get(values, 'telegram', '')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          fullWidth
                          id='line'
                          name='line'
                          label={'Line'}
                          value={get(values, 'line', '')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikSwitch
                          id='isEnabled'
                          name='isEnabled'
                          label={t('Enabled')}
                          value={get(values, 'isEnabled', false)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                }
                {
                  tabIndex === _TAB_INDEX.shift &&
                  <Paper sx={{ p: 2 }} elevation={0}>
                    <Grid container spacing={2}>
                     <Grid item xs={12}>
                      <FormikTimePicker
                        views={['hours', 'minutes', 'seconds']}
                        inputFormat="HH:mm:ss"
                        id='shift.startsAt'
                        name='shift.startsAt'
                        label={t('Shift Hour Starts')}
                        value={get(values, 'shift.startsAt', null)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTimePicker
                        views={['hours', 'minutes', 'seconds']}
                        inputFormat="HH:mm:ss"
                        id='shift.endsAt'
                        name='shift.endsAt'
                        label={t('Shift Hour Ends')}
                        value={get(values, 'shift.endsAt', null)}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <FormikSelect
                        fullWidth
                        id='shift.days'
                        name='shift.days'
                        label={t('Shift Days')}
                        value={get(values, 'shift.days', [])}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiple
                      >
                        <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                        {
                          Array.from(Array(7).keys()).map((day) => {
                            const dayValue = day + 1;
                            const dayKey = `day_${dayValue}`;
                            return (
                              <MenuItem key={dayKey} value={dayValue}>
                                {t(dayKey)}
                              </MenuItem>
                            );
                          })
                        }
                      </FormikSelect>
                    </Grid>
                    <Grid item xs={12}>
                      <FormikSwitch
                        id='onShift'
                        name='onShift'
                        label={t('On Shift')}
                        value={get(values, 'onShift', false)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
