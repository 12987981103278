import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import BankContext from 'features/context/bankContext';
import Decimal from 'decimal.js';
import PlusIcon from '@mui/icons-material/AddCircle';
import MinusIcon from '@mui/icons-material/RemoveCircle';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { getRemarks } from 'features/feathersStore/selectors';
import Autocomplete from '@mui/material/Autocomplete';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';

const _RNAME = 'bankAdjustments';

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const { banks, banksReady, bankData, bankDataReady } = useContext(BankContext);
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));
  const remarks = useSelector(getRemarks(_RNAME));

  const bankOptions = useMemo(
    () => {
      if (!banksReady || !bankDataReady) return [];

      const banksWithBalance = banks.map(b => {
        const d = bankData.find(bd => (bd.accountNumber === b.accountNumber));
        const balance = new Decimal(get(d, 'balance.$numberDecimal', '0')).toFixed(2);
        b.balance = balance;
        return b;
      });

      return banksWithBalance;
    }, [banks, banksReady, bankData, bankDataReady]
  );

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
        accountNumber: 'None'
      };
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    accountNumber: Yup.string().nullable(),
    type: Yup.string().nullable(),
    adjustmentType: Yup.object().shape({
      $in: Yup.array().of(Yup.string()).nullable(),
    }),
    amount: Yup.number().nullable(),
    remark: Yup.string().nullable(),
    updatedAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
    createdAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
           <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.accountNumber', false) && Boolean(get(formik, 'errors.accountNumber'))}
              >
                <InputLabel id='bank-select-label'>{t('Company Bank')}</InputLabel>
                <Select
                  autoWidth
                  labelId='bank-select-label'
                  id='accountNumber'
                  name='accountNumber'
                  value={get(formik, 'values.accountNumber', 'None')}
                  label={t('Company Bank')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    bankOptions.map(b => (
                      <MenuItem key={b._id} value={b.accountNumber}>
                        {b.name}
                        <Chip color='secondary' size='small' sx={{ fontWeight: 700, borderRadius: 1, ml: 1 }} label={b.balance} />
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.companyBank.accountNumber', false) && get(formik, 'errors.companyBank.accountNumber')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  formik.setFieldValue('type', formik.values.type === 'plus' ? null : 'plus');
                }}
                size='large'
                fullWidth
                variant={ formik.values.type === 'plus' ? 'contained' : 'outlined' }
                color={ formik.values.type === 'plus' ? 'success' : 'primary' }
                startIcon={<PlusIcon />}
              >
                {t('Plus')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  formik.setFieldValue('type', formik.values.type === 'minus' ? null : 'minus');
                }}
                size='large'
                fullWidth
                variant={ formik.values.type === 'minus' ? 'contained' : 'outlined' }
                color={ formik.values.type === 'minus' ? 'error' : 'primary' }
                startIcon={<MinusIcon />}
              >
                {t('Minus')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.adjustmentType.$in', false) && Boolean(get(formik, 'errors.adjustmentType.$in'))}
              >
                <InputLabel id='adjustment-type-select-label'>{t('Adjustment Type')}</InputLabel>
                <Select
                  autoWidth
                  labelId='adjustment-type-select-label'
                  id='adjustmentType.$in'
                  name='adjustmentType.$in'
                  value={get(formik, 'values.adjustmentType.$in', [])}
                  label={t('Adjustment Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    [
                      'capital',
                      'commission',
                      'correction',
                      'exchange',
                      'expense',
                      'payroll',
                      'transaction',
                      'transfer',
                    ].map(at => (
                      <MenuItem key={at} value={at}>
                        {t(at)}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.adjustmentType.$in', false) && get(formik, 'errors.adjustmentType.$in')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='amount'
                name='amount'
                label={t('Amount')}
                value={get(formik, 'values.amount', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, 'touched.amount', false) && Boolean(get(formik, 'errors.amount'))}
                helperText={get(formik, 'touched.amount', false) && get(formik, 'errors.amount')}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                id="autocomplete-remark"
                value={get(formik, 'values.remark', '')}
                onChange={formik.handleChange}
                disableClearable
                autoSelect
                options={remarks || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id='remark'
                    name='remark'
                    label={t('Remark')}
                    value={get(formik, 'values.remark', '')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={get(formik, `touched.remark`, false) && Boolean(get(formik, `errors.remark`))}
                    helperText={get(formik, `touched.remark`, false) && get(formik, `errors.remark`)}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$gte'
                  label={t('Updated From')}
                  value={get(formik, 'values.updatedAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$gte', '');
                    else formik.setFieldValue('updatedAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$gte', false) && Boolean(get(formik, 'errors.updatedAt.$gte'))}
                  helperText={get(formik, 'touched.updatedAt.$gte', false) && get(formik, 'errors.updatedAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$lt'
                  label={t('Updated To')}
                  value={get(formik, 'values.updatedAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$lt', '');
                    else formik.setFieldValue('updatedAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$lt', false) && Boolean(get(formik, 'errors.updatedAt.$lt'))}
                  helperText={get(formik, 'touched.updatedAt.$lt', false) && get(formik, 'errors.updatedAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleSave}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
