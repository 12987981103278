import { lookups as lookupCountries } from 'lookups/countries';
import MenuItem from '@mui/material/MenuItem';

export function getCountryOptions() {
  function renderMenuItem([key, label]) {
    return (
      <MenuItem key={key} value={key}>
        {label}
      </MenuItem>
    );
  }

  return Object.entries(lookupCountries).map(renderMenuItem);
}
