import PromptContext from './promptContext';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

function PromptProvider({ children }) {
  const [ open, setOpen ] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [confirmLabel, setConfirmLabel] = useState('Confirm');
  const [cancelLabel, setCancelLabel] = useState('Cancel');
  const [confirmColor, setConfirmColor] = useState('primary');
  const [cancelColor, setCancelColor] = useState('default');
  const [ defaultTextFieldValue, setDefaultTextFieldValue ] = useState('');
  const [ resultResolve, setResultResolve ] = useState(null);
  const { t } = useTranslation();

  const openPrompt = useCallback((options = {}) => {
    const {
      message = t('Are you sure?'),
      title = t('Confirmation'),
      confirmLabel = t('Confirm'),
      cancelLabel = t('Cancel'),
      confirmColor = 'primary',
      cancelColor = 'inherit',
      defaultTextFieldValue = undefined,
    } = options;

    setMessage(message);
    setTitle(title);
    setConfirmLabel(confirmLabel);
    setCancelLabel(cancelLabel);
    setConfirmColor(confirmColor);
    setCancelColor(cancelColor);
    setDefaultTextFieldValue(defaultTextFieldValue);
    setOpen(true);

    return new Promise((resolve) => {
      setResultResolve(() => resolve);
    });
  }, [t]);

  const closePrompt = () => {
    setOpen(false);
    setTitle('');
    setMessage('');
    setDefaultTextFieldValue('');
    setResultResolve(null);
  };

  const onConfirm = useCallback((value) => {
    if (resultResolve) resultResolve(value);
    closePrompt();
  }, [resultResolve]);

  const onClose = useCallback(() => {
    if (resultResolve) resultResolve(null);
    closePrompt();
  }, [resultResolve]);

  return (
    <PromptContext.Provider value={{
      openPrompt,
      onConfirm,
      onClose,
      message,
      title,
      confirmLabel,
      cancelLabel,
      confirmColor,
      cancelColor,
      defaultTextFieldValue,
      open,
    }}>
      {children}
    </PromptContext.Provider>
  );
}

export default PromptProvider;
