import React, { useMemo, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line } from 'recharts';
import { get } from 'lodash';
import Decimal from 'decimal.js';
import {
  green,
  red,
  blue,
  cyan,
  purple,
  deepPurple,
} from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

export default function RecentCashTransactions({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [ unit, setUnit ] = useState('month');

  const isDarkPalette = useMemo(
    () => {
      return get(theme, 'palette.mode', 'light') === 'dark' ? true : false;
    }, [theme]
  );

  const graphColorTone = useMemo(
    () => {
      return isDarkPalette ? 300 : 600;
    }, [isDarkPalette]
  );

  const startDate = useMemo(
    () => {
      switch (unit) {
        case 'day':
          return dayjs().subtract(14, 'd').startOf('d');

        case 'week':
          return dayjs().subtract(6, 'w').startOf('w');

        case 'month':
          return dayjs().subtract(6, 'M').startOf('M');

        case 'year':
          return dayjs().subtract(6, 'y').startOf('y');

        default:
          return dayjs().subtract(6, 'y').startOf('y');
      }
    }, [unit]
  );

  const dateFormat = useMemo(
    () => {
      switch (unit) {
        case 'day':
          return 'MM-DD';

        case 'week':
          return 'w';

        case 'month':
          return 'MMM';

        case 'year':
          return 'YYYY';

        default:
          return 'MM-DD';
      }
    }, [unit]
  );

  const chartData = useMemo(() => {
    const { timezone: companyTimezone = 'UTC' } = data || {};

    const recentDataKey = [
      'recentCashIn.deposits',
      'recentCashIn.walletAdjustments',
      'recentCashOut.withdrawals',
      'recentCashOut.walletAdjustments',
      'recentBonus.dailySlotLossRebates',
      'recentBonus.dailySlotTurnoverRebates',
      'recentBonus.dailyLiveTurnoverRebates',
      'recentBonus.pointRedemptions',
    ];

    const defaultData = Object.fromEntries(recentDataKey.map(key => [`${key}Amount`, 0]));

    const transformedData = recentDataKey.reduce((all, key) => {
      const recentData = get(data, `${key}.${unit}`, []);
      const items = recentData
        .filter(({ date }) => dayjs(date).utc().tz(companyTimezone, true).isAfter(startDate))
        .map(({ date, amount: { $numberDecimal = '0' } = {} }) => {
          const adjustedDate = dayjs(date).utc().tz(companyTimezone, true);
          return {
            unixTimestamp: adjustedDate.unix(),
            date: adjustedDate.format(dateFormat),
            [`${key}Amount`]: new Decimal($numberDecimal),
          };
        });

      return items.reduce((acc, item) => {
        const index = acc.findIndex(({ date }) => date === item.date);

        if (index === -1) {
          return [...acc, { ...defaultData, ...item }];
        }

        acc[index] = { ...acc[index], ...item };

        return acc;
      }, all);
    }, []);

    if (!transformedData) return defaultData;

    const sortedData = transformedData.slice().sort((a, b) => a.unixTimestamp - b.unixTimestamp);

    const dataWithRatio = sortedData.map((item) => {
      const cashInAmount = new Decimal(item['recentCashIn.depositsAmount'] || 0);
      const cashInAdjAmount = new Decimal(item['recentCashIn.walletAdjustments'] || 0);
      const totalCashInAmount = cashInAmount.plus(cashInAdjAmount);
      const cashRedemptionAmount = new Decimal(item['recentBonus.pointRedemptionsAmount'] || 0);
      const dailySlotLossRebateAmount = new Decimal(item['recentBonus.dailySlotLossRebatesAmount'] || 0);
      const dailySlotTurnoverRebateAmount = new Decimal(item['recentBonus.dailySlotTurnoverRebatesAmount'] || 0);
      const dailyLiveTurnoverRebateAmount = new Decimal(item['recentBonus.dailyLiveTurnoverRebatesAmount'] || 0);
      const cashRebateAmount = dailySlotLossRebateAmount.plus(dailySlotTurnoverRebateAmount).plus(dailyLiveTurnoverRebateAmount);

      const redemptionToDepositRatio = totalCashInAmount.gt(0)
        ? cashRedemptionAmount.dividedBy(totalCashInAmount) : new Decimal(0);

      const rebateToDepositRatio = totalCashInAmount.gt(0)
        ? cashRebateAmount.dividedBy(totalCashInAmount) : new Decimal(0);

      return {
        ...item,
        redemptionToDepositRatio,
        rebateToDepositRatio,
      };
    });

    return dataWithRatio;

  }, [data, unit, startDate, dateFormat]);


  const unitButtonVariant = useCallback(
    (u) => {
      return (unit === u) ? 'contained' : 'outlined';
    }, [unit]
  );

  const handleChartUnitChanged = (unit) => (event) => {
    event?.preventDefault();
    setUnit(unit);
  };

  const amountFormatter = (value, name) => {
    const translatedRedemptionRatioName = t('Redemption to Deposit Ratio');
    const translatedRebateRatioName = t('Rebate to Deposit Ratio');

    if ([translatedRedemptionRatioName, translatedRebateRatioName].includes(name)) return value.toFixed(4);
    return value.toFixed(2);
  };

  return (
     <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
        {t('Recent Cash Transactions')}
      </Typography>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup size='small'>
          <Button onClick={handleChartUnitChanged('day')} variant={unitButtonVariant('day')}>{t('Day')}</Button>
          <Button onClick={handleChartUnitChanged('week')} variant={unitButtonVariant('week')}>{t('Week')}</Button>
          <Button onClick={handleChartUnitChanged('month')} variant={unitButtonVariant('month')}>{t('Month')}</Button>
          <Button onClick={handleChartUnitChanged('year')} variant={unitButtonVariant('year')}>{t('Year')}</Button>
        </ButtonGroup>
      </Box>
      <Box sx={{ width: '100%', height: 280 }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke={theme.palette.divider} strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              formatter={amountFormatter}
              contentStyle={{
                borderRadius: '5px',
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary
              }}
            />
            <Legend />
            <Bar name={t('Deposits')} dataKey="recentCashIn.depositsAmount" barSize={10} fill={green[graphColorTone]} stackId="deposit" />
            <Bar name={t('Withdrawals')} dataKey="recentCashOut.withdrawalsAmount" barSize={10} fill={red[graphColorTone]} stackId="withdrawal" />
            <Bar name={t('Cash Adjustment +')} dataKey="recentCashIn.walletAdjustmentsAmount" barSize={10} fill={blue[graphColorTone]} stackId="deposit" />
            <Bar name={t('Cash Adjustment -')} dataKey="recentCashOut.walletAdjustmentsAmount" barSize={10} fill={cyan[graphColorTone]} stackId="withdrawal" />
            <Line name={t('Redemption to Deposit Ratio')} dataKey="redemptionToDepositRatio" stroke={purple[graphColorTone]} strokeWidth={2} />
            <Line name={t('Rebate to Deposit Ratio')} dataKey="rebateToDepositRatio" stroke={deepPurple[graphColorTone]} strokeWidth={2} />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
}
