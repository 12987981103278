const mandatoryFields = {
  // Transfer API
  'advant-play-api': ['customFields.gameUrl', 'customFields.brandCode', 'customFields.siteCode'],
  'awc-sexy-baccarat-api': ['assetDistributionDomain', 'customFields.gameIdPrefix', 'customFields.betLimit', 'customFields.dataApiUrl'],
  'bng-api': ['assetDistributionDomain', 'customFields.bucketName'],
  'bt-gaming-api': ['assetDistributionDomain', 'customFields.bucketName', 'customFields.gameIdPrefix'],
  'cq9-gaming-api': ['assetDistributionDomain'],
  'imperium-games-api': ['assetDistributionDomain', 'customFields.bucketName'],
  'jdb-api': ['customFields.domainName', 'customFields.initializationVector'],
  'jili-api': ['assetDistributionDomain', 'customFields.bucketName'],
  'joker-api': ['customFields.gameUrl'],
  'live22-metaverse-api': ['assetDistributionDomain', 'customFields.bucketName'],
  'mario-club-api': ['assetDistributionDomain', 'customFields.bucketName'],
  'microgaming-api': ['customFields.tokenUrl', 'assetDistributionDomain', 'customFields.bucketName'],
  'naga-games-api': ['customFields.hashKey', 'customFields.groupCode', 'customFields.brandCode'],
  'playtech-api': ['assetDistributionDomain', 'customFields.adminUsername', 'customFields.gameIdPrefix', 'customFields.integrationScriptUrl'],
  'pragmatic-play-api': ['customFields.providerId', 'customFields.dgaWebSocketUrl', 'customFields.casinoId'],
  'sa-gaming-api': ['assetDistributionDomain', 'customFields.encryptionKey', 'customFields.hashKey', 'customFields.dataApiUrl', 'customFields.gameUrl'],
  'spadegaming-api': ['assetDistributionDomain', 'customFields.gameUrl', 'customFields.bucketName'],

  // Seamless API
  'spribe-sapi': ['assetDistributionDomain', 'customFields.gameUrl', 'customFields.demoGameUrl'],
};

const allowedCurrencies = {
  // Transfer API
  'advant-play-api': ['MYR', 'THB', 'AUD'],
  'amb-pgsoft-api': ['THB'],
  'awc-sexy-baccarat-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'bng-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'bt-gaming-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'cq9-gaming-api': ['MYR', 'AUD', 'SGD'],
  'fa-chai-gaming-api': ['MYR', 'AUD', 'SGD'],
  'imperium-games-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'jdb-api': ['MYR', 'THB', 'AUD'],
  'jili-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'joker-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'live22-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'live22-metaverse-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'mario-club-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'microgaming-api': ['MYR', 'THB'],
  'naga-games-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'nextspin-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'playtech-api': ['MYR', 'THB'],
  'pragmatic-play-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'sa-gaming-api': ['MYR', 'THB', 'AUD', 'SGD'],
  'spadegaming-api': ['MYR', 'THB', 'AUD', 'SGD'],

  // Seamless API
  'spribe-sapi': ['MYR', 'THB', 'AUD', 'SGD'],
};

export {
  mandatoryFields,
  allowedCurrencies,
};
