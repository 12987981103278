import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

export default function AliasDialog(props) {
  const { onClose, open, data = [], title = '' } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {data.map((d, index) => (
            <ListItem key={d}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'info.light', color: 'info.dark' }}>
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={d} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

AliasDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
