import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { get, startCase } from 'lodash';

export default function ObjectDialog(props) {
  const { onClose, open, data = null, title = '' } = props;

  const handleClose = () => {
    onClose();
  };

  const items = useMemo(
    () => {
      if (!data) return [];
      return Object.entries(data) || [];
    }, [data]
  );

  function getPrimaryText(data) {
    const d0 = get(data, '[0]', '');
    const d1 = get(data, '[1]', '');
    if (d0 === 'location') {
      const lat = get(d1, 'coordinates[1]', '');
      const lon = get(d1, 'coordinates[0]', '');
      return `${lat}, ${lon}`;
    }
    return startCase(d1);
  }

  function getSecondaryText(data) {
    const d0 = get(data, '[0]', '');
    return startCase(d0);
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {
            items.map((d, index) => {
              return (
                <ListItem key={d[0]}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'info.light', color: 'info.dark' }}>
                      {index + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={getPrimaryText(d)} secondary={getSecondaryText(d)} />
                </ListItem>
              );
            })
          }
        </List>
      </DialogContent>
    </Dialog>
  );
}

ObjectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
