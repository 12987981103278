import { createContext } from 'react';

const BankContext = createContext({
  banks: [],
  banksReady: false,
  bankData: [],
  bankDataReady: false
});

export default BankContext;
