import React, { useRef, useLayoutEffect, useState, useContext, useMemo } from 'react';
import {
  Box,
  IconButton,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import CommonContext from '../context/commonContext';
import { useChats } from '../../hooks/useChats';
import { ProvideChatMessages } from '../../hooks/useChatMessages';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';

function ChatLayout() {
  const { t } = useTranslation();
  const chatContainerRef = useRef(null);
  const [ containerHeight, setContainerHeight ] = useState(0);
  const { appBarHeight } = useContext(CommonContext);
  const isMobile = useMediaQuery('(max-width:600px)');
  const { selectedChatId, setSelectedChatId } = useChats();

  useLayoutEffect(() => {
    if (chatContainerRef && chatContainerRef.current) {
      const containerTop = chatContainerRef.current.getBoundingClientRect().top;
      setContainerHeight(`calc(100vh - ${appBarHeight}px - ${containerTop}px - 24px)`);
    }
  }, [appBarHeight, chatContainerRef]);

  const showBackButton = useMemo(() => {
    return isMobile && selectedChatId;
  }, [isMobile, selectedChatId]);

  const showChatList = useMemo(() => {
    if (!isMobile) {
      return true;
    }
    return !selectedChatId;
  }, [isMobile, selectedChatId]);

  const showChatWindow = useMemo(() => {
    if (!isMobile) {
      return true;
    }
    return selectedChatId;
  }, [isMobile, selectedChatId]);

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Typography variant="h6">{t('Chats')}</Typography>
        {
          showBackButton && (
            <IconButton
              color='primary'
              onClick={() => setSelectedChatId(null)}
            >
              <ArrowBackIcon />
            </IconButton>
          )
        }
      </Box>

      <Grid
        container
        ref={chatContainerRef}
        spacing={1}
        sx={{
          height: containerHeight,
        }}
      >
        <Grid item xs={12} sm={5} lg={3} sx={{
          display: showChatList ? 'block' : 'none',
        }}>
          <ChatList />
        </Grid>
        <Grid item xs={12} sm={7} lg={9} sx={{
          display: showChatWindow ? 'block' : 'none',
        }}>
          <ChatWindow />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function Chats() {
  return (
    <ProvideChatMessages>
      <ChatLayout />
    </ProvideChatMessages>
  );
}