import flatten from 'flat';
import { set, unset, get } from 'lodash';
import dayjs from 'dayjs';

export function transformFilterFormData(values, isSafeFlatten = false) {
  const flattenVal = flatten(values, { safe: isSafeFlatten });

  // Remove empty properties
  function removeEmptyProperties(obj) {
    for (let key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        delete obj[key];
      } else if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        removeEmptyProperties(obj[key]);
      } else {
        if (obj[key] === 'None' || obj[key] === '') {
          delete obj[key];
        }
      }
    }
    return obj;
  }
  removeEmptyProperties(flattenVal);

  // Handle $gte and $lt key
  Object.entries(flattenVal).forEach(([key, val]) => {
    if (key.includes('.$gte') || key.includes('.$lt')) {
      const newKey = key.replace('.$gte', '[$gte]').replace('.$lt', '[$lt]');
      set(flattenVal, newKey, dayjs(val).format());
      delete flattenVal[key];
    }
  });

  // Handle $in key
  Object.entries(flattenVal).forEach(([key, val]) => {
    if (key.includes('.$in')) {
      const newKey = key.replace('.$in', '[$in]');
      set(flattenVal, newKey, val);
      delete flattenVal[key];
    }
  });

  return flattenVal;
}

export const unflatten = (data) => {
  const result = {};

  Object.keys(data).forEach((key) => {
    const keys = key.split('.');
    let obj = result;

    for (let i = 0; i < keys.length - 1; i++) {
      const k = keys[i];
      if (!obj.hasOwnProperty(k)) {
        obj[k] = {};
      }
      obj = obj[k];
    }

    obj[keys[keys.length - 1]] = data[key];
  });

  return result;
};

export function transformFilter(obj) {
  const result = {};
  for (let key in obj) {
    const value = obj[key];
    const parts = key.split(".");
    if (parts.length > 1) {
      const newKey = parts[0];
      const subObj = {};
      subObj[parts.slice(1).join(".")] = value;
      result[newKey] = transformFilter(subObj);
    } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      result[key] = transformFilter(value);
    } else {
      result[key] = value;
    }
  }
  return result;
}

/*export function transformSavedData(obj) {
  if (typeof obj === 'object' && obj !== null) {
    if ('$numberDecimal' in obj) {
      return obj['$numberDecimal'];
    }
    if (Array.isArray(obj)) {
      return obj.map(transformSavedData);
    }
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = transformSavedData(value);
      return acc;
    }, {});
  }
  return obj;
}*/

/**
 * Recursively transforms an object's data, converting $numberDecimal properties to their values
 * and joining the values of cashRewards and cashRewardWeights fields.
 *
 * @param {Object} obj - The object to transform.
 * @returns {any} - The transformed object.
 */
export function transformSavedData(obj, options = {}) {
  const { joinKeys = [], parentKey = [] } = options;

  if (typeof obj === 'object' && obj !== null) {
    if ('$numberDecimal' in obj) {
      return obj['$numberDecimal'];
    }
    if (Array.isArray(obj)) {
      return obj.map(transformSavedData);
    }
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const fullKey = [...parentKey, key].join('.');

      if (joinKeys.includes(fullKey)) {
        // Move the $numberDecimal value to the field's root before joining the array
        const arr = Array.isArray(value) ? value.map((item) => item?.$numberDecimal || item) : [];
        acc[key] = arr.join(', ');
      } else {
        acc[key] = transformSavedData(value, {
          ...options,
          parentKey: [
            ...parentKey,
            key,
          ]
        });
      }
      return acc;
    }, {});
  }
  return obj;
}

export function unsetEmptyValues(obj, keys) {
  const unset = {};
  for (const key of keys) {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      delete obj[key];
      unset[key] = true;
    } else if (!obj[key]) {
      delete obj[key];
      unset[key] = true;
    }
  }
  if (Object.keys(unset).length > 0) {
    obj.$unset = unset;
  }
  return obj;
}

export function formatValuesBeforeSave(obj, options = {}) {
  const {
    unsetKeysIfEmpty = [],
  } = options;
  const setObj = { ...obj };
  const unsetObj = {};

  for (const key of unsetKeysIfEmpty) {
    const keyVal = get(obj, key);

    if (Array.isArray(keyVal) && keyVal.length === 0) {
      unset(setObj, key)
      unsetObj[key] = true;
    } else if (!keyVal) {
      unset(setObj, key)
      unsetObj[key] = true;
    }
  }

  return {
    $set: setObj,
    $unset: unsetObj,
  };
}

export function transformArrayKeys(obj, keys) {
  keys.forEach((key) => {
    const val = obj[key];
    if (typeof val === 'string' && val.trim() !== '') {
      const arr = val.split(',').map((str) => str.trim());
      obj[key] = arr;
    } else if (typeof val === 'string' && val.trim() === '') {
      obj[key] = [];
    }
  });
  return obj;
}
