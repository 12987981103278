import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { transformSavedData, transformArrayKeys } from 'utils/form-utils';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import flatten from 'flat';
import {
  enums as enumCountries
} from 'lookups/countries';
import {
  enums as enumCountryCodes
} from 'lookups/country-codes';
import {
  lookups as lookupLocales,
  enums as enumLocales
} from 'lookups/locales';
import { getLocaleYupObject } from 'utils/yup-helper';
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material';
import useFeathers from 'hooks/useFeathers';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  FormikAvatar,
  FormikColorPicker,
  FormikSelect,
  FormikSwitch,
  FormikTextField,
} from 'features/formikControl';

const CASL_SUBJECT = 'companySettings';
const SERVICE_NAME = 'company-settings';

const defaultValues = {
  transaction: {
    minDeposit: 10,
    maxDeposit: 30000,
    minWithdrawal: 50,
    maxWithdrawal: 30000,
  },
  levelConfig: {
    level: {
      baseExp: 30,
      exponent: 1.35,
      turnoverContribution: 0.05,
    },
    derank: {
      thresholdValue: 1,
      thresholdUnit: 'month',
      voidExponent: 1.3,
    },
  },
  gameLogConfig: {
    idleCheckIntervalMs: 60000,
    maxIdleCount: 5,
  },
  walletConfig: {
    hideUnlimitedCashOut: false,
    hideLiveTransaction: false,
    skipDepositReward: true,
  },
  pageConfig: {
    brandLogo: '',
    brandLogoVariant: 'avatar',
    defaultThemeName: 'Default',
    defaultDarkThemeName: 'Default Dark',
    title: enumLocales.reduce((acc, locale) => {
      acc[locale] = 'FTW Wallet';
      return acc;
    }, {}),
    tagline: enumLocales.reduce((acc, locale) => {
      acc[locale] = '';
      return acc;
    }, {}),
    homeAnchorText: 'FTW',
    homeUrl: '#',
    pwaManifest: {
      brandLogo: '',
      brandLogoVariant: 'avatar',
      name: 'FTW Wallet',
      shortName: 'FTW',
      themeColor: '#000000',
      backgroundColor: '#ffffff',
      appleTouchIcon: '',
      favicon: '',
      logo512: '',
      logo192: '',
    }
  },
  backofficeConfig: {
    enableNotificationSound: false,
  }
};

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const [ tabValue, setTabValue ] = useState(0);

  const supportedCountryRegex = new RegExp(`^(${enumCountries.join('|')})$`);
  const supportedCountryCodesRegex = new RegExp(`^(${enumCountryCodes.join('|')})$`);
  const supportedLocalesRegex = new RegExp(`^(${enumLocales.join('|')})$`);
  const [ fullscreen, setFullscreen ] = useState(false);
  const { data: themes, ready: themesReady } = useFeathers('themes', {
    query: {
      $select: ['_id', 'name'],
      $limit: -1 // get all
    }
  });

  const data = useMemo(
    () => {
      if (savedData && savedData._id) return savedData;

      if (propData && propData._id) {
        const propDataWithDefault = {
          ...defaultValues,
          ...propData,
        };

        return transformSavedData(propDataWithDefault, {
          joinKeys: [
            'transaction.depositDenominations',
            'transaction.firstDepositDenominations',
            'transaction.depositDenoGhostWeights',
          ]
        });
      }

      return {
        ...defaultValues,
      }
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    companyId: Yup.string()
      .required(t("Required"))
      .matches(/^[a-zA-Z0-9]{2,}$/, t("Two or more alphanumeric")),
    groupName: Yup.array().of(Yup.string()).nullable(),
    country: Yup.string().matches(supportedCountryRegex).required(t("Required")),
    supportedCountryCodes: Yup.array().of(Yup.string().matches(supportedCountryCodesRegex)).required(t("Required")).min(1),
    supportedLocales: Yup.array().of(Yup.string().matches(supportedLocalesRegex)).required(t("Required")).min(1),
    defaultLocale: Yup.string().matches(supportedLocalesRegex).required(t("Required")),
    transaction: Yup.object().shape({
      minDeposit: Yup.number().min(0).required(t("Required")),
      maxDeposit: Yup.number().min(0).required(t("Required")),
      minWithdrawal: Yup.number().min(0).required(t("Required")),
      maxWithdrawal: Yup.number().min(0).required(t("Required")),
      depositDenominations: Yup.string().matches(/^([\s]*[\d]+\.?[\d]*[\s]*,)*([\s]*[\d]+\.?[\d]*[\s]*){1}$/, t('Array of numbers format')).nullable(),
      firstDepositDenominations: Yup.string().matches(/^([\s]*[\d]+\.?[\d]*[\s]*,)*([\s]*[\d]+\.?[\d]*[\s]*){1}$/, t('Array of numbers format')).nullable(),
      depositDenoGhostWeights: Yup.string().matches(/^([\s]*[\d]+\.?[\d]*[\s]*,)*([\s]*[\d]+\.?[\d]*[\s]*){1}$/, t('Array of numbers format')).nullable(),
    }),
    telegramNotification: Yup.object().shape({
      token: Yup.string().nullable(),
      chatIds: Yup.array().of(Yup.string()).nullable(),
      messagePrefix: Yup.string().matches(/^[a-zA-Z0-9]*$/, t("Alphanumeric only")).nullable(),
      intervalInSeconds: Yup.number().min(0).nullable(),
      isEnabled: Yup.bool().nullable(),
    }),
    socialChannels: Yup.object().shape({
      directChatLink: Yup.string().nullable(),
      facebookLink: Yup.string().nullable(),
      telegramLink: Yup.string().nullable()
    }),
    walletConfig: Yup.object().shape({
      walletHost: Yup.string().nullable(),
      walletAppVersion: Yup.string().matches(/^\d+(\.\d+)*$/, t('Version number format')).nullable(),
      hideUnlimitedCashOut: Yup.bool().nullable(),
      hideLiveTransaction: Yup.bool().nullable(),
      skipDepositReward: Yup.bool().nullable(),
      propagateWalletAppVersion: Yup.bool().nullable(),
    }),
    pageConfig: Yup.object().shape({
      title: Yup.object().shape(getLocaleYupObject(enumLocales, false)),
      tagline: Yup.object().shape(getLocaleYupObject(enumLocales, false)),
      homeAnchorText: Yup.string().nullable(),
      homeUrl: Yup.string().nullable(),
      pwaManifest: Yup.object().shape({
        brandLogo: Yup.string().nullable(),
        brandLogoVariant: Yup.string().matches(/(media|avatar)/).nullable(),
        defaultThemeName: Yup.string().nullable(),
        defaultDarkThemeName: Yup.string().nullable(),
        name: Yup.string().nullable(),
        shortName: Yup.string().nullable(),
        themeColor: Yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t("Hex color code")).nullable(),
        backgroundColor: Yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t("Hex color code")).nullable(),
        appleTouchIcon: Yup.string().nullable(),
        favicon: Yup.string().nullable(),
        logo512: Yup.string().nullable(),
        logo192: Yup.string().nullable(),
      }),
    }),
    backofficeConfig: Yup.object().shape({
      enableNotificationSound: Yup.bool().nullable(),
    }),
    levelConfig: Yup.object().shape({
      level: Yup.object().shape({
        baseExp: Yup.number().min(1).required(t("Required")),
        exponent: Yup.number().min(0.1).required(t("Required")),
        turnoverContribution: Yup.number().min(0).required(t("Required")),
      }),
      derank: Yup.object().shape({
        thresholdValue: Yup.number().min(0).required(t("Required")),
        thresholdUnit: Yup.string().matches(/(day|week|month|year)/).required(t("Required")),
        voidExponent: Yup.number().min(0.1).required(t("Required")),
      }),
    }),
    gameLogConfig: Yup.object().shape({
      idleCheckIntervalMs: Yup.number().min(0).required(t("Required")),
      maxIdleCount: Yup.number().min(0).required(t("Required")),
    }),
    socksProxy: Yup.object().shape({
      uri: Yup.string().nullable(),
      isEnable: Yup.bool().nullable(),
    }),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabIndexes = {
    general: 0,
    wallet: 1,
    page: 2,
    level: 3,
    transaction: 4,
    gameLog: 5,
    notification: 6,
    social: 7,
    backoffice: 8,
    socksProxy: 9,
  };

  const tabNames = useMemo(() => {
    return [
      t('General'),
      t('Wallet'),
      t('Page'),
      t('Level'),
      t('Transaction'),
      t('Game Log'),
      t('Notification'),
      t('Social'),
      t('Backoffice'),
      t('SOCKS Proxy'),
    ];
  }, [t]);

  return (
    <Dialog fullWidth maxWidth='md' open={open} onClose={handleClose} fullScreen={fullscreen}>
      <DialogTitle>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          {t('Company')}
          <IconButton
            onClick={() => setFullscreen(!fullscreen)}
          >
            {
              fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />
            }
          </IconButton>
        </Box>
      </DialogTitle>
      <Formik
        enableReinitialize={false}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const _id = get(data, '_id');
            const flattenVal = flatten(values, { safe: true });
            const transformedValues = transformArrayKeys(flattenVal, [
              'transaction.depositDenominations',
              'transaction.firstDepositDenominations',
              'transaction.depositDenoGhostWeights',
            ]);

            // Prevent overwriting the token with an empty string
            if (transformedValues['telegramNotification.token'] === '') delete transformedValues['telegramNotification.token'];

            // Remove empty strings from the groupName array
            if (transformedValues['groupName']) {
              transformedValues['groupName'] = transformedValues['groupName']
                .filter((val) => val !== '');
            }

            if (transformedValues['telegramNotification.chatIds']) {
              transformedValues['telegramNotification.chatIds'] = transformedValues['telegramNotification.chatIds']
                .filter((val) => val !== '');
            }

            if (!_id) {
              const { _id, ...newValues } = transformedValues;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              await feathers.service(SERVICE_NAME).patch(_id, transformedValues);
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
                <Tabs value={tabValue} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'>
                  {
                    tabNames.map((name, index) => (
                      <Tab key={index} label={name} />
                    ))
                  }
                </Tabs>
                <Paper sx={{ p: 2 }} elevation={0}>
                  <Grid container spacing={2}>
                    {
                      tabValue === tabIndexes.general &&
                      <>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='companyId'
                            name='companyId'
                            label={t('Company ID')}
                            value={values?.companyId || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='groupName'
                            name='groupName'
                            label={t('Group Name')}
                            multiline
                            rows={3}
                            value={values?.groupName?.join('\n') || ''}  // Join the array of strings with a newline character
                            onBlur={handleBlur}
                            onChange={(event) => {
                              const value = event.target.value;
                              const valuesArray = value === '' ? [] : value
                                .split('\n')
                                .map((val) => val.trim());
                              setFieldValue('groupName', valuesArray);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikSelect
                            fullWidth
                            id='country'
                            name='country'
                            label={t('Country')}
                            value={values?.country || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {
                              enumCountries.map(key => (
                                <MenuItem key={key} value={key}>
                                  {t(`country-${key}`)}
                                </MenuItem>
                              ))
                            }
                          </FormikSelect>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikSelect
                            fullWidth
                            id='supportedCountryCodes'
                            name='supportedCountryCodes'
                            label={t('Supported Country Codes')}
                            multiple
                            value={values?.supportedCountryCodes || []}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {
                              enumCountryCodes.map(key => (
                                <MenuItem key={key} value={key}>
                                  <Checkbox checked={values?.supportedCountryCodes?.indexOf(key) > -1} />
                                  <ListItemText primary={key} />
                                </MenuItem>
                              ))
                            }
                          </FormikSelect>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikSelect
                            fullWidth
                            id='supportedLocales'
                            name='supportedLocales'
                            label={t('Supported Locales')}
                            multiple
                            value={values?.supportedLocales || []}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            renderValue={(selected) => selected.map(key => lookupLocales[key]).join(', ')}
                          >
                            {
                              enumLocales.map(key => (
                                <MenuItem key={key} value={key}>
                                  <Checkbox checked={values?.supportedLocales?.indexOf(key) > -1} />
                                  <ListItemText primary={lookupLocales[key]} />
                                </MenuItem>
                              ))
                            }
                          </FormikSelect>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikSelect
                            fullWidth
                            id='defaultLocale'
                            name='defaultLocale'
                            label={t('Default Locale')}
                            value={values?.defaultLocale || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {
                              enumLocales.map(key => (
                                <MenuItem key={key} value={key}>
                                  {lookupLocales[key]}
                                </MenuItem>
                              ))
                            }
                          </FormikSelect>
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.wallet &&
                      <>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='walletConfig.walletHost'
                            name='walletConfig.walletHost'
                            label={t('Wallet Host')}
                            value={values?.walletConfig?.walletHost || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='walletConfig.walletAppVersion'
                            name='walletConfig.walletAppVersion'
                            label={t('App Version')}
                            value={values?.walletConfig?.walletAppVersion || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t('Version number format')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikSwitch
                            id='walletConfig.hideUnlimitedCashOut'
                            name='walletConfig.hideUnlimitedCashOut'
                            label={t('Hide Unlimited Cash Out')}
                            value={values?.walletConfig?.hideUnlimitedCashOut || false}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikSwitch
                            id='walletConfig.hideLiveTransaction'
                            name='walletConfig.hideLiveTransaction'
                            label={t('Hide Live Transaction')}
                            value={values?.walletConfig?.hideLiveTransaction || false}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikSwitch
                            id='walletConfig.skipDepositReward'
                            name='walletConfig.skipDepositReward'
                            label={t('Skip Deposit Reward')}
                            value={values?.walletConfig?.skipDepositReward || false}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikSwitch
                            id='walletConfig.propagateWalletAppVersion'
                            name='walletConfig.propagateWalletAppVersion'
                            label={t('Propagate App Version')}
                            value={values?.walletConfig?.propagateWalletAppVersion || false}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.page &&
                      <>
                        <Grid item xs={12}>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: 2,
                          }}>
                            <Card>
                              <CardHeader title={t('Brand Logo')} />
                              <CardContent>
                                <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: 2,
                                }}>
                                  <FormikAvatar
                                    id={`pageConfig.brandLogo`}
                                    name={`pageConfig.brandLogo`}
                                    value={values?.pageConfig?.brandLogo || ''}
                                    variant='rounded'
                                    sx={{ width: '5rem', height: '5rem' }}
                                  />
                                  <FormikSelect
                                    id='pageConfig.brandLogoVariant'
                                    name='pageConfig.brandLogoVariant'
                                    label={t('Variant')}
                                    value={values?.pageConfig?.brandLogoVariant || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  >
                                    <MenuItem value='media'>{t('Media')}</MenuItem>
                                    <MenuItem value='avatar'>{t('Avatar')}</MenuItem>
                                  </FormikSelect>
                                </Box>
                              </CardContent>
                            </Card>
                            <Card>
                              <CardHeader title={t('Default Theme')} />
                              <CardContent>
                                <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: 2,
                                }}>
                                  <FormikSelect
                                    fullWidth
                                    id='pageConfig.defaultThemeName'
                                    name='pageConfig.defaultThemeName'
                                    label={t('Normal Theme')}
                                    value={values?.pageConfig?.defaultThemeName || 'Default'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  >
                                    {
                                      themesReady && themes.map(theme => (
                                        <MenuItem key={theme._id} value={theme.name}>
                                          {theme.name}
                                        </MenuItem>
                                      ))
                                    }
                                  </FormikSelect>
                                  <FormikSelect
                                    fullWidth
                                    id='pageConfig.defaultDarkThemeName'
                                    name='pageConfig.defaultDarkThemeName'
                                    label={t('Dark Theme')}
                                    value={values?.pageConfig?.defaultDarkThemeName || 'Default Dark'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  >
                                    {
                                      themesReady && themes.map(theme => (
                                        <MenuItem key={theme._id} value={theme.name}>
                                          {theme.name}
                                        </MenuItem>
                                      ))
                                    }
                                  </FormikSelect>
                                </Box>
                              </CardContent>
                            </Card>
                            {
                              values?.supportedLocales?.map(locale => (
                                <Card key={locale}>
                                  <CardHeader title={`${t('Title')} (${locale})`} />
                                  <CardContent>
                                    <Box sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: 2,
                                    }}>
                                      <FormikTextField
                                        fullWidth
                                        id={`pageConfig.title.${locale}`}
                                        name={`pageConfig.title.${locale}`}
                                        label={t('Title')}
                                        value={values?.pageConfig?.title?.[locale] || ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                      <FormikTextField
                                        fullWidth
                                        id={`pageConfig.tagline.${locale}`}
                                        name={`pageConfig.tagline.${locale}`}
                                        label={t('Tagline')}
                                        value={values?.pageConfig?.tagline?.[locale] || ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  </CardContent>
                                </Card>
                              ))
                            }
                            <Card>
                              <CardHeader title={t('Home')} />
                              <CardContent>
                                <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: 2,
                                }}>
                                  <FormikTextField
                                    fullWidth
                                    id='pageConfig.homeAnchorText'
                                    name='pageConfig.homeAnchorText'
                                    label={t('Anchor Text')}
                                    value={values?.pageConfig?.homeAnchorText || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FormikTextField
                                    fullWidth
                                    id='pageConfig.homeUrl'
                                    name='pageConfig.homeUrl'
                                    label={t('URL')}
                                    value={values?.pageConfig?.homeUrl || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </CardContent>
                            </Card>
                            {
                              // seperate pwa and favicon to two cards
                            }
                            <Card>
                              <CardHeader title={t('PWA Manifest')} />
                              <CardContent>
                                <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: 2,
                                }}>
                                  <FormikTextField
                                    fullWidth
                                    id='pageConfig.pwaManifest.name'
                                    name='pageConfig.pwaManifest.name'
                                    label={t('Name')}
                                    value={values?.pageConfig?.pwaManifest?.name || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FormikTextField
                                    fullWidth
                                    id='pageConfig.pwaManifest.shortName'
                                    name='pageConfig.pwaManifest.shortName'
                                    label={t('Short Name')}
                                    value={values?.pageConfig?.pwaManifest?.shortName || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FormikColorPicker
                                    fullWidth
                                    id='pageConfig.pwaManifest.themeColor'
                                    name='pageConfig.pwaManifest.themeColor'
                                    label={t('Theme Color')}
                                    value={values?.pageConfig?.pwaManifest?.themeColor || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FormikColorPicker
                                    fullWidth
                                    id='pageConfig.pwaManifest.backgroundColor'
                                    name='pageConfig.pwaManifest.backgroundColor'
                                    label={t('Background Color')}
                                    value={values?.pageConfig?.pwaManifest?.backgroundColor || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </CardContent>
                            </Card>
                            <Card>
                              <CardHeader title={t('Favicon')} />
                              <CardContent>
                                <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: 2,
                                }}>
                                  <FormikAvatar
                                    id='pageConfig.pwaManifest.favicon'
                                    name='pageConfig.pwaManifest.favicon'
                                    label={t('Favicon')}
                                    value={values?.pageConfig?.pwaManifest?.favicon || ''}
                                    variant='rounded'
                                    allowedMimeTypes={['image/x-icon']}
                                  />
                                  <FormikAvatar
                                    id='pageConfig.pwaManifest.appleTouchIcon'
                                    name='pageConfig.pwaManifest.appleTouchIcon'
                                    label={t('Apple Touch Icon')}
                                    value={values?.pageConfig?.pwaManifest?.appleTouchIcon || ''}
                                    variant='rounded'
                                    allowedMimeTypes={['image/png']}
                                  />
                                  <FormikAvatar
                                    id='pageConfig.pwaManifest.logo512'
                                    name='pageConfig.pwaManifest.logo512'
                                    label={t('Logo 512')}
                                    value={values?.pageConfig?.pwaManifest?.logo512 || ''}
                                    variant='rounded'
                                    allowedMimeTypes={['image/png']}
                                  />
                                  <FormikAvatar
                                    id='pageConfig.pwaManifest.logo192'
                                    name='pageConfig.pwaManifest.logo192'
                                    label={t('Logo 192')}
                                    value={values?.pageConfig?.pwaManifest?.logo192 || ''}
                                    variant='rounded'
                                    allowedMimeTypes={['image/png']}
                                  />
                                </Box>
                              </CardContent>
                            </Card>
                          </Box>
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.level &&
                      <>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='levelConfig.level.baseExp'
                            name='levelConfig.level.baseExp'
                            label={t('Level Base Exp')}
                            value={values?.levelConfig?.level?.baseExp || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='levelConfig.level.exponent'
                            name='levelConfig.level.exponent'
                            label={t('Level Exponent')}
                            value={values?.levelConfig?.level?.exponent || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='levelConfig.level.turnoverContribution'
                            name='levelConfig.level.turnoverContribution'
                            label={t('Level Turnover Contribution')}
                            value={values?.levelConfig?.level?.turnoverContribution || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='levelConfig.derank.thresholdValue'
                            name='levelConfig.derank.thresholdValue'
                            label={t('Derank Threshold Value')}
                            value={values?.levelConfig?.derank?.thresholdValue || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t('Set to 0 to disable derank')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikSelect
                            fullWidth
                            id='levelConfig.derank.thresholdUnit'
                            name='levelConfig.derank.thresholdUnit'
                            label={t('Derank Threshold Unit')}
                            value={values?.levelConfig?.derank?.thresholdUnit || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {
                              ['day', 'week', 'month', 'year'].map(key => (
                                <MenuItem key={key} value={key}>
                                  {t(key)}
                                </MenuItem>
                              ))
                            }
                          </FormikSelect>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='levelConfig.derank.voidExponent'
                            name='levelConfig.derank.voidExponent'
                            label={t('Derank Void Exponent')}
                            value={values?.levelConfig?.derank?.voidExponent || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.transaction &&
                      <>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='transaction.minDeposit'
                            name='transaction.minDeposit'
                            label={t('Min Deposit')}
                            value={values?.transaction?.minDeposit || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='transaction.maxDeposit'
                            name='transaction.maxDeposit'
                            label={t('Max Deposit')}
                            value={values?.transaction?.maxDeposit || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='transaction.minWithdrawal'
                            name='transaction.minWithdrawal'
                            label={t('Min Withdrawal')}
                            value={values?.transaction?.minWithdrawal || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='transaction.maxWithdrawal'
                            name='transaction.maxWithdrawal'
                            label={t('Max Withdrawal')}
                            value={values?.transaction?.maxWithdrawal || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='transaction.depositDenominations'
                            name='transaction.depositDenominations'
                            label={t('Deposit Denominations')}
                            value={values?.transaction?.depositDenominations || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t('Comma separated numbers')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='transaction.firstDepositDenominations'
                            name='transaction.firstDepositDenominations'
                            label={t('First Deposit Denominations')}
                            value={values?.transaction?.firstDepositDenominations || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t('Comma separated numbers')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='transaction.depositDenoGhostWeights'
                            name='transaction.depositDenoGhostWeights'
                            label={t('Deposit Deno Ghost Weights')}
                            value={values?.transaction?.depositDenoGhostWeights || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={t('Comma separated numbers')}
                          />
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.gameLog &&
                      <>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='gameLogConfig.idleCheckIntervalMs'
                            name='gameLogConfig.idleCheckIntervalMs'
                            label={t('Idle Check Interval MS')}
                            value={values?.gameLogConfig?.idleCheckIntervalMs || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField
                            fullWidth
                            id='gameLogConfig.maxIdleCount'
                            name='gameLogConfig.maxIdleCount'
                            label={t('Max Idle Count')}
                            value={values?.gameLogConfig?.maxIdleCount || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.notification &&
                      <>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='telegramNotification.token'
                            name='telegramNotification.token'
                            label={t('Telegram Notification Token')}
                            value={values?.telegramNotification?.token || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='telegramNotification.chatIds'
                            name='telegramNotification.chatIds'
                            label={t('Telegram Notification Chat IDs')}
                            multiline
                            rows={3}
                            value={values?.telegramNotification?.chatIds?.join('\n') || ''}  // Join the array of strings with a newline character
                            onBlur={handleBlur}
                            onChange={(event) => {
                              const value = event.target.value;
                              const valuesArray = value === '' ? [] : value
                                .split('\n')
                                .map((val) => val.trim());
                              setFieldValue('telegramNotification.chatIds', valuesArray);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='telegramNotification.intervalInSeconds'
                            name='telegramNotification.intervalInSeconds'
                            label={t('Telegram Notification Interval')}
                            value={values?.telegramNotification?.intervalInSeconds || ''}
                            placeholder={t('In seconds')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='telegramNotification.messagePrefix'
                            name='telegramNotification.messagePrefix'
                            label={t('Telegram Notification Message Prefix')}
                            value={values?.telegramNotification?.messagePrefix || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikSwitch
                            id='telegramNotification.isEnabled'
                            name='telegramNotification.isEnabled'
                            label={t('Enable Telegram Notification')}
                            value={values?.telegramNotification?.isEnabled || false}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.social &&
                      <>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='socialChannels.directChatLink'
                            name='socialChannels.directChatLink'
                            label={t('Direct Chat Link')}
                            value={values?.socialChannels?.directChatLink || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='socialChannels.facebookLink'
                            name='socialChannels.facebookLink'
                            label={t('Facebook Link')}
                            value={values?.socialChannels?.facebookLink || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='socialChannels.telegramLink'
                            name='socialChannels.telegramLink'
                            label={t('Telegram Link')}
                            value={values?.socialChannels?.telegramLink || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.backoffice &&
                      <>
                        <Grid item xs={12}>
                          <FormikSwitch
                            id='backofficeConfig.enableNotificationSound'
                            name='backofficeConfig.enableNotificationSound'
                            label={t('Enable Notification Sound')}
                            value={values?.backofficeConfig?.enableNotificationSound || false}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    }
                    {
                      tabValue === tabIndexes.socksProxy &&
                      <>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='socksProxy.uri'
                            name='socksProxy.uri'
                            label={t('Proxy URI')}
                            value={values?.socksProxy?.uri || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikSwitch
                            id='socksProxy.isEnabled'
                            name='socksProxy.isEnabled'
                            label={t('Enable Proxy')}
                            value={values?.socksProxy?.isEnabled || false}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    }
                  </Grid>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
