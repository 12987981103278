import React, { useState, useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase, trim, sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { AbilityContext } from 'casl/Can';
import Decimal from 'decimal.js';
import AddIcon from '@mui/icons-material/AddCircle';
import Chance from 'chance';
import { transformSavedData, transformArrayKeys, formatValuesBeforeSave } from 'utils/form-utils';
import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject } from 'utils/yup-helper';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

const _NEWID = '@NewPointRedemption';
const _RNAME = 'pointRedemptionDeals';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const [ sampleSize, setSampleSize ] = useState(2000);
  const [ calcState, setCaclState ] = useState('idle');
  const [ calcProgress, setCalcProgress ] = useState(0);
  const [ calcForceStop, setCalcForceStop ] = useState(null);
  const [ sampleRewards, setSampleRewards ] = useState([]);
  const [ sampleRewardCount, setSampleRewardCount ] = useState([]);
  const [ adjustmentLevel, setAdjustmentLevel ] = useState(0);
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();

  const chance = useMemo(
    () => {
      return new Chance();
    }, []
  );

  const data = useMemo(
    () => {
      if (savedData && savedData._id) return savedData;

      if (propData && propData._id) {
        return transformSavedData(propData, {
          joinKeys: [
            'cashRewards',
            'cashRewardWeights',
          ]
        });
      }

      return {
        _id: _NEWID,
        isEnabled: true
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    name: Yup.object().shape(getLocaleYupObject(supportedLocales)),
    point: Yup.number().required(t("Required")),
    minCashReward: Yup.number().required(t("Required")),
    maxCashReward: Yup.number().required(t("Required")),
    cashRewards: Yup.string().matches(/^([\s]*[\d]+\.?[\d]*[\s]*,){7}([\s]*[\d]+\.?[\d]*[\s]*){1}$/, t('Array of numbers format')).required(t("Required")),
    cashRewardWeights: Yup.string().matches(/^([\s]*[\d]+\.?[\d]*[\s]*,){7}([\s]*[\d]+\.?[\d]*[\s]*){1}$/, t('Array of numbers format')).nullable(),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const cloneValues = { ...values };
        const transformedValues = transformArrayKeys(cloneValues, ['cashRewards', 'cashRewardWeights']);

        if (_id === _NEWID) {
          delete transformedValues._id;
          const saved = await feathers.service(serviceName).create(transformedValues);
          setSavedData(saved);
        } else {
          const formattedValues = formatValuesBeforeSave(transformedValues, {
            unsetKeysIfEmpty: ['cashRewardWeights']
          });
          await feathers.service(serviceName).patch(_id, formattedValues);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const cashRewardsMemo = useMemo(
    () => {
      const cashRewardsStr = get(formik, 'values.cashRewards', '');
      const ret = cashRewardsStr.split(',').map(cr => {
        try {
          return new Decimal(trim(cr)).toNumber();
        } catch(err) {
          return 0;
        }
      });

      return ret;
    }, [formik]
  );

  const cashRewardWeightsMemo = useMemo(
    () => {
      const cashRewardWeightsStr = get(formik, 'values.cashRewardWeights', '');
      const ret = cashRewardWeightsStr.split(',').map(cr => {
        try {
          return new Decimal(trim(cr)).toNumber();
        } catch(err) {
          return 0;
        }
      });

      return ret;
    }, [formik]
  );

  const pointMemo = useMemo(
    () => {
      const point = new Decimal(get(formik, 'values.point', '0'));
      return point.toNumber();
    }, [formik]
  );

  const totalReward = useMemo(
    () => {
      if (!sampleRewards.length) return 0;
      const total = new Decimal(sum(sampleRewards)).toNumber();
      return total;
    }, [sampleRewards]
  );

  const totalRewardCount = useMemo(
    () => {
      return sampleRewards.length;
    }, [sampleRewards]
  );

  const formattedTotalReward = useMemo(
    () => {
      return new Decimal(totalReward).toFixed(2);
    }, [totalReward]
  );

  const avgReward = useMemo(
    () => {
      if (!totalRewardCount) return 0;
      const avg = new Decimal(totalReward).dividedBy(totalRewardCount).toNumber();
      return avg;
    }, [totalReward, totalRewardCount]
  );

  const formattedAvgReward = useMemo(
    () => {
      return new Decimal(avgReward).toFixed(2);
    }, [avgReward]
  );

  const formattedAvgRewardPerPoint = useMemo(
    () => {
      if (!avgReward || !pointMemo) return '0.00';
      const avg = new Decimal(avgReward).dividedBy(pointMemo).toNumber();
      return avg.toFixed(2);
    }, [avgReward, pointMemo]
  );

  const calcButtonText = useMemo(
    () => {
      switch (calcState) {
        case 'idle':
          return 'Start';

        case 'calculating':
          return 'Pause';

        case 'pause':
          return 'Resume';

        default:
          return 'Start'
      }
    }, [calcState]
  );

  useEffect(
    () => {
      if (!calcForceStop) return;
      setCalcProgress(0);
      setCaclState('idle');
      setSampleRewards([]);
      setSampleRewardCount([]);
      setCalcForceStop(null);
    }, [calcForceStop]
  );

  useEffect(
    () => {
      setCalcProgress(0);
      setSampleRewards([]);
      setSampleRewardCount([]);
    }, [sampleSize]
  );

  useEffect(
    () => {
      if (calcState !== 'start') return;

      setCalcProgress(0);
      setSampleRewards([]);
      setSampleRewardCount([]);
      setCaclState('calculating');
    }, [calcState]
  );

  const adjustedWeightsMemo = useMemo(
    () => {
      if (cashRewardWeightsMemo.length !== 8) return [];

      const adjustmentMatrix = [
        [1, 1, 1, 1, 1, 1, 1, 1],
        [1, 1, 1, 1, 0.5, 0.5, 0.25, 0.25],
        [1, 1, 0.5, 0.5, 0.25, 0.25, 0.1, 0.1],
        [2, 2, 0.25, 0.25, 0.1, 0.1, 0.05, 0.05],
        [5, 5, 0.3, 0.3, 0.1, 0.1, 0.025, 0.025],
      ];

      const selectedAdjustment = adjustmentMatrix[adjustmentLevel] || adjustmentMatrix[0];

      return cashRewardWeightsMemo.map((w, index) => {
        return new Decimal(w).times(selectedAdjustment[index]).toNumber();
      });
    }, [adjustmentLevel, cashRewardWeightsMemo]
  );

  useEffect(
    () => {
      if (calcState !== 'calculating') return;
      if (calcForceStop) return;

      // calculated enough samples
      if (sampleRewards.length >= sampleSize) {
        setCaclState('idle');
        return;
      }

      if (cashRewardsMemo.length < 8 || adjustedWeightsMemo.length < 8) {
        setCaclState('idle');
        return;
      }

      const reward = chance.weighted(cashRewardsMemo, adjustedWeightsMemo);
      const sampleRewardPostLength = sampleRewards.length + 1;

      setSampleRewards(prev => [...prev, reward]);

      setSampleRewardCount(prev => {
        const findIndex = prev.find(r => r.key === `${reward}`);

        if (!findIndex) {
          return [
            ...prev, {
            key: `${reward}`,
            value: 1,
            percentage: new Decimal(1).dividedBy(sampleRewardPostLength).times(100).toFixed(2)
          }];
        }

        return prev.map(r => {
          if (r.key === `${reward}`) {
            const newValue = r.value + 1;
            return {
              ...r,
              value: newValue,
              percentage: new Decimal(newValue).dividedBy(sampleRewardPostLength).times(100).toFixed(2)
            }
          }
          return r;
        });
      });

    }, [calcState, calcForceStop, sampleRewards, sampleSize, cashRewardsMemo, adjustedWeightsMemo, chance]
  );

  const sampleRewardLength = useMemo(
    () => {
      return sampleRewards?.length || 0;
    }, [sampleRewards]
  );

  useEffect(
    () => {
      // calc progress
      if (sampleRewardLength > sampleSize) return;

      try {
        const progress = new Decimal(sampleRewardLength).dividedBy(sampleSize).times(100).toNumber();
        setCalcProgress(progress);
      } catch (err) {
        setCaclState('idle');
      }
    }, [sampleRewardLength, sampleSize]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  function handleAutoCashRewardsClicked(event) {
    event?.preventDefault();
    const decMinCashReward = new Decimal(get(formik, 'values.minCashReward', '0'));
    const decMaxCashReward = new Decimal(get(formik, 'values.maxCashReward', '0'));

    const stepSize = decMaxCashReward.minus(decMinCashReward).dividedBy(7).floor();

    let cashRewards = [];
    cashRewards.push(decMinCashReward.toNumber());

    for (let i = 1; i < 7; i++) {
      cashRewards.push(decMinCashReward.plus(stepSize * i).toNumber());
    }

    cashRewards.push(decMaxCashReward.toNumber());
    formik.setFieldValue('cashRewards', cashRewards.join(', '));
  }

  function handleWeightClicked(event) {
    event?.preventDefault();
    const equalWeights = Array.from({ length: 8 }, () => 1).join(', ');
    formik.setFieldValue('cashRewardWeights', equalWeights);
  }

  function handleSampleSizeChanged(event) {
    event?.preventDefault();
    const value = get(event, 'target.value', 0);
    setSampleSize(value);
  }

  function handleCalcStart(event) {
    event?.preventDefault();
    if (sampleSize <= 0) return;
    setCaclState(prev => {
      if (prev === 'idle') return 'start';
      if (prev === 'calculating') return 'pause';
      if (prev === 'pause') return 'calculating';
      return prev;
    });
  }

  function handleCalcStop(event) {
    event?.preventDefault();
    setCalcForceStop(new Date());
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Redemption Deal')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            {
              supportedLocales.map(locale => {
                return (
                  <Grid key={locale} item xs={12}>
                    <TextField
                      fullWidth
                      disabled={disableInput(`name.${locale}`)}
                      id={`name.${locale}`}
                      name={`name.${locale}`}
                      label={t(`name.${locale}`)}
                      value={get(formik, `values.name.${locale}`, '')}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={get(formik, `touched.name.${locale}`, false) && Boolean(get(formik, `errors.name.${locale}`))}
                      helperText={get(formik, `touched.name.${locale}`, false) && get(formik, `errors.name.${locale}`)}
                    />
                  </Grid>
                );
              })
            }
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={disableInput('point')}
                id='point'
                name='point'
                label={t('Point Needed')}
                value={get(formik, 'values.point', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.point`, false) && Boolean(get(formik, `errors.point`))}
                helperText={get(formik, `touched.point`, false) && get(formik, `errors.point`)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={disableInput('minCashReward')}
                id='minCashReward'
                name='minCashReward'
                label={t('Min Cash Reward')}
                value={get(formik, 'values.minCashReward', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.minCashReward`, false) && Boolean(get(formik, `errors.minCashReward`))}
                helperText={get(formik, `touched.minCashReward`, false) && get(formik, `errors.minCashReward`)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={disableInput('maxCashReward')}
                id='maxCashReward'
                name='maxCashReward'
                label={t('Max Cash Reward')}
                value={get(formik, 'values.maxCashReward', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.maxCashReward`, false) && Boolean(get(formik, `errors.maxCashReward`))}
                helperText={get(formik, `touched.maxCashReward`, false) && get(formik, `errors.maxCashReward`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('cashRewards')}
                id='cashRewards'
                name='cashRewards'
                label={t('Cash Rewards')}
                value={get(formik, 'values.cashRewards', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.cashRewards`, false) && Boolean(get(formik, `errors.cashRewards`))}
                helperText={get(formik, `touched.cashRewards`, false) && get(formik, `errors.cashRewards`)}
                InputProps={{
                  endAdornment:
                    <InputAdornment position='end'>
                      <IconButton disabled={disableInput('cashRewards')} onClick={handleAutoCashRewardsClicked}>
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('cashRewardWeights')}
                id='cashRewardWeights'
                name='cashRewardWeights'
                label={t('Cash Reward Weights')}
                value={get(formik, 'values.cashRewardWeights', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.cashRewardWeights`, false) && Boolean(get(formik, `errors.cashRewardWeights`))}
                helperText={get(formik, `touched.cashRewardWeights`, false) && get(formik, `errors.cashRewardWeights`)}
                InputProps={{
                  endAdornment:
                    <InputAdornment position='end'>
                      <IconButton disabled={disableInput('cashRewardWeights')} onClick={handleWeightClicked}>
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isEnabled', isChecked)
                  }} />
                }
                label={t('Enabled')}
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogContent dividers>
        <Grid container spacing={1.5}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              inputProps={{ inputMode: 'numeric' }}
              inputMode='numeric'
              id='sampleSize'
              name='sampleSize'
              label={t('Sample Size')}
              value={sampleSize}
              onChange={handleSampleSizeChanged}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              disabled
              type='text'
              id='totalReward'
              name='totalReward'
              label={t('Total Reward')}
              value={formattedTotalReward}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              disabled
              type='text'
              id='avgReward'
              name='avgReward'
              label={t('Avg Reward')}
              value={formattedAvgReward}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              disabled
              type='text'
              id='avgRewardPerPoint'
              name='avgRewardPerPoint'
              label={t('Avg Reward / Point')}
              value={formattedAvgRewardPerPoint}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              disabled
              type='text'
              id='iteration'
              name='iteration'
              label={`${t('Iteration')} ${calcProgress.toFixed(2)}%`}
              value={sampleRewardLength}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="adjustment-level-select-label">{t('Adjustment Level')}</InputLabel>
              <Select
                labelId="adjustment-level-select-label"
                label={t('Adjustment Level')}
                value={adjustmentLevel}
                onChange={(event) => {
                  const value = get(event, 'target.value', 0);
                  const numberValue = new Decimal(value).toNumber();
                  setAdjustmentLevel(numberValue);
                }}
              >
              {
                Array.from({ length: 5 }, (_, i) => i).map((i) => {
                  return (
                    <MenuItem key={i} value={i}>{t(`weightAdjustmentLevel.${i}`)}</MenuItem>
                  );
                })
              }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={true}
              label={t('Adjusted Weights')}
              value={adjustedWeightsMemo}

            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <ButtonGroup size='small' variant='contained'>
                <Button color='error' onClick={handleCalcStop}>{t('Stop')}</Button>
                <Button onClick={handleCalcStart}>{t(calcButtonText)}</Button>
              </ButtonGroup>
            </Box>
          </Grid>
          {
            !!sampleRewardCount.length &&
              sampleRewardCount.map(rc => {
                return (
                  <Grid key={rc.key} item xs={3} sx={{ textAlign: 'center' }}>
                    <Typography sx={{ color: 'text.secondary' }} variant='subtitle2'>
                      {`${rc.key}: `}
                      <Typography sx={{ fontWeight: 700, color: 'text.primary' }} variant='subtitle2' component='span'>
                        {`${rc.value} (${rc.percentage}%)`}
                      </Typography>
                    </Typography>
                  </Grid>
                );
              })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
