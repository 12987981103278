const lookups = {
  "AUD": "Australian Dollar",
  "MYR": "Malaysian Ringgit",
  "SGD": "Singapore Dollar",
  "THB": "Thai Baht",
};

const enums = Object.keys(lookups);

export {
  lookups,
  enums,
};
