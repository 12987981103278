import {
  initialState,
  updateData,
  updatePage,
  updateLimit,
  updateSort,
  updateColumnVisibility,
  updateFilter,
  updatePopulate,
  updateTextSearch,
  updateSearchFromUrl,
  updateTags,
  fetchAsync,
} from './slice';
import { debounce, uniq, get, map, pick } from 'lodash';
import {
  getData,
  getTags,
  getPopulate,
} from './selectors';
import ProtectedResources from './ProtectedResources.json';

export const refetch = (_RNAME) => (dispatch) => {
  dispatch(fetchAsync({ _RNAME }));
};

export const updateRealtime = (_RNAME, data) => (dispatch, getState) => {
  let isUpdated = false;
  const prevData = getData(_RNAME)(getState());

  const mergedData = prevData.map(d => {
    if (d._id !== data._id) return d;
    const populate = getPopulate(_RNAME)(getState());
    const paths = map(populate, p => p.path);
    const populatedData = pick(d, paths);

    // perserve protected fields
    const protectedFields = get(ProtectedResources, _RNAME, []);
    const protectedData = pick(d, protectedFields);

    isUpdated = true;
    return { ...data, ...populatedData, ...protectedData };
  });

  if (!isUpdated) return;
  //dispatch(fetchAsync({ _RNAME }));
  dispatch(updateData({ _RNAME, data: mergedData }));
};

export const updateFieldRealtime = (_RNAME, data, customId, fieldName) => (dispatch, getState) => {
  let isUpdated = false;
  const prevData = getData(_RNAME)(getState());

  const mergedData = prevData.map(d => {
    if (d[customId] !== data[customId]) return d;
    isUpdated = true;
    const newData = { ...d };
    newData[fieldName] = data;
    return newData;
  });

  if (!isUpdated) return;
  dispatch(updateData({ _RNAME, data: mergedData }));
};

export const updateRealtimeWithTags = (_RNAME, data) => (dispatch, getState) => {
  let isUpdated = false;
  const prevData = getData(_RNAME)(getState());
  const prevTags = getTags(_RNAME)(getState());
  const newTags = get(data, 'tags', []);
  const mergedTags = uniq([...prevTags, ...newTags]);
  dispatch(updateTags({ _RNAME, tags: mergedTags }));

  const mergedData = prevData.map(d => {
    if (d._id !== data._id) return d;
    isUpdated = true;
    return data;
  });

  if (!isUpdated) return;
  dispatch(updateData({ _RNAME, data: mergedData }));
};

export const setPage = (_RNAME, page) => (dispatch) => {
  dispatch(updatePage({ _RNAME, page }));
};

export const setLimit = (_RNAME, limit) => (dispatch) => {
  dispatch(updateLimit({ _RNAME, limit }));
};

export const setSort = (_RNAME, sort) => (dispatch) => {
  dispatch(updateSort({ _RNAME, sort }));
};

export const setFormattedSort = (_RNAME, reactTableSort) => (dispatch) => {
  const sort = reactTableSort.map(val => {
    const id = val.id;
    const direciton = val.desc ? -1 : 1;
    return [id, direciton];

  });
  dispatch(updateSort({ _RNAME, sort }));
};

export const storeColumnVisibility = (_RNAME, columnVisibility) => (dispatch) => {
  dispatch(updateColumnVisibility({ _RNAME, columnVisibility }));
};

export const setFilter = (_RNAME, filter) => (dispatch) => {
  dispatch(updateFilter({ _RNAME, filter }));
};

export const resetFilter = (_RNAME) => (dispatch) => {
  dispatch(updateFilter({ _RNAME, filter: null }));
};

export const setPopulate = (_RNAME, populate) => (dispatch) => {
  dispatch(updatePopulate({ _RNAME, populate }));
  //dispatch(fetchAsync({ _RNAME }));
};

const fetchDebounce = debounce((dispatch, _RNAME) => {
  dispatch(fetchAsync({ _RNAME}));
}, 1000);

export const setTextSearch = (_RNAME, textSearch) => (dispatch) => {
  dispatch(updateTextSearch({ _RNAME, textSearch }));
  fetchDebounce(dispatch, _RNAME);
};

export const resetTextSearch = (_RNAME) => (dispatch) => {
  dispatch(updateTextSearch({ _RNAME, textSearch: initialState.textSearch }));
  dispatch(fetchAsync({ _RNAME }));
};

export const setSearchFromUrl = (_RNAME, textSearch = '', filter = null) => (dispatch) => {
  dispatch(updateSearchFromUrl({ _RNAME, textSearch, filter }));
  dispatch(fetchAsync({ _RNAME }));
};
