import { createTheme } from '@mui/material/styles';

export default createTheme({
  /*shape: {
    borderRadius: 20
  },*/
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    /*MuiPaper: {
      styleOverrides: {
        root: {
          padding: 4
        }
      }
    }*/
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#d5d5d5',
    },
    secondary: {
      main: '#bdbdbd',
    },
    error: {
      main: '#ff1744',
    },
    warning: {
      main: '#ff9100',
    },
    info: {
      main: '#2979ff',
    },
    success: {
      main: '#00e676',
    },
    background: {
      //default: '#3c3c3c',
      //paper: '#3c3c3c'
    }
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'sans-serif',
      'Helvetica',
      'Arial'
    ].join(','),
  },
});
