import React from 'react';
import Box from '@mui/material/Box';

function extractVideoID(url) {
  let id = '';
  const urlParams = new URLSearchParams(new URL(url).search);
  if (url.includes('youtube.com/watch')) {
    id = urlParams.get('v');
  } else if (url.includes('youtu.be/')) {
    const path = new URL(url).pathname.split('/');
    id = path[path.length - 1];
  }
  return id;
}

export default function SlateYouTube({ attributes, children, element }) {
  const url = element.url;
  const videoId = extractVideoID(url);
  if (!videoId) return null;
  const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0&amp;autoplay=0&mute=0`;
  return (
    <Box {...attributes}
      sx={{
        width: {
          xs: '100%',
          sm: '50%',
        }
      }}
    >
      <Box
        contentEditable={false}
        sx={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingBottom: '56.25%' // full hd 16:9 (9/16)
        }}
      >
        <Box
          component='iframe'
          title={embedUrl}
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
      {
        children
      }
    </Box>
  );
};
