import React, { useCallback, useMemo } from 'react';
import { useSelected, useFocused, useSlateStatic } from 'slate-react';
import { removeLink } from 'features/slate/withLinks';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import IconButton from '@mui/material/IconButton';
import LinkOffIcon from '@mui/icons-material/LinkOff';

export default function SlateLink({ attributes, element, children }) {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  const isSelected = useMemo(
    () => {
      return (selected && focused);
    }, [selected, focused]
  );

  const removeLinkCallback = useCallback(
    (event) => {
      event.preventDefault();
      removeLink(editor);
    }, [editor]
  );

  return (
    <PopupState variant='popover' popupId='link-popover'>
      {(popupState) => (
        <>
          <Box
            sx={{
              color: 'primary.main',
              fontWeight: 700
            }}
            component='a' {...attributes}
            href={element.href}
            {
              ...(isSelected && {
                ...bindTrigger(popupState)
              })
            }
          >
            {children}
          </Box>
          <Popover
            {
              ...bindPopover(popupState)
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Box sx={{ fontWeight: 300, color: 'primary.main' }} component='a' href={element.href} rel='noreferrer' target='_blank'>
                {element.href}
              </Box>
              <IconButton color='primary' onClick={removeLinkCallback}>
                <LinkOffIcon />
              </IconButton>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
}
