const lookups = {
  "60": "Malaysia",
  "65": "Singapore",
  "61": "Australia",
  "64": "New Zealand",
  "66": "Thailand"
};

const enums = Object.keys(lookups);

export {
  lookups,
  enums,
};
