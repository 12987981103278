import { Editor, Transforms, Path, Range, Element } from 'slate';
import { ReactEditor } from 'slate-react';
import { createParagraphNode } from './utils/paragraph';

export default function withLinks(editor) {
  const { isInline } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  };

  return editor;
};

const createLinkNode = (href, text) => ({
  type: 'link',
  href,
  children: [{ text }]
});

export const insertLink = (editor, url, anchorName = 'New Link') => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, anchorName);

  ReactEditor.focus(editor);

  if (!!selection) {
    const [ parentNode, parentPath ] = Editor.parent(editor, selection.focus?.path);

    // remove the old link if exists
    if (parentNode.type === 'link') removeLink(editor);

    if (editor.isVoid(parentNode)) {
      // insert the new link after the void node
      Transforms.insertNodes(editor, createParagraphNode([link]), {
        at: Path.next(parentPath),
        select: true
      });
    } else if (Range.isCollapsed(selection)) {
      // insert the new link in our last known location
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      // wrap the currently selected range of text into a Link
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: 'end' });
    }
  } else {
    // insert the new link node at the bottom of the Editor when selection is falsey
    Transforms.insertNodes(editor, createParagraphNode([link]));
  }
};

export const removeLink = (editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link'
  });
};
