import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel } from '@mui/material';
import Select from '@mui/material/Select';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';
import BoolToggle from 'features/boolToggle/BoolToggle';

const _RNAME = 'userRelations';

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
      };
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    username: Yup.string().nullable(),
    referrerUsername: Yup.string().nullable(),
    relation: Yup.string().nullable(),
    isRewardable: Yup.boolean().nullable(),
    state: Yup.object().shape({
      $in: Yup.array().of(Yup.string()).nullable(),
    }),
    updatedAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
    createdAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='referrerUsername'
                name='referrerUsername'
                label={t('Referrer Username')}
                value={get(formik, 'values.referrerUsername', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.referrerUsername`, false) && Boolean(get(formik, `errors.referrerUsername`))}
                helperText={get(formik, `touched.referrerUsername`, false) && get(formik, `errors.referrerUsername`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='relation'
                name='relation'
                label={t('Relation')}
                value={get(formik, 'values.relation', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.relation`, false) && Boolean(get(formik, `errors.relation`))}
                helperText={get(formik, `touched.relation`, false) && get(formik, `errors.relation`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <BoolToggle
                    value={get(formik, 'values.isRewardable', null)}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('isRewardable', newValue);
                    }}
                  />
                }
                label={t('Rewardable')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.state.$in', false) && Boolean(get(formik, 'errors.state.$in'))}
              >
                <InputLabel id='state-select-label'>{t('State')}</InputLabel>
                <Select
                  autoWidth
                  labelId='state-select-label'
                  id='state.$in'
                  name='state.$in'
                  value={get(formik, 'values.state.$in', [])}
                  label={t('State')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    ['initial', 'pending', 'applied', 'done'].map(s => {
                      const isDone = s === 'done' ? true : false;
                      const color = !!isDone ? 'success.main' : 'inherit';
                      const fontWeight = !!isDone ? 700 : 'inherit';
                      return (
                        <MenuItem sx={{ color, fontWeight }} key={s} value={s}>
                          {t(s)}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.state.$in', false) && get(formik, 'errors.state.$in')}</FormHelperText>
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$gte'
                  label={t('Updated From')}
                  value={get(formik, 'values.updatedAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$gte', '');
                    else formik.setFieldValue('updatedAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$gte', false) && Boolean(get(formik, 'errors.updatedAt.$gte'))}
                  helperText={get(formik, 'touched.updatedAt.$gte', false) && get(formik, 'errors.updatedAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$lt'
                  label={t('Updated To')}
                  value={get(formik, 'values.updatedAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$lt', '');
                    else formik.setFieldValue('updatedAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$lt', false) && Boolean(get(formik, 'errors.updatedAt.$lt'))}
                  helperText={get(formik, 'touched.updatedAt.$lt', false) && get(formik, 'errors.updatedAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleSave}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
