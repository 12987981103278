const { flatten, groupBy, map, reduce } = require('lodash');

function determineLogDataType(logs) {
  if (logs.length > 0 && logs[0].hasOwnProperty('startDate') && logs[0].hasOwnProperty('endDate') && logs[0].hasOwnProperty('data')) {
    // If the logs array contains an object with startDate, endDate, and data fields, it is a container for game logs
    return 'container';
  } else if (logs.length > 0 && logs[0].hasOwnProperty('gameName') && logs[0].hasOwnProperty('totalBet') && logs[0].hasOwnProperty('totalWinLose') && logs[0].hasOwnProperty('count')) {
    // If the logs array contains objects with game log fields, it is a set of individual game logs
    return 'individual';
  } else {
    // If the logs array does not match either of the above structures, it is an invalid format
    return 'invalid';
  }
}

export function mergeDataItems(input, options = {}) {
  const logDataType = determineLogDataType(input);

  if (logDataType === 'invalid') return [];
  if (logDataType === 'individual') return input;

  let mappedInput = null;

  const { isHourlyType = false } = options;

  if (isHourlyType) {

    const groupedByDate = groupBy(input, 'startDate');

    const groupedByDateWithLargestEndDate = map(groupedByDate, (group) => {
      return reduce(group, (largestEndDateObj, obj) => {
        return obj.endDate > largestEndDateObj.endDate ? obj : largestEndDateObj;
      }, group[0]);
    }, {});

    mappedInput = groupedByDateWithLargestEndDate.map(i => {
      return i?.data || [];
    });
  } else {
    mappedInput = input.map(i => {
      return i?.data || [];
    });
  }

  const flattenInput = flatten(mappedInput);

  const groupedData = groupBy(flattenInput, 'gameName');

  const result = map(groupedData, (group) => {
    return reduce(group, (sum, obj) => {
      return {
        category: obj.category,
        gameCategory: obj.gameCategory,
        gameName: obj.gameName,
        totalBet: sum.totalBet + obj.totalBet,
        totalWinLose: sum.totalWinLose + obj.totalWinLose,
        count: sum.count + obj.count
      };
    }, {
      category: group[0].category,
      gameCategory: group[0].gameCategory,
      totalBet: 0,
      totalWinLose: 0,
      count: 0
    }); // include the initial value with the category
  });

  return result;
}
