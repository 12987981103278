const defaultThemeData = {
  shape: {
    borderRadius: 4
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '',
          backgroundImage: '',
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '',
          backgroundImage: '',
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 20
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#EBC106',
    },
    secondary: {
      main: '#616161',
    },
    text: {
      icon: '#FFFFFF',
    },
    error: {
      main: '#f50057',
    },
    warning: {
      main: '#ff9100',
    },
    info: {
      main: '#0091ea',
    },
    success: {
      main: '#00e676',
    },
    background: {
      default: '#303030',
      paper: '#212121'
    }
  },
  typography: {
    fontFamily: [
      '"Source Sans Pro"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
  },
};

export default defaultThemeData;