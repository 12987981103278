import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase, each } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AbilityContext } from 'casl/Can';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { lookupsKiosk as lookupKiosks } from 'lookups/games';
import { transformSavedData } from 'utils/form-utils';
import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject } from 'utils/yup-helper';

const _NEWID = '@NewGameUrl';
const _RNAME = 'gameUrls';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();

  const gameOptions = useMemo(
    () => {
      let ret = [];
      each(lookupKiosks, (value, key) => {
        ret.push({ key, value });
      });
      return ret;
    }, []
  );

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        isEnabled: true,
        propagateUrl: false,
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    name: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t('Required'))),
    type: Yup.string().required(t("Required")),
    appType: Yup.string().required(t("Required")),
    url: Yup.string().required(t("Required")),
    isEnabled: Yup.bool().required(t("Required")),
    propagateUrl: Yup.bool().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');

        if (_id === _NEWID) {
          delete values._id;
          const saved = await feathers.service(serviceName).create(values);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, {
            $set: values
          });
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Game URL')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            {
              supportedLocales.map(locale => (
                <Grid key={locale} item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput(`name.${locale}`)}
                    id={`name.${locale}`}
                    name={`name.${locale}`}
                    label={t(`name.${locale}`)}
                    value={get(formik, `values.name.${locale}`, '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.name.${locale}`, false) && Boolean(get(formik, `errors.name.${locale}`))}
                    helperText={get(formik, `touched.name.${locale}`, false) && get(formik, `errors.name.${locale}`)}
                  />
                </Grid>
              ))
            }
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.type', false) && Boolean(get(formik, 'errors.type'))}
              >
                <InputLabel id='apptype-select-label'>{t('Type')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('type')}
                  labelId='apptype-select-label'
                  id='type'
                  name='type'
                  value={get(formik, 'values.type', 'None')}
                  label={t('Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    gameOptions.map(g => (
                      <MenuItem key={g.key} value={g.key}>
                        {g.value}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.type', false) && get(formik, 'errors.type')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.appType', false) && Boolean(get(formik, 'errors.appType'))}
              >
                <InputLabel id='apptype-select-label'>{t('App Type')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('appType')}
                  labelId='apptype-select-label'
                  id='appType'
                  name='appType'
                  value={get(formik, 'values.appType', 'None')}
                  label={t('App Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  <MenuItem value={'android'}>{t('android')}</MenuItem>
                  <MenuItem value={'ios'}>{t('ios')}</MenuItem>
                  <MenuItem value={'web'}>{t('web')}</MenuItem>
                  <MenuItem value={'pc'}>{t('pc')}</MenuItem>
                </Select>
                <FormHelperText>{get(formik, 'touched.appType', false) && get(formik, 'errors.appType')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('url')}
                id='url'
                name='url'
                label={t('URL')}
                value={get(formik, 'values.url', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.url`, false) && Boolean(get(formik, `errors.url`))}
                helperText={get(formik, `touched.url`, false) && get(formik, `errors.url`)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isEnabled', isChecked)
                  }} />
                }
                label={t('Enabled')}
              />
            </Grid>
            {
              !disableInput('propagateUrl') && <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch color='error' checked={get(formik, 'values.propagateUrl', false)} onChange={(event) => {
                      const isChecked = get(event, 'target.checked', false);
                      formik.setFieldValue('propagateUrl', isChecked)
                    }} />
                  }
                  label={t('Propagate URL')}
                />
              </Grid>
            }
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
