import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import captchaSlice from 'features/captchaBridgeDialog/store/slice';
import feathersSlice from 'features/feathersStore/slice';
import { get } from 'lodash';

const rootReducer = (state, action) => {
  if (action.type === '@@RESET') state = undefined;

  return combineReducers({
    // captcha bridge
    captcha: captchaSlice,

    images: createFilteredReducer(feathersSlice, action => reducerFilter('images', action)),
    news: createFilteredReducer(feathersSlice, action => reducerFilter('news', action)),
    announcements: createFilteredReducer(feathersSlice, action => reducerFilter('announcements', action)),
    companySettings: createFilteredReducer(feathersSlice, action => reducerFilter('companySettings', action)),
    users: createFilteredReducer(feathersSlice, action => reducerFilter('users', action)),
    userRelations: createFilteredReducer(feathersSlice, action => reducerFilter('userRelations', action)),
    deposits: createFilteredReducer(feathersSlice, action => reducerFilter('deposits', action)),
    withdrawals: createFilteredReducer(feathersSlice, action => reducerFilter('withdrawals', action)),
    pointRedemptionDeals: createFilteredReducer(feathersSlice, action => reducerFilter('pointRedemptionDeals', action)),
    memberTiers: createFilteredReducer(feathersSlice, action => reducerFilter('memberTiers', action)),
    pointBoosters: createFilteredReducer(feathersSlice, action => reducerFilter('pointBoosters', action)),
    walletHistory: createFilteredReducer(feathersSlice, action => reducerFilter('walletHistory', action)),
    gameLogs: createFilteredReducer(feathersSlice, action => reducerFilter('gameLogs', action)),

    // game related
    balanceTransfers: createFilteredReducer(feathersSlice, action => reducerFilter('balanceTransfers', action)),
    gameIds: createFilteredReducer(feathersSlice, action => reducerFilter('gameIds', action)),
    games: createFilteredReducer(feathersSlice, action => reducerFilter('games', action)),
    gameUrls: createFilteredReducer(feathersSlice, action => reducerFilter('gameUrls', action)),
    kiosks: createFilteredReducer(feathersSlice, action => reducerFilter('kiosks', action)),
    gameApiSettings: createFilteredReducer(feathersSlice, action => reducerFilter('gameApiSettings', action)),

    // lucky draw related
    luckyDrawSettings: createFilteredReducer(feathersSlice, action => reducerFilter('luckyDrawSettings', action)),
    luckyDraws: createFilteredReducer(feathersSlice, action => reducerFilter('luckyDraws', action)),

    // adjustments
    bankAdjustments: createFilteredReducer(feathersSlice, action => reducerFilter('bankAdjustments', action)),
    walletAdjustments: createFilteredReducer(feathersSlice, action => reducerFilter('walletAdjustments', action)),

    // supports
    whatsappBots: createFilteredReducer(feathersSlice, action => reducerFilter('whatsappBots', action)),
    telegramBots: createFilteredReducer(feathersSlice, action => reducerFilter('telegramBots', action)),
    lineBots: createFilteredReducer(feathersSlice, action => reducerFilter('lineBots', action)),
    customerServices: createFilteredReducer(feathersSlice, action => reducerFilter('customerServices', action)),

    // events
    logonLogs: createFilteredReducer(feathersSlice, action => reducerFilter('logonLogs', action)),
    deviceFingerprints: createFilteredReducer(feathersSlice, action => reducerFilter('deviceFingerprints', action)),

    // banks
    companyBanks: createFilteredReducer(feathersSlice, action => reducerFilter('companyBanks', action)),
    bankBots: createFilteredReducer(feathersSlice, action => reducerFilter('bankBots', action)),
    bankTransactions: createFilteredReducer(feathersSlice, action => reducerFilter('bankTransactions', action)),
    clientBanks: createFilteredReducer(feathersSlice, action => reducerFilter('clientBanks', action)),

    // cash rebates
    cashRebates: createFilteredReducer(feathersSlice, action => reducerFilter('cashRebates', action)),

    // design
    themes: createFilteredReducer(feathersSlice, action => reducerFilter('themes', action)),
    luckyWheels: createFilteredReducer(feathersSlice, action => reducerFilter('luckyWheels', action)),

    // cors origins
    corsOrigins: createFilteredReducer(feathersSlice, action => reducerFilter('corsOrigins', action)),
  })(state, action);
};

function reducerFilter(name, action) {
  const metaMatched = get(action, 'meta.arg._RNAME') === name ? true : false;
  const payloadMatched = get(action, 'payload._RNAME') === name ? true : false;
  return (metaMatched || payloadMatched);
}

export const store = configureStore({
  reducer: rootReducer
});

function createFilteredReducer(reducerFunction, reducerPredicate) {
  return (state, action) => {
    const isInitializationCall = state === undefined;
    const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
    return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
  }
}