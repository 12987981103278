import { useCallback } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";

function TextSearchLink({ bankName = '', text = '', searchText = '' }) {
  const navigate = useNavigate();

  const handleTextSearch = useCallback(
    () => {
      if (!text?.length && !searchText?.length) return;
      const cacheBuster = Date.now();
      navigate(`?textSearch="${searchText ? searchText : text}"&cacheBuster=${cacheBuster}`);
    }, [navigate, text, searchText]
  );

  return (
    <Box
      component='span'
      onClick={handleTextSearch}
      sx={{
        textDecoration: 'underline',
        textDecorationColor: theme => (theme.palette.warning.light),
        textDecorationThickness: 2,
        cursor: 'pointer',
      }}
    >
      {bankName === 'my_maybank'
      ? text.split('*').map((line, index) => (
          <Box key={index} component='span' sx={{ display: 'block' }}>
            {line}
          </Box>
        ))
      : ['my_cimb', 'sg_posb'].includes(bankName)
      ? text.split('|').map((line, index) => (
          <Box key={index} component='span' sx={{ display: 'block' }}>
            {line}
          </Box>
        ))
      : text}
    </Box>
  );
}

export default TextSearchLink;
