import React, { useState, useMemo, useCallback, useContext, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Outlet } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import useNotification from 'hooks/useNotification';
import { get, map } from 'lodash';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { lookups as lookupGames } from 'lookups/games';
import DarkModeIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeIcon from '@mui/icons-material/LightModeTwoTone';
import { useAuth } from 'hooks/useAuth';
import { useUserConfig } from 'hooks/useUserConfig';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CaptchaBridgeDialog from 'features/captchaBridgeDialog/CaptchaBridgeDialog';
import { AbilityContext } from 'casl/Can';
import CompanySwitch from 'features/layout/CompanySwitch';
import CountrySwitch from 'features/layout/CountrySwitch';
import { useSound } from 'use-sound';
import notificationSound from 'assets/sounds/ill-make-it-possible-notification.mp3';
import UserDialog from 'features/userDialog/UserDialog';
import packageJson from '../../../package.json';

// icons
import HomeIcon from '@mui/icons-material/Home';
import NewsIcon from '@mui/icons-material/Article';
import UsersIcon from '@mui/icons-material/Group';
import DepositIcon from '@mui/icons-material/TrendingUp';
import WithdrawalIcon from '@mui/icons-material/TrendingDown';
import BalanceTransferIcon from '@mui/icons-material/SyncAlt';
import AdjustmentIcon from '@mui/icons-material/Tune';
import AccountIcon from '@mui/icons-material/AccountCircle';
import LinkIcon from '@mui/icons-material/Link';
import GameIcon from '@mui/icons-material/Casino';
import GiftIcon from '@mui/icons-material/CardGiftcard';
import MembershipIcon from '@mui/icons-material/CardMembership';
import BoosterIcon from '@mui/icons-material/ElectricBolt';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import { LineIcon } from 'features/customIcons/CustomIcons';
import CSIcon from '@mui/icons-material/LiveHelp';
import KioskIcon from '@mui/icons-material/Storefront';
import QrCodeIcon from '@mui/icons-material/QrCode';
import BankIcon from '@mui/icons-material/AccountBalance';
import PigBankIcon from '@mui/icons-material/Savings';
import RoomSettingIcon from '@mui/icons-material/RoomPreferences';
import RoomIcon from '@mui/icons-material/MeetingRoom';
import HistoryIcon from '@mui/icons-material/History';
import LoginIcon from '@mui/icons-material/Key';
import PasswordIcon from '@mui/icons-material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';
import RelationIcon from '@mui/icons-material/Diversity1';
import AnnouncementIcon from '@mui/icons-material/Campaign';
import GameLogIcon from '@mui/icons-material/ReceiptLong';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CompanyIcon from '@mui/icons-material/BusinessTwoTone';
import GameApiSettingIcon from '@mui/icons-material/CableTwoTone';
import ChatIcon from '@mui/icons-material/ChatBubbleTwoTone';
import CorsIcon from '@mui/icons-material/PolicyTwoTone';

import {
  PaletteTwoTone as ThemeIcon,
} from '@mui/icons-material';
import {
  LuckyWheelIcon,
} from 'features/customIcons/CustomIcons';
import {
  PercentTwoTone as PercentIcon,
  DescriptionTwoTone as AssetIcon,
} from '@mui/icons-material';
import CommonContext from 'features/context/commonContext';
import ConfirmationPrompt from 'features/prompt/ConfirmationPrompt';
import { useChats } from 'hooks/useChats';

// logout
import { useLocation, Link } from "react-router-dom";

const drawerWidth = 240;

const Main = styled('main', {
})(
  ({ theme }) => ({
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(9),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  }),
);

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Layout(props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { darkMode, toggleDarkMode } = useUserConfig();
  const { username, name = '' } = user;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const [ open, setOpen ] = useState(isSmallScreen ? false : true);
  const { data: notiData, hasNotification } = useNotification();
  const ability = useContext(AbilityContext);
  const canManageCompany = ability.can('update', 'companySettings');
  const canManageCorsOrigins = ability.can('manage', 'corsOrigins');
  const [ play, { stop } ] = useSound(notificationSound, { loop: true, interrupt: true });
  const { enableNotificationSound, setAppBarHeight } = useContext(CommonContext);
  const appBarRef = useRef(null);
  const { hasUnreadChats } = useChats();
  const { version } = packageJson;

  useEffect(
    () => {
      if (appBarRef.current) {
        setAppBarHeight(appBarRef.current.clientHeight);
      }
    }, [appBarRef, setAppBarHeight]
  );

  useEffect(
    () => {
      if (!enableNotificationSound) {
        stop();
        return;
      }
      if (hasNotification) {
        play();
      } else {
        stop();
      }
    }, [enableNotificationSound, hasNotification, play, stop]
  );

  const handleDarkModeToggle = useCallback(
    (event) => {
      event.preventDefault();
      toggleDarkMode();
    }, [toggleDarkMode]
  );

  const pathname = useMemo(
    () => {
      return location.pathname;
    }, [location]
  );

  const menuItems1 = useMemo(
    () => {
      return [
        { label: t('Deposits'), icon: <DepositIcon />, linkTo: '/deposits' },
        { label: t('Withdrawals'), icon: <WithdrawalIcon />, linkTo: '/withdrawals' },
        { label: t('Bank Adjustments'), icon: <AdjustmentIcon />, linkTo: '/bank-adjustments' },
        { label: t('Wallet Adjustments'), icon: <AdjustmentIcon />, linkTo: '/wallet-adjustments' },
        { label: t('Wallet History'), icon: <HistoryIcon />, linkTo: '/wallet-history' },
        { label: t('Game Logs'), icon: <GameLogIcon />, linkTo: '/game-logs' },
      ];
    }, [t]
  );

  const menuItems2 = useMemo(
    () => {
      const ret = [
        { label: t('Home'), icon: <HomeIcon />, linkTo: '/' },
        { label: t('Assets'), icon: <AssetIcon />, linkTo: '/assets'},
      ];

      if (canManageCorsOrigins) ret.push({ label: t('CORS Origins'), icon: <CorsIcon />, linkTo: '/cors-origins' });
      if (canManageCompany) ret.push({ label: t('Companies'), icon: <CompanyIcon />, linkTo: '/company-settings' });
      return ret;
    }, [t, canManageCompany, canManageCorsOrigins]
  );

  const menuUser = useMemo(
    () => {
      const ret = [
        { label: t('Chats'), icon: <ChatIcon />, linkTo: '/chats' },
        { label: t('Users'), icon: <UsersIcon />, linkTo: '/users' },
        { label: t('User Relations'), icon: <RelationIcon />, linkTo: '/user-relations' },
      ];
      return ret;
    }, [t]
  );

  const menuItems3 = useMemo(
    () => {
      return [
        { label: t('Redemption Deals'), icon: <GiftIcon />, linkTo: '/redemption-deals' },
        { label: t('Member Tiers'), icon: <MembershipIcon />, linkTo: '/member-tiers' },
        { label: t('Point Boosters'), icon: <BoosterIcon />, linkTo: '/point-boosters' },
      ];
    }, [t]
  );

  const luckyDrawMenuItems = useMemo(
    () => {
      return [
        { label: t('Cash Rebate Settings'), icon: <PercentIcon />, linkTo: '/cash-rebate-settings' },
        { label: t('Cash Rebates'), icon: <PercentIcon />, linkTo: '/cash-rebates'},
        { label: t('Lucky Draw Settings'), icon: <RoomSettingIcon />, linkTo: '/lucky-draw-settings' },
        { label: t('Lucky Draws'), icon: <RoomIcon />, linkTo: '/lucky-draws' },
      ];
    }, [t]
  );

  const menuItems4 = useMemo(
    () => {
      return [
        { label: t('Account'), icon: <AccountIcon />, linkTo: '/account' },
        { label: `${t('Logout')} ${name ? name : username}`, icon: <LogoutIcon />, linkTo: '/logout' },
      ];
    }, [t, name, username]
  );

  const gameMenuItems = useMemo(
    () => {
      return [
        { label: t('Balance Transfers'), icon: <BalanceTransferIcon />, linkTo: '/balance-transfers' },
        { label: t('Game Ids'), icon: <PasswordIcon />, linkTo: '/game-ids' },
        { label: t('Games'), icon: <GameIcon />, linkTo: '/games' },
        { label: t('Game URLs'), icon: <LinkIcon />, linkTo: '/game-urls' },
        { label: t('Kiosks'), icon: <KioskIcon />, linkTo: '/kiosks' },
        { label: t('Game APIs'), icon: <GameApiSettingIcon />, linkTo: '/game-api-settings' },
      ];
    }, [t]
  );

  const eventItems = useMemo(
    () => {
      return [
        { label: t('Fingerprints'), icon: <FingerprintIcon />, linkTo: '/device-fingerprints' },
        { label: t('Logon Logs'), icon: <LoginIcon />, linkTo: '/logon-logs' },
      ];
    }, [t]
  );

  const miscMenuItems = useMemo(
    () => {
      return [
        { label: t('Customer Services'), icon: <CSIcon />, linkTo: '/customer-services' },
        { label: t('WhatsApp Bots'), icon: <WhatsAppIcon />, linkTo: '/whatsapp-bots' },
        { label: t('Telegram Bots'), icon: <TelegramIcon />, linkTo: '/telegram-bots' },
        { label: t('Line Bots'), icon: <LineIcon />, linkTo: '/line-bots' },
        { label: t('News'), icon: <NewsIcon />, linkTo: '/news' },
        { label: t('Announcements'), icon: <AnnouncementIcon />, linkTo: '/announcements' },
      ];
    }, [t]
  );

  const designMenuItems = useMemo(
    () => {
      return [
        { label: t('Lucky Wheels'), icon: <LuckyWheelIcon />, linkTo: '/lucky-wheels' },
        { label: t('Themes'), icon: <ThemeIcon />, linkTo: '/themes' },
      ];
    }, [t]
  );

  const bankMenuItems = useMemo(
    () => {
      return [
        { label: t('Banks'), icon: <BankIcon />, linkTo: '/company-banks' },
        { label: t('Bank Bots'), icon: <BankIcon />, linkTo: '/bank-bots' },
        { label: t('Bank Transactions'), icon: <BankIcon />, linkTo: '/bank-transactions' },
        { label: t('Client Banks'), icon: <PigBankIcon />, linkTo: '/client-banks' },
      ];
    }, [t]
  );

  const lowBalanceKiosks = useMemo(
    () => {
      const lbk = get(notiData, 'lbk', []);
      const mappedLbk = map(lbk, k => {
        const type = get(k, 'type', '');
        const namedType = get(lookupGames, type, '');
        return {
          ...k,
          type: namedType
        }
      });
      return mappedLbk;
    }, [notiData]
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const listItems = (items) => {
    return (
      <>
        <List>
          {
            items.map((item) => {
              const isSelected = (item.linkTo === pathname) ? true : false;

              return (
                <Link key={item.linkTo} to={item.linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItem disablePadding sx={{
                    display: 'block',
                    bgcolor: isSelected ? 'divider' : 'inherit',
                  }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )
            })
          }
        </List>
      </>
    );
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <CaptchaBridgeDialog />
        <UserDialog />
        <ConfirmationPrompt />
        <AppBar position="fixed" open={open} ref={appBarRef}>
          <Toolbar sx={{ display: 'flex' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {t("branding:name")}
            </Typography>
            <Box sx={{
              mx: 2,
              gap: 2,
              display: 'flex',
              alignItems: 'center',
            }}>
              <CompanySwitch />
              <CountrySwitch />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={darkMode ? t('Disable Dark Mode') : t('Enable Dark Mode')}>
                <IconButton color='inherit' onClick={handleDarkModeToggle}>
                {
                  darkMode ? <LightModeIcon /> : <DarkModeIcon />
                }
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
              {
                hasUnreadChats &&
                <Link to='/chats' style={{ textDecoration: 'none', color: 'inherit', marginRight: '4px' }}>
                  <IconButton size='large' color='inherit'>
                    <Badge color='error' variant='dot'>
                      <ChatIcon />
                    </Badge>
                  </IconButton>
                </Link>
              }
              {
                notiData.dp > 0 &&
                <Link to='/deposits' style={{ textDecoration: 'none', color: 'inherit', marginRight: '4px' }}>
                  <IconButton size='large' color='inherit'>
                    <Badge badgeContent={notiData.dp} color='success'>
                      <DepositIcon sx={{ color: 'inherit' }} />
                    </Badge>
                  </IconButton>
                </Link>
              }
              {
                notiData.wd > 0 &&
                <Link to='/withdrawals' style={{ textDecoration: 'none', color: 'inherit', marginRight: '4px' }}>
                  <IconButton size='large' color='inherit'>
                    <Badge badgeContent={notiData.wd} color='error'>
                      <WithdrawalIcon sx={{ color: 'inherit' }} />
                    </Badge>
                  </IconButton>
                </Link>
              }
              {
                notiData.bt > 0 &&
                <Link to='/balance-transfers?state=manual' style={{ textDecoration: 'none', color: 'inherit', marginRight: '4px' }}>
                  <IconButton size='large' color='inherit'>
                    <Badge badgeContent={notiData.bt} color='info'>
                      <BalanceTransferIcon />
                    </Badge>
                  </IconButton>
                </Link>
              }
              {
                notiData.wa > 0 &&
                <Link to='/whatsapp-bots' style={{ textDecoration: 'none', color: 'inherit', marginRight: '4px' }}>
                  <IconButton size='large' color='inherit'>
                    <Badge badgeContent={notiData.wa} color='info'>
                      <QrCodeIcon />
                    </Badge>
                  </IconButton>
                </Link>
              }
              {
                lowBalanceKiosks.length > 0 &&
                <PopupState variant="popover" popupId='low-kiosk-balance-popover'>
                  {(popupState) => (
                    <>
                      <IconButton {...bindTrigger(popupState)} size='large' color='inherit' sx={{ marginRight: '4px' }}>
                        <Badge badgeContent={lowBalanceKiosks.length} color='warning'>
                          <KioskIcon />
                        </Badge>
                      </IconButton>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <Box>
                          <DialogTitle sx={{ textAlign: 'center' }}>
                            {t('Low Balance Kiosk')}
                          </DialogTitle>
                          <DialogContent dividers>
                            <List>
                              {
                                lowBalanceKiosks.map((k, index) => {
                                  return (
                                    <ListItem key={k.type}>
                                      <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: 'error.light', color: 'error.dark' }}>
                                          { index + 1 }
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText primary={`${k.type} - ${k.username}`} secondary={k.balance} />
                                    </ListItem>
                                  );
                                })
                              }
                            </List>
                          </DialogContent>
                        </Box>
                      </Popover>
                    </>
                  )}
                </PopupState>
              }
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {
            listItems(menuItems2)
          }
          <Divider />
          {
            listItems(menuUser)
          }
          <Divider />
          {
            listItems(menuItems1)
          }
          <Divider />
          {
            listItems(bankMenuItems)
          }
          <Divider />
          {
            listItems(gameMenuItems)
          }
          <Divider />
          {
            listItems(menuItems3)
          }
          <Divider />
          {
            listItems(luckyDrawMenuItems)
          }
          <Divider />
          {
            listItems(miscMenuItems)
          }
          <Divider />
          {
            listItems(designMenuItems)
          }
          <Divider />
          {
            listItems(eventItems)
          }
          <Divider />
          {
            listItems(menuItems4)
          }
          {
            !!version && (
              <Box sx={{ textAlign: 'right', mx: 1 }}>
                <Typography variant='caption' color={'text.secondary'} sx={{ fontStyle: 'italic' }}>
                  {`v${version}`}
                </Typography>
              </Box>
            )
          }
        </Drawer>
        <Main>
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>
    </React.Fragment>
  );
}
