import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AbilityContext } from 'casl/Can';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { transformSavedData, unsetEmptyValues } from 'utils/form-utils';
import { gameTypeMenu } from 'utils/menu';
import ListSubheader from '@mui/material/ListSubheader';

const _NEWID = '@NewGameLog';
const _RNAME = 'gameLogs';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        isEnabled: true
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    username: Yup.string().nullable(),
    gameType: Yup.string().required(t("Required")),
    gameId: Yup.string().required(t("Required")),
    startDate: Yup.date().required(t("Required")),
    endDate: Yup.date().nullable(),
    outOfSyncMs: Yup.number().integer().nullable(),
    balanceLogs: Yup.array().of(Yup.string()).nullable(),
    dpAmount: Yup.number().nullable(),
    wdAmount: Yup.number().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const flattenVal = flatten(values, { safe: true });

        unsetEmptyValues(flattenVal, [
          'outOfSyncMs',
          'endDate',
        ]);

        if (_id === _NEWID) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          delete flattenVal?.state;
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Game Log')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('username')}
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.gameType', false) && Boolean(get(formik, 'errors.gameType'))}
              >
                <InputLabel id='game-type-select-label'>{t('Game Type')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('gameType')}
                  labelId='game-type-select-label'
                  id='gameType'
                  name='gameType'
                  value={get(formik, 'values.gameType', 'None')}
                  label={t('Game Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    gameTypeMenu(true, true, true).map((g) => {
                      if (g.type === 'subheader') {
                        return <ListSubheader key={g.key}>{t(g.value)}</ListSubheader>
                      }
                      return <MenuItem key={g.key} value={g.key}>{g.value}</MenuItem>
                    })
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.gameType', false) && get(formik, 'errors.gameType')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('gameId')}
                id='gameId'
                name='gameId'
                label={t('Game Id')}
                value={get(formik, 'values.gameId', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.gameId`, false) && Boolean(get(formik, `errors.gameId`))}
                helperText={get(formik, `touched.gameId`, false) && get(formik, `errors.gameId`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('dpAmount')}
                id='dpAmount'
                name='dpAmount'
                label={t('Deposit Amount')}
                value={get(formik, 'values.dpAmount', '0')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.dpAmount`, false) && Boolean(get(formik, `errors.dpAmount`))}
                helperText={get(formik, `touched.dpAmount`, false) && get(formik, `errors.dpAmount`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('wdAmount')}
                id='wdAmount'
                name='wdAmount'
                label={t('Withdrawal Amount')}
                value={get(formik, 'values.wdAmount', '0')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.wdAmount`, false) && Boolean(get(formik, `errors.wdAmount`))}
                helperText={get(formik, `touched.wdAmount`, false) && get(formik, `errors.wdAmount`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('outOfSyncMs')}
                id='outOfSyncMs'
                name='outOfSyncMs'
                label={t('Out of Sync (ms)')}
                value={get(formik, 'values.outOfSyncMs', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.outOfSyncMs`, false) && Boolean(get(formik, `errors.outOfSyncMs`))}
                helperText={get(formik, `touched.outOfSyncMs`, false) && get(formik, `errors.outOfSyncMs`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('balanceLogs')}
                id='balanceLogs'
                name='balanceLogs'
                label={t('Balance Logs')}
                multiline
                rows={3}
                value={get(formik, 'values.balanceLogs', []).join('\n')}  // Join the array of strings with a newline character
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const value = event.target.value;
                  const valuesArray = value === '' ? [] : value
                    .split('\n')
                    .map((val) => val.trim());
                  formik.handleChange({
                    target: {
                      name: 'balanceLogs',
                      value: valuesArray,
                    },
                  });
                }}
                error={get(formik, `touched.balanceLogs`, false) && Boolean(get(formik, `errors.balanceLogs`))}
                helperText={get(formik, `touched.balanceLogs`, false) && get(formik, `errors.balanceLogs`)}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  disabled={disableInput('startDate')}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='startDate'
                  label={t('Start Date')}
                  value={get(formik, 'values.startDate', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('startDate', '');
                    else formik.setFieldValue('startDate', date.toDate());
                  }}
                  error={get(formik, 'touched.startDate', false) && Boolean(get(formik, 'errors.startDate'))}
                  helperText={get(formik, 'touched.startDate', false) && get(formik, 'errors.startDate')}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  disabled={disableInput('endDate')}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='endDate'
                  label={t('End Date')}
                  value={get(formik, 'values.endDate', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('endDate', '');
                    else formik.setFieldValue('endDate', date.toDate());
                  }}
                  error={get(formik, 'touched.endDate', false) && Boolean(get(formik, 'errors.endDate'))}
                  helperText={get(formik, 'touched.endDate', false) && get(formik, 'errors.endDate')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
