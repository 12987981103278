import { createContext } from 'react';

const CommonContext = createContext({
  companySettings: [],
  companySettingsReady: false,
  memberTiers: null,
  memberTiersReady: false,
  bankLookup: [],
  bankBotLookup: [],
  lookupReady: false,
  walletHost: null,
  enableNotificationSound: false,
  appBarHeight: 0,
  setAppBarHeight: () => {},
});

export default CommonContext;
