import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

import useCaptcha from 'hooks/useCaptcha';
import CaptchaRequest from './CaptchaRequest';

export default function CaptchaBridgeDialog(props) {
  const { t } = useTranslation();
  const [ open, setOpen ] = useState(false);

  const { requests, requestCount } = useCaptcha();

  useEffect(() => {
    if (!!requestCount) setOpen(true);
  }, [requests, requestCount]);

  const handleClose = () => {
    setOpen(false);
  };

  if (!requestCount) return null;

  return (
    <Dialog scroll='body' fullWidth={false} maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Captcha Verification')}</DialogTitle>
        {
          requests.map(function (request) {
            return (
              <DialogContent key={request?.captchaId}>
                <CaptchaRequest request={request} />
              </DialogContent>
            )
          })
        }
    </Dialog>
  );
};

/*CaptchaBridgeDialog.propTypes = {
};
*/