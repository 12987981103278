import React, { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from 'react-i18next';

export default function UserProfile({ data }) {
  const { t } = useTranslation();

  const handleWhatsAppClicked = useCallback(
    (event) => {
      event.preventDefault();
      window.open(`https://wa.me/${data?.username}/?text=`, '_blank');
    }, [data]
  );

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography sx={{ fontWeight: 700 }} variant='h6'>
          {t('User Profile')}
        </Typography>
        <Button
          onClick={handleWhatsAppClicked}
          size='small'
          variant="contained"
          startIcon={<WhatsAppIcon />}
          sx={{ bgcolor: '#25D366', '&:hover': { bgcolor: '#1da851' } }}
          color='inherit'
        >
          {t('WhatsApp')}
        </Button>
      </Box>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.name ? 'inherit' : 'text.disabled',
          fontStyle: data.name ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Name')}</span>
        <span>{data.name || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.nickname ? 'inherit' : 'text.disabled',
          fontStyle: data.nickname ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Nickname')}</span>
        <span>{data.nickname || t('TBD')}</span>
      </Typography>
      <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant='subtitle1'>
        <span>{t('Username')}</span>
        <span>{data.username}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.memberSince ? 'inherit' : 'text.disabled',
          fontStyle: data.memberSince ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Member Since')}</span>
        <span>{data.memberSince || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.lastDeposit ? 'inherit' : 'text.disabled',
          fontStyle: data.lastDeposit ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Last Deposit')}</span>
        <span>{data.lastDeposit || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.lastWithdrawal ? 'inherit' : 'text.disabled',
          fontStyle: data.lastWithdrawal ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Last Withdrawal')}</span>
        <span>{data.lastWithdrawal || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.lastSeenLine ? 'inherit' : 'text.disabled',
          fontStyle: data.lastSeenLine ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Line Last Seen')}</span>
        <span>{data.lastSeenLine || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.lastSeenTelegram ? 'inherit' : 'text.disabled',
          fontStyle: data.lastSeenTelegram ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Telegram Last Seen')}</span>
        <span>{data.lastSeenTelegram || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.lastSeenWhatsApp ? 'inherit' : 'text.disabled',
          fontStyle: data.lastSeenWhatsApp ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('WhatsApp Last Seen')}</span>
        <span>{data.lastSeenWhatsApp || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.address ? 'inherit' : 'text.disabled',
          fontStyle: data.address ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Last Location')}</span>
        <span>{data.address || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: data.lastLogon ? 'inherit' : 'text.disabled',
          fontStyle: data.lastLogon ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Last Logon')}</span>
        <span>{data.lastLogon || t('TBD')}</span>
      </Typography>
    </Paper>
  );
}
