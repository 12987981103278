/*
 <FormControlLabel
                control={
                  <Switch
                    disabled={disableInput('isVIPReward')}
                    checked={formik.values.isVIPReward}
                    onChange={(event) => {
                      const isChecked = get(event, 'target.checked', false);
                      formik.setFieldValue('isVIPReward', isChecked);
                    }}
                  />
                }
                label={t('Is VIP Reward')}
              />
*/

import React, { useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { AbilityContext } from 'casl/Can';
import {
  FormControlLabel,
  Switch
} from '@mui/material';

export default function FormikSwitch(props) {
  const [ field ] = useField(props);
  const { initialValues } = useFormikContext();
  const ability = useContext(AbilityContext);

  const { caslSubject = '', _id } = initialValues;
  const { name: fieldName, value: fieldValue = false } = field;

  const action = _id === undefined ? 'create' : 'update';
  const isAllowed = ability.can(action, caslSubject, fieldName);

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={!isAllowed}
          {...field}
          {...props}
          checked={fieldValue}
        />
      }
      label={props.label}
    />
  );
}