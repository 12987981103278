import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import FILTER_TYPES from './FilterTypes';
import { transformFilterFormData } from 'utils/form-utils';
const _RNAME = 'walletHistory';

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));

  const data = useMemo(
    () => {
      if (!filter) return { filters: [] };
      return filter;
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    username: Yup.string().nullable(),
    filters: Yup.array().of(Yup.string()),
    dateFrom: Yup.date().nullable(),
    dateTo: Yup.date().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values, true);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  const handleToggle = useCallback((option, filters, setFieldValue) => {
    const isChecked = filters.includes(option);
    const newFilters = isChecked
      ? filters.filter((filter) => filter !== option)
      : [...filters, option];
    setFieldValue('filters', newFilters);
  }, []);

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{t('Filter Types')}</FormLabel>
                <FormGroup>
                  {
                    FILTER_TYPES.map((option) => {
                      const filters = get(formik, 'values.filters', []);
                      const isChecked = filters.includes(option);

                      return (
                        <FormControlLabel
                          key={option}
                          control={
                            <Switch
                              checked={isChecked}
                              onChange={() => handleToggle(option, filters, formik.setFieldValue)}
                              name={option}
                              color="primary"
                            />
                          }
                          label={t(option)}
                        />
                      );
                    })
                  }
                </FormGroup>
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='dateFrom'
                  label={t('Date From')}
                  value={get(formik, 'values.dateFrom', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('dateFrom', '');
                    else formik.setFieldValue('dateFrom', date.toDate());
                  }}
                  error={get(formik, 'touched.dateFrom', false) && Boolean(get(formik, 'errors.dateFrom'))}
                  helperText={get(formik, 'touched.dateFrom', false) && get(formik, 'errors.dateFrom')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='dateTo'
                  label={t('Date To')}
                  value={get(formik, 'values.dateTo', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('dateTo', '');
                    else formik.setFieldValue('dateTo', date.toDate());
                  }}
                  error={get(formik, 'touched.dateTo', false) && Boolean(get(formik, 'errors.dateTo'))}
                  helperText={get(formik, 'touched.dateTo', false) && get(formik, 'errors.dateTo')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleSave}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
