import {
  lookupsKiosk,
  lookupsTransferApi,
  lookupsSeamlessApi,
} from 'lookups/games';

export function gameTypeMenu(showKiosk, showTransfer, showSeamless) {
  let ret = [];

  if (showSeamless && Object.keys(lookupsSeamlessApi).length) {
    ret.push({
      key: 'seamless',
      value: 'Seamless',
      type: 'subheader'
    })
    for (let key in lookupsSeamlessApi) {
      ret.push({ key, value: lookupsSeamlessApi[key] });
    }
  }

  if (showTransfer && Object.keys(lookupsTransferApi).length) {
    ret.push({
      key: 'transfer',
      value: 'Transfer',
      type: 'subheader'
    })
    for (let key in lookupsTransferApi) {
      ret.push({ key, value: lookupsTransferApi[key] });
    }
  }

  if (showKiosk && Object.keys(lookupsKiosk).length) {
    ret.push({
      key: 'kiosk',
      value: 'Kiosk',
      type: 'subheader'
    })
    for (let key in lookupsKiosk) {
      ret.push({ key, value: lookupsKiosk[key] });
    }
  }

  return ret;
};