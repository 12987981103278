import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { get, filter, orderBy } from 'lodash';
import Decimal from 'decimal.js';

import useFeathers from 'hooks/useFeathers';

export default function PreviewDialog(props) {
  const { open, onClose, luckyDrawId, previewType = 'participants' } = props;
  const { t } = useTranslation();
  const { data: luckyDrawData, ready: luckyDrawDataReady } = useFeathers('lucky-draw-data', {
    query: {
      luckyDrawId,
      $limit: 1
    }
  }, {
    luckyDrawId
  });

  const isParticipantType = useMemo(
    () => {
      const ret = !!(previewType === 'participants');
      return ret;
    }, [previewType]
  );

  const data = useMemo(
    () => {
      if (!luckyDrawDataReady || !luckyDrawData?.length) return [];

      const usernames = get(luckyDrawData[0], isParticipantType ? 'participants' : 'winners', []);
      const prizes = get(luckyDrawData[0], 'prizes', []);

      const mapped = usernames.map(function(u, index) {
        const prize = get(prizes, `[${index}].$numberDecimal`);

        return {
          username: u,
          ...(!!prize && {
            prize
          })
        }
      });

      const filtered = filter(mapped, function({ username }) {
        return !!(username.indexOf('#') < 0);
      });

      return orderBy(filtered, [
        function (d) {
          const prize = d?.prize || 0;
          return new Decimal(prize).toNumber();
        }
      ], ['desc'])
    }, [luckyDrawData, luckyDrawDataReady, isParticipantType]
  );

  const title = useMemo(
    () => {
      const ret = isParticipantType ? t('Real Participants', { count: data.length }) : t('Real Winners', { count: data.length });
      return ret;
    }, [isParticipantType, data, t]
  );

  const handleClose = (event) => {
    onClose(event);
  };

  const Row = ({ index, style }) => {
    const { username, prize } = data[index];
    return (
      <ListItem style={style} sx={{ textAlign: 'center' }} disablePadding>
        <ListItemText primary={username}
          {
            ...(!isParticipantType && !!prize && {
              secondary: new Decimal(prize).toFixed(2)
            })
          }
        />
      </ListItem>
    );
  };

  return (
    <Dialog maxWidth='xs' fullWidth open={open} onClose={handleClose}>
      {
        title && <DialogTitle>{title}</DialogTitle>
      }
      <DialogContent dividers sx={{ overflowX: 'hidden', minHeight: '50vh' }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="list-username"
              height={height}
              itemCount={data.length}
              itemSize={50}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' autoFocus onClick={handleClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PreviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
