import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function ColumnVisibilityDialog({ instance, isOpen, onClose }) {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const handleCheckAll = () => {
    instance.getToggleAllColumnsVisibilityHandler()(true);
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('Column Visibility')}</DialogTitle>
      <DialogContent>
        {instance.getAllLeafColumns().map(column => {
          const parentLabel = column.parent ? column.parent.columnDef.header() : '';
          const label = column.columnDef.header();
          const fullLabel = parentLabel ? `${parentLabel} - ${label}` : label;

          return (
            <Box key={column.id}>
              <FormControlLabel
                control={
                  <Switch
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  />
                }
                label={fullLabel}
              />
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleCheckAll}>{instance.getIsAllColumnsVisible() ? t('Select None') : t('Select All')}</Button>
        <Button onClick={handleClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
