import React, { useContext, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {
  TreeView,
  TreeItem,
} from '@mui/x-tree-view';
import {
  CheckBox as CheckBoxIcon,
  ChevronRight as RightIcon,
  Clear as ClearIcon,
  CloudUpload as UploadIcon,
  ContentCut as CutIcon,
  ContentPaste as PasteIcon,
  CreateNewFolder as CreateFolderIcon,
  Delete as DeleteIcon,
  DeleteForever as PermanentDeleteIcon,
  DescriptionTwoTone as FileIcon,
  Edit as EditIcon,
  ExpandMore as MoreIcon,
  FolderTwoTone as FolderIcon,
  LinkTwoTone as LinkIcon,
  RestoreFromTrash as RestoreIcon,
  Search as SearchIcon,
  SortByAlpha as SortByAlphaIcon,
} from '@mui/icons-material';
import AssetContext from 'features/context/assetContext';
import { useTranslation } from 'react-i18next';
import axios from 'services/axios';
// use react-window to render large list of files
import {
  FixedSizeList as List,
} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ProtectedImage from 'features/img/ProtectedImage';
import CommonContext from 'features/context/commonContext';
import { uniq } from 'lodash';
import PromptContext from 'features/context/promptContext';
import AssetUploader from './AssetUploader';
import copyToClipboard from 'utils/copy-to-clipboard';
import { useAuth } from 'hooks/useAuth';
import useMediaQuery from '@mui/material/useMediaQuery';

const HOME_FOLDER = 'home';

export default function AssetExplorer(props) {
  const { onSelected = () => { } } = props;
  const { appBarHeight } = useContext(CommonContext);
  const { user } = useAuth();

  const { t } = useTranslation();
  const {
    folders,
    data,
    total,
    path,
    breadcrumbsData,
    isFetching,
    errorMessage,
    fetchNextPage,
    parentAssetId,
    setParentAssetId,
    sortName,
    setSortName,
    searchText,
    onSearchText,
    onClearSearchText,
    isInSearch = false,
    singleSelect = false,
  } = useContext(AssetContext);
  const { setGlobalErrorMessage, setGlobalMessage } = useGlobalMessageActionsContext();
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState([HOME_FOLDER]);
  const [moveAssetId, setMoveAssetId] = useState(null);
  const listRef = useRef(null);
  const [listPositionY, setListPositionY] = useState(0);
  const { openPrompt } = useContext(PromptContext);
  const [openUploader, setOpenUploader] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const showAssetTree = useMemo(() => {
    return !isMobile;
  }, [isMobile]);

  const isRootInWildcard = useMemo(
    () => {
      const { role, companyId } = user;
      return role === 'root' && companyId === '*';
    }, [user]
  );

  useEffect(
    () => {
      if (!errorMessage) return;
      setGlobalErrorMessage({ err: new Error(errorMessage) });
    }, [errorMessage, setGlobalErrorMessage]
  );

  useEffect(
    () => {
      if (listRef.current) {
        const { top } = listRef.current?.getBoundingClientRect();
        setListPositionY(top);
      }
    }, []
  );

  const listHeight = useMemo(
    () => {
      return `calc(100vh - ${appBarHeight}px - ${listPositionY}px - 24px)`;
    }, [appBarHeight, listPositionY]
  );

  useEffect(
    () => {
      setSelected([]);
    },
    [path]
  );

  const isInHome = useMemo(
    () => {
      return path === null;
    }, [path]
  );

  const isInTrash = useMemo(
    () => {
      return path === ',trash,' && !isInSearch;
    }, [path, isInSearch]
  );

  const isInEditablePath = useMemo(
    () => {
      return !isInHome && !isInTrash && !isInSearch;
    }, [isInHome, isInTrash, isInSearch]
  );

  const showPath = useMemo(
    () => {
      return isInTrash || isInSearch;
    }, [isInTrash, isInSearch]
  );

  const onNodeSelect = useCallback(
    (event, assetId) => {
      event.preventDefault();
      setParentAssetId(assetId);
    }, [setParentAssetId]
  );

  const onNodeToggle = useCallback(
    (event, nodeIds) => {
      event.preventDefault();
      setExpanded(nodeIds);
    }, []
  );

  const onFileDownload = useCallback(
    async (asset) => {
      const { _id } = asset;

      try {
        const response = await axios.get(`/asset/${_id}`, {
          responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        //link.download = name;
        link.target = '_blank';
        link.click();
        link.remove();
      } catch (err) {
        console.error(err);
      }
    },
    []
  );

  const onFolderCreate = useCallback(() => {
    async function createFolder(folderName) {
      try {
        await feathers.service('/assets').create({
          type: 'folder',
          name: folderName,
          path: path === HOME_FOLDER ? null : path,
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    async function confirmationPrompt() {
      const result = await openPrompt({
        message: t('assetExplorer.createFolderPrompt'),
        title: t('assetExplorer.confirmation'),
        defaultTextFieldValue: '',
        confirmLabel: t('assetExplorer.confirm'),
        cancelLabel: t('assetExplorer.cancel'),
        confirmColor: 'primary',
        cancelColor: 'inherit',
      });

      if (!result) return;

      createFolder(result);
    }

    confirmationPrompt();
  }, [path, setGlobalErrorMessage, t, openPrompt]);

  const onAssetDelete = useCallback(() => {
    if (!selected?.length) return;

    let isMounted = true;

    async function deleteAssets() {
      try {
        for (const id of uniq(selected.reverse())) {

          await feathers.service('/assets').patch(id, {
            isDeleted: true,
          });
        }
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        if (isMounted) setSelected([]);
      }
    }

    async function confirmationPrompt() {
      const result = await openPrompt({
        message: t('assetExplorer.deletePrompt'),
        title: t('assetExplorer.confirmation'),
        confirmLabel: t('assetExplorer.confirm'),
        cancelLabel: t('assetExplorer.cancel'),
        confirmColor: 'error',
        cancelColor: 'inherit',
      });

      if (!result) return;

      deleteAssets();
    }

    confirmationPrompt();

    return () => {
      isMounted = false;
    };
  }, [selected, setGlobalErrorMessage, t, openPrompt]);

  const onAssetRestore = useCallback(() => {
    if (!selected?.length) return;

    let isMounted = true;

    async function restoreAssets() {
      try {
        for (const id of uniq(selected.reverse())) {
          await feathers.service('/assets').patch(id, {
            isDeleted: false,
          });
        }
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        if (isMounted) setSelected([]);
      }
    }

    async function confirmationPrompt() {
      const result = await openPrompt({
        message: t('assetExplorer.restorePrompt'),
        title: t('assetExplorer.confirmation'),
        confirmLabel: t('assetExplorer.confirm'),
        cancelLabel: t('assetExplorer.cancel'),
        confirmColor: 'success',
        cancelColor: 'inherit',
      });

      if (!result) return;

      restoreAssets();
    }

    confirmationPrompt();

    return () => {
      isMounted = false;
    };
  }, [selected, setGlobalErrorMessage, t, openPrompt]);

  const onAssetRename = useCallback(() => {
    if (!selected?.length) return;

    const assetToRename = data.find((a) => a._id === selected[0]);
    if (assetToRename === undefined) return;

    const { name, _id: assetIdToRename } = assetToRename;

    async function renameAsset(newName) {
      try {
        await feathers.service('/assets').patch(assetIdToRename, {
          name: newName,
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    async function confirmationPrompt() {
      const result = await openPrompt({
        message: t('assetExplorer.renamePrompt'),
        title: t('assetExplorer.confirmation'),
        defaultTextFieldValue: name,
        confirmLabel: t('assetExplorer.confirm'),
        cancelLabel: t('assetExplorer.cancel'),
        confirmColor: 'info',
        cancelColor: 'inherit',
      });

      if (!result) return;

      renameAsset(result);
    }

    confirmationPrompt();

  }, [selected, data, setGlobalErrorMessage, t, openPrompt]);

  const onAssetCut = useCallback(() => {
    if (!selected?.length) return;

    setMoveAssetId(selected[0]);
  }, [selected]);

  const onAssetPaste = useCallback(() => {
    if (moveAssetId === null) return;
    if (path === HOME_FOLDER) return;

    async function moveAsset() {
      try {
        await feathers.service('/assets').patch(moveAssetId, {
          path: path,
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        setMoveAssetId(null);
      }
    }

    moveAsset();
  }, [moveAssetId, path, setGlobalErrorMessage]);

  const onPermanentDelete = useCallback(() => {
    if (!selected?.length) return;

    let isMounted = true;

    async function confirmationPrompt() {
      const result = await openPrompt({
        message: t('assetExplorer.permanentDeletePrompt'),
        title: t('assetExplorer.confirmation'),
        confirmLabel: t('assetExplorer.confirm'),
        cancelLabel: t('assetExplorer.cancel'),
        confirmColor: 'error',
        cancelColor: 'inherit',
      });

      if (!result) return;

      permanentDelete();
    }

    async function permanentDelete() {
      try {
        for (const id of uniq(selected.reverse())) {
          await feathers.service('/assets').patch(id, {
            permanentDeletedAt: new Date(),
          });
        }
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        if (isMounted) setSelected([]);
      }
    }

    confirmationPrompt();

    return () => {
      isMounted = false;
    };
  }, [selected, setGlobalErrorMessage, t, openPrompt]);

  const handleFetchMore = useCallback(
    () => {
      fetchNextPage();
    },
    [fetchNextPage]
  );

  const renderTree = (folders, path = null, initialKey) => {
    const filteredFolders = folders.filter((f) => f.path === path);

    let key = !initialKey ? HOME_FOLDER : initialKey;

    // get the last part of the path as label
    const label = path?.split(',').filter((p) => p !== '').pop() || HOME_FOLDER;

    // if there's no folder, return a single TreeItem
    if (filteredFolders.length === 0) {
      return (
        <TreeItem key={key} nodeId={key} label={label} />
      );
    }

    return (
      <TreeItem key={key} nodeId={key} label={label}>
        {filteredFolders.map((folder) => {
          const { path, name, _id: key } = folder;
          const fullPath = path === null ? `,${name},` : `${path}${name},`;

          return renderTree(folders, fullPath, key);
        })}
      </TreeItem>
    );
  };

  const toggleSelected = useCallback(
    (id) => {

      if (singleSelect) {
        setSelected(prev => {
          if (prev.includes(id)) {
            return [];
          }
          return [id];
        });
        return;
      }

      setSelected((prev) => {
        let ret;
        if (prev.includes(id)) {
          ret = prev.filter((i) => i !== id);
          return ret;
        } else {
          ret = [...prev, id];
          return ret;
        }
      });
    }, [singleSelect]
  );

  const handleLinkClick = useCallback(
    (assetId) => {
      setParentAssetId(assetId);
    }, [setParentAssetId]
  );

  const Row = useCallback(
    ({ data, index, style }) => {
      //const { data: items, toggleSelected: myToggleSelected } = data;
      const item = data[index];

      if (!item) return null;

      const {
        _id,
        isSelected,
        createdBy,
        mimeType,
        name,
        originalPath,
        path,
        size = 0,
        type,
      } = item;

      const isImage = mimeType?.startsWith('image');
      const realPath = isInTrash ? originalPath : path;

      const sizeInKB = (size / 1024).toFixed(1);

      return (
        <ListItemButton onClick={(e) => {
          e.preventDefault();
          toggleSelected(_id);
          //myToggleSelected(_id);
        }} key={_id} style={style}>
          <ListItemIcon>
            {
              isSelected ? (
                <CheckBoxIcon />
              ) :
                type === 'folder' ? (
                  <FolderIcon />
                ) : (
                  <FileIcon />
                )
            }
          </ListItemIcon>
          {
            isImage && (
              <ListItemAvatar sx={{
                mr: 2,
              }}>
                <ProtectedImage
                  sx={{
                    height: 68,
                  }}
                  assetId={_id}
                  name={name}
                  thumbnail
                />
              </ListItemAvatar>
            )
          }
          <ListItemText
            primary={
              <Link href="#" underline="hover" variant="body1" color="inherit" onClick={(e) => {
                e.preventDefault();
                if (type === 'folder') {
                  handleLinkClick(_id);
                } else {
                  onFileDownload(item);
                }
                e.stopPropagation();
              }} >
                {name}
              </Link>
            }
            secondary={
              <Box sx={{ display: 'flex', gap: 1 }}>
                {
                  type === 'file' && (
                    <Typography variant="body2" color="textSecondary">
                      {`${sizeInKB} kB`}
                    </Typography>
                  )
                }
                {
                  createdBy && (
                    <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                      ({createdBy})
                    </Typography>
                  )
                }
                {
                  showPath && realPath && (
                    <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                      {realPath?.replace(/,/g, '/')}
                    </Typography>
                  )
                }
              </Box>
            }
            disableTypography
          />
        </ListItemButton>
      );
    }, [toggleSelected, onFileDownload, isInTrash, showPath, handleLinkClick]
  );

  const itemData = useMemo(
    () => {
      return data.map((d) => {

        const { name, path, originalPath, companyId } = d;
        const realPath = isInTrash ? originalPath : path;

        let humanReadableAssetId;

        if (companyId === '*') {
          humanReadableAssetId = `${name}?path=${realPath}`;
        } else {
          humanReadableAssetId = `${name}?path=${realPath}&companyId=${companyId}`;
        }

        return {
          ...d,
          humanReadableAssetId,
          isSelected: selected.includes(d._id),
        };
      });
    }, [data, selected, isInTrash]
  );

  useEffect(
    () => {
      if (isRootInWildcard) {
        const selectedHumanReadable = selected.map((s) => {
          const item = itemData.find((i) => i._id === s);
          if (!item) return null;
          return item.humanReadableAssetId;
        }).filter((s) => s !== null);

        if (onSelected) onSelected(selectedHumanReadable);
        return;
      }

      if (onSelected) onSelected(selected);
    }, [selected, onSelected, isRootInWildcard, itemData]
  );

  const onGetLocalLink = useCallback(() => {
    if (!selected?.length) return;

    const item = itemData.find((i) => i._id === selected[0]);

    if (!item) return;

    const { humanReadableAssetId } = item;

    const link = `/asset/${humanReadableAssetId}`;
    copyToClipboard(link);
    setSelected([]);

    setGlobalMessage({
      message: t('assetExplorer.localLinkCopied'),
      severity: 'success',
    });
  }, [selected, itemData, setGlobalMessage, t]);

  const handleCloseUploader = useCallback(() => {
    setOpenUploader(false);
  }, []);

  const handleOpenUploader = useCallback(() => {
    setOpenUploader(true);
  }, []);

  return (
    <Box>
      <Dialog open={openUploader} onClose={handleCloseUploader} fullWidth maxWidth='md'>
        <DialogTitle>
          {t('Upload Asset')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {path?.replace(/,/g, ' > ')}
          </DialogContentText>
          <AssetUploader onClose={handleCloseUploader} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUploader}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 2,
        flexWrap: 'wrap',
        gap: 1,
      }}>
        <Box sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Typography variant="h6">
            {t('assetExplorer.title')}
          </Typography>
          <Button
            startIcon={<CreateFolderIcon />}
            variant="outlined"
            color="primary"
            onClick={onFolderCreate}
            disabled={!isInEditablePath}
          >
            {t('assetExplorer.createFolder')}
          </Button>
          <Button
            startIcon={<UploadIcon />}
            variant="outlined"
            color="primary"
            onClick={handleOpenUploader}
            disabled={!isInEditablePath}
          >
            {t('assetExplorer.upload')}
          </Button>
        </Box>
        <TextField
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={onClearSearchText}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder={t('assetExplorer.search')}
          value={searchText}
          onChange={onSearchText}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={2} sx={{
          display: showAssetTree ? 'block' : 'none',
        }}>
          <Paper
            variant='outlined'
            sx={{
              p: 2,
            }}
          >
            <TreeView
              defaultCollapseIcon={<MoreIcon />}
              defaultExpandIcon={<RightIcon />}
              expanded={expanded}
              selected={parentAssetId ? parentAssetId : HOME_FOLDER}
              onNodeSelect={onNodeSelect}
              onNodeToggle={onNodeToggle}
            >
              {
                renderTree(folders)
              }
            </TreeView>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <LinearProgress sx={{
            visibility: isFetching ? 'visible' : 'hidden',
            mb: 1,
          }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pb: 2,
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Checkbox
                sx={{
                  ml: 1
                }}
                disabled={data.length === 0}
                checked={selected.length === data.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelected(data.map((d) => d._id));
                  } else {
                    setSelected([]);
                  }
                }}
              />
              <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="textSecondary">
                {selected.length} {t('assetExplorer.selected')}
              </Typography>
              {
                breadcrumbsData && (
                  <Breadcrumbs>
                    {
                      breadcrumbsData.map(({ name, assetId }, index) => {
                        const isLast = index === breadcrumbsData.length - 1;

                        if (isLast || !isInEditablePath) return (
                          <Typography
                            key={assetId}
                            variant='body2'
                            color="textSecondary"
                          >
                            {name}
                          </Typography>
                        );

                        return (
                          <Link
                            variant='body2'
                            key={assetId}
                            color="inherit"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick(assetId);
                            }}
                          >
                            {name}
                          </Link>
                        );
                      })
                    }
                  </Breadcrumbs>
                )
              }
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 1,
            }}>
              {
                isInTrash && (
                  <>
                    <Button
                      startIcon={<PermanentDeleteIcon />}
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={onPermanentDelete}
                    >
                      {t('assetExplorer.permanentDelete')}
                    </Button>
                    <Button
                      startIcon={<RestoreIcon />}
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={onAssetRestore}
                    >
                      {t('assetExplorer.restore')}
                    </Button>
                  </>
                )
              }
              {
                !isInTrash && (
                  <>
                    <Button
                      startIcon={<LinkIcon />}
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={onGetLocalLink}
                      disabled={!selected.length}
                    >
                      {t('assetExplorer.getLocalLink')}
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={onAssetDelete}
                      disabled={!isInEditablePath}
                    >
                      {t('assetExplorer.delete')}
                    </Button>
                    <Button
                      startIcon={<EditIcon />}
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onAssetRename}
                      disabled={!isInEditablePath}
                    >
                      {t('assetExplorer.rename')}
                    </Button>
                    <Button
                      startIcon={<CutIcon />}
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onAssetCut}
                      disabled={!isInEditablePath || !!moveAssetId}
                    >
                      {t('assetExplorer.cut')}
                    </Button>
                    <Button
                      startIcon={<PasteIcon />}
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onAssetPaste}
                      disabled={!isInEditablePath || !moveAssetId}
                    >
                      {t('assetExplorer.paste')}
                    </Button>
                  </>
                )
              }
              <Button
                startIcon={
                  <SortByAlphaIcon
                    sx={{
                      transform: `scaleX(${sortName === 1 ? 1 : -1})`,
                    }}
                  />
                }
                size="small"
                variant="contained"
                color="info"
                onClick={() => {
                  setSortName(sortName === 1 ? -1 : 1);
                }}
              >
                {t('assetExplorer.sort')}
              </Button>
            </Box>
          </Box>
          <Box
            ref={listRef}
            sx={{
              flexGrow: 2,
              height: listHeight,
            }}
          >
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={(index) => index < data.length}
                  itemCount={total}
                  loadMoreItems={handleFetchMore}
                >
                  {({ onItemsRendered, ref }) => {
                    return (
                      <List
                        height={height}
                        width={width}
                        itemCount={data.length}
                        itemSize={70}
                        itemData={itemData}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                      >
                        {Row}
                      </List>
                    )
                  }}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
