import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import Decimal from 'decimal.js';
import AliasDialog from 'features/aliasDialog/AliasDialog';
import CommonContext from 'features/context/commonContext';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'clientBanks';
const table = createColumnHelper();

export default function ClientBanks() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const [ aliasDialog, setAliasDialog ] = useState({
    open: false,
    title: '',
    data: []
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canCreate = ability.can('create', _RNAME);
  const canUpdate = ability.can('update', _RNAME);
  const canDelete = ability.can('delete', _RNAME);
  const { bankLookup, lookupReady } = useContext(CommonContext);

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor('accountNumber', {
          id: 'accountNumber',
          cell: info => info.getValue(),
          header: () => t('Account Number'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const t = get(row, 'bankName');
          const lookup = get(bankLookup, t, '');
          return lookup;
        }, {
          id: 'bankName',
          cell: info => info.getValue(),
          header: () => t('Bank Name'),
          footer: props => props.column.id,
        }),
        table.group({
          id: 'usernameAlias',
          header: () => t('Username Alias'),
          footer: props => props.column.id,
          columns: [
            table.accessor('usernameAlias', {
              id: 'usernameAlias.initialValue',
              cell: info => {
                const val = info.getValue() || [];
                const first = get(val, '[0]', '');
                return (
                  <Box component='span'>
                    {first}
                  </Box>
                );
              },
              header: () => t('Initial Value'),
              footer: props => props.column.id,
              enableSorting: false,
            }),
            table.accessor('usernameAlias', {
              id: 'usernameAlias.total',
              cell: info => {
                const val = info.getValue() || [];
                const aliasLength = val.length;
                const fontWeight = val.length > 1 ? 700 : 300;
                return (
                  <Button sx={{ fontWeight }} onClick={() => {
                    setAliasDialog({
                      open: true,
                      title: t('Username Alias'),
                      data: val
                    })
                  }} color='info' variant='text'>{aliasLength}
                  </Button>
                );
              },
              header: () => t('Total'),
              footer: props => props.column.id,
              enableSorting: false,
            }),
          ]
        }),
        table.group({
          id: 'accountNameAlias',
          header: () => t('Account Name Alias'),
          footer: props => props.column.id,
          columns: [
            table.accessor('accountNameAlias', {
              id: 'accountNameAlias.initialValue',
              cell: info => {
                const val = info.getValue() || [];
                const first = get(val, '[0]', '');
                return (
                  <Box component='span'>
                    {first}
                  </Box>
                );
              },
              header: () => t('Initial Value'),
              footer: props => props.column.id,
              enableSorting: false,
            }),
            table.accessor('accountNameAlias', {
              id: 'accountNameAlias.total',
              cell: info => {
                const val = info.getValue() || [];
                const aliasLength = val.length;
                const fontWeight = val.length > 1 ? 700 : 300;
                return (
                  <Button sx={{ fontWeight }} onClick={() => {
                    setAliasDialog({
                      open: true,
                      title: t('Account Name Alias'),
                      data: val
                    })
                  }} color='info' variant='text'>{aliasLength}
                  </Button>
                );
              },
              header: () => t('Total'),
              footer: props => props.column.id,
              enableSorting: false,
            }),
          ]
        }),
        table.accessor('cashOutCount', {
          id: 'cashOutCount',
          cell: info => info.getValue(),
          header: () => t('Cash Out Count'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const cashOutAmountRaw = get(row, `cashOutAmount.$numberDecimal`);
          if (!cashOutAmountRaw) return '';
          const cashOutAmount = new Decimal(cashOutAmountRaw).toFixed(2);
          return cashOutAmount;
        }, {
          id: 'cashOutAmount',
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right'
              }}
              element='span'>
              {val}
            </Box>
          },
          header: () => t('Cash Out Amount'),
          footer: props => props.column.id,
        }),
        table.accessor('remark', {
          id: 'remark',
          cell: info => info.getValue(),
          header: () => t('Remark'),
          footer: props => props.column.id,
        }),
        table.accessor('status', {
          id: 'status',
          cell: info => t(info.getValue()),
          header: () => t('Status'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang, bankLookup]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnCreateClick = useCallback(
    (event) => {
      event.preventDefault();
      setEditData({});
      setOpenForm(true);
    }, []
  );

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  const handleAliasDialogClose = useCallback(
    () => {
      setAliasDialog(prev => ({
        ...prev,
        open: false
      }));
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('Edit')}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Client Bank')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <AliasDialog { ...aliasDialog } onClose={handleAliasDialogClose} />
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      {
        !!lookupReady &&
        <Table
          name={t('Client Banks')}
          rname={_RNAME}
          defaultColumns={defaultColumns}
          canCreate={canCreate}
          onCreateClick={handleOnCreateClick}
          onFilterClick={handleOnFilterClick}
          defaultColumnVisibility={{
            _id: false,
          }}
        />
      }
    </Box>
  );
}
