import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import { AbilityContext } from 'casl/Can';
import PlusIcon from '@mui/icons-material/AddCircle';
import MinusIcon from '@mui/icons-material/RemoveCircle';
import { useSelector } from 'react-redux';
import { getRemarks } from 'features/feathersStore/selectors';
import Autocomplete from '@mui/material/Autocomplete';
import { transformSavedData } from 'utils/form-utils';

const _NEWID = '@NewWalletAdjustment';
const _RNAME = 'walletAdjustments';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const remarks = useSelector(getRemarks(_RNAME));

  const data = useMemo(
    () => {
      if (savedData && savedData._id) return savedData;

      // for template
      if (propData && propData._id) {
        const transformedData = transformSavedData(propData);
        if (propData._id === '__template__') {
          return {
            ...transformedData,
            username: '',
            _id: _NEWID
          }
        }

        return transformedData;
      }

      return {
        _id: _NEWID,
        remark: ''
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    username: Yup.string().required(t("Required")),
    balanceType: Yup.string().required(t("Required")),
    type: Yup.string().required(t("Required")),
    amount: Yup.number().required(t("Required")).min(0.01),
    remark: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const flattenVal = flatten(values);
        if (_id === _NEWID) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Wallet Adjustment')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('username')}
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableInput('type')}
                onClick={() => formik.setFieldValue('type', 'plus')}
                size='large'
                fullWidth
                variant={ formik.values.type === 'plus' ? 'contained' : 'outlined' }
                color={ formik.values.type === 'plus' ? 'success' : 'primary' }
                startIcon={<PlusIcon />}
              >
                {t('plus')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableInput('type')}
                onClick={() => formik.setFieldValue('type', 'minus')}
                size='large'
                fullWidth
                variant={ formik.values.type === 'minus' ? 'contained' : 'outlined' }
                color={ formik.values.type === 'minus' ? 'error' : 'primary' }
                startIcon={<MinusIcon />}
              >
                {t('minus')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('amount')}
                id='amount'
                name='amount'
                label={t('Amount')}
                value={get(formik, 'values.amount', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, 'touched.amount', false) && Boolean(get(formik, 'errors.amount'))}
                helperText={get(formik, 'touched.amount', false) && get(formik, 'errors.amount')}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableInput('balanceType')}
                onClick={() => formik.setFieldValue('balanceType', 'cash')}
                size='large'
                fullWidth
                variant={ formik.values.balanceType === 'cash' ? 'contained' : 'outlined' }
                color={ formik.values.balanceType === 'cash' ? 'warning' : 'primary' }
              >
                {t('cash')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableInput('balanceType')}
                onClick={() => formik.setFieldValue('balanceType', 'point')}
                size='large'
                fullWidth
                variant={ formik.values.balanceType === 'point' ? 'contained' : 'outlined' }
                color={ formik.values.balanceType === 'point' ? 'warning' : 'primary' }
              >
                {t('point')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                id="autocomplete-remark"
                value={get(formik, 'values.remark', '')}
                onChange={formik.handleChange}
                disableClearable
                autoSelect
                options={remarks || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id='remark'
                    name='remark'
                    label={t('Remark')}
                    value={get(formik, 'values.remark', '')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={get(formik, `touched.remark`, false) && Boolean(get(formik, `errors.remark`))}
                    helperText={get(formik, `touched.remark`, false) && get(formik, `errors.remark`)}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
