import React, { useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import CheckIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelIcon from '@mui/icons-material/CancelTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import UsernameMenu from 'features/usernameMenu/UsernameMenu';
import Decimal from 'decimal.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'cashRebates';
const table = createColumnHelper();

export default function UserRelations() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canDelete = ability.can('delete', _RNAME);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor('isRevoked', {
          id: 'isRevoked',
          cell: info => {
            const val = info.getValue();
            const color = val === true ? 'error.main' : 'inherit';
            const fontWeight = val === true ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('Revoked'),
          footer: props => props.column.id,
        }),
        table.accessor('username', {
          id: 'username',
          cell: info => {
            const username = info.getValue() || '';
            return (<UsernameMenu username={username} />);
          },
          header: () => t('Username'),
          footer: props => props.column.id,
        }),
        table.accessor('startDate', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Start Date'),
          footer: props => props.column.id,
        }),
        table.accessor('endDate', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('End Date'),
          footer: props => props.column.id,
        }),
        table.accessor('type', {
          cell: info => t(info.getValue()),
          header: () => t('Type'),
          footer: props => props.column.id,
        }),
        table.accessor('gameLogCount', {
          cell: info => info.getValue(),
          header: () => t('Game Log Count'),
          footer: props => props.column.id,
        }),
        table.accessor('eligibleAmount', {
          cell: info => {
            const val = get(info.getValue(), '$numberDecimal', '0');
            const decVal = new Decimal(val);
            return decVal.toFixed(2);
          },
          header: () => t('Eligible Amount'),
          footer: props => props.column.id,
        }),
        table.accessor('amount', {
          cell: info => {
            const val = get(info.getValue(), '$numberDecimal', '0');
            const decVal = new Decimal(val);
            return decVal.toFixed(2);
          },
          header: () => t('Amount'),
          footer: props => props.column.id,
        }),
        table.accessor('offsetAmount', {
          cell: info => {
            const val = get(info.getValue(), '$numberDecimal', '0');
            const decVal = new Decimal(val);
            return decVal.toFixed(2);
          },
          header: () => t('Offset Amount'),
          footer: props => props.column.id,
        }),
        table.accessor('maxAmount', {
          cell: info => {
            const val = get(info.getValue(), '$numberDecimal', '0');
            const decVal = new Decimal(val);
            return decVal.toFixed(2);
          },
          header: () => t('Max Amount'),
          footer: props => props.column.id,
        }),
        // applyOffset
        table.accessor(row => get(row, `applyOffset`, false), {
          id: 'applyOffset',
          cell: info => info.getValue() ? t('Yes') : t('No'),
          header: () => t('Apply Offset'),
          footer: props => props.column.id,
        }),
        // skipWallet
        table.accessor(row => get(row, `skipWallet`, false), {
          id: 'skipWallet',
          cell: info => info.getValue() ? t('Yes') : t('No'),
          header: () => t('Skip Wallet'),
          footer: props => props.column.id,
        }),
        table.accessor('state', {
          cell: info => {
            const val = info.getValue();
            const isDone = val === 'done' ? true : false;
            const color = !!isDone ? 'success.main' : 'inherit';
            const fontWeight = !!isDone ? 700 : 'inherit';
            return <Box sx={{ fontWeight, color }} element='span'>{t(val)}</Box>
          },
          header: () => t('State'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );


  const handleIsRevokedToggle = useCallback(
    (data) => async (event) => {
      event?.preventDefault();
      const rowId = get(data, '_id');
      const isRevoked = get(data, 'isRevoked', false);
      try {
        await feathers.service(serviceName).patch(rowId, {
          isRevoked: !isRevoked
        });
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [setGlobalMessage, setGlobalErrorMessage, serviceName, t]
  );

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const rowId = get(data, '_id');
    const isRevoked = get(data, 'isRevoked', false);
    const canUpdateIsRevoked = ability.can('update', _RNAME, 'isRevoked');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={isRevoked ? t('Unrevoke') : t('Revoke')}>
          <span>
            <IconButton disabled={!canUpdateIsRevoked} onClick={handleIsRevokedToggle(data)} color='primary'>
              {
                isRevoked ? <CancelIcon /> : <CheckIcon />
              }
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Cash Rebate')}?`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      <Table
        name={t('Cash Rebates')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={false}
        onCreateClick={null}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          gameLogCount: false,
          applyOffset: false,
          skipWallet: false,
          updatedAt: false,
        }}
      />
    </Box>
  );
}
