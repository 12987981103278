import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function WageringMetrics({ data }) {
  const { t } = useTranslation();

  const averageWagerAmount = useMemo(
    () => {
      const { betAmount, betCount } = data;
      if (betCount <= 0) return 0;

      return betAmount.dividedBy(betCount);
    }, [data]
  );

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Typography sx={{ fontWeight: 700 }} variant='h6'>
        {t('Wagering Metrics')}
      </Typography>
      <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant='subtitle1'>
        <span>{t('Total Wagers')}</span>
        <span>{data.betCount}</span>
      </Typography>
      <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant='subtitle1'>
        <span>{t('Total Wagered Amount')}</span>
        <span>{data.betAmount.toFixed(2)}</span>
      </Typography>
      <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant='subtitle1'>
        <span>{t('Average Wager Amount')}</span>
        <span>{averageWagerAmount.toFixed(2)}</span>
      </Typography>
      <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant='subtitle1'>
        <span>{t('Total Win/Loss')}</span>
        <span>{data.totalWinLose.toFixed(2)}</span>
      </Typography>
    </Paper>
  );
}
