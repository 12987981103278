import React, { useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { AbilityContext } from 'casl/Can';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';

export default function FormikTimePicker(props) {
  const [ field, meta, helpers ] = useField(props);
  const { initialValues } = useFormikContext();
  const ability = useContext(AbilityContext);

  const { caslSubject = '', _id } = initialValues;
  const { name: fieldName } = field;

  const action = _id === undefined ? 'create' : 'update';
  const isAllowed = ability.can(action, caslSubject, fieldName);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        {...field}
        {...props}
        renderInput={(props) => <TextField fullWidth {...props} />}
        disabled={isAllowed === false}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        onChange={(date) => { // date is a dayjs object
          const dateIsValid = dayjs(date).isValid();
          const valueToSet = dateIsValid ? date.toDate() : '';
          helpers.setValue(valueToSet);
        }}
      />
    </LocalizationProvider>
  );
}
