import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  FieldArray,
  Formik,
} from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { transformSavedData } from 'utils/form-utils';
import {
  FormikSelect,
  FormikSwitch,
  FormikTextField,
} from 'features/formikControl';
import dayjs from 'dayjs';
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import {
  AddCircleTwoTone as AddCircleIcon,
  DeleteTwoTone as DeleteIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material';
import ThemePreview from './ThemePreview';
import defaultThemeData from './defaultThemeData';
import { lookups as lookupGameButton, enums as enumGameButton } from 'lookups/game-buttons';
import useFeathers from 'hooks/useFeathers';
import { getLocaleYupObject } from 'utils/yup-helper';
import { useAuth } from 'hooks/useAuth';

const CASL_SUBJECT = 'themes';
const SERVICE_NAME = 'themes';

function formatJSON(data) {
  try {
    return JSON.stringify(JSON.parse(data), null, 2);
  } catch (e) {
    return data;
  }
}

export default function Form(props) {
  const { t } = useTranslation();
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const [ fullscreen, setFullscreen ] = useState(false);
  const { data: luckyWheels, ready: luckyWheelsReady } = useFeathers('lucky-wheels', {
    query: {
      $select: ['_id', 'name'],
      $limit: -1 // get all
    }
  });

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        savedData.data = formatJSON(savedData.data);
        return savedData;
      }

      if (!isEmpty(propData)) {
        const transformed = transformSavedData(propData);
        transformed.data = formatJSON(transformed.data);
        return transformed;
      }

      return {
        name: `Theme #${dayjs().unix()}`,
        fontImportUrls: ["https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"],
        data: JSON.stringify(defaultThemeData, null, 2),
        gameButtonVariant: 'simple',
        luckyWheelName: '',
        forDarkMode: false,
        isEnabled: true,
      };
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    name: Yup.string().required(),
    localizedNames: Yup.object().shape(getLocaleYupObject(supportedLocales)),
    fontImportUrls: Yup.array().of(Yup.string()).required(),
    data: Yup.string().test('is-json', t('Invalid JSON'), function(value) {
      try {
        JSON.parse(value);
        return true;
      } catch (e) {
        return false;
      }
    }).required(),
    gameButtonVariant: Yup.string().oneOf(enumGameButton).required(),
    luckyWheelName: Yup.string().required(),
    forDarkMode: Yup.boolean().required(),
    isEnabled: Yup.boolean().required(),
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='lg' open={open} onClose={handleClose} fullScreen={fullscreen}>
      <DialogTitle>
       <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          {t('Theme')}
          <IconButton
            onClick={() => setFullscreen(!fullscreen)}
          >
            {
              fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />
            }
          </IconButton>
        </Box>
      </DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const _id = get(data, '_id');

            const clonedValues = { ...values };
            clonedValues.data = JSON.stringify(JSON.parse(clonedValues.data));

            if (!_id) {
              const { _id, ...newValues } = clonedValues;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              await feathers.service(SERVICE_NAME).patch(_id, {
                $set: clonedValues
              });
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <>
              <DialogContent dividers>
              <Paper sx={{ p: 2 }} elevation={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card>
                      <CardHeader title={t('General')} />
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}>
                          <FormikTextField
                            fullWidth
                            id='name'
                            name='name'
                            label={t('Name')}
                            value={get(values, 'name', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikSelect
                            fullWidth
                            id='gameButtonVariant'
                            name='gameButtonVariant'
                            label={t('Game Button Variant')}
                            value={get(values, 'gameButtonVariant', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {
                              Object.keys(lookupGameButton).map((key) => (
                                <MenuItem key={key} value={key}>{lookupGameButton[key]}</MenuItem>
                              ))
                            }
                          </FormikSelect>
                          <FormikSelect
                            fullWidth
                            id='luckyWheelName'
                            name='luckyWheelName'
                            label={t('Lucky Wheel Name')}
                            value={luckyWheelsReady ? get(values, 'luckyWheelName', '') : ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {
                              luckyWheels.map((lw) => (
                                <MenuItem key={lw._id} value={lw.name}>{lw.name}</MenuItem>
                              ))
                            }
                          </FormikSelect>
                          <FormikSwitch
                            id='forDarkMode'
                            name='forDarkMode'
                            label={t('For Dark Mode')}
                            value={get(values, 'forDarkMode', false)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FieldArray name='fontImportUrls'>
                            {
                              ({ push, remove }) => (
                                <>
                                  <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    my: 1,
                                  }}>
                                    <Typography variant='subtitle2' color='textSecondary'>{t('Font Import URLs')}</Typography>
                                    <IconButton
                                      onClick={() => push('')}
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </Box>
                                  <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                  }}>
                                    {
                                      values?.fontImportUrls?.map((url, index) => (
                                        <Box key={`fontImportUrls.${index}`} sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}>
                                          <FormikTextField
                                            fullWidth
                                            id={`fontImportUrls.${index}`}
                                            name={`fontImportUrls.${index}`}
                                            label={t('Font Import URL')}
                                            value={url}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                          <IconButton
                                            onClick={() => remove(index)}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Box>
                                      ))
                                    }
                                  </Box>
                                </>
                              )
                            }
                          </FieldArray>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card>
                      <CardHeader title={t('Localizations')} subheader={t('Optional')} />
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}>
                          {
                            supportedLocales.map(locale => (
                              <FormikTextField
                                key={locale}
                                fullWidth
                                id={`localizedNames.${locale}`}
                                name={`localizedNames.${locale}`}
                                label={`${t('Name')} (${locale})`}
                                value={get(values, `localizedNames.${locale}`, '')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            ))
                          }
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card>
                      <CardHeader title={t('Theme Data')} />
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}>
                          <FormikTextField
                            fullWidth
                            id='data'
                            name='data'
                            label={t('Data')}
                            value={get(values, 'data', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            multiline
                            rows={15}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card>
                      <CardHeader title={t('Preview')} />
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                          <ThemePreview
                            fontImportUrls={values?.fontImportUrls || []}
                            data={values.data}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
