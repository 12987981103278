import React, { useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { AbilityContext } from 'casl/Can';
import { SketchPicker } from 'react-color';
import {
  TextField,
  InputAdornment,
  IconButton,
  Popover,
} from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {
  Circle as CircleIcon,
  PaletteTwoTone as PaletteIcon,
} from '@mui/icons-material';

export default function FormikColorPicker(props) {
  const [ field, meta ] = useField(props);
  const { initialValues } = useFormikContext();
  const ability = useContext(AbilityContext);

  const { caslSubject = '', _id } = initialValues;
  const { name: fieldName } = field;

  const action = _id === undefined ? 'create' : 'update';
  const isAllowed = ability.can(action, caslSubject, fieldName);

  return (
    <>
      <TextField
        { ...field }
        { ...props }
        disabled={isAllowed === false}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CircleIcon sx={{ color: field.value }} />
            </InputAdornment>
          ),
          endAdornment: (
            <PopupState variant="popover" popupId="color-picker-popover">
              {(popupState) => (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      { ...bindTrigger(popupState) }
                      disabled={isAllowed === false}
                    >
                      <PaletteIcon />
                    </IconButton>
                  </InputAdornment>
                  <Popover
                    { ...bindPopover(popupState) }
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <SketchPicker
                      color={field.value}
                      onChange={(color) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: color.hex,
                          },
                        });
                      }}
                    />
                  </Popover>
                </>
              )}
            </PopupState>
          ),
        }}
      />
    </>
  );
}
