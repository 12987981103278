import React, { useState, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase, each, includes, unset } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  ListSubheader
} from '@mui/material';
import { AbilityContext } from 'casl/Can';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { lookups as lookupCurrencies, enums as enumCurrencies } from 'lookups/currencies';
import { mandatoryFields, allowedCurrencies } from 'lookups/game-api-settings';
import { transformSavedData } from 'utils/form-utils';
import { gameTypeMenu } from 'utils/menu';

const _NEWID = '@@NEW@@';
const _RNAME = 'gameApiSettings';

const ALL_CUSTOM_FIELDS = Object.keys(mandatoryFields).reduce((acc, cur) => {
  const customFields = mandatoryFields[cur].reduce((acc, cur) => {
    if (cur.startsWith('customFields.')) {
      acc.push(cur);
    }
    return acc;
  }, []);
  return Array.from(new Set([...acc, ...customFields]));
}, []);

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        percentage: 80,
        isEnabled: true,
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const buildCustomFieldsSchema = (self) => {
    const { type } = self?.parent || {};
    return Yup.object().shape({
      providerId: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.providerId'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      casinoId: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.casinoId'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      dgaWebSocketUrl: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.dgaWebSocketUrl'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      adminUsername: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.adminUsername'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      gameIdPrefix: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.gameIdPrefix'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      integrationScriptUrl: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.integrationScriptUrl'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      domainName: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.domainName'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      initializationVector: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.initializationVector'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      gameUrl: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.gameUrl'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      encryptionKey: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.encryptionKey'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      hashKey: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.hashKey'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      groupCode: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.groupCode'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      brandCode: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.brandCode'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      tokenUrl: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.tokenUrl'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      bucketName: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.bucketName'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      betLimit: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.betLimit'),
        then: schema => schema.test('is-json', t('Invalid JSON format'), function (value) {
          try {
            JSON.parse(value);
            return true;
          } catch (error) {
            return false;
          }
        }).required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
      dataApiUrl: Yup.string().when([], {
        is: () => mandatoryFields[type]?.includes('customFields.dataApiUrl'),
        then: schema => schema.required(t("Required")),
        otherwise: schema => schema.nullable()
      }),
    }).default({});
  };

  const dataSchema = Yup.object().shape({
    type: Yup.string().required(t("Required")),
    apiUsername: Yup.string().required(t("Required")),
    apiPassword: Yup.string().required(t("Required")),
    apiUrl: Yup.string().required(t("Required")),
    assetDistributionDomain: Yup.string().when('type', {
      is: type => mandatoryFields[type]?.includes('assetDistributionDomain'),
      then: schema => schema.required(t("Required")),
      otherwise: schema => schema.nullable()
    }),
    customFields: Yup.object().when('type', {
      is: type => type !== 'None',
      then: Yup.lazy((obj, self) => {
        return buildCustomFieldsSchema(self)
      }),
      otherwise: Yup.object().nullable()
    }),
    currency: Yup.string().required(t("Required")).matches(new RegExp(`(${enumCurrencies.join('|')})`)),
    percentage: Yup.number().required(),
    socksProxyUri: Yup.string().nullable(),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const flattenVal = flatten(values);

        // avoid overwrite
        unset(flattenVal, 'isPaused');

        if (_id === _NEWID) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  // write a function to return either true or false to show or hide the field based on MANADATORY_FIELDS
  const showField = useCallback(
    (fieldName) => {
      const { type } = formik.values;
      return includes(mandatoryFields[type], fieldName);
    }, [formik.values]
  );

  const selectedType = useMemo(
    () => {
      return get(formik, 'values.type', 'None');
    }, [formik]
  );

    // Show currency based on selected type
  const currencyOptions = useMemo(
    () => {
      let ret = [];
      each(lookupCurrencies, (value, key) => {
        if (includes(allowedCurrencies[selectedType], key)) {
          ret.push({ key, value });
        }
      });
      return ret;
    }, [selectedType]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Game API Settings')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.type', false) && Boolean(get(formik, 'errors.type'))}
              >
                <InputLabel id='type-select-label'>{t('Type')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('type')}
                  labelId='type-select-label'
                  id='type'
                  name='type'
                  value={get(formik, 'values.type', 'None')}
                  label={t('Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    gameTypeMenu(false, true, true).map(g => {
                      if (g.type === 'subheader') {
                        return <ListSubheader key={g.key}>{t(g.value)}</ListSubheader>
                      }
                      return <MenuItem key={g.key} value={g.key}>{g.value}</MenuItem>
                    })
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.type', false) && get(formik, 'errors.type')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('apiUsername')}
                id='apiUsername'
                name='apiUsername'
                label={t('API Username')}
                value={get(formik, 'values.apiUsername', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.apiUsername`, false) && Boolean(get(formik, `errors.apiUsername`))}
                helperText={get(formik, `touched.apiUsername`, false) && get(formik, `errors.apiUsername`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('apiPassword')}
                id='apiPassword'
                name='apiPassword'
                label={t('API Password')}
                value={get(formik, 'values.apiPassword', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.apiPassword`, false) && Boolean(get(formik, `errors.apiPassword`))}
                helperText={get(formik, `touched.apiPassword`, false) && get(formik, `errors.apiPassword`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('apiUrl')}
                id='apiUrl'
                name='apiUrl'
                label={t('API URL')}
                value={get(formik, 'values.apiUrl', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.apiUrl`, false) && Boolean(get(formik, `errors.apiUrl`))}
                helperText={get(formik, `touched.apiUrl`, false) && get(formik, `errors.apiUrl`)}
              />
            </Grid>
            {
              ALL_CUSTOM_FIELDS.map((field, index) => (
                <Grid key={index} item xs={12} sx={{ display: showField(field) ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    disabled={disableInput(field)}
                    id={field}
                    name={field}
                    label={t(field)}
                    value={get(formik, `values.${field}`, '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.${field}`, false) && Boolean(get(formik, `errors.${field}`))}
                    helperText={get(formik, `touched.${field}`, false) && get(formik, `errors.${field}`)}
                  />
                </Grid>
              ))
            }
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('assetDistributionDomain')}
                id='assetDistributionDomain'
                name='assetDistributionDomain'
                label={t('Asset Distribution Domain')}
                value={get(formik, 'values.assetDistributionDomain', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.assetDistributionDomain`, false) && Boolean(get(formik, `errors.assetDistributionDomain`))}
                helperText={get(formik, `touched.assetDistributionDomain`, false) && get(formik, `errors.assetDistributionDomain`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.currency', false) && Boolean(get(formik, 'errors.currency'))}
              >
                <InputLabel id='currency-select-label'>{t('Currency')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('currency')}
                  labelId='currency-select-label'
                  id='currency'
                  name='currency'
                  value={get(formik, 'values.currency', 'None')}
                  label={t('Currency')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    currencyOptions.map(c => (
                      <MenuItem key={c.key} value={c.key}>
                        {c.value}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.currency', false) && get(formik, 'errors.currency')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('socksProxyUri')}
                id='socksProxyUri'
                name='socksProxyUri'
                label={t('Socks Proxy URI')}
                value={get(formik, 'values.socksProxyUri', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.socksProxyUri`, false) && Boolean(get(formik, `errors.socksProxyUri`))}
                helperText={get(formik, `touched.socksProxyUri`, false) && get(formik, `errors.socksProxyUri`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('percentage')}
                id='percentage'
                name='percentage'
                label={t('Percentage')}
                value={get(formik, 'values.percentage', '0')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.percentage`, false) && Boolean(get(formik, `errors.percentage`))}
                helperText={get(formik, `touched.percentage`, false) && get(formik, `errors.percentage`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isEnabled', isChecked)
                  }} />
                }
                label={t('Enabled')}
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
