import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line } from 'recharts';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

export default function AccountOverview({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const ratioData = useMemo(() => {
    const {
      redemptionToDepositRatio,
      cashToPointRatio
    } = data;

    return {
      redemptionToDepositRatio: redemptionToDepositRatio.toFixed(4),
      cashToPointRatio: cashToPointRatio.toFixed(4),
    }
  }, [data]);

  const chartData = useMemo(() => {
    const {
      cashInCount,
      cashInAmount,
      cashOutCount,
      cashOutAmount,
      bonusCount,
      bonusAmount,
      pointIssuedCount,
      pointIssuedAmount,
      pointRedeemedCount,
      pointRedeemedAmount,
    } = data;
    return [
      {
        name: t('Deposit'),
        amt: cashInAmount.toNumber(),
        cnt: cashInCount,
      },
      {
        name: t('Withdrawal'),
        amt: cashOutAmount.toNumber(),
        cnt: cashOutCount,
      },
      {
        name: t('Point Issuance'),
        amt: pointIssuedAmount.toNumber(),
        cnt: pointIssuedCount,
      },
      {
        name: t('Point Redemption'),
        amt: pointRedeemedAmount.toNumber(),
        cnt: pointRedeemedCount,
      },
      {
        name: t('Cash Bonus'),
        amt: bonusAmount.toNumber(),
        cnt: bonusCount,
      },
    ];
  }, [data, t]);

  const tooltipFormatter = (value, name, props) => {
    const { name: propName = '' } = props;
    if (propName === 'Amount') return value.toFixed(2);
    else if (propName === 'Count') return value.toString();
    return value;
  };

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={10}>
      <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
        {t('Account Overview')}
      </Typography>
      <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant='subtitle1'>
        <span>{t('Redemption to Deposit Ratio')}</span>
        <span>{ratioData.redemptionToDepositRatio}</span>
      </Typography>
      <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant='subtitle1'>
        <span>{t('Cash to Point Ratio')}</span>
        <span>{ratioData.cashToPointRatio}</span>
      </Typography>
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart
          layout="vertical"
          data={chartData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 50
          }}
        >
          <CartesianGrid stroke={theme.palette.divider} />
          <XAxis type='number' />
          <YAxis dataKey='name' type="category" scale="band" />
          <Tooltip
            formatter={tooltipFormatter}
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary
            }}
          />
          <Legend />
          <Bar name={t('Amount')} dataKey="amt" barSize={10} fill={theme.palette.primary.main} />
          <Line name={t('Count')} dataKey="cnt" stroke={theme.palette.secondary.main} />
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
}
