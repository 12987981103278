import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';
import {
  getData,
} from 'features/feathersStore/selectors';
import { get } from 'lodash';
import { QRCodeCanvas } from 'qrcode.react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const _RNAME = 'whatsappBots';

export default function QrBackdrop(props) {
  const { open, rowId, handleClose } = props;
  const data = useSelector(getData(_RNAME));

  const qrBase64 = useMemo(
    () => {
      if (!open || !rowId) return null;
      const found = data.find(d => d._id === rowId);
      return get(found, 'qrBase64', null);
    }, [data, open, rowId]
  );

  const isGoodToShow = useMemo(
    () => {
      if (open && qrBase64) return true;
      return false;
    }, [qrBase64, open]
  );

  return (
    <Backdrop open={isGoodToShow} onClick={handleClose}>
      <Dialog open={isGoodToShow}>
        <DialogContent>
          <QRCodeCanvas
            size={320}
            value={qrBase64}
          />
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}

QrBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
  rowId: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};
