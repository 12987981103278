import React, { useState, useContext, createContext, useMemo } from 'react';
import feathers from 'services/feathers';
import ability from 'casl/ability';
import { get } from 'lodash';
import dayjs from 'dayjs';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [isIdle, setIsIdle] = useState(true);
  const [user, setUser] = useState(null);
  const [ totpAuthSession, setTotpAuthSession ] = useState(null);

  const totpSessionToken = useMemo(
    () => {
      const { sessionToken, expiresAt } = totpAuthSession || {};
      const djsExpiresAt = dayjs(expiresAt);
      if (sessionToken && djsExpiresAt.isAfter(dayjs())) {
        return sessionToken;
      }
      return null;
    }, [totpAuthSession]
  );

  const login = async (username, password) => {
    try {
      setIsIdle(false);
      const res = await feathers.authenticate({
        strategy: 'local',
        username,
        password
      });
      const u = get(res, 'user', null);
      const rules = get(res, 'rules', []);
      const role = get(res, 'user.role', 'user');
      if (role === 'user') throw new Error('Forbidden');
      setUser(u);
      ability.update(rules);
    } catch (err) {
      setUser(null);
      ability.update([]);
      localStorage.clear();

      if (err.code === 204) {
        const { data } = err;
        setTotpAuthSession(data);
        setIsIdle(true);
        return;
      }

      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const totpAuthenticate = async (sessionToken, code) => {
    try {
      setIsIdle(false);
      const res = await feathers.authenticate({
        strategy: 'local',
        totpSessionToken: sessionToken,
        totpCode: code,
      });
      const u = get(res, 'user', null);
      const rules = get(res, 'rules', []);
      setUser(u);
      ability.update(rules);
      setTotpAuthSession(null);
    } catch (err) {
      setUser(null);
      ability.update([]);
      throw err;
    } finally {
      setIsIdle(true);
    }
  }

  const relogin = async () => {
    try {
      setIsIdle(false);
      const res = await feathers.reAuthenticate();
      const u = get(res, 'user', null);
      const rules = get(res, 'rules', []);
      setUser(u);
      ability.update(rules);
    } catch (err) {
      setUser(null);
      ability.update([]);
      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const logout = async () => {
    try {
      setIsIdle(false);
      setUser(null);
      ability.update([]);
      localStorage.clear();

      await feathers.logout();
      await feathers.io.close();
      await feathers.io.open();
    } catch (err) {
      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const update = (user) => {
    setUser(prev => ({ ...prev, ...user }));
  };

  return {
    isIdle,
    user,
    login,
    totpAuthenticate,
    relogin,
    logout,
    update,
    totpSessionToken,
  };
}
