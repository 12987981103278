import {
  Editor,
  Element as SlateElement,
  Point,
  Range,
  Transforms,
} from 'slate';
import { nanoid } from 'nanoid';
import { get } from 'lodash';

const createCellNode = (defaultChildren = [{ text: '' }]) => {
  return {
    type: 'table-cell',
    children: defaultChildren,
    key: nanoid(),
  };
};

const createRowNode = (columns = 2) => {
  return {
    type: 'table-row',
    children: Array.from({ length: columns }, (_, i) => createCellNode([{ text: `Cell ${i + 1}` }])),
    key: nanoid(),
  };
};

const createHeaderRowNode = (columns = 2) => {
  return {
    type: 'table-row',
    children: Array.from({ length: columns }, (_, i) => createCellNode([{ text: `Header ${i + 1}` }])),
    key: nanoid(),
  };
}

const createTableNode = (rows = 2, columns = 2) => {
  const table = {
    type: 'table',
    children: [],
    key: nanoid(),
  };

  const tableHead = {
    type: 'table-head',
    children: [createHeaderRowNode(columns)],
    key: nanoid(),
  };

  table.children.push(tableHead);

  const tableBody = {
    type: 'table-body',
    children: Array.from({ length: rows - 1 }, () => createRowNode(columns)),
    key: nanoid(),
  };

  table.children.push(tableBody);

  return [
    table,
    {
      type: 'paragraph',
      children: [{ text: '' }],
      key: nanoid(),
    }
  ];
};

export default function withTables(editor) {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  const matchElement = (n, elementType) => {
    return (
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      n.type === elementType
    );
  }

  editor.deleteBackward = unit => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {

      const [cell] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table-cell'),
      });

      if (cell) {
        const [, cellPath] = cell;
        const start = Editor.start(editor, cellPath);

        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }

      deleteBackward(unit);
    }
  };

  editor.deleteForward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table-cell'),
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }

      deleteForward(unit);
    }
  };

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table')
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };

  editor.insertTable = (rows, columns) => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table')
      });

      // Prevent nested tables
      if (table) {
        return;
      }
    }

    const tableNode = createTableNode(rows, columns);
    Transforms.insertNodes(editor, tableNode);
  };

  editor.bootstrapTable = () => {
    editor.insertTable(3, 3);
  };

  editor.deleteTable = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table')
      });

      if (table) {
        Transforms.removeNodes(editor, {
          at: table[1],
        });
      }
    }
  };

  editor.insertRow = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table')
      });

      if (table) {
        const bodyRows = get(table, '0.children.1.children.length', 0);
        const bodyColumns = get(table, '0.children.1.children.0.children.length', 0);

        if (bodyRows && bodyColumns) {
          const rowNode = createRowNode(bodyColumns);
          Transforms.insertNodes(editor, rowNode, {
            at: [...table[1], 1, bodyRows],
          });
        }
      }
    }
  };

  editor.deleteRow = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table')
      });

      if (table) {
        const bodyRows = get(table, '0.children.1.children.length', 0);
        const bodyColumns = get(table, '0.children.1.children.0.children.length', 0);

        if (bodyRows && bodyColumns && bodyRows > 1) {
          Transforms.removeNodes(editor, {
            at: [...table[1], 1, bodyRows - 1],
          });
        }
      }
    }
  };

  editor.insertColumn = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table')
      });

      if (!table) {
        return;
      }

      const headerColumns = get(table, '0.children.0.children.0.children.length', 0);

      if (headerColumns) {
        Transforms.insertNodes(editor, createCellNode([{ text: `Header ${headerColumns + 1}` }]), {
          at: [...table[1], 0, 0, headerColumns], // table head always has one row
        });
      }

      const bodyRows = get(table, '0.children.1.children.length', 0);
      const bodyColumns = get(table, '0.children.1.children.0.children.length', 0);

      if (bodyRows && bodyColumns) {
        for (let i = 0; i < bodyRows; i++) {
          Transforms.insertNodes(editor, createCellNode([{ text: `Cell ${bodyColumns + 1}` }]), {
            at: [...table[1], 1, i, bodyColumns],
          });
        }
      }
    }
  };

  editor.deleteColumn = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => matchElement(n, 'table')
      });

      if (!table) {
        return;
      }

      const headerColumns = get(table, '0.children.0.children.0.children.length', 0);

      if (headerColumns) {
        if (headerColumns > 1) {
          Transforms.removeNodes(editor, {
            at: [...table[1], 0, 0, headerColumns - 1],
          });
        }
      }

      const bodyRows = get(table, '0.children.1.children.length', 0);
      const bodyColumns = get(table, '0.children.1.children.0.children.length', 0);

      if (bodyRows && bodyColumns > 1) {
        for (let i = 0; i < bodyRows; i++) {
          Transforms.removeNodes(editor, {
            at: [...table[1], 1, i, bodyColumns - 1],
          });
        }
      }
    }
  };

  return editor;
}
