import BankContext from './bankContext';
import React from 'react';
import useFeathers from 'hooks/useFeathers';

function BankProvider({ children }) {
  const queryFetchAll = {
    query: {
      $limit: -1
    }
  };

/*  const queryFetchOne = {
    query: {
      $limit: 1
    }
  };*/

  const { data: banks, ready: banksReady } = useFeathers('company-banks', queryFetchAll);
  const { data: bankData, ready: bankDataReady } = useFeathers('company-bank-data', queryFetchAll);

  return (
    <BankContext.Provider value={{
      banks,
      banksReady,
      bankData,
      bankDataReady,
    }}>
      {children}
    </BankContext.Provider>
  );
}

export default BankProvider;
