import { Transforms } from 'slate'

export default function withYouTube(editor) {
  const { isVoid } = editor;

  editor.isVoid = element => {
    return element.type === 'youtube' ? true : isVoid(element)
  };

  return editor;
};

const createYouTubeNode = (url) => ({
  type: 'youtube',
  url: url,
  children: [{ text: '' }]
});

export const insertYouTube = (editor, url) => {
  const youtubeNode = createYouTubeNode(url);
  Transforms.insertNodes(editor, youtubeNode);
};
