import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { get } from 'lodash';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Done';
import Avatar from '@mui/material/Avatar';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import CropperDialog from 'features/image/CropperDialog';
import dayjs from 'dayjs';
import AssetContext from 'features/context/assetContext';
import {
  DescriptionTwoTone as FileIcon,
  RestartAltTwoTone as ResetIcon,
} from '@mui/icons-material';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from '@mui/material';

import axiosClient from 'services/axios';
//import axios from 'axios';

export default function AssetUploader(props) {
  const { t } = useTranslation();
  const [ files, setFiles ] = useState([]);
  const [ useOriginalImage, setUseOriginalImage ] = useState(false);
  const [ selectedIndex, setSelectedIndex ] = useState([]);
  const [ selectAnchor, setSelectAnchor ] = useState(null);
  const [ selectAll, setSelectAll ] = useState(false);
  const [ status, setStatus ] = useState('idle');
  const [ lastUpload, setLastUpload ] = useState(null);
  const [ controller, setController ] = useState(null);
  const tagsRef = useRef(null);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ cropperDialog, setCropperDialog ] = useState({
    open: false,
    file: null,
  });
  const { path: assetPath } = useContext(AssetContext);

  const filesLength = useMemo(() => files.length, [files]);

  useEffect(() => {
    if (!selectedIndex.length) return;
    if (tagsRef && tagsRef.current) tagsRef.current.focus();
  }, [selectedIndex]);

  const hideControl = useMemo(
    () => {
      if (!files.length || !selectedIndex.length) return true;
      let ret = false;
      for (let i = 0; i < selectedIndex.length; i++) {
        const ind = selectedIndex[i];
        const isUploaded = get(files, `[${ind}].isUploaded`, false);
        if (isUploaded) {
          ret = true;
          break;
        }
      }
      return ret;
    }, [selectedIndex, files]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/*': [],
      'video/*': [],
      'audio/*': [],
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
    },
    onDrop: acceptedFiles => {
      setFiles(prevState => {
        const newFiles = acceptedFiles.filter(file => {
          const duplicated = prevState.findIndex(f => f.path === file.path);

          if (duplicated >= 0) return false;

          return Object.assign(file, {
            customName: file.name,
            tags: [],
            isUploaded: false,
            uploadPercentage: 0,
            preview: URL.createObjectURL(file)
          });
        });

        return [...prevState, ...newFiles];
      });
    }
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    if (!lastUpload) return;
    setStatus('upload');
  }, [lastUpload]);

  useEffect(() => {
    if (status !== 'upload') return;

    const filesToUpload = files.filter(f => !f.isUploaded);

    async function uploadNow() {
      setStatus('uploading');

      for (const file of filesToUpload) {
        const formData = new FormData();

        const customName = get(file, 'customName');
        const path = get(file, 'path');

        if (customName) formData.append('customName', customName);
        formData.append('path', assetPath);
        formData.append('uri', file);
        formData.append('useOriginalImage', useOriginalImage);

        const handleUploadProgress = (file) => async (pe) => {
          const p = Math.round(pe.loaded / pe.total * 100);

          setFiles((prevState) => {
            return prevState.map((d) => {
              if (d.path !== path) return d;

              return Object.assign(d, {
                uploadPercentage: p,
                isUploaded: p >= 100 ? true : false
              });
            });
          });
        };

        try {
          const controller = new AbortController();
          setController(controller);

          await axiosClient.post('asset-upload', formData, {
            signal: controller.signal,
            onUploadProgress: handleUploadProgress(file)
          });
        } catch (err) {
          const errorCode = get(err, 'code', '');

          if (errorCode === 'ERR_CANCELED') {
            setStatus('idle');
            return;
          }

          const errData = get(err, 'response.data');
          const message = get(errData, 'message', '');
          if (message.indexOf('E11000') < 0) setGlobalErrorMessage({ err: errData });
        }
      }
      setStatus('idle');
    }

    uploadNow();
  }, [status, files, setGlobalErrorMessage, assetPath, useOriginalImage]);

  useEffect(() => {
    if (status !== 'cancel') return;
    if (controller) controller.abort();
    setStatus('idle');
  }, [status, controller]);

  useEffect(() => {
    if (selectedIndex.length <= 0) {
      setSelectAnchor(null);
    } else if (selectedIndex.length === 1) {
      setSelectAnchor(selectedIndex[0]);
    }
  }, [selectedIndex]);

  const handlePreviewClicked = (index) => (event) => {
    event.preventDefault();

    const ctrlOn = get(event, 'ctrlKey', false);
    const shiftOn = get(event, 'shiftKey', false);

    if (ctrlOn) {
      setSelectedIndex(prevState => {
        if (prevState.indexOf(index) >= 0) {
          return prevState.filter(i => i !== index);
        } else {
          return [...prevState, index];
        }
      });
      setSelectAnchor(prevState => (prevState === index) ? null : index);
    } else if (shiftOn) {
      setSelectedIndex(prevState => {
        if (selectAnchor === null) return [index];
        const min = index <= selectAnchor ? index : selectAnchor;
        const max = index >= selectAnchor ? index : selectAnchor;

        return Array.from({length: max - min + 1}, (_, i) => i + min);
      });
    } else {
      setSelectedIndex(prevState => {
        if (prevState.length > 1) {
          return [index];
        } else {
          return prevState.indexOf(index) >= 0 ? [] : [index];
        }
      });
    }
  };

  useEffect(() => {
    if (selectAll) {
      setSelectedIndex(Array.from(Array(filesLength).keys()));
      setSelectAnchor(0);
    } else {
      setSelectedIndex([]);
      setSelectAnchor(null);
    }
  }, [selectAll, filesLength]);

  const toggleAllLabel = useMemo(() => {
    if (selectAll) return t('Select None');
    else return t('Select All');
  }, [selectAll, t]);

  const handleSelectAllToggle = (event) => {
    event.preventDefault();
    setSelectAll(prevState => !prevState);
  };

  const handleDeleteClicked = (event) => {
    event.preventDefault();
    setFiles((prevState) => {
      return prevState.filter((d, ind) =>
        selectedIndex.indexOf(ind) < 0
      );
    });
    setSelectedIndex([]);
  };

  const onNameChanged = (event) => {
    setFiles((prevState) => {
      return prevState.map((d, index) => {
        const editIndex = get(selectedIndex, '[0]');
        if (index !== editIndex) return d;
        return Object.assign(d, {
          customName: event.target.value
        });
      });
    });
  };

  const onNameReset = (event) => {
    event.preventDefault();
    setFiles((prevState) => {
      return prevState.map((d, index) => {
        const editIndex = get(selectedIndex, '[0]');
        if (index !== editIndex) return d;
        return Object.assign(d, {
          customName: d.name
        });
      });
    });
  };

  const handleUploadClicked = (event) => {
    setSelectedIndex([]);
    setSelectAnchor(null);
    setLastUpload(Date.now());
  };

  const handleCancelClicked = (event) => {
    setStatus('cancel');
  };

  function getName() {
    if (!selectedIndex.length) return '';
    if (selectedIndex.length > 1) {
      const n1 = get(files, `[${selectedIndex[0]}].customName`, '');
      const n2 = get(files, `[${selectedIndex[1]}].customName`, '');
      return `${n1}, ${n2}...`;
    }
    return get(files, `[${selectedIndex[0]}].customName`, '');
  }

  const handleCropperClicked = (event) => {
    event.preventDefault();

    if (!selectedIndex.length) return;
    const file = get(files, `[${selectedIndex[0]}]`);

    setCropperDialog({
      open: true,
      file
    });
  };

  const handleCropperDialogClosed = (croppedImage) => {
    setCropperDialog({
      open: false,
      file: null
    });

    if (!croppedImage) return;

    const fileName = get(cropperDialog, 'file.name', '');
    const appendDt = dayjs().format('HHmmss');
    const nameOnly = fileName.split('.').slice(0, -1).join('.');
    const extOnly = fileName.split('.').pop();
    const thumbnailFileName = `${nameOnly}_${appendDt}.${extOnly}`;

    async function appendCroppedImage() {
      try {
        const blob = await fetch(croppedImage).then(r => r.blob());

        const croppedFile = new File([blob], thumbnailFileName, {
          type: blob.type,
        });

        Object.assign(croppedFile, {
          path: croppedImage,
          customName: croppedFile.name,
          tags: [],
          isUploaded: false,
          uploadPercentage: 0,
          preview: croppedImage
        });
        setFiles(prev => ([...prev, croppedFile]));
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }

    appendCroppedImage();
  };

  const handleUseOriginalImageChanged = (event) => {
    setUseOriginalImage(event.target.checked);
  };

  const thumbs = () => {
    return (
      <Grid container spacing={2}>
      {
        files.map((file, index) => {
          const { type } = file;
          const isImage = type && type.startsWith('image');

          const isUploaded = get(file, 'isUploaded', false);
          const uploadPercentage = get(file, 'uploadPercentage', 0);
          return (
            <Grid key={file.path} item xs={6} md={3}>
              <Box sx={{ position: 'relative' }}>
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 1,
                  display: isImage && selectedIndex.indexOf(index) >= 0 ? 'block' : 'none'
                }}>
                  <Button onClick={handleCropperClicked} size='small' variant='contained'>
                    { t('Edit') }
                  </Button>
                </Box>
                {
                  isImage ?
                  (
                    <Box
                      onClick={handlePreviewClicked(index)}
                      sx={{
                        cursor: 'pointer',
                        width: '100%',
                        height: 'auto',
                        border: 1,
                        borderRadius: 0,
                        ...(selectedIndex.indexOf(index) >= 0 && {
                          padding: 0.5,
                          border: 2,
                          borderColor: 'info.main'
                        })
                      }}
                      component='img'
                      key={file.path}
                      src={file.preview}
                      // Revoke data uri after image is loaded
                      onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                  ) : (
                    <Box
                      onClick={handlePreviewClicked(index)}
                      sx={{
                        cursor: 'pointer',
                        width: '100%',
                        border: 1,
                        borderRadius: 0,
                        ...(selectedIndex.indexOf(index) >= 0 && {
                          border: 2,
                          borderColor: 'info.main'
                        })
                      }}
                    >
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        gap: 1,
                      }}>

                      <FileIcon sx={{ fontSize: 48 }} />
                      <Typography variant='body2' color='textSecondary'>
                        {
                          file?.name || ''
                        }
                      </Typography>
                      </Box>
                    </Box>
                  )
                }

                {
                  (isUploaded || uploadPercentage > 0) &&
                  <Avatar
                    sx={{
                      transform: 'translate(-50%, -50%)',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      color: isUploaded ? 'success.dark' : 'warning.dark',
                      bgcolor: isUploaded ? 'success.light' : 'warning.light'
                    }}
                  >
                  {
                    isUploaded ?
                    <CheckIcon /> :
                    uploadPercentage
                  }
                  </Avatar>
                }
              </Box>
            </Grid>
          );
        })
      }
      </Grid>
    );
  };

  return (
    <Paper sx={{ p: 2 }} elevation={0}>
      <CropperDialog open={cropperDialog.open} file={cropperDialog.file} onClose={handleCropperDialogClosed} />
      <Box sx={{
        position: 'relative',
        minHeight: 180,
        border: 1.5,
        textAlign: 'center',
        borderStyle: 'dashed',
        borderRadius: 0,
        borderColor: (theme) => {
          if (isFocused) return theme.palette.info.light;
          else if (isDragAccept) return theme.palette.success.light;
          else if (isDragReject) return theme.palette.error.light;
          else return theme.palette.text.secondary;
        },
      }} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontWeight: 100,
        }} variant='h6'>
        {
          t('assetUploader.dropFilesHere')
        }
        </Typography>
      </Box>
      <Paper elevation={4} sx={{ mt: 2, p: 2, display: !filesLength ? 'none' : 'block' }}>
        <Typography variant='h6' gutterBottom color='textSecondary'>
          { t('assetUploader.uploadDeck') }
        </Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
        }}>
          <Button variant='text' onClick={handleSelectAllToggle}>{toggleAllLabel}</Button>
          <Button color='error' onClick={handleDeleteClicked}>{t('Clear')}</Button>
        </Box>
        {
          thumbs()
        }
        <Box sx={{ my: 1, display: hideControl ? 'none' : 'block' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                margin='normal'
                disabled={selectedIndex.length !== 1}
                fullWidth
                id='name'
                name={t('Save As')}
                label={t('Save As')}
                value={getName()}
                onChange={onNameChanged}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={onNameReset}>
                        <ResetIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 2,
      }}>
        <FormControlLabel control={
          <Checkbox checked={useOriginalImage} onChange={handleUseOriginalImageChanged} />
        } label={ t('assetUploader.useOriginalImage') } />
        <Box sx={{
          display: 'flex',
          gap: 1,
        }}>
          <Button variant='contained' onClick={handleCancelClicked} color='error'>
            { t('assetUploader.stopUpload') }
          </Button>
          <Button variant='contained' onClick={handleUploadClicked} >
            { t('assetUploader.upload') }
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}