import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  TextareaAutosize
} from '@mui/base';
import { useChats } from '../../hooks/useChats';
import { useChatMessages } from '../../hooks/useChatMessages';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { Virtuoso } from 'react-virtuoso';
import {
  WhatsApp as WhatsAppIcon,
  Telegram as TelegramIcon,
  Send as SendIcon,
  AttachFile as AttachIcon,
  InsertEmoticon as EmojiIcon,
} from '@mui/icons-material';
import { LineIcon } from 'features/customIcons/CustomIcons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ChatMessage } from './ChatMessage';
import MediaSender from './MediaSender';
import { debounce } from 'lodash';
import EmojiPicker from '@emoji-mart/react';
import emojiData from '@emoji-mart/data';

function SendChannelSelect({ isInitiated = false }) {
  const { t } = useTranslation();
  const { selectedChatSendChannel, setSelectedChatSendChannel, selectedChatAvailableSendChannels } = useChats();
  const translatedPrefix = t('Send via');
  const menuItems = [
    {
      value: 'local',
      label: `${translatedPrefix} ${t('Local')}`
    },
    {
      value: 'whatsapp',
      label: `${translatedPrefix} WhatsApp`
    },
    {
      value: 'telegram',
      label: `${translatedPrefix} Telegram`
    },
    {
      value: 'line',
      label: `${translatedPrefix} Line`
    },
  ];

  const filteredMenuItems = menuItems.filter((menuItem) => selectedChatAvailableSendChannels.includes(menuItem.value));

  return (
    <FormControl variant="standard">
      <Select
        disabled={!isInitiated}
        value={selectedChatSendChannel || 'local'}
        onChange={(e) => setSelectedChatSendChannel(e.target.value)}
        label={t('Send channel')}
      >
        {
          !filteredMenuItems.length && (
            <MenuItem value="local">
              {`${translatedPrefix} ${t('Local')}`}
            </MenuItem>
          )
        }
        {
          filteredMenuItems.map((menuItem) => (
            <MenuItem key={menuItem.value} value={menuItem.value}>
              {menuItem.label}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

export default function ChatWindow() {
  const { t } = useTranslation();
  const {
    chatMessages,
    fetchMore: loadMoreChatMessages,
    firstItemIndex,
    initialTopMostItemIndex,
    setSearchText,
    isIdle,
    isInitiated,
    markChatMessageAsRead,
  } = useChatMessages();
  const {
    sendTextMessage,
  } = useChats();
  const theme = useTheme();
  const [ bottomHeight, setBottomHeight ] = useState(theme.mixins.toolbar.minHeight);
  const [ text, setText ] = useState('');
  const [ mediaUploaderOpen, setMediaUploaderOpen ] = useState(false);
  const [ localSearchText, setLocalSearchText ] = useState('');
  const [ emojiAnchorEl, setEmojiAnchorEl ] = useState(null);
  const maxInputRowCount = 5;

  const ChatMessageItem = useCallback(
    (index, data) => {
      const { type, _id: chatMessageId, readAt, sentAt, fromMe, origin, sentBy: rawSentBy = '' } = data;
      const isSticker = type === 'sticker';
      const sentAtDate = dayjs(sentAt);
      const now = dayjs();
      const isToday = sentAtDate.isSame(now, 'day');
      const isThisYear = sentAtDate.isSame(now, 'year');
      const isThisWeek = sentAtDate.isSame(now, 'week');

      const sentAtFormat = isToday ? 'HH:mm' : isThisYear ? 'MMM D HH:mm' : isThisWeek ? 'ddd HH:mm' : 'YYYY MMM D HH:mm';
      const sentAtFormatted = sentAtDate.format(sentAtFormat);

      if (!readAt) {
        markChatMessageAsRead(chatMessageId);
      }

      const sentBy = rawSentBy === 'root' ? '' : rawSentBy;

      return (
        <Box>
          <Box sx={{
            display: 'flex',
            justifyContent: fromMe ? 'flex-end' : 'flex-start',
          }}>
            <Paper
              variant={readAt ? 'outlined' : 'elevation'}
              sx={{
                ...(isSticker && {
                  border: 'none',
                }),
                minWidth: '10%',
                m: .5,
                p: 1,
              }}
              {
                ...(!readAt && {
                  elevation: 1,
                })
              }
            >
              <ChatMessage
                message={data}
              />
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 1,
              }}>
                {
                  !!sentBy && (
                    <Typography variant="caption" sx={{
                      color: 'text.secondary',
                      fontStyle: 'italic',
                    }}>
                      {sentBy}
                    </Typography>
                  )
                }
                {
                  origin !== 'local' && (
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'text.secondary',
                      fontSize: '.5rem',
                    }}>
                      {
                        origin === 'whatsapp' && <WhatsAppIcon style={{ fontSize: '1rem' }} />
                      }
                      {
                        origin === 'telegram' && <TelegramIcon style={{ fontSize: '1rem' }} />
                      }
                      {
                        origin === 'line' && <LineIcon style={{ fontSize: '1rem' }} />
                      }
                    </Box>
                  )
                }
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {sentAtFormatted}
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Box>
      );
    }, [markChatMessageAsRead]
  );

  useEffect(() => {
    const baseHeight = theme.mixins.toolbar.minHeight;
    const lineHeight = 18;

    let lineCount = text?.split('\n').length || 1;
    if (lineCount > maxInputRowCount) {
      lineCount = maxInputRowCount;
    }

    const bottomHeight = baseHeight + (lineHeight * (lineCount - 1));
    setBottomHeight(bottomHeight);
  }, [text, theme]);

  const messageAreaHeight = useMemo(() => {
    return `calc(100% - ${theme.mixins.toolbar.minHeight}px - ${bottomHeight}px)`;
  }, [bottomHeight, theme]);

  const onTextareaKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      if (!text.trim()) return;
      sendTextMessage(text);
      setText('');
    }
  }, [sendTextMessage, text]);

  const onSendClick = useCallback((e) => {
    e.preventDefault();

    if (!text.trim()) return;
    sendTextMessage(text);
    setText('');
  }, [sendTextMessage, text]);

  const handleEmojiClick = useCallback((event) => {
    event.preventDefault();
    setEmojiAnchorEl(prev => prev ? null : event.currentTarget);
  }, []);

  const handleEmojiClose = useCallback((event) => {
    event.preventDefault();
    //setEmojiAnchorEl(null);
  }, []);

  const openEmojiPicker = useMemo(
    () => {
      if (!emojiAnchorEl) return false;
      return Boolean(emojiAnchorEl);
    }, [emojiAnchorEl]
  );

  const emojiPickerId = useMemo(() => {
    return openEmojiPicker ? 'emoji-picker' : undefined;
  }, [openEmojiPicker]);

  const handleEmojiSelect = useCallback((emojiData) => {
    const { native } = emojiData;
    setText(`${text}${native}`);
  }, [text]);

  const onMediaSenderClose = useCallback(() => {
    setMediaUploaderOpen(false);
  }, []);

  return (
    <>
      <Dialog
        open={mediaUploaderOpen}
        onClose={() => setMediaUploaderOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {t('Send media')}
        </DialogTitle>
        <DialogContent>
          <MediaSender onClose={onMediaSenderClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMediaUploaderOpen(false)}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{
        width: '100%',
        height: '100%',
      }}>
        <Box sx={{
          height: theme => theme.mixins.toolbar.minHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: 1,
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
          gap: 1,
        }}>
          {
            !isIdle && (
              <Box sx={{ mx: 2 }}>
                <CircularProgress size={"1rem"} />
              </Box>
            )
          }
          <SendChannelSelect isInitiated={isInitiated} />
          <TextField
            disabled={!isInitiated}
            variant="standard"
            placeholder={t('Search messages')}
            value={localSearchText}
            onChange={
              (e) => {
                e.preventDefault();
                setLocalSearchText(e.target.value);
                debounce(() => {
                  setSearchText(e.target.value);
                }, 1000)();
              }
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setLocalSearchText('');
                      setSearchText('');
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box sx={{
          width: '100%',
          height: messageAreaHeight,
          position: 'relative',
        }}>
          {
            chatMessages.length === 0 && isInitiated && (
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}>
                <Alert severity="info">
                  {t('No messages')}
                </Alert>
              </Box>
            )
          }
          {
            isInitiated &&
            <Virtuoso
              style={{
                height: '100%',
                width: '100%',
              }}
              firstItemIndex={firstItemIndex}
              initialTopMostItemIndex={initialTopMostItemIndex}
              data={chatMessages}
              startReached={() => loadMoreChatMessages()}
              itemContent={ChatMessageItem}
              followOutput={'auto'}
            />
          }
        </Box>
        <Box sx={{
          height: bottomHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 1,
          borderTop: theme => `1px solid ${theme.palette.divider}`,
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}>
            <IconButton disabled={!isInitiated} onClick={() => setMediaUploaderOpen(true)}>
              <AttachIcon />
            </IconButton>
          </Box>
          <TextareaAutosize
            disabled={!isInitiated}
            maxRows={maxInputRowCount}
            style={{
              width: '100%',
              resize: 'none',
              padding: '8px',
              borderRadius: '4px',
              border: 'none',
            }}
            placeholder={t('Type a message')}
            value={text}
            onKeyDown={onTextareaKeyDown}
            onChange={(e) => {
              e.preventDefault();
              setText(e.target.value);
            }}
          />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}>
            <Popper
              id={emojiPickerId}
              open={openEmojiPicker}
              anchorEl={emojiAnchorEl}
            >
              <EmojiPicker
                data={emojiData}
                onEmojiSelect={handleEmojiSelect}
                onClickOutside={handleEmojiClose}
              />
            </Popper>
            <IconButton disabled={!isInitiated} onClick={handleEmojiClick}>
              <EmojiIcon color={emojiAnchorEl ? 'primary' : 'inherit'} />
            </IconButton>
            <IconButton disabled={!isInitiated} onClick={onSendClick}>
              <SendIcon color={isInitiated ? 'primary' : 'inherit'} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}