import React, { useContext, useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useAuth } from 'hooks/useAuth';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import feathers from 'services/feathers';
import { useNavigate } from "react-router-dom";
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function CountrySwitch() {
  const { user } = useAuth();
  const {
    _id: userId,
    managedCountry = '',
    manageableCountries = [],
  } = user;

  const ability = useContext(AbilityContext);
  const canUpdateManagedCountry = ability.can('update', subject('users', user), 'managedCountry')
  const navigate = useNavigate();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { t } = useTranslation();

  const onChangeCountry = useCallback(
    (country) => async (event) => {

      const updateData = !!country ? {
        managedCountry: country
      } : {
        $unset: {
          managedCountry: '1'
        }
      };

      try {
        await feathers.service('users').patch(userId, updateData);
        navigate(0);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [navigate, setGlobalErrorMessage, userId]
  );

  if (!canUpdateManagedCountry || !manageableCountries.length) {
    return null;
  }

  return (
    <PopupState variant="popover" popupId="country-switch">
      {(popupState) => (
        <React.Fragment>
          <Tooltip title={t('Switch Country')}>
            <Button
              variant="contained"
              size='small'
              color='error'
              {...bindTrigger(popupState)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {managedCountry || 'n/a'}
            </Button>
          </Tooltip>
          <Menu {...bindMenu(popupState)}>
            {<MenuItem key={''} onClick={onChangeCountry('')}>n/a</MenuItem>}
            {
              manageableCountries.map(function(country) {
                if (!country) return null;
                return <MenuItem key={country} onClick={onChangeCountry(country)}>{country}</MenuItem>
              })
            }
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}