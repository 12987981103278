import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Paper,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  FileCopy as FileCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from 'hooks/useAuth';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import feathers from 'services/feathers';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';
import CopyToClipboard from 'features/copyToClipboard/CopyToClipboard';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

function TwoFactorEnableDialog(props) {
  const [ recoveryCodes, setRecoveryCodes ] = useState([]);
  const { open, onClose, lastEnabledAttemptAt } = props;
  const { user, update: updateUser } = useAuth();
  const [totpSecret, setTOTPSecret] = useState('');
  const { t } = useTranslation();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ showPassword, setShowPassword ] = useState(false);
  const [ issuerName, setIssuerName ] = useState('FTW');

  const userId = get(user, '_id');
  const username = get(user, 'username');

  const qrCodeData = useMemo(() => {
    if (!totpSecret || !username) return '';
    return `otpauth://totp/${issuerName}:${username}?secret=${totpSecret}&issuer=${issuerName}`;
  }, [totpSecret, username, issuerName]);

  useEffect(() => {
    const fetchTOTPSecret = async () => {
      try {
        const res = await feathers.service('totp-secret').get(userId);
        const { totpSecret } = res;
        setTOTPSecret(totpSecret);
      } catch (err) {
        console.error(err);
      }
    }
    fetchTOTPSecret();
  }, [userId, lastEnabledAttemptAt]);

  const handleOnClose = useCallback(() => {
    onClose();
    setRecoveryCodes([]);
    setTOTPSecret('');
  }, [onClose]);

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t('Required')),
    code: Yup.string().required(t('Required')),
  });

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        code: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const { code, currentPassword } = values;
          const res = await feathers.service('users').patch(userId, {
            $set: {
              currentPassword,
              'totp.lastEnabledAttemptAt': new Date(),
              'totp.code': code,
            },
          });
          const totpRecoveryCodes = get(res, 'totp.recoveryCodes', []);
          setRecoveryCodes(totpRecoveryCodes);
          updateUser(res);
          setGlobalMessage({
            message: t(`Saved`),
            severity: 'success'
          });
          resetForm();
        } catch (err) {
          setGlobalErrorMessage({ err });
        }
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Dialog open={open} onClose={handleOnClose} maxWidth="md">
          <form onSubmit={handleSubmit} noValidate>
            <DialogTitle>{t('Enable TOTP 2FA')}</DialogTitle>
            <DialogContent>
              <Box sx={{
                display: recoveryCodes.length > 0 ? 'none' : 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
              }}>
                <QRCodeCanvas
                  size={240}
                  value={qrCodeData}
                />
                <Typography variant="body1" gutterBottom>
                  {t('Scan this QR code with your authenticator app')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t('Or manually enter the following secret key into your authenticator app')}
                </Typography>
                <Box sx={{ p: 1, width: '100%' }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={totpSecret}
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard text={totpSecret} copiedText={t('Copied to clipboard')}>
                            <IconButton>
                              <FileCopyIcon />
                            </IconButton>
                          </CopyToClipboard>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Paper
                  sx={{
                    p: 2,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                  elevation={4}
                >
                  <Typography variant="body1" gutterBottom>
                    {t('Enter the code from your authenticator app')}
                  </Typography>
                  <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor="code">{t('Code')}</InputLabel>
                    <Input
                      id="code"
                      name="code"
                      type="text"
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.code && Boolean(errors.code)}
                      disabled={isSubmitting}
                      fullWidth
                      autoFocus
                    />
                    <FormHelperText error>{touched.code && errors.code}</FormHelperText>
                  </FormControl>
                  <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor="currentPassword">{t('Current Password')}</InputLabel>
                    <Input
                      id="currentPassword"
                      name="currentPassword"
                      type={showPassword ? 'text' : 'password'}
                      value={values.currentPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.currentPassword && Boolean(errors.currentPassword)}
                      disabled={isSubmitting}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText error>{touched.currentPassword && errors.currentPassword}</FormHelperText>
                  </FormControl>
                  <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor="issuerName">{t('Issuer Name')}</InputLabel>
                    <Input
                      id="issuerName"
                      name="issuerName"
                      type="text"
                      value={issuerName}
                      onChange={(e) => setIssuerName(e.target.value)}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </FormControl>
                </Paper>
              </Box>
              <Box sx={{
                display: recoveryCodes.length > 0 ? 'flex' : 'none',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
              }}>
                <Typography variant="body1" gutterBottom>
                  {t('Save these recovery codes in a safe place')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t('You will need them to access your account if you lose your device or cannot use your authenticator app')}
                </Typography>
                <Paper
                  sx={{
                    p: 2,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                  elevation={4}
                >
                  {
                    recoveryCodes.map((code, index) => (
                      <Typography key={index} variant="body1" gutterBottom sx={{ fontFamily: 'monospace' }}>
                        {code}
                      </Typography>
                    ))
                  }
                </Paper>
              </Box>
            </DialogContent>
            <DialogActions>
              {
                recoveryCodes.length > 0 ? (
                  <Button onClick={handleOnClose}>{t('Close')}</Button>
                ) : (
                  <>
                    <Button onClick={handleOnClose}>{t('Cancel')}</Button>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    >
                      {t('Enable')}
                    </LoadingButton>
                  </>
                )
              }
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
}

function TwoFactorDisableDialog(props) {
  const { open, onClose } = props;
  const { user, update: updateUser } = useAuth();
  const { t } = useTranslation();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ showPassword, setShowPassword ] = useState(false);

  const userId = get(user, '_id');

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t('Required')),
  });

  return (
    <Formik
      initialValues={{
        currentPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const { currentPassword } = values;
          const res = await feathers.service('users').patch(userId, {
            $set: {
              currentPassword,
              'totp.lastDisabledAttemptAt': new Date(),
            },
          });
          updateUser(res);
          setGlobalMessage({
            message: t(`Saved`),
            severity: 'success'
          });
          resetForm();
          onClose();
        } catch (err) {
          setGlobalErrorMessage({ err });
        }
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Dialog open={open} onClose={onClose} maxWidth="md">
          <form onSubmit={handleSubmit} noValidate>
            <DialogTitle>{t('Disable TOTP 2FA')}</DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Paper
                  sx={{
                    p: 2,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                  elevation={4}
                >
                  <Typography variant="body1" gutterBottom>
                    {t('Enter your current password to disable TOTP')}
                  </Typography>
                  <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor="currentPassword">{t('Current Password')}</InputLabel>
                    <Input
                      id="currentPassword"
                      name="currentPassword"
                      type={showPassword ? 'text' : 'password'}
                      value={values.currentPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.currentPassword && Boolean(errors.currentPassword)}
                      disabled={isSubmitting}
                      fullWidth
                      autoFocus
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText error>{touched.currentPassword && errors.currentPassword}</FormHelperText>
                  </FormControl>
                </Paper>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{t('Cancel')}</Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="secondary"
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {t('Disable')}
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
}

export default function TwoFactor(props) {
  const { user } = useAuth();
  const totpEnabled = get(user, 'totp.enabled', false);
  const { t } = useTranslation();
  const [inEnabling, setInEnabling] = useState(false);
  const [inDisabling, setInDisabling] = useState(false);
  const [ lastEnabledAttemptAt, setLastEnabledAttemptAt ] = useState(null);

  const onEnableClick = (event) => {
    event.preventDefault();
    setInEnabling(true);
    setInDisabling(false);
    setLastEnabledAttemptAt(new Date());
  };

  const onDialogClose = () => {
    setInEnabling(false);
    setInDisabling(false);
  };

  const onDisableClick = (event) => {
    event.preventDefault();
    setInDisabling(true);
    setInEnabling(false);
  };

  return (
    <>
      <TwoFactorEnableDialog open={inEnabling} onClose={onDialogClose} lastEnabledAttemptAt={lastEnabledAttemptAt} />
      <TwoFactorDisableDialog open={inDisabling} onClose={onDialogClose} />
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          {t('Two Factor Authentication - TOTP')}
        </Typography>
        <Box sx={{
          color: 'text.secondary',
          fontStyle: 'italic',
          my: 1,
        }}>
          {
            totpEnabled ? (
              <Typography variant="body1" gutterBottom>
                {t('You have enabled TOTP')}
              </Typography>
            ) : (
              <Box>
                <Typography variant="body1" gutterBottom>
                  {t('Protect your account with Time-based One-Time Password (TOTP)')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t('You can use apps like Google Authenticator, Microsoft Authenticator, Authy, FreeOTP, and others.')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t('You will need to enter a code from the app every time you log in.')}
                </Typography>
              </Box>
            )
          }
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {
            totpEnabled ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={onDisableClick}
              >
                {t('Disable')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={onEnableClick}
              >
                {t('Enable')}
              </Button>
            )
          }
        </Box>
      </Box>
    </>
  );
}