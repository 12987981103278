// @refresh reset
import React, { useCallback, useRef, useState, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey';
import {
  Editable,
  withReact,
  useSlate,
  Slate,
} from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import ElementComponent from './Element';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { ToggleButton, ToggleButtonGroup } from './components';
import withCorrectVoidBehavior from './withCorrectVoidBehavior';
import withImages, { insertImageId } from './withImages';
import withLinks, { insertLink } from './withLinks';
import withYouTube, { insertYouTube } from './withYouTube';
import withTables from './withTables';
import AssetContext from 'features/context/assetContext';
import {
  Box,
  Button,
  ButtonGroup,
  Typography,
} from '@mui/material';
import {
  grey
} from '@mui/material/colors';
/*import LinkDialog from 'features/linkDialog/LinkDialog';*/

// icons
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LinkIcon from '@mui/icons-material/Link';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {
  TableChartTwoTone as TableIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { createParagraphNode } from 'features/slate/utils/paragraph';
import AssetExplorerDialog from 'features/assets/AssetExplorerDialog';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export default function SlateEditor(props) {
  const { value, storageKey } = props;
  const renderElement = useCallback(props => <ElementComponent { ...props } />, []);
  const renderLeaf = useCallback(props => <Leaf { ...props } />, []);
  const [ openTableControls, setOpenTableControls ] = useState(false);
  const { t } = useTranslation();
  const { openExplorer } = useContext(AssetContext);

  const editorRef = useRef();
  if (!editorRef.current) editorRef.current =
    withCorrectVoidBehavior(
      withYouTube(
        withImages(
          withLinks(
            withTables(
              withHistory(
                withReact(
                  createEditor()
                )
              )
            )
          )
        )
      )
    );
  const editor = editorRef.current;

  const initialValue = useMemo(
    () => {
      try {
        // load storage content if any
        const unsavedContent = localStorage.getItem(storageKey);
        if (unsavedContent) return JSON.parse(decodeURIComponent(unsavedContent));

        // load content from prop
        if (value) return JSON.parse(decodeURIComponent(value));
      } catch (e) {
        console.error(e);
      }

      return createParagraphNode();
    }, [value, storageKey]
  );

  useEffect(() => {
    try {
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      });

      // Removes empty node
      Transforms.removeNodes(editor, {
        at: [0],
      });

      // Insert array of children nodes
      Transforms.insertNodes(
        editor,
        initialValue
      );
    } catch (err) {
      console.error(err);
    }

    // reset history
    editor.history = {
      redos: [],
      undos: [],
    };
  }, [initialValue, editor]);

  const onSlateChange = (value) => {
    const isAstChange = editor.operations.some(
      op => 'set_selection' !== op.type
    );
    if (isAstChange) {
      const content = encodeURIComponent(JSON.stringify(value));
      localStorage.setItem(storageKey, content);
    }
  };

  const onImagePickerClicked = useCallback(
    (event) => {
      event.preventDefault();

      async function showExplorer() {
        try {
          const result = await openExplorer() || [];

          result.forEach((image) => {
            insertImageId(editor, image);
          });

        } catch (err) {
          console.error(err);
        }
      }

      showExplorer();
    }, [openExplorer, editor]
  );

  const handleInsertLink = () => {
    const url = prompt('Enter a URL');
    if (url) insertLink(editor, url);
  };

  const handleInsertYouTube = () => {
    const url = prompt('Enter a YouTube URL');
    if (url) insertYouTube(editor, url);
  };

  return (
    <>
      {/*<LinkDialog open={openLinkDialog} onClose={handleOnNewLinkClosed} />*/}
      <AssetExplorerDialog />
      <Slate editor={editor} initialValue={initialValue} onChange={onSlateChange}>
        <Box sx={{
          position: 'sticky',
          top: -16,
          zIndex: 1,
        }}>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 0,
              flexWrap: 'wrap',
            }}
          >
            <ToggleButtonGroup size='small'>
              <MarkButton format="bold" icon={<FormatBoldIcon />} />
              <MarkButton format="italic" icon={<FormatItalicIcon />} />
              <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
            </ToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <ToggleButtonGroup size='small'>
              <BlockButton format="heading-one" icon={<LooksOneIcon />} />
              <BlockButton format="heading-two" icon={<LooksTwoIcon />} />
              <BlockButton format="heading-three" icon={<Looks3Icon />} />
              <BlockButton format="heading-four" icon={<Looks4Icon />} />
              <BlockButton format="heading-five" icon={<Looks5Icon />} />
              <BlockButton format="heading-six" icon={<Looks6Icon />} />
            </ToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <ToggleButtonGroup size='small'>
              <MarkButton format="code" icon={<CodeIcon />} />
              <BlockButton format="block-quote" icon={<FormatQuoteIcon />} />
            </ToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <ToggleButtonGroup size='small'>
              <BlockButton format="numbered-list" icon={<FormatListNumberedIcon />} />
              <BlockButton format="bulleted-list" icon={<FormatListBulletedIcon />} />
            </ToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <ToggleButtonGroup size='small'>
              <BlockButton format="left" icon={<FormatAlignLeftIcon />} />
              <BlockButton format="center" icon={<FormatAlignCenterIcon />} />
              <BlockButton format="right" icon={<FormatAlignRightIcon />} />
              <BlockButton format="justify" icon={<FormatAlignJustifyIcon />} />
            </ToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <ToggleButtonGroup size='small'>
              <ImageButton onMouseDown={handleInsertLink}
                icon={<LinkIcon />}
              />
              <ImageButton onMouseDown={onImagePickerClicked}
                icon={<InsertPhotoIcon />}
              />
            <ImageButton onMouseDown={handleInsertYouTube}
                icon={<YouTubeIcon />}
              />
            </ToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <ToggleButtonGroup size='small'>
              <ToggleButton
                value='table'
                active={openTableControls}
                icon={<TableIcon />}
                onClick={() => setOpenTableControls(!openTableControls)}
              />
            </ToggleButtonGroup>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              p: 1,
              display: openTableControls ? 'flex' : 'none',
              alignItems: 'center',
              bgcolor: grey[200],
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 0,
              flexWrap: 'wrap',
            }}
          >
            <Typography variant='caption' sx={{
              mx: 1,
              color: theme => theme.palette.getContrastText(grey[200]),
              fontStyle: 'italic',
            }}>
              {t('Table')}
            </Typography>
            <ButtonGroup variant='contained' color='secondary' size='small'>
              <Button onClick={editor.bootstrapTable}>{t('Insert')}</Button>
              <Button onClick={editor.deleteTable}>{t('Delete')}</Button>
            </ButtonGroup>
            <ButtonGroup variant='contained' color='secondary' size='small' sx={{ mx: 1 }}>
              <Button onClick={editor.insertRow}>{`+ ${t('Row')}`}</Button>
              <Button onClick={editor.deleteRow}>{`- ${t('Row')}`}</Button>
              <Button onClick={editor.insertColumn}>{`+ ${t('Col')}`}</Button>
              <Button onClick={editor.deleteColumn}>{`- ${t('Col')}`}</Button>
            </ButtonGroup>
          </Paper>
        </Box>
        <Box sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 0,
        }}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder=''
            spellCheck
            autoFocus
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </Box>
      </Slate>
    </>
  );
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
};

const BlockButton = ({ format, icon, ...props }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      {
        ...props
      }
      value={format}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      icon={icon}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    />
  );
};

const MarkButton = ({ format, icon, ...props }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      {
        ...props
      }
      value={format}
      active={isMarkActive(editor, format)}
      icon={icon}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    />
  );
};

const ImageButton = ({ icon, ...props }) => {
  return (
    <ToggleButton
      {
        ...props
      }
      value='insert-image'
      icon={icon}
    />
  )
};

SlateEditor.propTypes = {
  storageKey: PropTypes.string.isRequired,
};
