import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, find, endsWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import ProofOfTransfer from 'features/img/ProofOfTransfer';
import Typography from '@mui/material/Typography';
import Decimal from 'decimal.js';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import BankContext from 'features/context/bankContext';
import BankBotContext from 'features/context/bankBotContext';
import Loader from 'features/loader/Loader';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import flatten from 'flat';
import CopyToClipboard from 'features/copyToClipboard/CopyToClipboard';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import Uploader from './Uploader';
import BankStatusControl from 'features/clientBanks/StatusControl';
import { getCompanyBankOptions, getFullBankLabel } from 'utils/bank';
import CommonContext from 'features/context/commonContext';
import { UserDialogContext } from 'features/context/userDialogContext';

const _SERVICENAME = 'withdrawals';

export default function Form(props) {
  const { open, setOpen, data: propData } = props;
  const { onOpen: onUserDialogOpen } = useContext(UserDialogContext);
  const service = feathers.service(_SERVICENAME);
  const { t } = useTranslation();
  const { banks, banksReady, bankData, bankDataReady } = useContext(BankContext);
  const { bankBots, bankBotsReady } = useContext(BankBotContext);
  const [ potRefId, setPotRefId ] = useState(null);
  const [ showDropzone, setShowDropZone ] = useState(false);
  const [ pastedImage, setPastedImage ] = useState(null);
  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ selectedBank, setSelectedBank ] = useState('None');
  const { bankLookup } = useContext(CommonContext);
  const [ bankCredential, setBankCredential ] = useState({
    username: '',
    password: ''
  });

  const initialValues = useMemo(
    () => {
      const _id = get(propData, '_id');
      const companyId = get(propData, 'companyBank.companyId', '');
      const displayNumber = get(propData, 'companyBank.displayNumber', '');
      const accountNumber = get(propData, 'companyBank.accountNumber', '');
      const transactedAt = get(propData, 'transactedAt', new Date());
      const state = get(propData, 'state');
      const remark = get(propData, 'remark', '');
      const cappedAmountStr = get(propData, 'cappedAmount.$numberDecimal', '');
      const cappedAmount = cappedAmountStr ? new Decimal(cappedAmountStr).toFixed(2) : '';

      return {
        _id,
        companyBank: {
          companyId,
          displayNumber,
          accountNumber,
        },
        transactedAt,
        state,
        remark,
        cappedAmount,
      }
    }, [propData]
  );

  const [transactionState, setTransactionState] = useState(get(propData, 'state', ''));

  const transactionId = useMemo(
    () => {
      return get(propData, '_id');
    }, [propData]
  );

  const readOnly = useMemo(
    () => {
      return transactionState === 'deducted' ? false : true;
    }, [transactionState]
  );

  const resetBankCredential = useCallback(
    () => {
      setBankCredential({
        username: '',
        password: ''
      });
    }, []
  );

  const findAndSetBankCredential = useCallback(
    (accountNumber) => {
      const bankBot = find(bankBots, { accountNumber });
      if (!bankBot) {
        resetBankCredential();
        return;
      }
      const { username = '', password = '' } = bankBot;

      // append a random number of username mask at the back
      const maskedUsername = username.substring(0, 2) + '~';
      const passwordMaskLength = password.length > 2 ? password.length - 2 : 0;
      const maskedPassword = password.substring(0, 2) + '*'.repeat(passwordMaskLength);
      setBankCredential({
        username: maskedUsername,
        password,
        maskedPassword
      });
    }, [bankBots, resetBankCredential]
  );

  /*
    Try to set bank credential on initial load
  */
  useEffect(() => {
    const accountNumber = get(initialValues, 'companyBank.accountNumber');
    findAndSetBankCredential(accountNumber);
  }, [initialValues, bankBots, findAndSetBankCredential]);

  const data = useMemo(
    () => {
      const refId = get(propData, '_id');
      const username = get(propData, 'username', '');
      const amountStr = get(propData, 'amount.$numberDecimal', '0');
      const amount = new Decimal(amountStr).toFixed(2);
      const cappedAmountStr = get(propData, 'cappedAmount.$numberDecimal');
      const cappedAmount = cappedAmountStr ? new Decimal(cappedAmountStr).toFixed(2) : '';
      const bankNameRaw = get(propData, 'clientBank.bankName', '');
      const accountName = get(propData, 'clientBank.accountName', '');
      const rawAccountNumber = get(propData, 'clientBank.accountNumber', '');
      const bankName = getFullBankLabel(t, bankNameRaw);
      let accountNumber = rawAccountNumber;
      let bsbNumber = '';
      const isBsbType = endsWith(bankNameRaw, '_bsb');

      if (isBsbType) {
        bsbNumber = rawAccountNumber.substring(0, 6);
        accountNumber = rawAccountNumber.substring(6);
      }

      return {
        refId,
        username,
        amount,
        cappedAmount,
        bankName,
        accountName,
        rawAccountNumber,
        accountNumber,
        ...(
          !!bsbNumber && {
            bsbNumber
          }
        )
      };
    }, [propData, t]
  );

  useEffect(() => {
    const propPotRefId = get(propData, 'potRefId');
    if (propPotRefId) setPotRefId(propPotRefId);
  }, [propData]);

  useEffect(() => {
    const companyId = get(initialValues, 'companyBank.companyId');
    const displayNumber = get(initialValues, 'companyBank.displayNumber');
    const accountNumber = get(initialValues, 'companyBank.accountNumber');

    if (!displayNumber || !accountNumber || !banksReady) return;

    let found = null;

    if (companyId) {
      found = find(banks, { companyId, displayNumber, accountNumber });
    } else {
      found = find(banks, { displayNumber, accountNumber });
    }

    if (!found) return;
    setSelectedBank(found._id);
  }, [initialValues, banks, banksReady]);

  useEffect(() => {
    const show = potRefId ? false : true;
    setShowDropZone(show);
  }, [potRefId]);

  const bankOptions = useMemo(
    () => {
      if (!banksReady || !bankDataReady || !bankBotsReady) return null;

      return getCompanyBankOptions(t, bankLookup, banks, bankData);
    }, [t, bankLookup, banks, banksReady, bankData, bankDataReady, bankBotsReady]
  );

  const onUsernameClicked = useCallback(
    (username) => (event) => {
      event?.preventDefault();
      onUserDialogOpen(username);
    }, [onUserDialogOpen]
  );

  const onPatched = useCallback(
    (data) => {
      if (!transactionId) return;
      if (get(data, '_id') !== transactionId) return;
      const state = get(data, 'state');
      setTransactionState(state);
    },
    [transactionId]
  );

  useEffect(() => {
    if (transactionState === 'done') return;

    service.on('updated', onPatched);
    service.on('patched', onPatched);

    return () => {
      service.removeListener('updated', onPatched);
      service.removeListener('patched', onPatched);
    };
  }, [service, onPatched, transactionState]);

  const dataSchema = Yup.object().shape({
    transactedAt: Yup.date().required(t("Required")),
    //state: Yup.string().matches(/(initial|canceling)/),
    companyBank: Yup.object({
      displayNumber: Yup.string().nullable(),
      accountNumber: Yup.string().nullable(),
      companyId: Yup.string().nullable(),
    }),
    state: Yup.string().nullable(),
    remark: Yup.string().nullable(),
    cappedAmount: Yup.number().min(0.1).max(data?.amount).nullable(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(values, '_id');
        const transactedAt = get(values, 'transactedAt');
        const cappedAmount = get(values, 'cappedAmount');
        const remark = get(values, 'remark');
        const state = get(values, 'state');
        const isInitial = state === 'initial' ? true : false;

        const formData = {
          transactedAt,
          remark,
          ...(
            isInitial && selectedBank && {
              companyBankId: selectedBank
            }
          ),
          ...(
            !!cappedAmount && {
              cappedAmount
            }
          ),
          ...(
            state && {
              state: state
            }
          ),
        };

        const flattenFormData = flatten(formData);
        await service.patch(_id, flattenFormData);
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const finalAmount = useMemo(
    () => {
      const cappedAmount = get(formik, 'values.cappedAmount');
      return cappedAmount ? cappedAmount : data.amount;
    }, [formik, data]
  );

  const handleRoundDown = useCallback(
    () => {
      const decAmount = new Decimal(data?.amount || 0);
      const roundedAmount = decAmount.floor(Decimal.ROUND_DOWN);
      formik.setFieldValue('cappedAmount', roundedAmount.toFixed(2));
    }, [data, formik]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  const handleUploadedPot = (refId) => {
    setPotRefId(refId);
    setPastedImage(null);
  };

  const handlePotDeleteClick = async (event) => {
    event.preventDefault();
    try {
      const _id = get(initialValues, '_id');
      await service.patch(_id, {
        $unset: {
          potRefId: 1
        }
      });
      setPotRefId(null);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleImagePaste = (event) => {
    if (!showDropzone) return;
    const item = get(event, 'clipboardData.items[0]');
    const itemType = get(item, 'type', '');

    if (itemType.indexOf('image') !== 0) return;
    const blob = item.getAsFile();
    setPastedImage(blob);
  };

  const handleWhatsAppClicked = (event) => {
    event.preventDefault();
    window.open(`https://wa.me/${data?.username}/?text=`, '_blank');
  };

  function getChipColor() {
    return transactionState === 'done' ? 'success' : transactionState === 'canceled' ? 'error' : 'default';
  }

  if (!banksReady) return null;

  return (
    <>
      <Dialog maxWidth='xl' open={open} onClose={handleClose}>
        <Loader open={!banksReady || !bankDataReady} />
        <DialogTitle>
          {t('Withdrawal')}
          {
            transactionState && <Chip size='small' sx={{ ml: 1, borderRadius: 1, fontWeight: 700 }} label={t(transactionState)} color={getChipColor()} />
          }
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {
              showDropzone &&
                <Grid item xs={12} md={4}>
                  <Uploader refId={data.refId} onUploaded={handleUploadedPot} pastedImage={pastedImage} />
                </Grid>
            }
            {
              potRefId &&
              <Grid item xs={12} md={4}>
                <Paper elevation={8} sx={{ p: 1, position: 'relative' }}>
                  <ProofOfTransfer sx={{ width: '100%', border: 1, borderRadius: 1, borderColor: 'divider' }} potId={potRefId} />
                  <IconButton onClick={handlePotDeleteClick} color='error' size='large' sx={{ position: 'absolute', top: 1, left: 1 }}>
                    <DeleteForeverIcon fontSize='large' />
                  </IconButton>
                </Paper>
              </Grid>
            }
            <Grid item xs={12}  md={4}>
              <Paper elevation={8} sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    data.bankName
                  }
                </Typography>
                <Typography variant='subtitle1' sx={{ fontStyle: 'oblique' }} gutterBottom>
                  {
                    data.accountName
                  }
                </Typography>
                {
                  !!data?.bsbNumber &&
                  <Typography variant='h6'>
                    <Box sx={{ color: 'info.main' }} component='span'>{`BSB `}</Box>
                    {
                      data.bsbNumber
                    }
                    <CopyToClipboard text={data.bsbNumber} copiedText={t('BSB number copied')}>
                      <IconButton size='large'>
                        <CopyIcon fontSize='small' />
                      </IconButton>
                    </CopyToClipboard>
                  </Typography>
                }
                <Typography variant='h6' gutterBottom>
                  {
                    data.accountNumber
                  }
                  <CopyToClipboard text={data.accountNumber} copiedText={t('Account number copied')}>
                    <IconButton size='large'>
                      <CopyIcon fontSize='small' />
                    </IconButton>
                  </CopyToClipboard>
                </Typography>
                <BankStatusControl accountNumber={data.rawAccountNumber} />
                <Box sx={{
                  my: 1.5,
                }}>
                  <Divider />
                </Box>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                  <Button
                    onClick={handleRoundDown}
                    size='small'
                    variant='contained'
                    color='primary'
                  >
                    {t('Round down')}
                  </Button>
                  <Typography variant='h4' gutterBottom>
                    <CopyToClipboard text={finalAmount} copiedText={t('Amount copied')}>
                      <IconButton size='small'>
                        <CopyIcon fontSize='small' />
                      </IconButton>
                    </CopyToClipboard>
                    {
                      `$${finalAmount}`
                    }
                  </Typography>
                </Box>
                <Box sx={{
                  my: 1.5,
                }}>
                  <Divider />
                </Box>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                 }}>
                  <Button
                    sx={{ bgcolor: '#25D366', '&:hover': { bgcolor: '#1da851' } }}
                    color='inherit'
                    variant='contained'
                    size='small'
                    startIcon={<WhatsAppIcon />}
                    onClick={handleWhatsAppClicked}
                  >
                    {t('WhatsApp')}
                  </Button>
                  <Typography variant='h6'
                    sx={{
                      textDecoration: 'underline',
                      textDecorationColor: theme => (theme.palette.info.main),
                      textDecorationThickness: 2,
                      cursor: 'pointer',
                    }}
                    onClick={onUsernameClicked(data.username)}
                  >
                    {data.username}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}  md={4}>
              <Paper elevation={8} sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                    >
                      <InputLabel id='bank-select-label'>{t('Company Bank')}</InputLabel>
                      <Select
                        id='companyBank'
                        name='companyBank'
                        autoWidth
                        disabled={readOnly}
                        labelId='bank-select-label'
                        value={selectedBank}
                        label={t('Company Bank')}
                        onChange={(event) => {
                          const value = get(event, 'target.value');
                          if (!value) {
                            resetBankCredential();
                            return;
                          }
                          setSelectedBank(value);

                          const found = find(banks, { _id: value });
                          if (!found) return;
                          const { displayNumber, accountNumber, companyId } = found;
                          formik.setFieldValue('companyBank.displayNumber', displayNumber);
                          formik.setFieldValue('companyBank.accountNumber', accountNumber);
                          formik.setFieldValue('companyBank.companyId', companyId);
                          formik.setFieldValue('state', 'initial');

                          findAndSetBankCredential(accountNumber);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                        {
                          bankOptions
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper elevation={4} sx={{ p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant='subtitle2' sx={{ mx: 1, color: 'text.disabled' }}>{t('Bank Username')}</Typography>
                      <Typography variant='subtitle2' sx={{ color: 'info.main' }}>{bankCredential?.username || 'N/A'}</Typography>
                      <Typography variant='subtitle2' sx={{ mx: 1, color: 'text.disabled' }}>{t('Bank Password')}</Typography>
                      <Typography variant='subtitle2' sx={{ color: 'error.main' }}>{bankCredential?.maskedPassword || 'N/A'}</Typography>

                      <CopyToClipboard text={bankCredential?.password || ''} copiedText='Bank password copied'>
                        <IconButton disabled={!bankCredential?.password} color='error' size='small'>
                          <CopyIcon fontSize='small' />
                        </IconButton>
                      </CopyToClipboard>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        views={['day', 'hours', 'minutes', 'seconds']}
                        inputFormat="YYYY-MM-DD HH:mm:ss"
                        renderInput={(props) => <TextField fullWidth {...props} />}
                        id='transactedAt'
                        label={t('Transacted At')}
                        value={get(formik, 'values.transactedAt', '')}
                        onBlur={formik.handleBlur}
                        onChange={(date) => {
                          formik.setFieldValue('transactedAt', date.toDate());
                        }}
                        error={get(formik, 'touched.transactedAt', false) && Boolean(get(formik, 'errors.transactedAt'))}
                        helperText={get(formik, 'touched.transactedAt', false) && get(formik, 'errors.transactedAt')}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      disabled={readOnly}
                      id='cappedAmount'
                      name='cappedAmount'
                      label={t('Capped Amount')}
                      value={formik.values.cappedAmount}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={get(formik, `touched.cappedAmount`, false) && Boolean(get(formik, `errors.cappedAmount`))}
                      helperText={get(formik, `touched.cappedAmount`, false) && get(formik, `errors.cappedAmount`)}
                      onPaste={handleImagePaste}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='remark'
                      name='remark'
                      label={t('Remark')}
                      value={formik.values.remark}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={get(formik, `touched.remark`, false) && Boolean(get(formik, `errors.remark`))}
                      helperText={get(formik, `touched.remark`, false) && get(formik, `errors.remark`)}
                      onPaste={handleImagePaste}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      disabled={readOnly}
                      onClick={() => formik.setFieldValue('state', 'initial')}
                      size='large'
                      fullWidth
                      variant={ formik.values.state === 'initial' ? 'contained' : 'outlined' }
                      color={ formik.values.state === 'initial' ? 'success' : 'primary' }
                      startIcon={<DoneIcon />}
                    >
                      {t('Done')}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      disabled={readOnly}
                      onClick={() => formik.setFieldValue('state', 'canceling')}
                      size='large'
                      fullWidth
                      variant={ formik.values.state === 'canceling' ? 'contained' : 'outlined' }
                      color={ formik.values.state === 'canceling' ? 'error' : 'primary' }
                      startIcon={<CancelIcon />}
                    >
                      {t('Cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Close')}</Button>
          <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
