import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AbilityContext } from 'casl/Can';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { transformSavedData } from 'utils/form-utils';
import { lookupsCategory as lookupCategories } from 'lookups/games';
import { gameTypeMenu } from 'utils/menu';
import ListSubheader from '@mui/material/ListSubheader';

import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject } from 'utils/yup-helper';

const _NEWID = '@NewGame';
const _RNAME = 'games';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const { user: { supportedLocales = ['en'] } = {} } = useAuth();

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        weight: 100,
        isEnabled: true
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    name: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t("Required"))),
    type: Yup.string().required(t("Required")),
    //categories: Yup.array().min(1, t("Required")),
    categories: Yup.array().of(Yup.string()).nullable(),
    weight: Yup.number().integer().nullable(),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');

        if (_id === _NEWID) {
          delete values._id;
          const saved = await feathers.service(serviceName).create(values);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, {
            $set: values
          });
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const selectedType = useMemo(
    () => {
      return get(formik, 'values.type', 'None');
    }, [formik]
  );

  const categoriesOptions = useMemo(
    () => {
      const categories = get(lookupCategories, selectedType, []);

      return categories;
    }, [selectedType]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Game')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            {
              supportedLocales.map(locale => (
                <Grid key={locale} item xs={12}>
                  <TextField
                    fullWidth
                    disabled={disableInput(`name.${locale}`)}
                    id={`name.${locale}`}
                    name={`name.${locale}`}
                    label={t(`name.${locale}`)}
                    value={get(formik, `values.name.${locale}`, '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.name.${locale}`, false) && Boolean(get(formik, `errors.name.${locale}`))}
                    helperText={get(formik, `touched.name.${locale}`, false) && get(formik, `errors.name.${locale}`)}
                  />
                </Grid>
              ))
            }
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.type', false) && Boolean(get(formik, 'errors.type'))}
              >
                <InputLabel id='type-select-label'>{t('Type')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('type')}
                  labelId='type-select-label'
                  id='type'
                  name='type'
                  value={get(formik, 'values.type', 'None')}
                  label={t('Type')}
                  onChange={
                    (event) => {
                      const type = get(event, 'target.value', 'None');
                      formik.handleChange(event);
                      formik.setFieldValue('categories', get(lookupCategories, type, []));
                    }
                  }
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    gameTypeMenu(true, true, true).map((g) => {
                      if (g.type === 'subheader') {
                        return <ListSubheader key={g.key}>{t(g.value)}</ListSubheader>
                      }
                      return <MenuItem key={g.key} value={g.key}>{g.value}</MenuItem>
                    })
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.type', false) && get(formik, 'errors.type')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.categories', false) && Boolean(get(formik, 'errors.categories'))}
              >
                <InputLabel id='categories-select-label'>{t('Categories')}</InputLabel>
                <Select
                  autoWidth
                  disabled={disableInput('categories')}
                  labelId='categories-select-label'
                  id='categories'
                  name='categories'
                  multiple
                  value={get(formik, 'values.categories', [])}
                  label={t('Categories')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {
                    categoriesOptions.map(g => (
                      <MenuItem key={g} value={g}>
                        {t(`gameCategories.${g}`)}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.categories', false) && get(formik, 'errors.categories')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('weight')}
                id='weight'
                name='weight'
                label={t('Weight')}
                value={get(formik, 'values.weight', '0')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.weight`, false) && Boolean(get(formik, `errors.weight`))}
                helperText={get(formik, `touched.weight`, false) && get(formik, `errors.weight`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isEnabled', isChecked)
                  }} />
                }
                label={t('Enabled')}
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
