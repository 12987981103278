import { useEffect, useState } from 'react';
import feathers from 'services/feathers';

export default function useKioskApiResponse(type = 'kiosks') {
  const service = feathers.service(type);
  const [ data, setData ] = useState(null);

  useEffect(() => {
    const onPatched = (data) => {
      const { __externalCall__ = false } = data;
      if (!__externalCall__) return;
      setData(data);
    };

    const onRemoved = (data) => {
      const { __externalCall__ = false } = data;
      if (!__externalCall__) return;
      setData(data);
    };

    const onFailed = (data) => {
      const { __externalCall__ = false } = data;
      if (!__externalCall__) return;
      setData(data);
    };

    service.on('patched', onPatched);
    service.on('removed', onRemoved);
    service.on('failed', onFailed);

    return () => {
      service.removeListener('patched', onPatched);
      service.removeListener('removed', onPatched);
      service.removeListener('failed', onFailed);
    };
  }, [service]);

  return data;
}
