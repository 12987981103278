import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Formik,
  FieldArray,
} from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { transformSavedData } from 'utils/form-utils';
import {
  FormikTextField,
  FormikAvatar,
  FormikSwitch,
  FormikSelect,
  FormikColorPicker,
} from 'features/formikControl';
import dayjs from 'dayjs';
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import {
  AddCircleTwoTone as AddCircleIcon,
  DeleteTwoTone as DeleteIcon,
  EditTwoTone as EditIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material';
import LuckyWheelPreview from './LuckyWheelPreview';

const CASL_SUBJECT = 'luckyWheels';
const SERVICE_NAME = 'lucky-wheels';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const [ fullscreen, setFullscreen ] = useState(false);
  const [ openBlockDialog, setOpenBlockDialog ] = useState(false);
  const [ blockIndex, setBlockIndex ] = useState(null);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (!isEmpty(propData)) {
        return transformSavedData(propData);
      }

      return {
        name: `Lucky Wheel #${dayjs().unix()}`,
        background: '',
        backgroundImage: '',
        blocks: [],
        prize: {
          top: '0',
          fontColors: ['#000000'],
          fontSize: '1rem',
          fontWeight: '700',
        },
        config: {
          offsetDegree: 22.5,
          stopRange: 0.8,
          speed: 10,
          accelerationTime: 500,
          decelerationTime: 1500,
          duration: 4000,
        },
      };
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const cssLengthRegex = /^-?\d*\.?\d+(px|em|rem|%|)$/;
  const hexColorRegex = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;

  const dataSchema = Yup.object().shape({
    name: Yup.string().required(),
    background: Yup.string().nullable(),
    backgroundImage: Yup.string().nullable(),
    blocks: Yup.array().of(
      Yup.object().shape({
        padding: Yup.string().nullable().matches(cssLengthRegex),
        imgs: Yup.array().of(
          Yup.object().shape({
            src: Yup.string().nullable(),
            top: Yup.string().nullable().matches(cssLengthRegex),
            width: Yup.string().nullable().matches(cssLengthRegex),
            height: Yup.string().nullable().matches(cssLengthRegex),
            rotate: Yup.boolean().nullable(),
          })
        )
      })
    ),
    prize: Yup.object().shape({
      top: Yup.string().nullable().matches(cssLengthRegex),
      fontColors: Yup.array().of(
        Yup.string().matches(hexColorRegex)
      ),
      fontSize: Yup.string().nullable().matches(cssLengthRegex),
      fontWeight: Yup.string().nullable().oneOf(['100', '200', '300', '400', '500', '600', '700', '800', '900']),
    }),
    config: Yup.object().shape({
      offsetDegree: Yup.number().required().min(0),
      stopRange: Yup.number().required().min(0).max(1),
      speed: Yup.number().required().min(1),
      accelerationTime: Yup.number().required().min(100),
      decelerationTime: Yup.number().required().min(100),
      duration: Yup.number().required().min(100),
    }),
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='lg' open={open} onClose={handleClose} fullScreen={fullscreen}>
      <DialogTitle>
       <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          {t('Lucky Wheel')}
          <IconButton
            onClick={() => setFullscreen(!fullscreen)}
          >
            {
              fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />
            }
          </IconButton>
        </Box>
      </DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const _id = get(data, '_id');

            if (!_id) {
              const { _id, ...newValues } = values;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              await feathers.service(SERVICE_NAME).patch(_id, {
                $set: values
              });
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <>
              <DialogContent dividers>
              <Paper sx={{ p: 2 }} elevation={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card>
                      <CardHeader title={t('General')} />
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}>
                          <FormikTextField
                            fullWidth
                            id='name'
                            name='name'
                            label={t('Name')}
                            value={get(values, 'name', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikTextField
                            fullWidth
                            id='background'
                            name='background'
                            label={t('Background')}
                            value={get(values, 'background', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikAvatar
                            id='backgroundImage'
                            name='backgroundImage'
                            label={t('Background Image')}
                            value={get(values, 'backgroundImage', '')}
                            variant='square'
                            sx={{
                              width: 120,
                              height: 120,
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card>
                      <FieldArray
                        name='blocks'
                        render={arrayHelpers => (
                          <>
                            <CardHeader
                              title={t('Blocks')}
                              action={
                                <IconButton
                                  onClick={() => arrayHelpers.push({
                                    padding: '0',
                                    imgs: []
                                  })}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              }
                            />
                            <CardContent>
                              <List>
                                {
                                  values.blocks.map((block, index) => (
                                    <>
                                      <ListItem
                                        key={`block-${index}`}
                                      >
                                        <ListItemText primary={`#${index + 1}`} />
                                        <ListItemSecondaryAction>
                                          <IconButton
                                            onClick={() => {
                                              setBlockIndex(index);
                                              setOpenBlockDialog(true);
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                          <IconButton
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <Divider />
                                    </>
                                  ))
                                }
                              </List>
                            </CardContent>
                            <Dialog open={openBlockDialog} onClose={() => setOpenBlockDialog(false)} fullWidth maxWidth='sm'>
                              <DialogTitle>{t('Block')}</DialogTitle>
                              <DialogContent sx={{ p: 1 }}>
                                <Box sx={{
                                  p: 1,
                                }}>
                                  <FormikTextField
                                    sx={{
                                      width: {
                                        sx: '100%',
                                        md: '50%',
                                      }
                                    }}
                                    id={`blocks.${blockIndex}.padding`}
                                    name={`blocks.${blockIndex}.padding`}
                                    label={t('Padding')}
                                    value={get(values, `blocks.${blockIndex}.padding`, '')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FieldArray
                                    name={`blocks.${blockIndex}.imgs`}
                                    render={arrayHelpers => (
                                      <>
                                        <Box sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          my: 1,
                                        }}>
                                          <Typography variant='subtitle2' color='textSecondary'>{t('Images')}</Typography>
                                          <IconButton
                                            onClick={() => arrayHelpers.push({
                                              src: '',
                                              top: '0',
                                              width: '100%',
                                              height: '100%',
                                              rotate: false,
                                            })}
                                          >
                                            <AddCircleIcon />
                                          </IconButton>
                                        </Box>
                                        <Box>
                                          {
                                            values.blocks[blockIndex].imgs.map((img, index) => (
                                              <Box
                                                key={`blocks.${blockIndex}.imgs.${index}`}
                                                sx={{
                                                  display: 'flex',
                                                  gap: 1,
                                                  alignItems: 'center',
                                                  mb: 1,
                                                }}
                                              >
                                                <FormikAvatar
                                                  id={`blocks.${blockIndex}.imgs.${index}.src`}
                                                  name={`blocks.${blockIndex}.imgs.${index}.src`}
                                                  value={get(values, `blocks.${blockIndex}.imgs.${index}.src`, '')}
                                                  variant='square'
                                                  sx={{
                                                    width: 48,
                                                    height: 48,
                                                  }}
                                                />
                                                <FormikTextField
                                                  id={`blocks.${blockIndex}.imgs.${index}.top`}
                                                  name={`blocks.${blockIndex}.imgs.${index}.top`}
                                                  label={t('Top')}
                                                  value={get(values, `blocks.${blockIndex}.imgs.${index}.top`, '')}
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                />
                                                <FormikTextField
                                                  id={`blocks.${blockIndex}.imgs.${index}.width`}
                                                  name={`blocks.${blockIndex}.imgs.${index}.width`}
                                                  label={t('Width')}
                                                  value={get(values, `blocks.${blockIndex}.imgs.${index}.width`, '')}
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                />
                                                <FormikTextField
                                                  id={`blocks.${blockIndex}.imgs.${index}.height`}
                                                  name={`blocks.${blockIndex}.imgs.${index}.height`}
                                                  label={t('Height')}
                                                  value={get(values, `blocks.${blockIndex}.imgs.${index}.height`, '')}
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                />
                                                <FormikSwitch
                                                  id={`blocks.${blockIndex}.imgs.${index}.rotate`}
                                                  name={`blocks.${blockIndex}.imgs.${index}.rotate`}
                                                  label={t('Rotate')}
                                                  checked={get(values, `blocks.${blockIndex}.imgs.${index}.rotate`, false)}
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                />
                                                <IconButton
                                                  onClick={() => arrayHelpers.remove(index)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Box>
                                            ))
                                          }
                                        </Box>
                                      </>
                                    )}
                                  />
                                </Box>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={() => setOpenBlockDialog(false)}>{t('Close')}</Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card>
                      <CardHeader title={t('Prize')} />
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}>
                          <FormikTextField
                            id='prize.top'
                            name='prize.top'
                            label={t('Top')}
                            value={get(values, 'prize.top', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FieldArray
                            name='prize.fontColors'
                            render={arrayHelpers => (
                              <>
                                <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}>
                                  <Typography variant='subtitle2' color='textSecondary'>{t('Alternate Font Colors')}</Typography>
                                  <IconButton
                                    onClick={() => arrayHelpers.push('#000000')}
                                  >
                                    <AddCircleIcon />
                                  </IconButton>
                                </Box>
                                <Box>
                                  {
                                    values.prize.fontColors.map((color, index) => (
                                      <Box
                                        key={`prize.fontColors.${index}`}
                                        sx={{
                                          display: 'flex',
                                          gap: 1,
                                          alignItems: 'center',
                                          my: 0.5,
                                        }}
                                      >
                                        <FormikColorPicker
                                          id={`prize.fontColors.${index}`}
                                          name={`prize.fontColors.${index}`}
                                          value={color}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                        <IconButton
                                          onClick={() => arrayHelpers.remove(index)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    ))
                                  }
                                </Box>
                              </>
                            )}
                          />
                          <FormikTextField
                            id='prize.fontSize'
                            name='prize.fontSize'
                            label={t('Font Size')}
                            value={get(values, 'prize.fontSize', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikSelect
                            id='prize.fontWeight'
                            name='prize.fontWeight'
                            label={t('Font Weight')}
                            value={get(values, 'prize.fontWeight', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {
                              ['100', '200', '300', '400', '500', '600', '700', '800', '900'].map(weight => (
                                <MenuItem key={weight} value={weight}>{weight}</MenuItem>
                              ))
                            }
                          </FormikSelect>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card>
                      <CardHeader title={t('Config')} />
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}>
                          <FormikTextField
                            id='config.offsetDegree'
                            name='config.offsetDegree'
                            label={t('Offset Degree')}
                            value={get(values, 'config.offsetDegree', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikTextField
                            id='config.stopRange'
                            name='config.stopRange'
                            label={t('Stop Range')}
                            value={get(values, 'config.stopRange', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikTextField
                            id='config.speed'
                            name='config.speed'
                            label={t('Speed')}
                            value={get(values, 'config.speed', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikTextField
                            id='config.accelerationTime'
                            name='config.accelerationTime'
                            label={t('Acceleration Time')}
                            value={get(values, 'config.accelerationTime', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikTextField
                            id='config.decelerationTime'
                            name='config.decelerationTime'
                            label={t('Deceleration Time')}
                            value={get(values, 'config.decelerationTime', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormikTextField
                            id='config.duration'
                            name='config.duration'
                            label={t('Duration')}
                            value={get(values, 'config.duration', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Card>
                      <CardHeader title={t('Preview')} />
                      <CardContent>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                          <LuckyWheelPreview
                            data={{
                              background: values.background,
                              backgroundImage: values.backgroundImage,
                              blocks: values.blocks,
                              prize: values.prize,
                              config: values.config,
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
