import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import { transformSavedData } from 'utils/form-utils';
import CommonContext from 'features/context/commonContext';
import { useAuth } from 'hooks/useAuth';
import { getLocaleYupObject } from 'utils/yup-helper';
import {
  FormikTextField,
  FormikAvatar,
  FormikDateTimePicker,
  FormikSwitch,
  FormikSelect,
} from 'features/formikControl';
import dayjs from 'dayjs';

const CASL_SUBJECT = 'pointBoosters';
const SERVICE_NAME = 'point-boosters';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const { user: {
    lang = 'en',
    supportedLocales = ['en']
  } = {} } = useAuth();
  const { memberTiers: ranks } = useContext(CommonContext);

  const requirementTypeOptions = useMemo(
    () => {
      let ret = [];
      ['none', 'deposit', 'level', 'rank'].forEach(item => {
        ret.push({ key: item, value: `requirementType.${item}` });
      });
      return ret;
    }, []
  );

  const rankOptions = useMemo(
    () => {
      return ranks.map(r => {
        return {
          key: r._id,
          value: get(r, `name.${lang}`, r._id)
        }
      });
    }, [ranks, lang]
  );

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        daysAllowed: [1, 2, 3, 4, 5, 6, 7],
        requirementType: 'none',
        pointMultiplier: 0,
        isEnabled: true,
        startsAt: dayjs().add(1, 'day').startOf('day').toDate(),
        endsAt: dayjs().add(1, 'day').add(3, 'year').startOf('day').toDate(),
      }
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    name: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t("Required"))),
    photo: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t("Required"))),
    startsAt: Yup.date().required(t("Required")),
    endsAt: Yup.date().required(t("Required")),
    daysAllowed: Yup.array().of(Yup.number().integer()),
    requirementType: Yup.string().matches(/none|deposit|level|rank/).required(t("Required")),
    requirementAmount: Yup.object()
      .when('requirementType', {
        is: (val) => (val === 'deposit') ,
        then: (schema) => schema.shape({
          min: Yup.number().required(t("Required")).min(-1),
          max: Yup.number().required(t("Required")).min(-1),
        }),
        otherwise: (schema) => schema.nullable()
      }),
    requirementLevel: Yup.object()
      .when('requirementType', {
        is: (val) => (val === 'level') ,
        then: (schema) => schema.shape({
          min: Yup.number().integer().required(t("Required")).min(-1),
          max: Yup.number().integer().required(t("Required")).min(-1),
        }),
        otherwise: (schema) => schema.nullable()
      }),
    requirementRanks: Yup.array().of(Yup.string())
      .when('requirementType', {
        is: (val) => (val === 'rank') ,
        then: (schema) => schema.required(t("Required")).min(1),
        otherwise: (schema) => schema.nullable()
      }),
    pointMultiplier: Yup.number().required(t("Required")).min(0.01).max(1),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Point Booster')}</DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const { _id } = values;

            if (!_id) {
              const { _id, ...newValues } = values;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              await feathers.service(SERVICE_NAME).patch(_id, {
                $set: values
              });
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
                <Paper sx={{ p: 2 }} elevation={0}>
                  <Grid container spacing={2}>
                    {
                      supportedLocales.map(locale => {
                        const nameKey = `name.${locale}`;
                        const photoKey = `photo.${locale}`;
                        return (
                          <React.Fragment key={locale}>
                            <Grid item xs={10}>
                              <FormikTextField
                                fullWidth
                                id={nameKey}
                                name={nameKey}
                                label={t(nameKey)}
                                value={get(values, nameKey, '')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FormikAvatar
                                id={photoKey}
                                name={photoKey}
                                value={get(values, photoKey, '')}
                                variant='rounded'
                                sx={{ mx: 'auto', my: 1 }}
                                onChange={(imageId) => {
                                  if (locale === supportedLocales[0]) {
                                    supportedLocales.forEach(l => {
                                      setFieldValue(`photo.${l}`, imageId);
                                    });
                                  }
                                }}
                              />
                            </Grid>
                          </React.Fragment>
                        );
                      })
                    }
                    <Grid item xs={12}>
                      <FormikDateTimePicker
                        views={['day', 'hours', 'minutes', 'seconds']}
                        inputFormat="YYYY-MM-DD HH:mm:ss"
                        id='startsAt'
                        name='startsAt'
                        label={t('Starts At')}
                        value={get(values, 'startsAt', null)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikDateTimePicker
                        views={['day', 'hours', 'minutes', 'seconds']}
                        inputFormat="YYYY-MM-DD HH:mm:ss"
                        id='endsAt'
                        name='endsAt'
                        label={t('Ends At')}
                        value={get(values, 'endsAt', null)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikSelect
                        fullWidth
                        id='daysAllowed'
                        name='daysAllowed'
                        label={t('Days Allowed')}
                        value={get(values, 'daysAllowed', [])}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiple
                      >
                        <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                        {
                          Array.from(Array(7).keys()).map((day) => {
                            const dayValue = day + 1;
                            const dayKey = `day_${dayValue}`;
                            return (
                              <MenuItem key={dayKey} value={dayValue}>
                                {t(dayKey)}
                              </MenuItem>
                            );
                          })
                        }
                      </FormikSelect>
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        fullWidth
                        select
                        defaultValue={'None'}
                        id='requirementType'
                        name='requirementType'
                        label={t('Requirement Type')}
                        value={get(values, 'requirementType', 'None')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {
                          requirementTypeOptions.map(o => (
                            <MenuItem key={o.key} value={o.key}>
                              {t(o.value)}
                            </MenuItem>
                          ))
                        }
                      </FormikTextField>
                    </Grid>
                    {
                      values.requirementType === 'deposit' ? (
                        <>
                          <Grid item xs={12}>
                            <FormikTextField
                              fullWidth
                              id='requirementAmount.min'
                              name='requirementAmount.min'
                              label={t('Minimum Requirement Amount')}
                              value={get(values, 'requirementAmount.min', '')}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField
                              fullWidth
                              id='requirementAmount.max'
                              name='requirementAmount.max'
                              label={t('Maximum Requirement Amount')}
                              value={get(values, 'requirementAmount.max', '')}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                        </>
                      ) : null
                    }
                    {
                      values.requirementType === 'level' ? (
                        <>
                          <Grid item xs={12}>
                            <FormikTextField
                              fullWidth
                              id='requirementLevel.min'
                              name='requirementLevel.min'
                              label={t('Minimum Requirement Level')}
                              value={get(values, 'requirementLevel.min', '')}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField
                              fullWidth
                              id='requirementLevel.max'
                              name='requirementLevel.max'
                              label={t('Maximum Requirement Level')}
                              value={get(values, 'requirementLevel.max', '')}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                        </>
                      ) : null
                    }
                    {
                      values.requirementType === 'rank' ? (
                        <Grid item xs={12}>
                          <FormikSelect
                            fullWidth
                            multiple
                            id='requirementRanks'
                            name='requirementRanks'
                            label={t('Requirement Ranks')}
                            value={get(values, 'requirementRanks', [])}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {
                              rankOptions.map(o => (
                                <MenuItem key={o.key} value={o.key}>
                                  {o.value}
                                </MenuItem>
                              ))
                            }
                          </FormikSelect>
                        </Grid>
                      ) : null
                    }
                    <Grid item xs={12}>
                      <FormikTextField
                        fullWidth
                        id='pointMultiplier'
                        name='pointMultiplier'
                        label={t('Point Multiplier')}
                        value={get(values, 'pointMultiplier', '')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikSwitch
                        id='isEnabled'
                        name='isEnabled'
                        label={t('Enabled')}
                        value={get(values, 'isEnabled', false)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
