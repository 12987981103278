import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiIconButton from '@mui/material/IconButton';
import {
  Button,
} from '@mui/material';

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export const IconButton = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <MuiIconButton {...props} ref={ref}>
      {
        children
      }
      </MuiIconButton>
    );
  }
);

export const ToggleButton = forwardRef(
  ({ icon, active, ...props }, ref) => {
    return (
      <MuiToggleButton {...props} ref={ref} selected={active}>
      {
        icon
      }
      </MuiToggleButton>
    );
  }
);

//export const Toolbar = StyledToggleButtonGroup;

export const ToggleButtonGroup = React.forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <StyledToggleButtonGroup
        ref={ref}
        {
          ...props
        }
      >
      {children}
      </StyledToggleButtonGroup>
    );
  }
);

export const TextButton = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <Button {...props} ref={ref}>
      {
        children
      }
      </Button>
    );
  }
);