const lookups = {
  "my_maybank": "Maybank",
  "my_cimb": "CIMB",
  "au_upb_bsb": "UP Bank",
  "nz_wise": "Wise",
  "sg_posb": "POSB"
};

const enums = Object.keys(lookups);

function getLookupsByCountry(country) {
  const ret = enums.reduce((acc, key) => {
    if (key.startsWith(country)) {
      return {
        ...acc,
        [key]: lookups[key]
      };
    }

    return acc;
  }, {});

  return ret;
}

export {
  lookups,
  enums,
  getLookupsByCountry,
};
