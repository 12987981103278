const kioskData = {
  "1slot": { name: "1Slot", type: ["slot"] },
  "2win": { name: "2WIN", type: ["slot"] },
  "918kaya": { name: "918Kaya", type: ["slot"] },
  "918kiss": { name: "918Kiss", type: ["slot"] },
  "918kissh5": { name: "918Kiss H5", type: ["slot"] },
  "918kissplus": { name: "918Kiss Plus", type: ["slot"] },
  "ace333": { name: "Ace 333", type: ["slot", "live"] },
  "apollo777": { name: "Apollo 777", type: ["slot"] },
  "crown128": { name: "Crown 128", type: ["slot", "live"] },
  "csc": { name: "Club Sun City", type: ["slot"] },
  "evo888": { name: "Evo 888", type: ["slot"] },
  "gw99": { name: "Great Wall 99", type: ["slot"] },
  "jili": { name: "Jili", type: ["slot", "fishing"] },
  "joker": { name: "Joker", type: ["slot", "fishing"] },
  "king855": { name: "King 855", type: ["slot", "live"] },
  "lc88": { name: "Leo City 88", type: ["slot", "live"] },
  "live22": { name: "Live22", type: ["slot", "live"] },
  "lpe88": { name: "Lucky Palace 88", type: ["slot", "live"] },
  "mario-club": { name: "Mario Club", type: ["slot", "fishing"] },
  "mega888": { name: "Mega 888", type: ["slot"] },
  "ntc33": { name: "New Town 33", type: ["slot", "live"] },
  "playboy": { name: "Playboy", type: ["slot"] },
  "pussy888": { name: "Pussy 888", type: ["slot"] },
  "rollex11": { name: "Rollex 11", type: ["slot", "live"] },
  "scr2": { name: "SCR 2", type: ["slot"] },
  "scr888": { name: "SCR 888", type: ["slot"] },
  "vpower": { name: "V-Power", type: ["slot", "live", "fishing"] },
  "xe88": { name: "XE 88", type: ["slot"] }
};

const transferApiData = {
  "advant-play-api": { name: "Advant Play API", type: ["slot"] },
  "amb-pgsoft-api": { name: "AMB PGSoft API", type: ["slot"] },
  "awc-sexy-baccarat-api": { name: "AWC Sexy Baccarat API", type: ["live"] },
  "bng-api": { name: "BNG API", type: ["slot"] },
  "bt-gaming-api": { name: "BT Gaming API", type: ["slot", 'fishing'] },
  "cq9-gaming-api": { name: "CQ9 Gaming API", type: ["slot", "fishing"] },
  "fa-chai-gaming-api": { name: "Fa Chai Gaming API", type: ["slot", "fishing"] },
  "imperium-games-api": { name: "Imperium Games API", type: ["slot", "fishing"] },
  "jdb-api": { name: "JDB API", type: ["slot", "fishing"] },
  "jili-api": { name: "Jili API", type: ["slot", "fishing"] },
  "joker-api": { name: "Joker API", type: ["slot", "fishing"] },
  "live22-api": { name: "Live22 API", type: ["slot"] },
  "live22-metaverse-api": { name: "Live22 Metaverse API", type: ["slot"] },
  "mario-club-api": { name: "Mario Club API", type: ["slot", "fishing"] },
  "microgaming-api": { name: "Microgaming API", type: ["slot", "live"] },
  "naga-games-api": { name: "Naga Games API", type: ["slot"] },
  "nextspin-api": { name: "Nextspin API", type: ["slot"] },
  "playtech-api": { name: "Playtech API", type: ["slot", "live"] },
  "pragmatic-play-api": { name: "Pragmatic Play API", type: ["slot", "live"] },
  "sa-gaming-api": { name: "SA Gaming API", type: ["live"] },
  "spadegaming-api": { name: "Spadegaming API", type: ["slot", "fishing"] }
};

const seamlessApiData = {
  "spribe-sapi": { name: "Spribe Seamless API", type: ['innovative'] },
};

const enumsKiosk = Object.keys(kioskData);
const enumsTransferApi = Object.keys(transferApiData);
const enumsSeamlessApi = Object.keys(seamlessApiData);

const enums = [
  ...enumsTransferApi,
  ...enumsKiosk,
  ...enumsSeamlessApi,
];

const getLookupsByTypeAndKey = (type, key) => {
  let result = null;

  if (type === 'kiosk') {
    result = enumsKiosk.reduce((acc, cur) => {
      acc[cur] = kioskData[cur][key];
      return acc;
    }, {});
  } else if (type === 'transferApi') {
    result = enumsTransferApi.reduce((acc, cur) => {
      acc[cur] = transferApiData[cur][key];
      return acc;
    }, {});
  } else if (type === 'seamlessApi') {
    result = enumsSeamlessApi.reduce((acc, cur) => {
      acc[cur] = seamlessApiData[cur][key];
      return acc;
    }, {});
  }

  return result;
}

const lookupsKiosk = getLookupsByTypeAndKey('kiosk', 'name');
const lookupsTransferApi = getLookupsByTypeAndKey('transferApi', 'name');
const lookupsSeamlessApi = getLookupsByTypeAndKey('seamlessApi', 'name');
const lookups = {
  ...lookupsTransferApi,
  ...lookupsKiosk,
  ...lookupsSeamlessApi,
};

const lookupsKioskCategory = getLookupsByTypeAndKey('kiosk', 'type');
const lookupsTransferApiCategory = getLookupsByTypeAndKey('transferApi', 'type');
const lookupsSeamlessApiCategory = getLookupsByTypeAndKey('seamlessApi', 'type');
const lookupsCategory = {
  ...lookupsTransferApiCategory,
  ...lookupsKioskCategory,
  ...lookupsSeamlessApiCategory,
};

export {
  lookupsKiosk,
  lookupsTransferApi,
  lookupsSeamlessApi,
  lookups,
  lookupsCategory,
  enumsKiosk,
  enumsTransferApi,
  enumsSeamlessApi,
  enums,
};
