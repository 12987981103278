import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import feathers from 'services/feathers';
import {
  getPage,
  getLimit,
  getSort,
  getFilter,
  getPopulate,
  getTextSearch,
} from './selectors';
import { get, kebabCase } from 'lodash';

export const initialState = {
  page: 0,
  total: 0,
  limit: 10,
  sort: [['createdAt', -1]],
  columnVisibility: {},
  data: [],
  tags: [],
  remarks: [],
  filter: null,
  populate: null,
  textSearch: '',
  status: 'idle',
  errorMessage: '',
};

const PARTIAL_SEARCH_FIELDS = {
  users: ['username', 'nickname', 'name'],
};

const generateTextSearchFilter = (textSearch, serviceName) => {
  if (!textSearch) return null;

  const partialSearchFields = PARTIAL_SEARCH_FIELDS[serviceName];

  if (partialSearchFields) {
    const partialSearchFilters = partialSearchFields.map((field) => ({
      [field]: {
        $regex: textSearch,
        $options: 'i'
      }
    }));

    return {
      $or: [
        ...partialSearchFilters,
      ]
    };
  } else {
    return {
      $text: {
        $search: textSearch
      }
    };
  }
};

export const fetchAsync = createAsyncThunk(
  'feathers/fetch',
  async (params, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const _RNAME = get(params, '_RNAME');
    const isWalletHistory = _RNAME === 'walletHistory' ? true : false;
    if (!_RNAME) return;
    const serviceName = isWalletHistory ? 'wallets' : kebabCase(_RNAME);

    const state = getState();

    try {
      //dispatch(clearErrorMessage({ _RNAME }));
      const page = getPage(_RNAME)(state);
      const limit = getLimit(_RNAME)(state);
      const sort = getSort(_RNAME)(state);
      const filter = getFilter(_RNAME)(state);
      const populate = getPopulate(_RNAME)(state);
      const textSearch = getTextSearch(_RNAME)(state);
      const textSearchFilter = generateTextSearchFilter(textSearch, serviceName);

      const response = await feathers.service(serviceName).find({
        query: {
          ...(isWalletHistory && {
            $aggregate: 'history',
          }),
          ...(filter && {
            ...filter
          }),
          ...(textSearchFilter && {
            ...textSearchFilter
          }),
          $limit: limit,
          $skip: limit * page,
          $sort: sort,
          ...(populate && {
            $populate: populate
          })
        }
      });
      return fulfillWithValue(response);
      //return fulfillWithValue({ _RNAME, ...response });
    } catch (err) {
      return rejectWithValue(err);
      //return rejectWithValue({ _RNAME, ...err});
    }
  }
);

export const slice = createSlice({
  name: 'feathers',
  initialState,
  reducers: {
    clearErrorMessage: (state) => {
      state.errorMessage = '';
    },
    updateData: (state, action) => {
      state.data = action.payload.data;
    },
    updatePage: (state, action) => {
      state.page = action.payload.page;
    },
    updateLimit: (state, action) => {
      state.limit = action.payload.limit;
      state.page = 0;
    },
    updateSort: (state, action) => {
      state.sort = action.payload.sort;
      state.page = 0;
    },
    updateColumnVisibility: (state, action) => {
      state.columnVisibility = action.payload.columnVisibility;
    },
    updateFilter: (state, action) => {
      state.filter = action.payload.filter;
      state.page = 0;
    },
    updatePopulate: (state, action) => {
      state.populate = action.payload.populate;
    },
    updateTextSearch: (state, action) => {
      state.textSearch = action.payload.textSearch;
      state.page = 0;
    },
    updateSearchFromUrl: (state, action) => {
      state.textSearch  =action.payload.textSearch;
      state.filter = action.payload.filter;
      state.page = 0;
    },
    updateTags: (state, action) => {
      state.tags = action.payload.tags;
    },
    reset: (state, action) => {
      const defaultState = { ...initialState };
      return defaultState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, (state, action) => {
        state.errorMessage = '';
        state.status = 'loading';
      })
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.total = action.payload.total;
        state.limit = action.payload.limit;
        //state.page = action.payload.page;
        state.data = action.payload.data || [];
        state.tags = action.payload.tags || [];
        state.remarks = action.payload.remarks || [];
        state.status = 'idle';
      })
      .addCase(fetchAsync.rejected, (state, action) => {
        state.errorMessage = action.payload.message;
        state.status = 'idle';
      });
  }
});

export const {
  clearErrorMessage,
  clearRealTimeData,
  updateData,
  updatePage,
  updateLimit,
  updateSort,
  updateColumnVisibility,
  updateFilter,
  updatePopulate,
  updateTextSearch,
  updateSearchFromUrl,
  updateTags,
  reset,
} = slice.actions;

export default slice.reducer;
