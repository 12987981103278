import { lookups as lookupBanks } from 'lookups/banks';
import { get, camelCase, groupBy } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Decimal from 'decimal.js';

// id type account number
const _ID_PATTERN = /^(my_duitnow_|sg_paynow_|au_payid_|nz_payidnz_)/g;

export function checkIsBankIdType(bankName) {
  return !!_ID_PATTERN.test(bankName);
}

export function getFullBankLabel(t, bankName = '') {
  const matches = bankName.match(_ID_PATTERN);
  let label = get(lookupBanks, bankName, '');

  if (!matches) return label;

  const type = camelCase(matches[0]);
  label = bankName.replace(_ID_PATTERN, 'pay_');

  return `${t(type)} - ${t(label)}`;
}

export function getBankLabel(t, bankName) {
  const matches = bankName.match(_ID_PATTERN);
  let label = get(lookupBanks, bankName, '');

  if (!matches) return label;

  label = bankName.replace(_ID_PATTERN, 'pay_');

  return t(label);
}

export function getBankOptions(t, bankLookup) {
  if (!bankLookup) return [];

  const mappedBankList = Object.entries(bankLookup).map(([key, label]) => {
    const matches = key.match(_ID_PATTERN);
    const type = matches ? camelCase(matches[0]) : 'bank';
    const newLabel = matches ? key.replace(_ID_PATTERN, 'pay_') : label;
    return { type, key, label: newLabel };
  });

  const groupByType = groupBy(mappedBankList, 'type');

  function renderMenuItem({ type, key, label }) {
    return (
      <MenuItem key={key} value={key}>
        {type === 'bank' ? label : t(label)}
      </MenuItem>
    );
  }

  return Object.entries(groupByType).flatMap(([key, items]) => [
    <ListSubheader key={key}>{t(key)}</ListSubheader>,
    ...items.map(renderMenuItem),
  ]);
}

export function getCompanyBankOptions(t, bankLookup, banks, bankData) {
  if (!bankLookup) return [];

  const mappedBankList = banks.map((bank) => {
    const bankName = get(bank, 'bankName', '');
    const matches = bankName.match(_ID_PATTERN);
    const type = matches ? camelCase(matches[0]) : 'bank';
    const label = matches ? bankName.replace(_ID_PATTERN, '') : bankLookup[bankName];
    const data = bankData.find(bd => bd.accountNumber === bank.accountNumber);
    const balance = new Decimal(get(data, 'balance.$numberDecimal', '0')).toFixed(2);

    return { ...bank, type, label, data, balance };
  });

  const groupByType = groupBy(mappedBankList, 'type');

  return Object.entries(groupByType).flatMap(([key, items]) => [
    <ListSubheader key={key}>{t(key)}</ListSubheader>,
    ...items.map(item => renderMenuItem(item, t)),
  ]);

  function renderMenuItem(item, t) {
    return (
      <MenuItem key={item._id} value={item._id}>
        <Paper variant='outlined' sx={{ p: 2, minWidth: '240px' }}>
          <Typography variant='h6'>{item.name}</Typography>
          <Box sx={{ width: '100%', textAlign: 'right' }}>
            <Typography variant='body2'>{`$${item.balance}`}</Typography>
          </Box>
        </Paper>
      </MenuItem>
    );
  }
}

export function getBankOptionsWithoutMobilePayment(bankLookup) {
  if (bankLookup === null || bankLookup === undefined) {
    return [];
  }

  return Object.entries(bankLookup)
  .filter(([key, label]) => {
    const matches = key.match(_ID_PATTERN);
    return matches ? false : true;
  })
  .map(([key, label]) => {
    return {
      key,
      label
    }
  });
}
