import React, { useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  ButtonGroup,
} from '@mui/material';
import {
  ReactEditor,
  useSlateStatic,
  useSelected,
  useFocused,
} from 'slate-react';
import { Transforms } from 'slate';
import { get } from 'lodash';
import ProtectedImage from 'features/img/ProtectedImage';
import GenericImage from 'features/img/GenericImage';
import { createParagraphNode } from 'features/slate/utils/paragraph';
import { useTranslation } from 'react-i18next';

const LIST_TYPES = ['bulleted-list', 'numbered-list'];

export default function SlateImage({ attributes, element, children }) {
  const theme = useTheme();
  const editor = useSlateStatic();
  const { t } = useTranslation();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  const id = get(element, 'id');
  const isSelectedAndFocused = (selected && focused) ? true : false;
  const boxShadowStyle = `0 0 0 4px ${theme.palette.warning.light}`;

  const isInList = useMemo(() => {
    const parentPath = path.slice(0, -1);
    const parentNode = get(editor, 'children')[parentPath[0]];

    return parentNode && LIST_TYPES.includes(parentNode.type);
  }, [editor, path]);

  const handleRemoveImage = useCallback((event) => {
    event.preventDefault();
    Transforms.removeNodes(editor, { at: path });
  }, [editor, path]);

  const handleMoveToLeft = useCallback((event) => {
    event?.preventDefault();

    if (!isInList) return;

    const currentImage = path.reduce((node, index, currentIndex) => {
      if (currentIndex === path.length - 1) {
        return node[index];
      }

      return node[index].children;
    }, editor.children);

    if (!currentImage) return;

    Transforms.insertNodes(editor, createParagraphNode(), {
      at: [editor.children.length]
    });

    Transforms.insertNodes(editor, currentImage, {
      at: [editor.children.length]
    });

    Transforms.removeNodes(editor, { at: path });
  }, [editor, path, isInList]);

  return (
    <Box {...attributes}
        sx={{
          width: {
            xs: '100%',
            sm: '50%',
          }
        }}
      >
      {children}
      <Box
        contentEditable={false}
        sx={{
          position: 'relative'
        }}
      >
        <Box sx={{
          display: isSelectedAndFocused ? 'flex' : 'none',
          position: 'absolute',
          top: 0,
          right: 0,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
          <ButtonGroup variant='contained' size='small'>
            <Button
              disabled={!isInList}
              color='warning'
              onMouseDown={handleMoveToLeft}
            >
              {t('Move to left')}
            </Button>
            <Button
              color='error'
              onMouseDown={handleRemoveImage}
            >
              {t('Remove')}
            </Button>
          </ButtonGroup>
        </Box>
        {
          id ?
          <ProtectedImage
            sx={{
              display: 'block',
              width: '100%',
              borderRadius: isSelectedAndFocused ? 0 : 0,
              boxShadow: isSelectedAndFocused ? boxShadowStyle : 'none'
            }}
            assetId={id}
            name={id}
          /> :
          <GenericImage
            sx={{
              display: 'block',
              width: '100%',
              borderRadius: isSelectedAndFocused ? 0 : 0,
              boxShadow: isSelectedAndFocused ? boxShadowStyle : 'none'
            }} src={element.url} alt={element.url}
          />
        }
      </Box>
    </Box>
  );
};
