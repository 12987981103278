import * as React from 'react';
import { SvgIcon } from '@mui/material';

export function LineIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="24px"
        height="24px"
      >
        <path d="M25.12,44.521c-2.114,1.162-2.024-0.549-1.933-1.076c0.054-0.314,0.3-1.787,0.3-1.787c0.07-0.534,0.144-1.36-0.067-1.887 c-0.235-0.58-1.166-0.882-1.85-1.029C11.48,37.415,4.011,30.4,4.011,22.025c0-9.342,9.42-16.943,20.995-16.943S46,12.683,46,22.025 C46,32.517,34.872,39.159,25.12,44.521z M18.369,25.845c0-0.56-0.459-1.015-1.023-1.015h-2.856v-6.678 c0-0.56-0.459-1.015-1.023-1.015c-0.565,0-1.023,0.455-1.023,1.015v7.694c0,0.561,0.459,1.016,1.023,1.016h3.879 C17.91,26.863,18.369,26.406,18.369,25.845z M21.357,18.152c0-0.56-0.459-1.015-1.023-1.015c-0.565,0-1.023,0.455-1.023,1.015 v7.694c0,0.561,0.459,1.016,1.023,1.016c0.565,0,1.023-0.456,1.023-1.016V18.152z M30.697,18.152c0-0.56-0.459-1.015-1.023-1.015 c-0.565,0-1.025,0.455-1.025,1.015v4.761l-3.978-5.369c-0.192-0.254-0.499-0.406-0.818-0.406c-0.11,0-0.219,0.016-0.325,0.052 c-0.419,0.139-0.7,0.526-0.7,0.963v7.694c0,0.561,0.46,1.016,1.025,1.016c0.566,0,1.025-0.456,1.025-1.016v-4.759l3.976,5.369 c0.192,0.254,0.498,0.406,0.818,0.406c0.109,0,0.219-0.018,0.325-0.053c0.42-0.137,0.7-0.524,0.7-0.963V18.152z M36.975,20.984 h-2.856v-1.817h2.856c0.566,0,1.025-0.455,1.025-1.015c0-0.56-0.46-1.015-1.025-1.015h-3.879c-0.565,0-1.023,0.455-1.023,1.015 c0,0.001,0,0.001,0,0.003v3.842v0.001c0,0,0,0,0,0.001v3.845c0,0.561,0.46,1.016,1.023,1.016h3.879 c0.565,0,1.025-0.456,1.025-1.016c0-0.56-0.46-1.015-1.025-1.015h-2.856v-1.817h2.856c0.566,0,1.025-0.455,1.025-1.015 c0-0.561-0.46-1.016-1.025-1.016V20.984z"/>
      </svg>
    </SvgIcon>
  );
};

export function LuckyWheelIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M2370 5114 c-19 -2 -78 -9 -131 -14 -456 -50 -926 -245 -1284 -535
          -122 -99 -365 -349 -451 -465 -190 -254 -338 -561 -414 -856 -126 -489 -117
          -989 27 -1464 87 -287 248 -591 438 -825 99 -122 349 -365 465 -452 341 -255
          731 -413 1185 -479 183 -27 552 -25 744 5 289 44 502 111 766 241 265 130 445
          262 676 497 208 212 316 358 439 596 467 898 359 2033 -265 2802 -40 50 -140
          155 -221 235 -223 218 -387 335 -649 461 -355 171 -677 247 -1085 254 -113 2
          -221 1 -240 -1z m398 -300 l61 -7 -135 -268 c-74 -148 -136 -269 -138 -269 -5
          0 -276 525 -276 534 0 13 382 21 488 10z m602 -144 c809 -313 1326 -979 1426
          -1836 20 -167 15 -441 -11 -609 -99 -650 -446 -1206 -973 -1558 -185 -124
          -451 -237 -694 -297 -212 -52 -322 -64 -563 -64 -236 0 -365 16 -575 69 -308
          78 -609 228 -850 422 -176 143 -377 377 -497 580 -338 576 -416 1304 -206
          1933 93 279 247 547 434 754 214 236 462 417 755 549 148 67 342 134 353 121
          4 -5 38 -66 74 -135 l66 -126 -143 -47 c-534 -174 -938 -520 -1165 -998 -139
          -291 -200 -590 -188 -923 29 -815 499 -1494 1227 -1771 268 -103 615 -151 885
          -125 813 79 1433 564 1679 1311 278 846 -8 1758 -702 2238 -192 133 -436 244
          -659 302 l-43 11 70 138 69 139 73 -23 c40 -12 111 -37 158 -55z m-1037 -630
          l77 -149 0 -450 c0 -450 0 -450 -21 -462 -20 -10 -55 22 -455 422 -344 344
          -431 436 -422 447 26 30 229 157 332 207 126 60 336 131 396 134 9 0 43 -54
          93 -149z m611 135 c196 -50 375 -129 541 -240 61 -40 116 -80 123 -87 9 -11
          -75 -100 -422 -447 -400 -400 -435 -432 -455 -422 -21 12 -21 12 -21 461 l0
          450 75 150 c41 83 80 150 86 150 6 0 39 -7 73 -15z m-803 -1444 l-12 -21 -611
          0 -610 0 7 68 c9 84 56 272 91 366 53 139 129 278 222 405 l54 74 435 -435
          c402 -402 434 -437 424 -457z m1795 757 c61 -92 130 -223 170 -325 41 -103 89
          -293 99 -385 l7 -68 -610 0 -611 0 -12 21 c-10 20 23 55 422 456 l434 434 28
          -33 c15 -18 48 -63 73 -100z m-1282 -811 c85 -65 68 -205 -30 -252 -72 -34
          -164 -2 -198 69 -68 143 102 279 228 183z m-513 -288 c10 -20 -23 -55 -422
          -456 l-434 -434 -28 33 c-80 95 -187 281 -243 425 -41 103 -89 293 -99 386
          l-7 67 610 0 611 0 12 -21z m2064 -45 c-16 -150 -84 -373 -160 -525 -44 -86
          -172 -286 -197 -307 -11 -9 -103 78 -447 422 -400 400 -432 435 -422 455 l12
          21 611 0 610 0 -7 -66z m-1814 -204 c18 -10 19 -28 19 -622 l0 -610 -67 7
          c-85 9 -273 56 -367 91 -141 53 -281 131 -404 222 l-74 55 434 434 c238 238
          434 433 436 433 2 0 12 -5 23 -10z m797 -423 l434 -434 -39 -30 c-113 -90
          -299 -194 -439 -247 -94 -35 -282 -82 -366 -91 l-68 -7 0 610 c0 571 1 612 18
          621 9 6 19 11 22 11 3 0 200 -195 438 -433z"/>
          </g>
      </svg>
    </SvgIcon>
  );
};
