import { useCallback, useEffect, useState, useMemo } from 'react';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

export default function useNotification() {
  const service = feathers.service(`notifications`);
  const [ fetched, setFetched ] = useState({
    dp: 0,
    wd: 0,
    bt: 0,
    wa: 0,
    lbk: [],
  });

  const hasNotification = useMemo(
    () => {
      const { dp = 0, wd = 0, bt = 0, wa = 0, lbk = [] } = fetched;
      return (dp > 0 || wd > 0 || bt > 0 || wa > 0 || lbk?.length > 0);
    }, [fetched]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const onAddedCallback = useCallback(
    (data) => {
      setFetched(data);
    },
    []
  );

  useEffect(() => {
    let isMounted = true;
    async function fetch () {
      try {
        setStatus('fetch');
        const find = await service.find();
        if (isMounted) setFetched(find);
      } catch (err) {
        if (isMounted) setGlobalErrorMessage({ err });
      } finally {
        if (isMounted) setStatus('idle');
      }
    };

    fetch();
    return () => {
      isMounted = false;
    };
  }, [service, setGlobalErrorMessage]);

  useEffect(() => {
    service.on('created', onAddedCallback);

    return () => {
      service.removeListener('created', onAddedCallback);
    };
  }, [service, onAddedCallback]);

  return {
    data: fetched,
    ready: status === 'idle' ? true : false,
    hasNotification,
  };
}
