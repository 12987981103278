import React, { useMemo, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { getFullBankLabel } from 'utils/bank';
import { AbilityContext } from 'casl/Can';

export default function BankInformation({ data }) {
  const { t } = useTranslation();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const ability = useContext(AbilityContext);

  const canDeleteSavedBank = useMemo(
    () => {
      return ability.can('delete', 'wallets', 'savedBank');
    }, [ability]
  );

  const savedBank = useMemo(
    () => {
      return data?.savedBank || {};
    }, [data]
  );

  const humanBankName = useMemo(
    () => {
      const { bankName } = savedBank;
      return getFullBankLabel(t, bankName);
    }, [savedBank, t]
  );

  async function handleDeleteSavedBank(event) {
    event?.preventDefault();
    const { walletId } = data;

    try {
      await feathers.service('wallets').patch(walletId, {
        $unset: {
          'savedBank': 1
        }
      });
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }

  return (
    <Paper sx={{ p: 2, m: 1 }} elevation={10}>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography sx={{ fontWeight: 700 }} variant='h6'>
          {t('Bank Information')}
        </Typography>
        {
          canDeleteSavedBank && (
            <Button disabled={!savedBank?.accountNumber} onClick={handleDeleteSavedBank} size='small' color='error' variant="contained" startIcon={<DeleteIcon />}>
              {t('Delete')}
            </Button>
          )
        }
      </Box>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: humanBankName ? 'inherit' : 'text.disabled',
          fontStyle: humanBankName ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Bank Name')}</span>
        <span>{humanBankName || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: savedBank.accountName ? 'inherit' : 'text.disabled',
          fontStyle: savedBank.accountName ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Account Name')}</span>
        <span>{savedBank.accountName || t('TBD')}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: savedBank.accountNumber ? 'inherit' : 'text.disabled',
          fontStyle: savedBank.accountNumber ? 'inherit' : 'oblique',
        }}
        variant='subtitle1'
      >
        <span>{t('Account Number')}</span>
        <span>{savedBank.accountNumber || t('TBD')}</span>
      </Typography>
    </Paper>
  );
}
