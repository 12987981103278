import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LinearProgress from '@mui/material/LinearProgress';
import Cropper from 'react-easy-crop'
import { getCroppedImg, getRotatedImage } from 'utils/canvas-utils';
import { getOrientation } from 'get-orientation/browser';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import ZoomOutIcon from '@mui/icons-material/ZoomOutTwoTone';
import ZoomInIcon from '@mui/icons-material/ZoomInTwoTone';
import RotateLeftIcon from '@mui/icons-material/RotateLeftTwoTone';
import RotateRightIcon from '@mui/icons-material/RotateRightTwoTone';
import DoneIcon from '@mui/icons-material/CheckTwoTone';
import CancelIcon from '@mui/icons-material/ClearTwoTone';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Decimal from 'decimal.js';

import axiosClient from 'services/axios';
//import axios from 'axios';

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  })
}

const CropperArea = styled('div', {})(
  ({ theme }) => ({
    position: 'relative',
    height: 300,
    [theme.breakpoints.up('sm')]: {
      height: 340
    },
    '& .reactEasyCrop_Container': {
    }
  })
);

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
};

export default function Uploader(props) {
  const { refId, onUploaded, pastedImage } = props;
  const { t } = useTranslation();
  const [ status, setStatus ] = useState('idle');
  const [ controller, setController ] = useState(null);
  const [ uploadPercentage, setUploadPercentage ] = useState(0);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [ aspectRatio, setAspectRatio ] = useState('2/3');

  const showProgressBar = useMemo(
    () => {
      return (uploadPercentage > 0  && uploadPercentage < 100) ? true : false
    }, [uploadPercentage]
  );

  const handleOnDrop = useCallback(
    async (acceptedFiles) => {
      const file = get(acceptedFiles, '[0]');
      if (!file) return;

      setCrop({ x: 0, y: 0});
      setZoom(1);
      setRotation(0);
      setImageSrc(null);
      setCroppedAreaPixels(null);
      setCroppedImage(null);

      let imageDataUrl;

      try {
        imageDataUrl = await readFile(file);
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (err) {
        console.error(err);
      }

      setImageSrc(imageDataUrl);
    }, []
  );

  useEffect(() => {
    if (!croppedImage) return;

    async function upload() {
      const blob = await fetch(croppedImage).then(r => r.blob());

      const formData = new FormData();
      formData.append('refId', refId);
      formData.append('uri', blob);

      const handleUploadProgress = () => async (pe) => {
        const p = Math.round(pe.loaded / pe.total * 100);
        setUploadPercentage(p);
      };

      try {
        setStatus('uploading');
        const controller = new AbortController();
        setController(controller);
        const uploaded = await axiosClient.post('pot-upload-wd', formData, {
          signal: controller.signal,
          onUploadProgress: handleUploadProgress()
        });
        const refId = get(uploaded, 'data._id');
        onUploaded(refId);
      } catch (err) {
        const errorCode = get(err, 'code', '');

        // error display is unnecessary
        if (errorCode === 'ERR_CANCELED') return;

        const errData = get(err, 'response.data');
        setGlobalErrorMessage({ err: errData });
      } finally {
        setStatus('idle');
      }
    }

    upload();
  }, [croppedImage, onUploaded, refId, setGlobalErrorMessage])

  useEffect(() => {
    if (!pastedImage || status !== 'idle') return;
    handleOnDrop([pastedImage]);
  }, [pastedImage, handleOnDrop, status]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: handleOnDrop,
    disabled: (status === 'idle') ? false : true
  });

  useEffect(() => {
    return () => {
      if (!controller) return;
      controller.abort();
    }
  }, [controller]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onConfirmCrop = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage);
      setImageSrc(null);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  }, [imageSrc, setCroppedImage, croppedAreaPixels, rotation, setGlobalErrorMessage]);

  const onCancelCrop = useCallback(() => {
    setImageSrc(null);
    setCroppedAreaPixels(null);
    setCroppedImage(null);
  }, [setCroppedImage]);

  const handleAspectRatioChanged = (ar) => (event) => {
    event.preventDefault();
    setAspectRatio(ar);
  };

  function parse(arStr) {
    const arArr = arStr.split('/');
    if (arArr.length !== 2) return 1;
    const a1 = new Decimal(arArr[0]);
    const a2 = new Decimal(arArr[1]);
    return a1.dividedBy(a2).toNumber();
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {
          (!imageSrc && !croppedImage) &&
          <Grid item xs={12}>
            <Paper sx={{
              position: 'relative',
              minHeight: 250,
              border: 1.5,
              textAlign: 'center',
              borderStyle: 'dashed',
              borderRadius: 1,
              borderColor: (theme) => {
                if (isFocused) return theme.palette.info.main;
                else if (isDragAccept) return theme.palette.success.main;
                else if (isDragReject) return theme.palette.error.main;
                else return theme.palette.text.secondary;
              }
            }} elevation={0} {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Typography sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontWeight: 100,
              }} variant='h6'>
                <em>
                {
                  t('POT Dropzone')
                }
                </em>
              </Typography>
            </Paper>
          </Grid>
        }
        {
          showProgressBar &&
          <Grid item xs={12}>
            <Paper elevation={0}>
              <LinearProgress variant="determinate" value={uploadPercentage} />
            </Paper>
          </Grid>
        }
        {
          imageSrc &&
          <>
            <Grid item xs={12}>
              <Paper elevation={8} sx={{ p: 1 }}>
                <CropperArea>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={parse(aspectRatio)}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onRotationChange={setRotation}
                    onZoomChange={setZoom}
                  />
                </CropperArea>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign='center'>
                <ButtonGroup size='small'>
                  {
                    ['4/3', '3/4', '3/2', '2/3', '16/9', '9/16', '1'].map(function(ar) {
                      return <Button variant={ar === aspectRatio ? 'contained' : 'outlined'} key={ar} onClick={handleAspectRatioChanged(ar)}>{ar}</Button>
                    })
                  }
                </ButtonGroup>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction="row" alignItems="center">
                <ZoomOutIcon />
                <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)} />
                <ZoomInIcon />
              </Stack>
              <Stack spacing={1} direction="row" alignItems="center">
                <RotateLeftIcon />
                <Slider min={0} max={360} step={1} value={rotation} onChange={(e, rotation) => setRotation(rotation)} />
                <RotateRightIcon />
              </Stack>
              <Stack sx={{ m: 1 }} direction='row' spacing={1}>
                <Button size='large' variant='contained' fullWidth onClick={onConfirmCrop}><DoneIcon /></Button>
                <Button size='large' variant='contained' fullWidth color='secondary' onClick={onCancelCrop}><CancelIcon /></Button>
              </Stack>
            </Grid>
          </>
        }
      </Grid>
    </Box>
  );
}

Uploader.propTypes = {
  refId: PropTypes.string.isRequired,
  onUploaded: PropTypes.func.isRequired,
};
