import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/AddCircleTwoTone';
import FilterIcon from '@mui/icons-material/FilterAltTwoTone';
import VisibilityIcon from '@mui/icons-material/VisibilityTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  getCoreRowModel,
  //getSortedRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';
import {
  fetchAsync
} from 'features/feathersStore/slice';
import {
  getData,
  getErrorMessage,
  getStatus,
  getTotal,
  getTextSearch,
  getPage,
  getLimit,
  getFilter,
  getFormattedSort,
  getColumnVisibility,
  getIsFiltered,
} from 'features/feathersStore/selectors';
import {
  setFormattedSort,
  storeColumnVisibility,
  setPage,
  setLimit,
  setSearchFromUrl,
  setPopulate,
  setTextSearch,
  resetTextSearch,
  refetch,
  updateRealtime,
} from 'features/feathersStore/actions';
import { get, kebabCase, isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useSearchParams } from 'react-router-dom';
import ColumnVisibilityDialog from './ColumnVisibilityDialog';

function ReactTableHead(props) {
  const { instance } = props;

  function generateHeader(header) {
    const { size, minSize, maxSize } = header.column.columnDef;

    const cellStyle = {
      width: size || 'auto',
      minWidth: minSize || 'auto',
      maxWidth: maxSize || 'auto',
    };

    if (!header.column.getCanSort()) {
      return (
        <TableCell key={header.id} colSpan={header.colSpan} sx={cellStyle}>
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
        </TableCell>
      );
    }

    const getIsSorted = header.column.getIsSorted();
    const isSorted = getIsSorted === false ? false : true;
    const direction = getIsSorted === false ? 'asc' : getIsSorted;

    return (
      <TableCell key={header.id} colSpan={header.colSpan} sx={cellStyle}>
        {header.isPlaceholder ? null : (
          <TableSortLabel active={isSorted} direction={direction} onClick={header.column.getToggleSortingHandler()}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </TableSortLabel>
        )}
      </TableCell>
    );
  }

  return (
    <TableHead>
      {instance.getHeaderGroups().map(headerGroup => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map(header => generateHeader(header))}
        </TableRow>
      ))}
    </TableHead>
  );
}

const ReactTableToolbar = (props) => {
  const { t } = useTranslation();
  const { name, rname, onCreateClick, canCreate, onFilterClick = null, instance } = props;
  const dispatch = useDispatch();
  const textSearch = useSelector(getTextSearch(rname));
  const isFiltered = useSelector(getIsFiltered(rname));
  const status = useSelector(getStatus(rname));
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleTextSearchChange = (event) => {
    event.preventDefault();
    const text = get(event, 'target.value', '');
    dispatch(setTextSearch(rname, text));
  };

  const handleTextSearchClear = (event) => {
    event.preventDefault();
    dispatch(resetTextSearch(rname));
  };

  const handleOpenDialog = (event) => {
    event?.preventDefault();
    setDialogOpen(true);
  };

  const handleOnDialogClose = useCallback(
    (event) => {
      event?.preventDefault();
      setDialogOpen(false);
    }, []
  );

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        mb: 2
      }}
    >
      <Typography
        sx={{ flex: '1 1 50%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {name}
      </Typography>
      <Box sx={{ display: 'flex', mx: 1 }}>
        <CircularProgress size='1rem' sx={{ visibility: status === 'idle' ? 'hidden' : 'visible' }} />
      </Box>
      <Stack direction='row' spacing={1} sx={{ mx: 1 }}>
        <Button
          variant="outlined"
          onClick={handleOpenDialog}
          color={instance.getIsAllColumnsVisible() ? 'inherit' : 'primary'}
          startIcon={<VisibilityIcon />
          }
        >
          {t('Column Visibility')}
        </Button>
        {
          canCreate &&
            <Button onClick={onCreateClick} variant='outlined' startIcon={<AddIcon />}>
              {t('Insert')}
            </Button>
        }
        {
          onFilterClick &&
            <Button
              onClick={onFilterClick}
              color={isFiltered ? 'primary' : 'inherit'}
              variant={isFiltered ? 'contained' : 'outlined'}
              startIcon={<FilterIcon />}
            >
              {t('Filter')}
            </Button>
        }
      </Stack>
      <FormControl sx={{ m: 1 }} variant="standard">
        <Input
          value={textSearch}
          placeholder={t('Search')}
          onChange={handleTextSearchChange}
          startAdornment={<InputAdornment position='start'><SearchIcon /></InputAdornment>}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={handleTextSearchClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <ColumnVisibilityDialog instance={instance} isOpen={dialogOpen} onClose={handleOnDialogClose} />
    </Toolbar>
  );
};

export default function ReactTable(props) {
  const { rname, defaultColumns, populate = null, defaultSort = null, defaultColumnVisibility = null } = props;
  const serviceName = kebabCase(rname);
  const service = feathers.service(serviceName);
  const { t } = useTranslation();
  const data = useSelector(getData(rname));
  const errorMessage = useSelector(getErrorMessage(rname));
  const filter = useSelector(getFilter(rname));
  const totalDataCount = useSelector(getTotal(rname));
  const pageSize = useSelector(getLimit(rname));
  const pageIndex = useSelector(getPage(rname));
  const [ dense, setDense ] = useState(true);
  const [ fetchSkip, setFetchSkip ] = useState((defaultSort || populate) ? true : false)
  const dispatch = useDispatch();
  //const [data, setData] = useState(defaultData);
  //const defaultColumns = initDefaultColumns(t, lang, theme);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const sort = useSelector(getFormattedSort(rname));
  const storedColumnVisibility = useSelector(getColumnVisibility(rname));

  const formattedSort = useMemo(
    () => {
      return JSON.stringify(sort);
    }, [sort]
  );
  const [sorting, setSorting] = useState(sort);
  const [columnVisibility, setColumnVisibility] = useState(storedColumnVisibility);
  const [ searchParams ] = useSearchParams();

  const memoizedDefaultColumnVisibility = useMemo(() => {
    if (defaultColumnVisibility == null) {
      return null;
    }
    return JSON.stringify(defaultColumnVisibility);
  }, [defaultColumnVisibility]);

  useEffect(() => {
    if (memoizedDefaultColumnVisibility == null) {
      return;
    }
    try {
      const parsed = JSON.parse(memoizedDefaultColumnVisibility);
      setColumnVisibility(parsed);
    } catch (error) {
      console.error(`Failed to parse column visibility: ${error}`);
    }
  }, [memoizedDefaultColumnVisibility]);

  const populateMemo = useMemo(
    () => {
      if (!populate) return null;
      return JSON.stringify(populate);
    }, [populate]
  );

  const defaultSortMemo = useMemo(
    () => {
      if (!defaultSort) return null;
      return JSON.stringify(defaultSort);
    }, [defaultSort]
  );

  const formattedFilter = useMemo(
    () => {
      if (!filter) return '';
      return JSON.stringify(filter);
    }, [filter]
  );

  const searchFromUrl = useMemo(
    () => {
      const textSearch = searchParams.get('textSearch') || '';
      const filterState = searchParams.get('state');
      const cacheBuster = searchParams.get('cacheBuster') || '';

      const ret = {
        textSearch,
        cacheBuster,
        filter: {
          ...(filterState && {
            state: filterState
          })
        }
      };

      return JSON.stringify(ret);
    }, [searchParams]
  );

  useEffect(
    () => {
      let parsedSearch;
      try {
        parsedSearch = JSON.parse(searchFromUrl);
      } catch (error) {
        console.error(`Failed to parse search from URL: ${error}`);
      }
      const { textSearch = '', filter = {} } = parsedSearch;
      if (isEmpty(textSearch) && isEmpty(filter)) return;
      dispatch(setSearchFromUrl(rname, textSearch, filter));
    }, [dispatch, rname, searchFromUrl]
  );

  const [columns] = useState(() => [
    ...defaultColumns
  ]);

  const instance = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    manualSorting: true,
    manualPagination: true,
  });

  // default sort / populate
  useEffect(() => {
    if (!populateMemo && !defaultSortMemo) return;

    if (populateMemo) dispatch(setPopulate(rname, JSON.parse(populateMemo)));
    if (defaultSortMemo) setSorting(JSON.parse(defaultSortMemo));

    setFetchSkip(false);
  }, [dispatch, rname, populateMemo, defaultSortMemo]);

  useEffect(() => {
    if (!errorMessage) return;
    const err = {
      message: errorMessage
    };
    setGlobalErrorMessage({ err });
  }, [errorMessage, setGlobalErrorMessage]);

  useEffect(() => {
    let currentSort;

    try {
      currentSort = JSON.parse(formattedSort);
    } catch (error) {
      console.error(`Failed to parse formatted sort: ${error}`);
    }

    if (isEqual(sorting, currentSort)) return;

    dispatch(setFormattedSort(rname, sorting));
  }, [dispatch, sorting, rname, formattedSort]);

  useEffect(() => {
    dispatch(storeColumnVisibility(rname, columnVisibility));
  }, [dispatch, columnVisibility, rname]);

  const onDataCreated = useCallback(
    (data) => {
      dispatch(refetch(rname));
    }, [dispatch, rname]
  );

  const onDataRemoved = useCallback(
    (data) => {
      dispatch(refetch(rname));
    }, [dispatch, rname]
  );

  const onDataUpdated = useCallback(
    (data) => {
      dispatch(updateRealtime(rname, data));
    }, [dispatch, rname]
  );

  useEffect(() => {
    service.on('created', onDataCreated);
    service.on('removed', onDataRemoved);
    service.on('updated', onDataUpdated);
    service.on('patched', onDataUpdated);

    return () => {
      service.removeListener('created', onDataCreated);
      service.removeListener('removed', onDataRemoved);
      service.removeListener('updated', onDataUpdated);
      service.removeListener('patched', onDataUpdated);
    };
  }, [service, onDataCreated, onDataRemoved, onDataUpdated]);

  useEffect(() => {
    if (fetchSkip) return;

    dispatch(fetchAsync({ _RNAME: rname }));
  }, [dispatch, rname, formattedSort, formattedFilter, pageSize, pageIndex, fetchSkip]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(rname, newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    const limit = Number(event.target.value);
    dispatch(setLimit(rname, limit));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <ReactTableToolbar {...props} instance={instance} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <ReactTableHead instance={instance} />
            <TableBody>
              {
                instance.getRowModel().rows.map(row => {
                  return (
                    <TableRow key={row.id}>
                    {
                      row.getVisibleCells().map(cell => {
                        return (
                          <TableCell key={cell.id}>
                          {
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                          }
                          </TableCell>
                        )
                      })
                    }
                    </TableRow>
                  )
                })
              }

            </TableBody>
            <TableFooter>
              {
                instance.getFooterGroups().map(footerGroup => {
                  return (
                    <TableRow key={footerGroup.id}>
                    {
                      footerGroup.headers.map(header => {
                        return (
                          <TableCell key={header.id} colSpan={header.colSpan}>
                            {
                              header.isPlaceholder ?
                              null :
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                            }
                          </TableCell>
                        );
                      })
                    }
                    </TableRow>
                  )
                })
              }
            </TableFooter>
          </Table>
        </TableContainer>
        <TablePagination
          showFirstButton
          showLastButton
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={totalDataCount}
          rowsPerPage={pageSize}
          page={pageIndex}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          getItemAriaLabel={(type) => t(`mui:tablePagination.${type}`)}
          labelRowsPerPage={t('mui:tablePagination.labelRowsPerPage')}
          labelDisplayedRows={({from, to, count}) => {
            if (count === -1) return t('mui:tablePagination.labelDisplayedRowsSay', { from, to });
            return t('mui:tablePagination.labelDisplayedRows', { from, to, count });
          }}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label={t('Dense Mode')}
      />
    </Box>
  );
}

ReactTable.propTypes = {
  name: PropTypes.string.isRequired,
  rname: PropTypes.string.isRequired,
  defaultColumns: PropTypes.array.isRequired,
  canCreate: PropTypes.bool.isRequired,
};

ReactTableToolbar.propTypes = {
  rname: PropTypes.string.isRequired,
  canCreate: PropTypes.bool.isRequired,
};
