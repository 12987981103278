import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get, each } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import CommonContext from 'features/context/commonContext';
import { useAuth } from 'hooks/useAuth';
import { transformSavedData } from 'utils/form-utils';
import { getLocaleYupObject } from 'utils/yup-helper';
import {
  FormikTextField,
  FormikAvatar,
  FormikDateTimePicker,
  FormikSwitch,
  FormikSelect,
} from 'features/formikControl';
import dayjs from 'dayjs';

const CASL_SUBJECT = 'luckyDrawSettings';
const SERVICE_NAME = 'lucky-draw-settings';

export default function Form(props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { supportedLocales = ['en'] } = user || {};
  const lang = get(user, 'lang', 'en');
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const { memberTiers: ranks } = useContext(CommonContext);

  const prizeDistributionOptions = useMemo(
    () => {
      let ret = [];
      each(['fixed', 'random'], (item) => {
        ret.push({ key: item, value: `distribution_${item}` });
      });
      return ret;
    }, []
  );

  const drawFrequencyUnitOptions = useMemo(
    () => {
      let ret = [];
      each(['minute', 'hour', 'day', 'week', 'month'], (item) => {
        ret.push({ key: item, value: `luckydraw_${item}` });
      });
      return ret;
    }, []
  );

  const requirementTypeOptions = useMemo(
    () => {
      let ret = [];
      each(['deposit', 'turnover', 'level', 'rank'], (item) => {
        ret.push({ key: item, value: `luckydraw_${item}` });
      });
      return ret;
    }, []
  );

  const rankOptions = useMemo(
    () => {
      return ranks.map(r => {
        return {
          key: r._id,
          value: get(r, `name.${lang}`, r._id)
        }
      });
    }, [ranks, lang]
  );

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        drawFrequency: 1,
        requirementType: 'deposit',
        isVIPReward: false,
        weight: 0,
        startsAt: dayjs().add(1, 'day').startOf('day').toDate(),
        endsAt: dayjs().add(1, 'day').add(3, 'year').startOf('day').toDate(),
        isEnabled: true,
      }
    }, [propData, savedData]
  );

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    name: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t("Required"))),
    photo: Yup.object().shape(getLocaleYupObject(supportedLocales, true, t("Required"))),
    prizeAmount: Yup.number().required(t("Required")).min(1),
    prizeDistribution: Yup.string().matches(/fixed|random/).required(t("Required")),
    participantsPerPrize: Yup.number().integer().required(t("Required")).min(1),
    requirementType: Yup.string().matches(/deposit|turnover|level|rank/).required(t("Required")),
    requirementAmount: Yup.number()
      .when('requirementType', {
        is: (val) => (val === 'deposit' || val === 'turnover') ,
        then: (schema) => schema.required(t("Required")).min(1),
        otherwise: (schema) => schema.nullable()
      }),
    requirementLevel: Yup.object()
      .when('requirementType', {
        is: (val) => (val === 'level') ,
        then: (schema) => schema.shape({
          min: Yup.number().integer().required(t("Required")).min(-1),
          max: Yup.number().integer().required(t("Required")).min(-1),
        }),
        otherwise: (schema) => schema.nullable()
      }),
    requirementRanks: Yup.array().of(Yup.string())
      .when('requirementType', {
        is: (val) => (val === 'rank') ,
        then: (schema) => schema.required(t("Required")).min(1),
        otherwise: (schema) => schema.nullable()
      }),
    drawFrequency: Yup.number().integer().required(t("Required")).min(1),
    drawFrequencyUnit: Yup.string().matches(/minute|hour|day|week|month/).required(t("Required")),
    isVIPReward: Yup.bool().nullable(),
    weight: Yup.number().integer().required(t("Required")),
    startsAt: Yup.date().required(t("Required")),
    endsAt: Yup.date().required(t("Required")),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Lucky Draw Setting')}</DialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...data,
          caslSubject: CASL_SUBJECT,
          serviceName: SERVICE_NAME,
        }}
        validationSchema={dataSchema}
        onSubmit={async values => {
          try {
            const _id = get(data, '_id');

            if (!_id) {
              const { _id, ...newValues } = values;
              const saved = await feathers.service(SERVICE_NAME).create(newValues);
              setSavedData(saved);
            } else {
              await feathers.service(SERVICE_NAME).patch(_id, {
                $set: values
              });
            }
            setGlobalMessage({
              message: t(`Saved`),
              severity: 'success'
            });
          } catch (err) {
            setGlobalErrorMessage({ err });
          }
        }}
      >
        {
          ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <>
              <DialogContent dividers>
              <Paper sx={{ p: 2 }} elevation={0}>
                <Grid container spacing={2}>
                  {
                    supportedLocales.map(locale => {
                      const nameKey = `name.${locale}`;
                      const photoKey = `photo.${locale}`;
                      return (
                        <React.Fragment key={locale}>
                          <Grid item xs={10}>
                            <FormikTextField
                              fullWidth
                              id={nameKey}
                              name={nameKey}
                              label={t(nameKey)}
                              value={get(values, nameKey, '')}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FormikAvatar
                              id={photoKey}
                              name={photoKey}
                              value={get(values, photoKey, '')}
                              variant='rounded'
                              sx={{ mx: 'auto', my: 1 }}
                              onChange={(imageId) => {
                                if (locale === supportedLocales[0]) {
                                  supportedLocales.forEach(l => {
                                    setFieldValue(`photo.${l}`, imageId);
                                  });
                                }
                              }}
                            />
                          </Grid>
                        </React.Fragment>
                      );
                    })
                  }
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='prizeAmount'
                      name='prizeAmount'
                      label={t('Prize Amount')}
                      value={get(values, 'prizeAmount', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      select
                      defaultValue={'None'}
                      id='prizeDistribution'
                      name='prizeDistribution'
                      label={t('Prize Distribution')}
                      value={get(values, 'prizeDistribution', 'None')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                      {
                        prizeDistributionOptions.map(o => (
                          <MenuItem key={o.key} value={o.key}>
                            {t(o.value)}
                          </MenuItem>
                        ))
                      }
                    </FormikTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='participantsPerPrize'
                      name='participantsPerPrize'
                      label={t('Participants Per Prize')}
                      value={get(values, 'participantsPerPrize', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      select
                      defaultValue={'None'}
                      id='requirementType'
                      name='requirementType'
                      label={t('Requirement Type')}
                      value={get(values, 'requirementType', 'None')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                      {
                        requirementTypeOptions.map(o => (
                          <MenuItem key={o.key} value={o.key}>
                            {t(o.value)}
                          </MenuItem>
                        ))
                      }
                    </FormikTextField>
                  </Grid>
                  {
                    ['deposit', 'turnover'].includes(values.requirementType) ? (
                      <Grid item xs={12}>
                        <FormikTextField
                          fullWidth
                          id='requirementAmount'
                          name='requirementAmount'
                          label={t('Requirement Amount')}
                          value={get(values, 'requirementAmount', '')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    ) : null
                  }
                  {
                    ['level'].includes(values.requirementType) ? (
                      <>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='requirementLevel.min'
                            name='requirementLevel.min'
                            label={t('Minimum Requirement Level')}
                            value={get(values, 'requirementLevel.min', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikTextField
                            fullWidth
                            id='requirementLevel.max'
                            name='requirementLevel.max'
                            label={t('Maximum Requirement Level')}
                            value={get(values, 'requirementLevel.max', '')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    ) : null
                  }
                  {
                    ['rank'].includes(values.requirementType) ? (
                      <Grid item xs={12}>
                        <FormikSelect
                          fullWidth
                          multiple
                          id='requirementRanks'
                          name='requirementRanks'
                          label={t('Requirement Ranks')}
                          value={get(values, 'requirementRanks', [])}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {
                            rankOptions.map(o => (
                              <MenuItem key={o.key} value={o.key}>
                                {o.value}
                              </MenuItem>
                            ))
                          }
                        </FormikSelect>
                      </Grid>
                    ) : null
                  }
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='drawFrequency'
                      name='drawFrequency'
                      label={t('Draw Frequency')}
                      value={get(values, 'drawFrequency', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      select
                      defaultValue={'None'}
                      id='drawFrequencyUnit'
                      name='drawFrequencyUnit'
                      label={t('Draw Frequency Unit')}
                      value={get(values, 'drawFrequencyUnit', 'None')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                      {
                        drawFrequencyUnitOptions.map(o => (
                          <MenuItem key={o.key} value={o.key}>
                            {t(o.value)}
                          </MenuItem>
                        ))
                      }
                    </FormikTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDateTimePicker
                      fullWidth
                      id='startsAt'
                      name='startsAt'
                      label={t('Starts At')}
                      value={get(values, 'startsAt', null)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDateTimePicker
                      fullWidth
                      id='endsAt'
                      name='endsAt'
                      label={t('Ends At')}
                      value={get(values, 'endsAt', null)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      fullWidth
                      id='weight'
                      name='weight'
                      label={t('Weight')}
                      value={get(values, 'weight', '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSwitch
                      id='isVIPReward'
                      name='isVIPReward'
                      label={t('Is VIP Reward')}
                      value={get(values, 'isVIPReward', false)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSwitch
                      id='isEnabled'
                      name='isEnabled'
                      label={t('Enabled')}
                      value={get(values, 'isEnabled', false)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton loading={isSubmitting} loadingIndicator={t('Saving')} onClick={handleSubmit}>{t('Save')}</LoadingButton>
              </DialogActions>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
