import { createTheme } from '@mui/material/styles';

export default createTheme({
  /*shape: {
    borderRadius: 20
  },*/
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#3949ab',
    },
    secondary: {
      main: '#616161',
    },
    error: {
      main: '#e53935',
    },
    warning: {
      main: '#fb8c00',
    },
    info: {
      main: '#1e88e5',
    },
    success: {
      main: '#43a047',
    },
    background: {
      //default: '#3c3c3c',
      //paper: '#3c3c3c'
    }
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'sans-serif',
      'Helvetica',
      'Arial'
    ].join(','),
  },
});
