import React, { useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import SyncIcon from '@mui/icons-material/SyncAltTwoTone';
import MarkClaimIcon from '@mui/icons-material/BeenhereTwoTone';
import { get, kebabCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { AbilityContext } from 'casl/Can';
import CommonContext from 'features/context/commonContext';
import Decimal from 'decimal.js';
import Tooltip from '@mui/material/Tooltip';
import { subject } from '@casl/ability';
import TextSearchLink from 'features/textSearchLink/TextSearchLink';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'bankTransactions';
const table = createColumnHelper();

export default function BankTransactions() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const [ openFilter, setOpenFilter ] = useState(false);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ confirmDialog, setConfirmDialog ] = useState({
    open: false,
    rowId: null
  });
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);
  const canDelete = ability.can('delete', _RNAME);
  const { bankLookup, lookupReady } = useContext(CommonContext);

  const setOpenConfirmDialog = useCallback(
    (open) => {
      if (open === false) {
        setConfirmDialog({ open: false, rowId: null });
      } else {
        setConfirmDialog(prev => ({ open: true, ...prev }));
      }
    }, []
  );

  function getSearchText(description, bankName, reference) {
    if (bankName === 'my_maybank') {
      return description?.split('*')[0]?.trim();
    } else if (bankName === 'my_cimb') {
      const [,,, fourthPart, fifthPart] = description?.split('|').map(part => part.trim());
      if (fourthPart === reference) {
        return fifthPart;
      }
      return fourthPart;
    } else if (bankName === 'sg_posb') {
      return description?.split('|')[3]?.trim();
    } else {
      return description;
    }
  }


  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const t = get(row, 'bankName');
          const lookup = get(bankLookup, t, '');
          return lookup;
        }, {
          id: 'bankName',
          cell: info => info.getValue(),
          header: () => t('Bank Name'),
          footer: props => props.column.id,
        }),
        table.accessor('accountNumber', {
          id: 'accountNumber',
          cell: info => {
            const accountNumber = info.getValue() || '';
            return <TextSearchLink text={accountNumber} />;
          },
          header: () => t('Account Number'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const amountRaw = get(row, `amount.$numberDecimal`);
          if (!amountRaw) return '';
          const amount = new Decimal(amountRaw).toFixed(2);
          return amount;
        }, {
          id: 'amount',
          cell: info => {
            const val = info.getValue();
            return <Box
              sx={{
                textAlign: 'right'
              }}
              element='span'>
              {val}
            </Box>
          },
          header: () => t('Amount'),
          footer: props => props.column.id,
          enableSorting: false,
        }),
        table.accessor(row => {
          const { description = '', bankName = '', reference = '' } = row;
          return { description, bankName, reference };
        }, {
          size: 400,
          id: 'description',
          cell: info => {
            const { description, bankName, reference } = info.getValue();
            const searchText = getSearchText(description, bankName, reference);
            return <TextSearchLink bankName={bankName} text={description} searchText={searchText} />;
          },
          header: () => t('Description'),
          footer: props => props.column.id,
        }),
        table.accessor('reference', {
          id: 'reference',
          cell: info => info.getValue(),
          header: () => t('Reference'),
          footer: props => props.column.id,
        }),
        table.accessor('status', {
          id: 'status',
          cell: info => info.getValue(),
          header: () => t('Status'),
          footer: props => props.column.id,
        }),
        table.accessor('isClaimed', {
          id: 'isClaimed',
          cell: info => {
            const val = info.getValue();
            return <Box element='span'>{t(val)}</Box>
          },
          header: () => t('Claimed'),
          footer: props => props.column.id,
        }),
        table.accessor(row => {
          const claimedAt = get(row, 'claimedAt');
          if (!claimedAt) return null;
          return dayjs(claimedAt).locale(lang).format('YYYY-MM-DD HH:mm:ss');
        }, {
          id: 'claimedAt',
          cell: info => info.getValue(),
          header: () => t('Claimed At'),
          footer: props => props.column.id,
        }),
        table.accessor('transactedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Transacted At'),
          footer: props => props.column.id,
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang, bankLookup]
  );

  const handleRowDelete = (rowId) => (event) => {
    event.preventDefault();
    setConfirmDialog({
      open: true,
      rowId
    });
  };

  const handleMarkClaim = useCallback(
    (rowId) => async (event) => {
      event?.preventDefault();
      try {
        await feathers.service('bank-transactions').patch(rowId, {
          isClaimed: true,
          claimedAt: new Date(),
        });

        setGlobalMessage({
          message: t(`Executed`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [setGlobalMessage, setGlobalErrorMessage, t]
  );

  const handleSyncTransaction = useCallback(
    (transactionId) => async (event) => {
      event?.preventDefault();
      try {
        await feathers.service('bank-transactions').get(transactionId, { query: { __action__: 'sync-transaction' }});
        setGlobalMessage({
          message: t(`Executed`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [setGlobalMessage, setGlobalErrorMessage, t]
  );

  const onDeleteConfirmed =  async () => {
    try {
      const rowId = get(confirmDialog, 'rowId');
      await feathers.service(serviceName).remove(rowId);
    } catch (err) {
      setGlobalErrorMessage({ err });
    }
  };

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');
    const { _id: rowId, companyId, transactionId } = data;
    const canRetrieveTransaction = ability.can('get', subject(_RNAME, {
      companyId,
      __action__: 'sync-transaction',
    }));
    const canClaimTransaction = ability.can('update', subject(_RNAME, { ...data }));

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Delete')}>
          <span>
            <IconButton disabled={!canDelete} onClick={handleRowDelete(rowId)} color='error'>
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={t('Mark Claim')}>
          <span>
            <IconButton disabled={!canClaimTransaction} onClick={handleMarkClaim(rowId)} color='success'>
              <MarkClaimIcon />
            </IconButton>
          </span>
        </Tooltip>
        {
          transactionId &&
          <Tooltip title={t('Sync Transaction')}>
            <span>
              <IconButton disabled={!canRetrieveTransaction} onClick={handleSyncTransaction(transactionId)} color='primary'>
                <SyncIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
      </Stack>
    );
  }

  return (
    <Box>
      <ConfirmDialog
        title={`${t('Delete Bank Transaction')}`}
        open={get(confirmDialog, 'open', false)}
        setOpen={setOpenConfirmDialog}
        onConfirm={onDeleteConfirmed}
      >
        {t('Confirm to delete?')}
      </ConfirmDialog>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      {
        !!lookupReady &&
        <Table
          name={t('Bank Transactions')}
          rname={_RNAME}
          defaultColumns={defaultColumns}
          canCreate={false}
          onCreateClick={null}
          onFilterClick={handleOnFilterClick}
          defaultColumnVisibility={{
            _id: false,
          }}
        />
      }
    </Box>
  );
}
