import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import PromptContext from 'features/context/promptContext';

function ConfirmationPrompt() {
  const {
    open,
    message,
    title,
    confirmLabel,
    cancelLabel,
    confirmColor,
    cancelColor,
    defaultTextFieldValue,
    onConfirm,
    onClose,
  } = useContext(PromptContext);

  const needTextField = useMemo(() => {
    return defaultTextFieldValue !== undefined && defaultTextFieldValue !== null;
  }, [defaultTextFieldValue]);

  const [textAnswer, setTextAnswer] = useState('');

  useEffect(() => {
    setTextAnswer(defaultTextFieldValue);
  }, [defaultTextFieldValue]);

  const handleOnConfirm = useCallback((e) => {
    e.preventDefault();

    // This is for confirmation prompts that don't need a text answer
    if (!needTextField) {
      onConfirm(true);
      return;
    }

    if (!textAnswer) {
      onConfirm(null);
      return;
    }

    onConfirm(textAnswer);
    setTextAnswer('');
  }, [onConfirm, needTextField, textAnswer]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleOnConfirm(e);
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [open, handleOnConfirm]);

  const handleOnClose = useCallback((e) => {
    e.preventDefault();
    onClose();
    setTextAnswer('');
  }, [onClose]);

  const handleTextAnswerChange = useCallback((e) => {
    e.preventDefault();
    setTextAnswer(e.target.value);
  }, []);

  return (
    <Dialog open={open} onClose={onClose} disableRestoreFocus>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {
          needTextField ? null : (
            <DialogContentText>
              {message}
            </DialogContentText>
          )
        }
        {
          !needTextField ? null : (
            <TextField
              autoFocus
              margin='normal'
              id='textAnswer'
              name='textAnswer'
              label={message}
              type='text'
              fullWidth
              value={textAnswer || ''}
              onChange={handleTextAnswerChange}
              variant='standard'
            />
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color={cancelColor}>
          {cancelLabel}
        </Button>
        <Button onClick={handleOnConfirm} color={confirmColor}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationPrompt
