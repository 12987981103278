import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import flatten from 'flat';
import LoadingButton from '@mui/lab/LoadingButton';
import { AbilityContext } from 'casl/Can';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { transformSavedData } from 'utils/form-utils';

const _NEWID = '@@NEW@@';
const _RNAME = 'lineBots'

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
        isEnabled: true
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    channelName: Yup.string().required(t("Required")),
    botBasicId: Yup.string().required(t("Required")),
    channelAccessToken: Yup.string().required(t("Required")),
    twilioVerify: Yup.object().shape({
      accountSid: Yup.string().required(t("Required")),
      authToken: Yup.string().required(t("Required")),
      serviceSid: Yup.string().required(t("Required")),
    }),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');
        const flattenVal = flatten(values);

        // Delete token if it is an empty string to prevent it from overriding a valid token.
        if (flattenVal?.twilioVerify?.authToken === '') delete flattenVal.twilioVerify.authToken;

        if (_id === _NEWID) {
          delete flattenVal._id;
          const saved = await feathers.service(serviceName).create(flattenVal);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, flattenVal);
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Line Bot')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('channelName')}
                id='channelName'
                name='channelName'
                label={t('Channel Name')}
                value={get(formik, 'values.channelName', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.channelName`, false) && Boolean(get(formik, `errors.channelName`))}
                helperText={get(formik, `touched.channelName`, false) && get(formik, `errors.channelName`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('botBasicId')}
                id='botBasicId'
                name='botBasicId'
                label={t('Bot Basic ID')}
                value={get(formik, 'values.botBasicId', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.botBasicId`, false) && Boolean(get(formik, `errors.botBasicId`))}
                helperText={get(formik, `touched.botBasicId`, false) && get(formik, `errors.botBasicId`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('channelAccessToken')}
                id='channelAccessToken'
                name='channelAccessToken'
                label={t('Channel Access Token')}
                value={get(formik, 'values.channelAccessToken', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.channelAccessToken`, false) && Boolean(get(formik, `errors.channelAccessToken`))}
                helperText={get(formik, `touched.channelAccessToken`, false) && get(formik, `errors.channelAccessToken`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('twilioVerify.accountSid')}
                id='twilioVerify.accountSid'
                name='twilioVerify.accountSid'
                label={t('Twilio Verify Account SID')}
                value={get(formik, 'values.twilioVerify.accountSid', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.twilioVerify.accountSid`, false) && Boolean(get(formik, `errors.twilioVerify.accountSid`))}
                helperText={get(formik, `touched.twilioVerify.accountSid`, false) && get(formik, `errors.twilioVerify.accountSid`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('twilioVerify.authToken')}
                id='twilioVerify.authToken'
                name='twilioVerify.authToken'
                label={t('Twilio Verify Auth Token')}
                value={get(formik, 'values.twilioVerify.authToken', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.twilioVerify.authToken`, false) && Boolean(get(formik, `errors.twilioVerify.authToken`))}
                helperText={get(formik, `touched.twilioVerify.authToken`, false) && get(formik, `errors.twilioVerify.authToken`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disableInput('twilioVerify.serviceSid')}
                id='twilioVerify.serviceSid'
                name='twilioVerify.serviceSid'
                label={t('Twilio Verify Service SID')}
                value={get(formik, 'values.twilioVerify.serviceSid', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.twilioVerify.serviceSid`, false) && Boolean(get(formik, `errors.twilioVerify.serviceSid`))}
                helperText={get(formik, `touched.twilioVerify.serviceSid`, false) && get(formik, `errors.twilioVerify.serviceSid`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch disabled={disableInput('isEnabled')} checked={formik.values.isEnabled} onChange={(event) => {
                    const isChecked = get(event, 'target.checked', false);
                    formik.setFieldValue('isEnabled', isChecked)
                  }} />
                }
                label={t('Enabled')}
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
