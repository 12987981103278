import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Table from 'features/reactTable/Table';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditTwoTone';
import { get } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Filter from './Filter';
import { AbilityContext } from 'casl/Can';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
dayjs.extend(relativeTime);

const _RNAME = 'memberTiers';
const table = createColumnHelper();

export default function WalletAdjustments() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [ editData, setEditData ] = useState(null);
  const lang = get(user, 'lang', 'en');
  const [ openForm, setOpenForm ] = useState(false);
  const [ openFilter, setOpenFilter ] = useState(false);
  const ability = useContext(AbilityContext);
  const canUpdate = ability.can('update', _RNAME);

  useEffect(() => {
    if (!openForm) setEditData(null);
  }, [openForm]);

  const defaultColumns = useMemo(
    () => {
      return [
        table.display({
          id: 'actions',
          header: () => t('Actions'),
          cell: props => <RowActions row={props.row} />
        }),
        table.accessor('_id', {
          id: '_id',
          cell: info => info.getValue(),
          header: () => 'ID',
          footer: props => props.column.id,
        }),
        table.accessor(row => get(row, `name.${lang}`, ''), {
          id: `name.${lang}`,
          cell: info => info.getValue(),
          header: () => t('Name'),
          footer: props => props.column.id,
        }),
        table.group({
          id: 'dailySlotLoss',
          header: () => t('dailySlotLoss'),
          footer: props => props.column.id,
          columns: [
            table.accessor(row => get(row, `cashRebateSettings.dailySlotLoss.multiplier.$numberDecimal`, '0'), {
              id: 'cashRebateSettings.dailySlotLoss.multiplier',
              cell: info => info.getValue(),
              header: () => t('Multiplier'),
              footer: props => props.column.id,
            }),
            table.accessor(row => get(row, `cashRebateSettings.dailySlotLoss.maxAmount.$numberDecimal`, '0'), {
              id: 'cashRebateSettings.dailySlotLoss.maxAmount',
              cell: info => info.getValue(),
              header: () => t('Max Amount'),
              footer: props => props.column.id,
            }),
            table.accessor(row => get(row, `cashRebateSettings.dailySlotLoss.skipWallet`, false), {
              id: 'cashRebateSettings.dailySlotLoss.skipWallet',
              cell: info => info.getValue() ? t('Yes') : t('No'),
              header: () => t('Skip Wallet'),
              footer: props => props.column.id,
            }),
            table.accessor(row => get(row, `cashRebateSettings.dailySlotLoss.applyOffset`, false), {
              id: 'cashRebateSettings.dailySlotLoss.applyOffset',
              cell: info => info.getValue() ? t('Yes') : t('No'),
              header: () => t('Apply Offset'),
              footer: props => props.column.id,
            }),
          ]
        }),
        table.group({
          id: 'dailySlotTurnover',
          header: () => t('dailySlotTurnover'),
          footer: props => props.column.id,
          columns: [
            table.accessor(row => get(row, `cashRebateSettings.dailySlotTurnover.multiplier.$numberDecimal`, '0'), {
              id: 'cashRebateSettings.dailySlotTurnover.multiplier',
              cell: info => info.getValue(),
              header: () => t('Multiplier'),
              footer: props => props.column.id,
            }),
            table.accessor(row => get(row, `cashRebateSettings.dailySlotTurnover.maxAmount.$numberDecimal`, '0'), {
              id: 'cashRebateSettings.dailySlotTurnover.maxAmount',
              cell: info => info.getValue(),
              header: () => t('Max Amount'),
              footer: props => props.column.id,
            }),
            table.accessor(row => get(row, `cashRebateSettings.dailySlotTurnover.skipWallet`, false), {
              id: 'cashRebateSettings.dailySlotTurnover.skipWallet',
              cell: info => info.getValue() ? t('Yes') : t('No'),
              header: () => t('Skip Wallet'),
              footer: props => props.column.id,
            }),
          ]
        }),
        table.group({
          id: 'dailyLiveTurnover',
          header: () => t('dailyLiveTurnover'),
          footer: props => props.column.id,
          columns: [
            table.accessor(row => get(row, `cashRebateSettings.dailyLiveTurnover.multiplier.$numberDecimal`, '0'), {
              id: 'cashRebateSettings.dailyLiveTurnover.multiplier',
              cell: info => info.getValue(),
              header: () => t('Multiplier'),
              footer: props => props.column.id,
            }),
            table.accessor(row => get(row, `cashRebateSettings.dailyLiveTurnover.maxAmount.$numberDecimal`, '0'), {
              id: 'cashRebateSettings.dailyLiveTurnover.maxAmount',
              cell: info => info.getValue(),
              header: () => t('Max Amount'),
              footer: props => props.column.id,
            }),
            table.accessor(row => get(row, `cashRebateSettings.dailyLiveTurnover.skipWallet`, false), {
              id: 'cashRebateSettings.dailyLiveTurnover.skipWallet',
              cell: info => info.getValue() ? t('Yes') : t('No'),
              header: () => t('Skip Wallet'),
              footer: props => props.column.id,
            }),
          ]
        }),
        table.accessor('updatedAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Updated At'),
          footer: props => props.column.id,
        }),
        table.accessor('createdAt', {
          cell: info => dayjs(info.getValue()).locale(lang).format('YYYY-MM-DD HH:mm:ss'),
          header: () => t('Created At'),
          footer: props => props.column.id,
        }),
      ];
    }, [t, lang]
  );

  const handleRowEdit = (data) => (event) => {
    event.preventDefault();
    setEditData(data);
    setOpenForm(true);
  };

  const handleOnFilterClick = useCallback(
    (event) => {
      event.preventDefault();
      setOpenFilter(true);
    }, []
  );

  function RowActions(props) {
    const data = get(props, 'row.original');

    return (
      <Stack direction='row' spacing={1}>
        <Tooltip title={t('Edit')}>
          <span>
            <IconButton disabled={!canUpdate} onClick={handleRowEdit(data)} color='primary'>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box>
      <Filter open={openFilter} setOpen={setOpenFilter} />
      { editData && <Form open={openForm} setOpen={setOpenForm} data={editData} /> }
      <Table
        name={t('Cash Rebate Settings')}
        rname={_RNAME}
        defaultColumns={defaultColumns}
        canCreate={false}
        onCreateClick={null}
        onFilterClick={handleOnFilterClick}
        defaultColumnVisibility={{
          _id: false,
          'cashRebateSettings.dailySlotLoss.skipWallet': false,
          'cashRebateSettings.dailySlotLoss.applyOffset': false,
          'cashRebateSettings.dailySlotTurnover.skipWallet': false,
          'cashRebateSettings.dailyLiveTurnover.skipWallet': false,
          updatedAt: false,
          createdAt: false,
        }}
      />
    </Box>
  );
}
