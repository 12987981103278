const lookups = {
  "mandala_green": "Mandala Green",
  "mandala_red": "Mandala Red",
  "mandala_yellow": "Mandala Yellow",
  "mandala": "Mandala",
  "podium": "Podium",
  "rotary": "Rotary",
  "simple": "Simple",
};

const enums = Object.keys(lookups);

export {
  lookups,
  enums,
};