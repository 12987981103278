import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import feathers from 'services/feathers';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
//import { get } from 'lodash';

export default function CaptchaRequest(props) {
  const { t } = useTranslation();
  const { request } = props;
  const [ captchaAnswer, setCaptchaAnswer ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isSubmitted, setIsSubmitted ] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!captchaAnswer) return;
    try {
      const service = feathers.service('/captcha');
      let data = { ...request };
      delete data.captcha;
      setIsSubmitted(true);
      await service.update(null, {
        captchaAnswer, ...data
      });
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const onCaptchaAnswerChanged = (event) => {
    event.preventDefault();
    setCaptchaAnswer(event.target.value);
  };

  if (!request) return null;

  return (
    <form onSubmit={ event => event.preventDefault() } autoComplete='off' noValidate>
      <Paper elevation={8} sx={{ p: 2 }}>
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12}>
          <Box
            component='img'
            alt={`Captcha ${request.type} ${request._id}`}
            src={`data:image/png;base64,${request.captcha}`}
            title={`${t('Captcha')} ${request.type} ${request._id}`}
            width="100%"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            id='captcha-answer'
            label={t('Answer')}
            value={captchaAnswer}
            onChange={onCaptchaAnswerChanged}
            InputProps={{
            }}
            placeholder={t('Enter to send')}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button size='large' variant='contained' type='submit' disabled={isSubmitted} color='primary' onClick={handleSubmit}>
            {t('Submit')}
          </Button>
        </Grid>
        {
          errorMessage &&
          <Grid item xs={12}>
            <Alert severity='error'>{errorMessage}</Alert>
          </Grid>
        }
      </Grid>
      </Paper>
    </form>
  );
};
