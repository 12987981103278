import { createContext } from 'react';

// This is the context that will be used to display a prompt dialog
const PrompContext = createContext({
  openPrompt: () => {},
  onConfirm: () => {},
  onClose: () => {},
  message: '',
  title: '',
  confirmLabel: '',
  cancelLabel: '',
  confirmColor: '',
  cancelColor: '',
  defaultTextFieldValue: undefined,
  open: false,
});

export default PrompContext;
