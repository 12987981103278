const lookups = {
  "my": "Malaysia",
  "sg": "Singapore",
  "au": "Australia",
  "nz": "New Zealand",
  "th": "Thailand"
};

const enums = Object.keys(lookups);

export {
  lookups,
  enums,
};
