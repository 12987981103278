import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';
import { gameTypeMenu } from 'utils/menu';
import { ListSubheader } from '@mui/material';

const _RNAME = 'gameLogs';
const AVAILABLE_STATES = [
  'open',
  'downloading',
  'downloaded',
  'pending',
  'applied',
  'done',
  'pre-reset',
  'reset',
  'post-reset',
];

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));
  const playedGameTypes = ['slot', 'live', 'other', 'system', 'unknown'];

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
      };
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    username: Yup.string().nullable(),
    gameType: Yup.object().shape({
      $in: Yup.array().of(Yup.string()).nullable(),
    }),
    state: Yup.object().shape({
      $in: Yup.array().of(Yup.string()).nullable(),
    }),
    gameId: Yup.string().nullable(),
    playedGames: Yup.object().shape({
      $in: Yup.array().of(Yup.string()).nullable(),
    }),
    startDate: Yup.object().shape({
      $gte: Yup.date().nullable(),
    }),
    endDate: Yup.object().shape({
      $lt: Yup.date().nullable()
    }),
    updatedAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
    createdAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.gameType.$in', false) && Boolean(get(formik, 'errors.gameType.$in'))}
              >
                <InputLabel id='game-type-select-label'>{t('Game Type')}</InputLabel>
                <Select
                  autoWidth
                  labelId='game-type-select-label'
                  id='gameType.$in'
                  name='gameType.$in'
                  value={get(formik, 'values.gameType.$in', [])}
                  label={t('Game Type')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    gameTypeMenu(true, true, true).map((g) => {
                      if (g.type === 'subheader') {
                        return <ListSubheader key={g.key}>{t(g.value)}</ListSubheader>
                      }
                      return <MenuItem key={g.key} value={g.key}>{g.value}</MenuItem>
                    })
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.gameType.$in', false) && get(formik, 'errors.gameType.$in')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='gameId'
                name='gameId'
                label={t('Game Id')}
                value={get(formik, 'values.gameId', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.gameId`, false) && Boolean(get(formik, `errors.gameId`))}
                helperText={get(formik, `touched.gameId`, false) && get(formik, `errors.gameId`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.playedGames.$in', false) && Boolean(get(formik, 'errors.playedGames.$in'))}
              >
                <InputLabel id='game-type-select-label'>{t('Played Games')}</InputLabel>
                <Select
                  autoWidth
                  labelId='game-type-select-label'
                  id='playedGames.$in'
                  name='playedGames.$in'
                  value={get(formik, 'values.playedGames.$in', [])}
                  label={t('Played Games')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    playedGameTypes.map(gt => (
                      <MenuItem key={gt} value={gt}>
                        {t(`gameCategories.${gt}`)}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.playedGames.$in', false) && get(formik, 'errors.playedGames.$in')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={get(formik, 'touched.state.$in', false) && Boolean(get(formik, 'errors.state.$in'))}
              >
                <InputLabel id='state-select-label'>{t('State')}</InputLabel>
                <Select
                  autoWidth
                  labelId='state-select-label'
                  id='state.$in'
                  name='state.$in'
                  value={get(formik, 'values.state.$in', [])}
                  label={t('State')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  multiple
                >
                  <MenuItem value={'None'}><em>{t('None')}</em></MenuItem>
                  {
                    AVAILABLE_STATES.map(s => {
                      const isDone = s === 'done' ? true : false;
                      const isCanceled = s === 'canceled' ? true : false;
                      const color = isDone ? 'success.main' : isCanceled ? 'error.main' : 'inherit';
                      const fontWeight = (isDone || isCanceled) ? 700 : 'inherit';
                      return (
                        <MenuItem sx={{ color, fontWeight }} key={s} value={s}>
                          {t(s)}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
                <FormHelperText>{get(formik, 'touched.state.$in', false) && get(formik, 'errors.state.$in')}</FormHelperText>
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='startDate.$gte'
                  label={t('Start Date')}
                  value={get(formik, 'values.startDate.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('startDate.$gte', '');
                    else formik.setFieldValue('startDate.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.startDate.$gte', false) && Boolean(get(formik, 'errors.startDate.$gte'))}
                  helperText={get(formik, 'touched.startDate.$gte', false) && get(formik, 'errors.startDate.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='endDate.$lt'
                  label={t('End Date')}
                  value={get(formik, 'values.endDate.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('endDate.$lt', '');
                    else formik.setFieldValue('endDate.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.endDate.$lt', false) && Boolean(get(formik, 'errors.endDate.$lt'))}
                  helperText={get(formik, 'touched.endDate.$lt', false) && get(formik, 'errors.endDate.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$gte'
                  label={t('Updated From')}
                  value={get(formik, 'values.updatedAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$gte', '');
                    else formik.setFieldValue('updatedAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$gte', false) && Boolean(get(formik, 'errors.updatedAt.$gte'))}
                  helperText={get(formik, 'touched.updatedAt.$gte', false) && get(formik, 'errors.updatedAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='updatedAt.$lt'
                  label={t('Updated To')}
                  value={get(formik, 'values.updatedAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('updatedAt.$lt', '');
                    else formik.setFieldValue('updatedAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.updatedAt.$lt', false) && Boolean(get(formik, 'errors.updatedAt.$lt'))}
                  helperText={get(formik, 'touched.updatedAt.$lt', false) && get(formik, 'errors.updatedAt.$lt')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleSave}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
