import React from 'react';
import AssetExplorer from "./AssetExplorer";
import {
  Paper,
} from '@mui/material';

export default function Assets() {
  return (
    <Paper sx={{ p: 2 }}>
      <AssetExplorer />
    </Paper>
  );
}