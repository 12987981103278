import AssetContext from './assetContext';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import useFeathers from 'hooks/useFeathersV2';
import useFeathersNoPagination from 'hooks/useFeathersNoPagination';

const DEFAULT_HOME = {
  name: 'home',
  assetId: null
};

function AssetProvider({ children }) {
  const [ open, setOpen ] = useState(false);
  const [ resultResolve, setResultResolve ] = useState(null);
  const [ data, setData ] = useState([]);
  const [ folderData, setFolderData ] = useState([]);
  const [ path, setPath ] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [ page, setPage ] = useState(0);
  const [ total, setTotal ] = useState(0);
  const [ limit ] = useState(10);
  const [ totalPage, setTotalPage ] = useState(0);
  const [ sortName, setSortName ] = useState(1);
  const [ parentAssetId, setParentAssetId ] = useState(null);
  const [ singleSelect, setSingleSelect ] = useState(false);
  const [ searchText, setSearchText ] = useState('');
  const [ allowedMimeTypes, setAllowedMimeTypes ] = useState([]);

  const openExplorer = useCallback((options = {}) => {
    const { singleSelect, allowedMimeTypes } = options;
    if (singleSelect !== undefined) setSingleSelect(true);

    if (allowedMimeTypes !== undefined) setAllowedMimeTypes(allowedMimeTypes);

    setOpen(true);

    return new Promise((resolve) => {
      setResultResolve(() => resolve);
    });
  }, []);

  const closeExpolorer = useCallback(() => {
    setOpen(false);
    setResultResolve(null);
    setSingleSelect(false);
    setAllowedMimeTypes([]);
  }, []);

  const onConfirm = useCallback((value) => {
    if (resultResolve) resultResolve(value);
    closeExpolorer();
  }, [resultResolve, closeExpolorer]);

  const onClose = useCallback(() => {
    if (resultResolve) resultResolve(null);
    closeExpolorer();
  }, [resultResolve, closeExpolorer]);

  const onSearchText = useCallback((event) => {
    event.preventDefault();
    setSearchText(event.target.value);
  }, []);

  const onClearSearchText = useCallback((event) => {
    event.preventDefault();
    setSearchText('');
  }, []);

  useEffect(
    () => {
      if (parentAssetId === DEFAULT_HOME.assetId || parentAssetId === DEFAULT_HOME.name) {
        setPath(null);
        return;
      }

      const folder = folderData.find((f) => f._id === parentAssetId);
      if (!folder) return;

      const { path, originalPath, name, isDeleted } = folder;
      let fullPath;

      if (isDeleted) {
        fullPath = `${originalPath}${name},`;
      } else {
        fullPath = path === null ? `,${name},` : `${path}${name},`;
      }

      setPath(fullPath);
    }, [parentAssetId, folderData]
  );

  const breadcrumbsData = useMemo(
    () => {
      if (path === null) return [DEFAULT_HOME];

      const splitPath = path.split(',').filter((f) => f !== '');

      const breadcrumbs = splitPath.reduce((acc, cur, index) => {
        const pathId = folderData.find((f) => f.name === cur)._id;
        return [
          ...acc,
          {
            name: cur,
            assetId: pathId,
          }
        ];
      }, [DEFAULT_HOME]);

      return breadcrumbs;
    }, [path, folderData]
  );

  const sort = useMemo(
    () => {
      return {
        path: 1,
        type: -1,
        name: sortName,
      };
    }, [sortName]
  );

  const filter = useMemo(
    () => {
      if (searchText === '') return null;

      return {
        $text: {
          $search: searchText
        },
        path: {
          $regex: '^(?!.*(,trash,)).*$'
        }
      };
    }, [searchText]
  );

  useEffect(
    () => {
      setPage(0);
    }, [filter, sort, path]
  );

  const isInSearch = useMemo(
    () => {
      return filter !== null;
    }, [filter]
  );

  const query = useMemo(
    () => {
      const query = {
        path: filter ? undefined : path,
        $limit: limit,
        $skip: page * limit,
        ...(sort && {
          $sort: sort
        }),
        ...(filter && {
          ...filter
        }),
        permanentDeletedAt: {
          $exists: false
        },
      };

      return {
        query,
      }
    }, [path, page, limit, sort, filter]
  );

  const {
    result: filesResult,
    isFetching: isFetchingFile,
    error: fileError
  } = useFeathers('assets', query, {
    appendData: true, // for infinite scroll
    liveUpdatePredicate: (data) => {
      const { path: updatedPath, isDeleted, originalPath, permanentDeletedAt } = data;
      if (!!permanentDeletedAt) return true;
      if (isDeleted) {
        return path === originalPath;
      } else {
        if (path === ',trash,') {
          return true;
        } else {
          return updatedPath === path;
        }
      }
    }
  });

  const {
    result: foldersResult,
    isFetching: isFetchingFolder,
    error: folderError
  } = useFeathersNoPagination('assets', {
    query: {
      type: 'folder',
    }
  }, {
    liveUpdatePredicate: (data) => {
      const { type, permanentDeletedAt } = data;
      if (!!permanentDeletedAt) return true;
      if (type !== 'folder') return false;
      return true;
    }
  });

  const fetchNextPage = useCallback(
    () => {
      setPage(prev => {
        if (prev + 1 >= totalPage) return prev;
        return prev + 1;
      });
    }, [totalPage]
  );

  useEffect(
    () => {
      const { total, limit } = filesResult;
      setTotal(total);

      const calcTotalPage = total === 0 ? 0 : Math.ceil(total / limit);
      setTotalPage(calcTotalPage);
    }, [filesResult]
  );

  useEffect(
    () => {
      const filteredData = foldersResult.filter((f) => {
        const { permanentDeletedAt } = f;
        if (!!permanentDeletedAt) return false;
        return true;
      }) || [];
      setFolderData(filteredData);
    }, [foldersResult]
  );

  useEffect(
    () => {
      const filteredData = filesResult.data.filter((f) => {
        const { isDeleted, permanentDeletedAt, mimeType, type } = f;

        if (allowedMimeTypes.length > 0 && type === 'file') {
          if (!allowedMimeTypes.includes(mimeType)) return false;
        }

        if (!!permanentDeletedAt) return false;

        if (path === ',trash,') {
          if (isDeleted) return true;
          return false;
        } else {
          if (isDeleted) return false;
          return true;
        }
      }) || [];
      setData(filteredData);
    }, [filesResult, path, allowedMimeTypes]
  );

  const isFetching = useMemo(
    () => {
      return isFetchingFile || isFetchingFolder;
    }, [isFetchingFile, isFetchingFolder]
  );

  const errorMessage = useMemo(
    () => {
      let error = [];
      const { message: fileMessage } = fileError || {};
      const { message: folderMessage } = folderError || {};

      if (fileMessage) error.push(fileMessage);
      if (folderMessage) error.push(folderMessage);

      return error.join(', ');
    }, [fileError, folderError]
  );

  return (
    <AssetContext.Provider value={{
      folders: folderData,
      data,
      total,
      totalPage,
      page,
      path,
      isFetching,
      errorMessage,
      fetchNextPage,
      parentAssetId,
      setParentAssetId,
      breadcrumbsData,
      sortName,
      setSortName,
      isInSearch,
      open,
      openExplorer,
      onConfirm,
      onClose,
      singleSelect,
      searchText,
      onSearchText,
      onClearSearchText,
    }}>
      {children}
    </AssetContext.Provider>
  );
}

export default AssetProvider;
