import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { get, map, groupBy, sumBy, sortBy, includes } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import feathers from 'services/feathers';
import { useTheme } from '@mui/material/styles';
import Decimal from 'decimal.js';
import { useAuth } from 'hooks/useAuth';
import {
  amber,
  blue,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { lookups as lookupGames } from 'lookups/games';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { GameTransactionAmount, GameTransactionCount } from './GameTransaction';
import { GameTypeAmount, GameTypeCount } from './GameType';
import { styled } from '@mui/material/styles';

const StickyPaperOnBigScreen = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: theme.mixins.toolbar.minHeight + 8,
    zIndex: 100,
  },
}));

export default function Home(props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const userRole = user?.role || '';
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [userData, setUserData] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const [balanceTransferData, setBalanceTransferData] = useState(null);
  const [redemptionRewardData, setRedemptionRewardData] = useState(null);
  const [pendingFetch, setPendingFetch] = useState(0);
  const theme = useTheme();
  const utcOffset = dayjs().format('Z');

  const isDarkPalette = useMemo(
    () => {
      return get(theme, 'palette.mode', 'light') === 'dark' ? true : false;
    }, [theme]
  );

  const graphColorTone = useMemo(
    () => {
      return isDarkPalette ? 300 : 600;
    }, [isDarkPalette]
  );

  const isManagementRole = useMemo(
    () => {
      const supportedRoles = ['admin', 'superadmin', 'root'];
      return includes(supportedRoles, userRole);
    }, [userRole]
  );

  const isBusy = useMemo(
    () => {
      if (pendingFetch <= 0) return false;
      return true;
    }, [pendingFetch]
  );

  const data = useMemo(
    () => {
      const now = dayjs();
      return {
        dateFrom: now.startOf('day').toDate(),
        dateTo: now.add(1, 'd').startOf('day').toDate(),
      }
    }, []
  );

  function generateTxnPerformance(groupByKey, transactionData) {
    const isGroupByBank = groupByKey === 'accountNumber' ? true : false;
    const groupByData = groupBy(transactionData, groupByKey);
    const performance = map(groupByData, (data, key) => {

      return {
        [groupByKey]: key,
        dpCnt: sumBy(data, 'dpCount'),
        dpAmt: sumBy(data, function (o) {
          const amount = new Decimal(get(o, 'dpAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        wdCnt: sumBy(data, 'wdCount'),
        wdAmt: sumBy(data, function (o) {
          const amount = new Decimal(get(o, 'wdAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        adjDpCnt: sumBy(data, 'dpAdjCount'),
        adjDpAmt: sumBy(data, function (o) {
          const amount = new Decimal(get(o, 'dpAdjAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        adjWdCnt: sumBy(data, 'wdAdjCount'),
        adjWdAmt: sumBy(data, function (o) {
          const amount = new Decimal(get(o, 'wdAdjAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        netCashFlowAmt: sumBy(data, function (o) {
          const dpAmount = new Decimal(get(o, 'dpAmount.$numberDecimal', '0'));
          const dpAdjAmount = new Decimal(get(o, 'dpAdjAmount.$numberDecimal', '0'));
          const wdAmount = new Decimal(get(o, 'wdAmount.$numberDecimal', '0'));
          const wdAdjAmount = new Decimal(get(o, 'wdAdjAmount.$numberDecimal', '0'));
          return dpAmount.plus(dpAdjAmount).minus(wdAmount).minus(wdAdjAmount).toNumber();
        }),
        dpAvgAmt: sumBy(data, function (o) {
          const amount = new Decimal(get(o, 'dpAvgAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        wdAvgAmt: sumBy(data, function (o) {
          const amount = new Decimal(get(o, 'wdAvgAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        ...(isGroupByBank && {
          bankName: get(data, '[0]bankName', ''),
          bankLabel: get(data, '[0]bankLabel', '')
        })
      }
    });

    const sorted = sortBy(performance, groupByKey);
    return sorted;
  }

  function generateGamePerformance(groupByKey, balanceTransferData) {
    const isGroupByGame = groupByKey === 'gameType' ? true : false;

    const groupByData = groupBy(balanceTransferData, groupByKey);
    const performance = map(groupByData, (day, key) => {
      return {
        [groupByKey]: key,
        dpCnt: sumBy(day, 'dpCnt'),
        dpAmt: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'dpAmt.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        wdCnt: sumBy(day, 'wdCnt'),
        wdAmt: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'wdAmt.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        grossWinLossAmt: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'grossWinLoss.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        commissionAmt: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'commission.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        netWinLossAmt: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'netWinLoss.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        percentage: new Decimal(get(day, '[0].percentage', '0')).toNumber(),
        ...(isGroupByGame && {
          gameName: get(lookupGames, key, '')
        })
      }
    });
    const sorted = sortBy(performance, groupByKey);
    return sorted;
  }

  function generateBonusPerformance(groupByKey, redemptionRewardData) {
    const groupByData = groupBy(redemptionRewardData, groupByKey);
    const performance = map(groupByData, (day, key) => {
      return {
        [groupByKey]: key,
        dailySlotLossRebateCount: sumBy(day, 'dailySlotLossRebateCount'),
        dailySlotLossRebateAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'dailySlotLossRebateAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        dailySlotTurnoverRebateCount: sumBy(day, 'dailySlotTurnoverRebateCount'),
        dailySlotTurnoverRebateAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'dailySlotTurnoverRebateAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        dailyLiveTurnoverRebateCount: sumBy(day, 'dailyLiveTurnoverRebateCount'),
        dailyLiveTurnoverRebateAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'dailyLiveTurnoverRebateAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        cashRebateCount: sumBy(day, 'cashRebateCount'),
        cashRebateAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'cashRebateAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        referralCount: sumBy(day, 'referralCount'),
        referralPointCount: sumBy(day, 'referralPointCount'),
        referralCashCount: sumBy(day, 'referralCashCount'),
        referralCashAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'referralCashAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        referralPointAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'referralPointAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        boosterCount: sumBy(day, 'boosterCount'),
        boosterAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'boosterAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        tierCount: sumBy(day, 'tierCount'),
        tierAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'tierAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        luckyDrawCount: sumBy(day, 'luckyDrawCount'),
        luckyDrawAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'luckyDrawAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        vipRewardCount: sumBy(day, 'vipRewardCount'),
        vipRewardAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'vipRewardAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        redemptionCount: sumBy(day, 'redemptionCount'),
        redemptionCashAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'redemptionCashAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        redemptionPointAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'redemptionPointAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        cashAdjustmentCount: sumBy(day, 'cashAdjustmentCount'),
        cashAdjustmentAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'cashAdjustmentAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
        pointAdjustmentCount: sumBy(day, 'pointAdjustmentCount'),
        pointAdjustmentAmount: sumBy(day, function (o) {
          const amount = new Decimal(get(o, 'pointAdjustmentAmount.$numberDecimal', '0'));
          return amount.toNumber();
        }),
      }
    });

    const avg = map(performance, (p) => {
      const { redemptionPointAmount = '0', redemptionCashAmount = '0' } = p;
      const decPoint = new Decimal(redemptionPointAmount);
      const decCash = new Decimal(redemptionCashAmount);

      return {
        cashToPointRatio: decPoint.eq(0) ? 0 : decCash.dividedBy(decPoint).toNumber(),
        ...p
      }
    });

    const sorted = sortBy(avg, groupByKey);
    return sorted;
  }

  function generateNetFinancialMetrics(groupByKey, transactionData) {
    function sumCountAndAmountByType(type, day) {
      const countKey = `${type}Count`;
      const amountKey = `${type}Amount`;
      return {
        [countKey]: sumBy(day, countKey),
        [amountKey]: sumBy(day, function (o) {
          const amount = new Decimal(get(o, `${amountKey}.$numberDecimal`, '0'));
          return amount.toNumber();
        }),
      };
    }

    const groupByData = groupBy(transactionData, groupByKey);
    const ret = map(groupByData, (day, key) => {
      return {
        [groupByKey]: key,
        ...sumCountAndAmountByType('capital', day),
        ...sumCountAndAmountByType('commission', day),
        ...sumCountAndAmountByType('exchange', day),
        ...sumCountAndAmountByType('expense', day),
        ...sumCountAndAmountByType('payroll', day),
        ...sumCountAndAmountByType('transfer', day),
      }
    });

    const sorted = sortBy(ret, groupByKey);
    return sorted;
  }

  const txnPerformance = useMemo(
    () => {
      if (transactionData === null) return { date: null, accountNumber: null };

      return {
        date: generateTxnPerformance('date', transactionData),
        accountNumber: generateTxnPerformance('accountNumber', transactionData)
      };
    }, [transactionData]
  );

  const gamePerformance = useMemo(
    () => {
      if (balanceTransferData === null) return { date: null, gameType: null };

      return {
        date: generateGamePerformance('date', balanceTransferData),
        gameType: generateGamePerformance('gameType', balanceTransferData)
      };
    }, [balanceTransferData]
  );

  const netFinancialMetrics = useMemo(
    () => {
      if (transactionData === null) return { date: null };
      return {
        date: generateNetFinancialMetrics('date', transactionData),
      };
    }, [transactionData]
  );

  const bonusPerformance = useMemo(
    () => {
      if (redemptionRewardData === null) return { date: null };
      return {
        date: generateBonusPerformance('date', redemptionRewardData)
      }
    }, [redemptionRewardData]
  );

  const userPerformance = useMemo(
    () => {
      if (userData === null) return { active: null, new: null };
      const active = get(userData, 'active.date', []);
      const newData = get(userData, 'new', []);
      return {
        active,
        new: newData
      }
    }, [userData]
  );

  const topDepositors = useMemo(
    () => {
      const ret = get(userData, 'active.topDP', []).map((item) => {
        const amountStr = get(item, 'amount.$numberDecimal', '0');
        const amountDec = new Decimal(amountStr);
        return {
          ...item,
          amount: amountDec.toFixed(2)
        }
      });
      return ret;
    }, [userData]
  );

  const topWithdrawers = useMemo(
    () => {
      const ret = get(userData, 'active.topWD', []).map((item) => {
        const amountStr = get(item, 'amount.$numberDecimal', '0');
        const amountDec = new Decimal(amountStr);
        return {
          ...item,
          amount: amountDec.toFixed(2)
        }
      });
      return ret;
    }, [userData]
  );

  const totalDepositorsCount = useMemo(
    () => {
      return get(userData, 'active.total[0].dpCount', 0);
    }, [userData]
  );

  const totalWithdrawersCount = useMemo(
    () => {
      return get(userData, 'active.total[0].wdCount', 0);
    }, [userData]
  );

  const summaryData = useMemo(
    () => {
      if (transactionData === null || pendingFetch > 0) return {
        totalDeposits: 0,
        totalDepositsCount: 0,
        totalWithdrawals: 0,
        totalWithdrawalsCount: 0,
        netDeposits: 0,
        totalGamingDeposits: 0,
        totalGamingWithdrawals: 0,
        netGamingDeposits: 0,
        gamingCommissions: 0,
        totalPointIssuance: 0,
        totalPointRedemption: 0,
        totalRedeemedCash: 0,
        cashToPointRatio: 0,
        redemptionToDepositRatio: 0,
        totalCashValueReferral: 0,
        totalReferralCount: 0,
        totalReferralPointCount: 0,
        totalReferralCashCount: 0,
        totalReferralPointAmount: 0,
        totalReferralCashAmount: 0,
        totalSlotLossRebateCount: 0,
        totalSlotLossRebateAmount: 0,
        totalSlotTurnoverRebateCount: 0,
        totalSlotTurnoverRebateAmount: 0,
        totalLiveTurnoverRebateCount: 0,
        totalLiveTurnoverRebateAmount: 0,
        totalCashRebateCount: 0,
        totalCashRebateAmount: 0,
        cashRebateToDepositRatio: 0,
        totalExpenses: 0,
        grossProfit: 0,
        netProfit: 0,
      };

      const totalDeposits = transactionData.reduce(
        (accumulator, o) => {
          const dp = new Decimal(get(o, 'dpAmount.$numberDecimal', '0'));
          const dpAdj = new Decimal(get(o, 'dpAdjAmount.$numberDecimal', '0'));
          const amount = dp.plus(dpAdj).toNumber();

          const { dpCount = 0, dpAdjCount = 0 } = o || {};
          const count = dpCount + dpAdjCount;

          accumulator.amount += amount;
          accumulator.count += count;
          return accumulator;
        },
        { amount: 0, count: 0 }
      );

      const totalWithdrawals = transactionData.reduce(
        (accumulator, o) => {
          const wd = new Decimal(get(o, 'wdAmount.$numberDecimal', '0'));
          const wdAdj = new Decimal(get(o, 'wdAdjAmount.$numberDecimal', '0'));
          const amount = wd.plus(wdAdj).toNumber();

          const { wdCount = 0, wdAdjCount = 0 } = o || {};
          const count = wdCount + wdAdjCount;

          accumulator.amount += amount;
          accumulator.count += count;
          return accumulator;
        },
        { amount: 0, count: 0 }
      );

      const netDeposits = new Decimal(totalDeposits.amount).minus(totalWithdrawals.amount).toNumber();

      const totalGamingDeposits = sumBy(balanceTransferData, function (o) {
        const amount = new Decimal(get(o, 'dpAmt.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalGamingWithdrawals = sumBy(balanceTransferData, function (o) {
        const amount = new Decimal(get(o, 'wdAmt.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const netGamingDeposits = new Decimal(totalGamingDeposits).minus(totalGamingWithdrawals);

      const gamingCommissions = sumBy(balanceTransferData, function (o) {
        const amount = new Decimal(get(o, 'commission.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalPointIssuance = sumBy(redemptionRewardData, function (o) {
        const tier = new Decimal(get(o, 'tierAmount.$numberDecimal', '0'));
        const booster = new Decimal(get(o, 'boosterAmount.$numberDecimal', '0'));
        const luckyDraw = new Decimal(get(o, 'luckyDrawAmount.$numberDecimal', '0'));
        const vipReward = new Decimal(get(o, 'vipRewardAmount.$numberDecimal', '0'));
        const referral = new Decimal(get(o, 'referralPointAmount.$numberDecimal', '0'));
        return tier.plus(booster).plus(luckyDraw).plus(vipReward).plus(referral).toNumber();
      });

      const totalPointRedemption = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'redemptionPointAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalRedeemedCash = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'redemptionCashAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const redemptionToDepositRatio = (new Decimal(totalDeposits.amount)).gt(0) ?
        (new Decimal(totalRedeemedCash)).dividedBy(totalDeposits.amount).toNumber() : 0;

      const totalReferralCount = sumBy(redemptionRewardData, function (o) {
        const count = new Decimal(get(o, 'referralCount', '0'));
        return count.toNumber();
      });

      const totalReferralPointCount = sumBy(redemptionRewardData, function (o) {
        const count = new Decimal(get(o, 'referralPointCount', '0'));
        return count.toNumber();
      });

      const totalReferralCashCount = sumBy(redemptionRewardData, function (o) {
        const count = new Decimal(get(o, 'referralCashCount', '0'));
        return count.toNumber();
      });

      const totalReferralPointAmount = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'referralPointAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalReferralCashAmount = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'referralCashAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalCashValueReferral = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'referralCashAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalSlotLossRebateCount = sumBy(redemptionRewardData, function (o) {
        const count = new Decimal(get(o, 'dailySlotLossRebateCount', '0'));
        return count.toNumber();
      });

      const totalSlotLossRebateAmount = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'dailySlotLossRebateAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalSlotTurnoverRebateCount = sumBy(redemptionRewardData, function (o) {
        const count = new Decimal(get(o, 'dailySlotTurnoverRebateCount', '0'));
        return count.toNumber();
      });

      const totalSlotTurnoverRebateAmount = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'dailySlotTurnoverRebateAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalLiveTurnoverRebateCount = sumBy(redemptionRewardData, function (o) {
        const count = new Decimal(get(o, 'dailyLiveTurnoverRebateCount', '0'));
        return count.toNumber();
      });

      const totalLiveTurnoverRebateAmount = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'dailyLiveTurnoverRebateAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const totalCashRebateCount = sumBy(redemptionRewardData, function (o) {
        const count = new Decimal(get(o, 'cashRebateCount', '0'));
        return count.toNumber();
      });

      const totalCashRebateAmount = sumBy(redemptionRewardData, function (o) {
        const amount = new Decimal(get(o, 'cashRebateAmount.$numberDecimal', '0'));
        return amount.toNumber();
      });

      const cashRebateToDepositRatio = (new Decimal(totalDeposits.amount)).gt(0) ?
        (new Decimal(totalCashRebateAmount)).dividedBy(totalDeposits.amount).toNumber() : 0;

      const decTotalPointUsed = new Decimal(totalPointRedemption);
      const decTotalCashValueRedeemed = new Decimal(totalRedeemedCash);
      const cashToPointRatio = decTotalPointUsed.gt(0) ?
        decTotalCashValueRedeemed.dividedBy(decTotalPointUsed).toNumber() : 0;

      const totalExpenses = sumBy(transactionData, function (o) {
        const payroll = new Decimal(get(o, 'payrollAmount.$numberDecimal', '0'));
        const expense = new Decimal(get(o, 'expenseAmount.$numberDecimal', '0'));
        return payroll.plus(expense).negated().toNumber();
      });

      const decGrossProfit = new Decimal(netDeposits).minus(gamingCommissions);

      const decNetProfit = decGrossProfit.minus(totalExpenses);

      return {
        totalDeposits: totalDeposits.amount,
        totalDepositsCount: totalDeposits.count,
        totalWithdrawals: totalWithdrawals.amount,
        totalWithdrawalsCount: totalWithdrawals.count,
        netDeposits,
        totalGamingDeposits,
        totalGamingWithdrawals,
        netGamingDeposits,
        gamingCommissions,
        totalPointIssuance,
        totalPointRedemption,
        totalRedeemedCash,
        cashToPointRatio,
        redemptionToDepositRatio,
        totalCashValueReferral,
        totalReferralCount,
        totalReferralPointCount,
        totalReferralCashCount,
        totalReferralPointAmount,
        totalReferralCashAmount,
        totalSlotLossRebateCount,
        totalSlotLossRebateAmount,
        totalSlotTurnoverRebateCount,
        totalSlotTurnoverRebateAmount,
        totalLiveTurnoverRebateCount,
        totalLiveTurnoverRebateAmount,
        totalCashRebateCount,
        totalCashRebateAmount,
        cashRebateToDepositRatio,
        totalExpenses,
        grossProfit: decGrossProfit?.toNumber() || 0,
        netProfit: decNetProfit?.toNumber() || 0,
      };
    }, [pendingFetch, transactionData, balanceTransferData, redemptionRewardData]
  );

  const amountFormatter = (value) => {
    return value.toFixed(2);
  };

  const dataSchema = Yup.object().shape({
    dateFrom: Yup.date().required(t('Required')),
    dateTo: Yup.date().required(t('Required'))
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        const { dateFrom, dateTo } = values;
        setPendingFetch(4);
        aggregateUser(dateFrom, dateTo);
        aggregateTransaction(dateFrom, dateTo);
        aggregateBalanceTransfer(dateFrom, dateTo);
        aggregateRedemptionReward(dateFrom, dateTo);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    },
  });

  async function aggregateUser(dateFrom, dateTo) {
    try {
      const ret = await feathers.service('users').find({
        query: {
          $aggregate: 'new-active-user',
          dateFrom,
          dateTo,
          utcOffset,
        }
      });
      setUserData(ret);
      return ret;
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setPendingFetch(prev => prev - 1);
    }
  }

  async function aggregateTransaction(dateFrom, dateTo) {
    try {
      const ret = await feathers.service('deposits').find({
        query: {
          $aggregate: 'transaction',
          dateFrom,
          dateTo,
          utcOffset,
        }
      });
      setTransactionData(ret);
      return ret;
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setPendingFetch(prev => prev - 1);
    }
  }

  async function aggregateBalanceTransfer(dateFrom, dateTo) {
    try {
      const ret = await feathers.service('balance-transfers').find({
        query: {
          $aggregate: 'balance-transfer',
          dateFrom,
          dateTo,
          utcOffset,
        }
      });
      setBalanceTransferData(ret);
      return ret;
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setPendingFetch(prev => prev - 1);
    }
  }

  async function aggregateRedemptionReward(dateFrom, dateTo) {
    try {
      const ret = await feathers.service('point-redemptions').find({
        query: {
          $aggregate: 'redemption-reward',
          dateFrom,
          dateTo,
          utcOffset,
        }
      });

      setRedemptionRewardData(ret);
      return ret;
    } catch (err) {
      setGlobalErrorMessage({ err });
    } finally {
      setPendingFetch(prev => prev - 1);
    }
  }

  const handleOnDateClicked = (mode) => (event) => {
    event.preventDefault();
    const params = mode?.split(',');
    if (params.length !== 4) return;
    const now = dayjs();
    const start = now.subtract(params[0], params[1]).startOf(params[1]);
    const end = now.subtract(params[2], params[3]).endOf(params[3]).add(1, 'd').startOf('d');
    formik.setFieldValue('dateFrom', start.toDate());
    formik.setFieldValue('dateTo', end.toDate());
    formik.handleSubmit();
  };

  const onAggregateClicked = (event) => {
    event.preventDefault();
    if (isBusy) return;
    formik.handleSubmit();
  };

  return (
    <Box>
      <StickyPaperOnBigScreen variant='outlined' sx={{ p: 2, m: 1 }}>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item>
              <DateTimePicker
                views={['day', 'hours', 'minutes', 'seconds']}
                inputFormat="YYYY-MM-DD HH:mm:ss"
                renderInput={(props) => <TextField {...props} />}
                id='dateFrom'
                label={t('Date From')}
                value={get(formik, 'values.dateFrom', null)}
                onBlur={formik.handleBlur}
                onChange={(date) => {
                  if (!dayjs(date).isValid()) formik.setFieldValue('dateFrom', '');
                  else formik.setFieldValue('dateFrom', date.toDate());
                }}
                error={get(formik, 'touched.dateFrom', false) && Boolean(get(formik, 'errors.dateFrom'))}
                helperText={get(formik, 'touched.dateFrom', false) && get(formik, 'errors.dateFrom')}
              />
            </Grid>
            <Grid item>
              <DateTimePicker
                views={['day', 'hours', 'minutes', 'seconds']}
                inputFormat="YYYY-MM-DD HH:mm:ss"
                renderInput={(props) => <TextField {...props} />}
                id='dateTo'
                label={t('Date To')}
                value={get(formik, 'values.dateTo', null)}
                onBlur={formik.handleBlur}
                onChange={(date) => {
                  if (!dayjs(date).isValid()) formik.setFieldValue('dateTo', '');
                  else formik.setFieldValue('dateTo', date.toDate());
                }}
                error={get(formik, 'touched.dateTo', false) && Boolean(get(formik, 'errors.dateTo'))}
                helperText={get(formik, 'touched.dateTo', false) && get(formik, 'errors.dateTo')}
              />
            </Grid>
            <Grid item>
              <Button disabled={isBusy} onClick={onAggregateClicked} variant='contained'>
                {t('Aggregate')}
              </Button>
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('0,d,0,d')}>
                  {t('Today')}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('1,d,1,d')}>
                  {t('Yesterday')}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('24,h,0,d')}>
                  {t('Last n hour', { count: 24 })}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('3,d,0,d')}>
                  {t('Last n day', { count: 3 })}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('7,d,0,d')}>
                  {t('Last n day', { count: 7 })}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('30,d,0,d')}>
                  {t('Last n day', { count: 30 })}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('0,M,0,d')}>
                  {t('This month')}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('1,M,1,M')}>
                  {t('Last month')}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('3,M,0,d')}>
                  {t('Last n month', { count: 3 })}
                </Button>
              </Grid>
              <Grid item>
                <Button size='small' variant='outlined' color='secondary' onClick={handleOnDateClicked('6,M,0,d')}>
                  {t('Last n month', { count: 6 })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StickyPaperOnBigScreen>
      <Grid container spacing={2}>
        {
          isManagementRole &&
          <>
            <Grid item xs={12} lg={6}>
              <Paper sx={{ p: 2, m: 1 }} elevation={10}>
                <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
                  {t('Summary')}
                </Typography>
                <Box sx={{ textAlign: 'right' }}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Total Depositors')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {totalDepositorsCount}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Total Withdrawers')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {totalWithdrawersCount}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{`${t('Total Deposits')} (#${summaryData?.totalDepositsCount})`}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalDeposits.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{`${t('Total Withdrawals')} (#${summaryData?.totalWithdrawalsCount})`}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalWithdrawals.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Net Deposits')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.netDeposits.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Total Gaming Deposits')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalGamingDeposits.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Total Gaming Withdrawals')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalGamingWithdrawals.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Net Gaming Deposits')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.netGamingDeposits.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Gaming Commissions')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.gamingCommissions.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                  <Grid container justifyContent="space-between">
                    <Typography sx={{ fontStyle: 'oblique', color: 'text.secondary' }} variant="subtitle1">{t('Total Point Issuance')}</Typography>
                    <Typography sx={{ fontStyle: 'oblique', color: 'text.secondary' }} variant="subtitle1" align="right">
                      {summaryData?.totalPointIssuance.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Total Point Redemption')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalPointRedemption.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Total Redeemed Cash')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalRedeemedCash.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography sx={{ fontWeight: '600', color: 'info.main' }} variant="subtitle1">{t('Cash to Point Ratio')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.cashToPointRatio.toFixed(4)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography sx={{ fontWeight: '600', color: 'info.main' }} variant="subtitle1">{t('Redemption to Deposit Ratio')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.redemptionToDepositRatio.toFixed(4)}
                    </Typography>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                  <Grid container justifyContent="space-between">
                    <Typography sx={{ fontStyle: 'oblique', color: 'text.secondary' }} variant="subtitle1">
                      {
                        `${t('Total Slot Loss Rebate')} (#${summaryData?.totalSlotLossRebateCount})`
                      }
                    </Typography>
                    <Typography sx={{ fontStyle: 'oblique', color: 'text.secondary' }} variant="subtitle1" align="right">
                      {summaryData?.totalSlotLossRebateAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography sx={{ fontStyle: 'oblique', color: 'text.secondary' }} variant="subtitle1">
                      {
                        `${t('Total Slot Turnover Rebate')} (#${summaryData?.totalSlotTurnoverRebateCount})`
                      }
                    </Typography>
                    <Typography sx={{ fontStyle: 'oblique', color: 'text.secondary' }} variant="subtitle1" align="right">
                      {summaryData?.totalSlotTurnoverRebateAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography sx={{ fontStyle: 'oblique', color: 'text.secondary' }} variant="subtitle1">
                      {
                        `${t('Total Live Turnover Rebate')} (#${summaryData?.totalLiveTurnoverRebateCount})`
                      }
                    </Typography>
                    <Typography sx={{ fontStyle: 'oblique', color: 'text.secondary' }} variant="subtitle1" align="right">
                      {summaryData?.totalLiveTurnoverRebateAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">
                      {
                        `${t('Total Cash Rebate')} (#${summaryData?.totalCashRebateCount})`
                      }</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalCashRebateAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography sx={{ fontWeight: '600', color: 'info.main' }} variant="subtitle1">{t('Cash Rebate to Deposit Ratio')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.cashRebateToDepositRatio.toFixed(4)}
                    </Typography>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Total Referral')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalReferralCount}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{`${t('Total Referral Point')} (#${summaryData?.totalReferralPointCount})`}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalReferralPointAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{`${t('Total Referral Cash')} (#${summaryData?.totalReferralCashCount})`}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalReferralCashAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Gross Profit')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.grossProfit.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1">{t('Total Expenses')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.totalExpenses.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography sx={{
                      color: summaryData?.netProfit > 0 ?
                        'success.main' : 'error.main',
                      fontWeight: '600'
                    }} variant="subtitle1">{t('Net Profit')}</Typography>
                    <Typography variant="subtitle1" align="right">
                      {summaryData?.netProfit.toFixed(2)}
                    </Typography>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Paper sx={{ p: 2, m: 1 }} elevation={10}>
                <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
                  {t('Top Depositors')}
                </Typography>
                <List>
                  {
                    !!topDepositors.length ? topDepositors.map((row) => (
                      <ListItem key={row.username}>
                        <ListItemText primary={row.username} secondary={row.amount} />
                      </ListItem>
                    )) :
                      <ListItem>
                        <ListItemText primary={'-'} />
                      </ListItem>
                  }
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Paper sx={{ p: 2, m: 1 }} elevation={10}>
                <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
                  {t('Top Withdrawers')}
                </Typography>
                <List>
                  {
                    !!topWithdrawers.length ? topWithdrawers.map((row) => (
                      <ListItem key={row.username}>
                        <ListItemText primary={row.username} secondary={row.amount} />
                      </ListItem>
                    )) :
                      <ListItem>
                        <ListItemText primary={'-'} />
                      </ListItem>
                  }
                </List>
              </Paper>
            </Grid>
          </>
        }
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('User')}
              {' '}
              <Box sx={{ fontWeight: 300, color: pink[graphColorTone] }} component='span'>
                {t('Active')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={userPerformance.active}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('DP')} dataKey="dpCount" barSize={15} fill={pink[graphColorTone]} />
                <Bar name={t('WD')} dataKey="wdCount" barSize={15} fill={grey[graphColorTone]} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('User')}
              {' '}
              <Box sx={{ fontWeight: 300, color: pink[graphColorTone] }} component='span'>
                {t('New')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={userPerformance.new}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('Count')} dataKey="count" barSize={15} fill={pink[graphColorTone]} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Txn')}
              {' '}
              <Box sx={{ fontWeight: 300, color: deepOrange[700] }} component='span'>
                {t('Average Amount')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={txnPerformance.date}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  formatter={amountFormatter}
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('DP')} stackId="dp" dataKey="dpAvgAmt" barSize={15} fill={deepOrange[600]} />
                <Bar name={t('WD')} stackId="wd" dataKey="wdAvgAmt" barSize={15} fill={grey[600]} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Txn')}
              {' '}
              <Box sx={{ fontWeight: 300, color: deepOrange[700] }} component='span'>
                {t('Net Cash Flow')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={txnPerformance.date}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  formatter={amountFormatter}
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Line type="monotone" name={t('Amount')} dataKey="netCashFlowAmt" stroke={deepOrange[600]} strokeWidth={3} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Txn')}
              {' '}
              <Box sx={{ fontWeight: 300, color: deepOrange[700] }} component='span'>
                {t('Amount')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={txnPerformance.date}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  formatter={amountFormatter}
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('DP')} stackId="dp" dataKey="dpAmt" barSize={15} fill={deepOrange[600]} />
                <Bar name={t('DP Adj')} stackId="dp" dataKey="adjDpAmt" barSize={15} fill={deepOrange[800]} />
                <Bar name={t('WD')} stackId="wd" dataKey="wdAmt" barSize={15} fill={grey[600]} />
                <Bar name={t('WD Adj')} stackId="wd" dataKey="adjWdAmt" barSize={15} fill={grey[800]} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Txn')}
              {' '}
              <Box sx={{ fontWeight: 300, color: deepOrange[700] }} component='span'>
                {t('Count')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={txnPerformance.date}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('DP')} stackId="dp" dataKey="dpCnt" barSize={15} fill={deepOrange[600]} />
                <Bar name={t('DP Adj')} stackId="dp" dataKey="adjDpCnt" barSize={15} fill={deepOrange[800]} />
                <Bar name={t('WD')} stackId="wd" dataKey="wdCnt" barSize={15} fill={grey[600]} />
                <Bar name={t('WD Adj')} stackId="wd" dataKey="adjWdCnt" barSize={15} fill={grey[800]} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 500 }} variant='h6' gutterBottom>
              {t('Bank')}
              {' '}
              <Box sx={{ fontWeight: 300, color: amber[700] }} component='span'>
                {t('Amount')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={txnPerformance.accountNumber}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='bankLabel' />
                <YAxis />
                <Tooltip
                  formatter={amountFormatter}
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('DP')} stackId="dp" dataKey="dpAmt" barSize={15} fill={amber[600]} />
                <Bar name={t('DP Adj')} stackId="dp" dataKey="adjDpAmt" barSize={10} fill={amber[800]} />
                <Bar name={t('WD')} stackId="wd" dataKey="wdAmt" barSize={15} fill={grey[600]} />
                <Bar name={t('WD Adj')} stackId="wd" dataKey="adjWdAmt" barSize={10} fill={grey[800]} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Bank')}
              {' '}
              <Box sx={{ fontWeight: 300, color: amber[700] }} component='span'>
                {t('Count')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={txnPerformance.accountNumber}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='bankLabel' />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('DP')} stackId="dp" dataKey="dpCnt" barSize={15} fill={amber[600]} />
                <Bar name={t('DP Adj')} stackId="dp" dataKey="adjDpCnt" barSize={10} fill={amber[800]} />
                <Bar name={t('WD')} stackId="wd" dataKey="wdCnt" barSize={15} fill={grey[600]} />
                <Bar name={t('WD Adj')} stackId="wd" dataKey="adjWdCnt" barSize={10} fill={grey[800]} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <GameTransactionAmount data={gamePerformance} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GameTransactionCount data={gamePerformance} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GameTypeAmount data={gamePerformance} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GameTypeCount data={gamePerformance} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Bonus')}
              {' '}
              <Box sx={{ fontWeight: 300, color: red[700] }} component='span'>
                {t('Amount')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={bonusPerformance.date}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  formatter={amountFormatter}
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('Referral Point')} stackId="amt" dataKey="referralPointAmount" barSize={15} fill={teal[graphColorTone]} />
                <Line type="monotone" name={t('Referral Cash')} dataKey="referralCashAmount" stroke={blue[graphColorTone]} strokeWidth={3} />
                <Bar name={t('Tier Point')} stackId="amt" dataKey="tierAmount" barSize={15} fill={amber[graphColorTone]} />
                <Bar name={t('Booster Point')} stackId="amt" dataKey="boosterAmount" barSize={15} fill={indigo[graphColorTone]} />
                <Bar name={t('Lucky Draw Point')} stackId="amt" dataKey="luckyDrawAmount" barSize={15} fill={pink[graphColorTone]} />
                <Bar name={t('VIP Reward Point')} stackId="amt" dataKey="vipRewardAmount" barSize={15} fill={deepPurple[graphColorTone]} />
                <Line type="monotone" name={t('Point Redemption')} dataKey="redemptionPointAmount" stroke={red[graphColorTone]} strokeWidth={3} />
                <Line type="monotone" name={t('Redeemed Cash')} dataKey="redemptionCashAmount" stroke={green[graphColorTone]} strokeWidth={3} />
                <Line type="monotone" name={t('Cash to Point Ratio')} dataKey="cashToPointRatio" stroke={purple[graphColorTone]} strokeWidth={1} />
                <Line type="monotone" name={t('Cash Rebate')} dataKey="cashRebateAmount" stroke={yellow[graphColorTone]} strokeWidth={3} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Bonus')}
              {' '}
              <Box sx={{ fontWeight: 300, color: red[700] }} component='span'>
                {t('Count')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={bonusPerformance.date}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Bar name={t('Referral Point')} stackId="amt" dataKey="referralPointCount" barSize={15} fill={teal[graphColorTone]} />
                <Line type="monotone" name={t('Referral Cash')} dataKey="referralCashCount" stroke={blue[graphColorTone]} strokeWidth={3} />
                <Bar name={t('Tier')} stackId="amt" dataKey="tierCount" barSize={15} fill={amber[graphColorTone]} />
                <Bar name={t('Booster')} stackId="amt" dataKey="boosterCount" barSize={15} fill={indigo[graphColorTone]} />
                <Bar name={t('Lucky Draw')} stackId="amt" dataKey="luckyDrawCount" barSize={15} fill={pink[graphColorTone]} />
                <Bar name={t('VIP Reward')} stackId="amt" dataKey="vipRewardCount" barSize={15} fill={deepPurple[graphColorTone]} />
                <Line type="monotone" name={t('Redeemed Cash')} dataKey="redemptionCount" stroke={red[graphColorTone]} strokeWidth={3} />
                <Line type="monotone" name={t('Cash Rebate')} dataKey="cashRebateCount" stroke={yellow[graphColorTone]} strokeWidth={3} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Net Financial Metric')}
              {' '}
              <Box sx={{ fontWeight: 300, color: deepPurple[700] }} component='span'>
                {t('Amount')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={netFinancialMetrics.date}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  formatter={amountFormatter}
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Line type="monotone" name={t('Capital')} dataKey="capitalAmount" stroke={teal[graphColorTone]} strokeWidth={3} />
                <Line type="monotone" name={t('Exchange')} dataKey="exchangeAmount" stroke={purple[graphColorTone]} strokeWidth={3} />
                <Bar name={t('Commission')} stackId="amt" dataKey="commissionAmount" barSize={15} fill={amber[graphColorTone]} />
                <Bar name={t('Expense')} stackId="amt" dataKey="expenseAmount" barSize={15} fill={pink[graphColorTone]} />
                <Bar name={t('Payroll')} stackId="amt" dataKey="payrollAmount" barSize={15} fill={green[graphColorTone]} />
                <Line type="monotone" name={t('Transfer')} dataKey="transferAmount" stroke={blue[graphColorTone]} strokeWidth={3} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={{ p: 2, m: 1 }} elevation={10}>
            <Typography sx={{ fontWeight: 700 }} variant='h6' gutterBottom>
              {t('Net Financial Metric')}
              {' '}
              <Box sx={{ fontWeight: 300, color: deepPurple[700] }} component='span'>
                {t('Count')}
              </Box>
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={netFinancialMetrics.date}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid stroke={theme.palette.divider} />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    borderRadius: '5px',
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                />
                <Legend />
                <Line type="monotone" name={t('Capital')} dataKey="capitalCount" stroke={teal[graphColorTone]} strokeWidth={3} />
                <Line type="monotone" name={t('Exchange')} dataKey="exchangeCount" stroke={purple[graphColorTone]} strokeWidth={3} />
                <Bar name={t('Commission')} stackId="cnt" dataKey="commissionCount" barSize={15} fill={amber[graphColorTone]} />
                <Bar name={t('Expense')} stackId="cnt" dataKey="expenseCount" barSize={15} fill={pink[graphColorTone]} />
                <Bar name={t('Payroll')} stackId="cnt" dataKey="payrollCount" barSize={15} fill={green[graphColorTone]} />
                <Line type="monotone" name={t('Transfer')} dataKey="transferCount" stroke={blue[graphColorTone]} strokeWidth={3} />
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
