import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { AbilityContext } from 'casl/Can';
import { transformSavedData } from 'utils/form-utils';
import {
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';

const _NEWID = '@NewMemberTier';
const _RNAME = 'memberTiers';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const [ savedData, setSavedData ] = useState(null);
  const ability = useContext(AbilityContext);
  const serviceName = kebabCase(_RNAME);

  const data = useMemo(
    () => {
      if (savedData && savedData._id) {
        return transformSavedData(savedData);
      }

      if (propData && propData._id) {
        return transformSavedData(propData);
      }

      return {
        _id: _NEWID,
      }
    }, [propData, savedData]
  );

  const [ status, setStatus ] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const dataSchema = Yup.object().shape({
    cashRebateSettings: Yup.object().shape({
      dailySlotLoss: Yup.object().shape({
        multiplier: Yup.number().required(t("Required")).min(0),
        maxAmount: Yup.number().required(t("Required")).min(0),
        applyOffset: Yup.boolean().required(t("Required")),
        skipWallet: Yup.boolean().required(t("Required")),
      }),
      dailySlotTurnover: Yup.object().shape({
        multiplier: Yup.number().required(t("Required")).min(0),
        maxAmount: Yup.number().required(t("Required")).min(0),
        skipWallet: Yup.boolean().required(t("Required")),
      }),
      dailyLiveTurnover: Yup.object().shape({
        multiplier: Yup.number().required(t("Required")).min(0),
        maxAmount: Yup.number().required(t("Required")).min(0),
        skipWallet: Yup.boolean().required(t("Required")),
      }),
    })
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(data, '_id');

        if (_id === _NEWID) {
          delete values._id;
          const saved = await feathers.service(serviceName).create(values);
          setSavedData(saved);
        } else {
          await feathers.service(serviceName).patch(_id, {
            $set: values
          });
        }
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  function disableInput(fieldName) {
    const action = get(data, '_id') !== _NEWID ? 'update' : 'create';
    return ability.can(action, _RNAME, fieldName) ? false : true;
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
      <DialogTitle>{t('Cash Rebate Setting')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 700, color: 'text.secondary' }}>
                    {t('dailySlotLoss')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={disableInput('cashRebateSettings.dailySlotLoss.multiplier')}
                    id='cashRebateSettings.dailySlotLoss.multiplier'
                    name='cashRebateSettings.dailySlotLoss.multiplier'
                    label={t('Multiplier')}
                    value={get(formik, 'values.cashRebateSettings.dailySlotLoss.multiplier', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.cashRebateSettings.dailySlotLoss.multiplier`, false) && Boolean(get(formik, `errors.cashRebateSettings.dailySlotLoss.multiplier`))}
                    helperText={get(formik, `touched.cashRebateSettings.dailySlotLoss.multiplier`, false) && get(formik, `errors.cashRebateSettings.dailySlotLoss.multiplier`)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={disableInput('cashRebateSettings.dailySlotLoss.maxAmount')}
                    id='cashRebateSettings.dailySlotLoss.maxAmount'
                    name='cashRebateSettings.dailySlotLoss.maxAmount'
                    label={t('Max Amount')}
                    value={get(formik, 'values.cashRebateSettings.dailySlotLoss.maxAmount', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.cashRebateSettings.dailySlotLoss.maxAmount`, false) && Boolean(get(formik, `errors.cashRebateSettings.dailySlotLoss.maxAmount`))}
                    helperText={get(formik, `touched.cashRebateSettings.dailySlotLoss.maxAmount`, false) && get(formik, `errors.cashRebateSettings.dailySlotLoss.maxAmount`)}
                  />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={disableInput('cashRebateSettings.dailySlotLoss.skipWallet')}
                        checked={get(formik, 'values.cashRebateSettings.dailySlotLoss.skipWallet', false)}
                        onChange={(event) => {
                          const isChecked = get(event, 'target.checked', false);
                          formik.setFieldValue('cashRebateSettings.dailySlotLoss.skipWallet', isChecked);
                        }
                      } />
                    }
                    label={t('Skip Wallet')}
                  />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={disableInput('cashRebateSettings.dailySlotLoss.applyOffset')}
                        checked={get(formik, 'values.cashRebateSettings.dailySlotLoss.applyOffset', false)}
                        onChange={(event) => {
                          const isChecked = get(event, 'target.checked', false);
                          formik.setFieldValue('cashRebateSettings.dailySlotLoss.applyOffset', isChecked);
                        }
                      } />
                    }
                    label={t('Apply Offset')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 700, color: 'text.secondary' }}>
                    {t('dailySlotTurnover')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={disableInput('cashRebateSettings.dailySlotTurnover.multiplier')}
                    id='cashRebateSettings.dailySlotTurnover.multiplier'
                    name='cashRebateSettings.dailySlotTurnover.multiplier'
                    label={t('Multiplier')}
                    value={get(formik, 'values.cashRebateSettings.dailySlotTurnover.multiplier', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.cashRebateSettings.dailySlotTurnover.multiplier`, false) && Boolean(get(formik, `errors.cashRebateSettings.dailySlotTurnover.multiplier`))}
                    helperText={get(formik, `touched.cashRebateSettings.dailySlotTurnover.multiplier`, false) && get(formik, `errors.cashRebateSettings.dailySlotTurnover.multiplier`)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={disableInput('cashRebateSettings.dailySlotTurnover.maxAmount')}
                    id='cashRebateSettings.dailySlotTurnover.maxAmount'
                    name='cashRebateSettings.dailySlotTurnover.maxAmount'
                    label={t('Max Amount')}
                    value={get(formik, 'values.cashRebateSettings.dailySlotTurnover.maxAmount', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.cashRebateSettings.dailySlotTurnover.maxAmount`, false) && Boolean(get(formik, `errors.cashRebateSettings.dailySlotTurnover.maxAmount`))}
                    helperText={get(formik, `touched.cashRebateSettings.dailySlotTurnover.maxAmount`, false) && get(formik, `errors.cashRebateSettings.dailySlotTurnover.maxAmount`)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={disableInput('cashRebateSettings.dailySlotTurnover.skipWallet')}
                        checked={get(formik, 'values.cashRebateSettings.dailySlotTurnover.skipWallet', false)}
                        onChange={(event) => {
                          const isChecked = get(event, 'target.checked', false);
                          formik.setFieldValue('cashRebateSettings.dailySlotTurnover.skipWallet', isChecked);
                        }
                      } />
                    }
                    label={t('Skip Wallet')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 700, color: 'text.secondary' }}>
                    {t('dailyLiveTurnover')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={disableInput('cashRebateSettings.dailyLiveTurnover.multiplier')}
                    id='cashRebateSettings.dailyLiveTurnover.multiplier'
                    name='cashRebateSettings.dailyLiveTurnover.multiplier'
                    label={t('Multiplier')}
                    value={get(formik, 'values.cashRebateSettings.dailyLiveTurnover.multiplier', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.cashRebateSettings.dailyLiveTurnover.multiplier`, false) && Boolean(get(formik, `errors.cashRebateSettings.dailyLiveTurnover.multiplier`))}
                    helperText={get(formik, `touched.cashRebateSettings.dailyLiveTurnover.multiplier`, false) && get(formik, `errors.cashRebateSettings.dailyLiveTurnover.multiplier`)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={disableInput('cashRebateSettings.dailyLiveTurnover.maxAmount')}
                    id='cashRebateSettings.dailyLiveTurnover.maxAmount'
                    name='cashRebateSettings.dailyLiveTurnover.maxAmount'
                    label={t('Max Amount')}
                    value={get(formik, 'values.cashRebateSettings.dailyLiveTurnover.maxAmount', '')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={get(formik, `touched.cashRebateSettings.dailyLiveTurnover.maxAmount`, false) && Boolean(get(formik, `errors.cashRebateSettings.dailyLiveTurnover.maxAmount`))}
                    helperText={get(formik, `touched.cashRebateSettings.dailyLiveTurnover.maxAmount`, false) && get(formik, `errors.cashRebateSettings.dailyLiveTurnover.maxAmount`)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={disableInput('cashRebateSettings.dailyLiveTurnover.skipWallet')}
                        checked={get(formik, 'values.cashRebateSettings.dailyLiveTurnover.skipWallet', false)}
                        onChange={(event) => {
                          const isChecked = get(event, 'target.checked', false);
                          formik.setFieldValue('cashRebateSettings.dailyLiveTurnover.skipWallet', isChecked);
                        }
                      } />
                    }
                    label={t('Skip Wallet')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
