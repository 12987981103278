import React, { useContext, useState, useCallback } from 'react';
import AssetExplorer from "./AssetExplorer";
import AssetContext from 'features/context/assetContext';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Assets() {
  const {
    open,
    onConfirm,
    onClose,
  } = useContext(AssetContext);
  const [ selected, setSelected ] = useState(null);
  const { t } = useTranslation();

  const handleOnSelected = useCallback((value) => {
    setSelected(value);
  }, [setSelected]);

  const handleOnConfirm = (event) => {
    event.preventDefault();
    onConfirm(selected);
    setSelected(null);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <AssetExplorer onSelected={handleOnSelected} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button variant='contained' color='success' onClick={handleOnConfirm} disabled={!selected}>{t('Confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}