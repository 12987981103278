import { createContext } from 'react';

const AssetContext = createContext({
  folders: [],
  data: [],
  total: 0,
  totalPage: 0,
  page: 0,
  path: null,
  isFetching: false,
  errorMessage: '',
  fetchNextPage: () => {},
  parentAssetId: null,
  setParentAssetId: () => {},
  breadcrumbsData: [],
  sortName: 1,
  setSortName: () => {},
  isInSearch: false,
  open: false,
  openExplorer: () => {},
  onConfirm: () => {},
  onClose: () => {},
  singleSelect: false,
  searchText: '',
  onSearchText: () => {},
  onClearSearchText: () => {},
});

export default AssetContext;
