import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import BoolToggle from 'features/boolToggle/BoolToggle';
import {
  getFilter,
} from 'features/feathersStore/selectors';
import {
  setFilter,
  resetFilter,
} from 'features/feathersStore/actions';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { transformFilterFormData, transformFilter } from 'utils/form-utils';

const _RNAME = 'logonLogs';

export default function Filter(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const filter = useSelector(getFilter(_RNAME));

  const data = useMemo(
    () => {
      if (filter) {
        const transformedFilter = transformFilter(filter);
        return transformedFilter;
      }
      return {
      };
    }, [filter]
  );

  const dataSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    username: Yup.string().nullable(),
    ip: Yup.string().nullable(),
    'deviceInfo.fingerprint': Yup.string().nullable(),
    'deviceInfo.deviceType': Yup.string().nullable(),
    'deviceInfo.osName': Yup.string().nullable(),
    'deviceInfo.osVersion': Yup.string().nullable(),
    'deviceInfo.browserName': Yup.string().nullable(),
    'deviceInfo.fullBrowserVersion': Yup.string().nullable(),
    'deviceInfo.mobileVendor': Yup.string().nullable(),
    'deviceInfo.mobileModel': Yup.string().nullable(),
    isSucceed: Yup.string().nullable(),
    createdAt: Yup.object().shape({
      $gte: Yup.date().nullable(),
      $lt: Yup.date().nullable()
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: dataSchema,
    onSubmit: async values => {
      if (isEmpty(values)) return;
      const transformedVal = transformFilterFormData(values);
      dispatch(setFilter(_RNAME, transformedVal));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = (event) => {
    event.preventDefault();
    formik.resetForm();
    dispatch(resetFilter(_RNAME));
  };

  const handleFilter = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{t('Filter')}</DialogTitle>
      <DialogContent dividers>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='_id'
                name='_id'
                label='ID'
                value={get(formik, 'values._id', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched._id`, false) && Boolean(get(formik, `errors._id`))}
                helperText={get(formik, `touched._id`, false) && get(formik, `errors._id`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='username'
                name='username'
                label={t('Username')}
                value={get(formik, 'values.username', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.username`, false) && Boolean(get(formik, `errors.username`))}
                helperText={get(formik, `touched.username`, false) && get(formik, `errors.username`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='ip'
                name='ip'
                label={t('IP Address')}
                value={get(formik, 'values.ip', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.ip`, false) && Boolean(get(formik, `errors.ip`))}
                helperText={get(formik, `touched.ip`, false) && get(formik, `errors.ip`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceInfo.fingerprint'
                name='deviceInfo.fingerprint'
                label={t('Fingerprint')}
                value={get(formik, 'values.deviceInfo.fingerprint', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceInfo.fingerprint`, false) && Boolean(get(formik, `errors.deviceInfo.fingerprint`))}
                helperText={get(formik, `touched.deviceInfo.fingerprint`, false) && get(formik, `errors.deviceInfo.fingerprint`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceInfo.deviceType'
                name='deviceInfo.deviceType'
                label={t('Device Type')}
                value={get(formik, 'values.deviceInfo.deviceType', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceInfo.deviceType`, false) && Boolean(get(formik, `errors.deviceInfo.deviceType`))}
                helperText={get(formik, `touched.deviceInfo.deviceType`, false) && get(formik, `errors.deviceInfo.deviceType`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceInfo.osName'
                name='deviceInfo.osName'
                label={t('OS Name')}
                value={get(formik, 'values.deviceInfo.osName', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceInfo.osName`, false) && Boolean(get(formik, `errors.deviceInfo.osName`))}
                helperText={get(formik, `touched.deviceInfo.osName`, false) && get(formik, `errors.deviceInfo.osName`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceInfo.osVersion'
                name='deviceInfo.osVersion'
                label={t('OS Version')}
                value={get(formik, 'values.deviceInfo.osVersion', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceInfo.osVersion`, false) && Boolean(get(formik, `errors.deviceInfo.osVersion`))}
                helperText={get(formik, `touched.deviceInfo.osVersion`, false) && get(formik, `errors.deviceInfo.osVersion`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceInfo.browserName'
                name='deviceInfo.browserName'
                label={t('Browser Name')}
                value={get(formik, 'values.deviceInfo.browserName', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceInfo.browserName`, false) && Boolean(get(formik, `errors.deviceInfo.browserName`))}
                helperText={get(formik, `touched.deviceInfo.browserName`, false) && get(formik, `errors.deviceInfo.browserName`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceInfo.fullBrowserVersion'
                name='deviceInfo.fullBrowserVersion'
                label={t('Full Browser Version')}
                value={get(formik, 'values.deviceInfo.fullBrowserVersion', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceInfo.fullBrowserVersion`, false) && Boolean(get(formik, `errors.deviceInfo.fullBrowserVersion`))}
                helperText={get(formik, `touched.deviceInfo.fullBrowserVersion`, false) && get(formik, `errors.deviceInfo.fullBrowserVersion`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceInfo.mobileVendor'
                name='deviceInfo.mobileVendor'
                label={t('Mobile Vendor')}
                value={get(formik, 'values.deviceInfo.mobileVendor', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceInfo.mobileVendor`, false) && Boolean(get(formik, `errors.deviceInfo.mobileVendor`))}
                helperText={get(formik, `touched.deviceInfo.mobileVendor`, false) && get(formik, `errors.deviceInfo.mobileVendor`)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='deviceInfo.mobileModel'
                name='deviceInfo.mobileModel'
                label={t('Mobile Model')}
                value={get(formik, 'values.deviceInfo.mobileModel', '')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={get(formik, `touched.deviceInfo.mobileModel`, false) && Boolean(get(formik, `errors.deviceInfo.mobileModel`))}
                helperText={get(formik, `touched.deviceInfo.mobileModel`, false) && get(formik, `errors.deviceInfo.mobileModel`)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <BoolToggle
                    value={get(formik, 'values.isSucceed', null)}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('isSucceed', newValue)
                    }}
                  />
                }
                label={t('Is Succeed')}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$gte'
                  label={t('Created From')}
                  value={get(formik, 'values.createdAt.$gte', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$gte', '');
                    else formik.setFieldValue('createdAt.$gte', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$gte', false) && Boolean(get(formik, 'errors.createdAt.$gte'))}
                  helperText={get(formik, 'touched.createdAt.$gte', false) && get(formik, 'errors.createdAt.$gte')}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  views={['day', 'hours', 'minutes', 'seconds']}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  id='createdAt.$lt'
                  label={t('Created To')}
                  value={get(formik, 'values.createdAt.$lt', null)}
                  onBlur={formik.handleBlur}
                  onChange={(date) => {
                    if (!dayjs(date).isValid()) formik.setFieldValue('createdAt.$lt', '');
                    else formik.setFieldValue('createdAt.$lt', date.toDate());
                  }}
                  error={get(formik, 'touched.createdAt.$lt', false) && Boolean(get(formik, 'errors.createdAt.$lt'))}
                  helperText={get(formik, 'touched.createdAt.$lt', false) && get(formik, 'errors.createdAt.$lt')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Close')}</Button>
        <Button onClick={handleReset}>{t('Reset')}</Button>
        <Button onClick={handleFilter}>{t('Filter')}</Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
