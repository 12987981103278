import React from 'react';
import LinkElement from './elements/Link';
import ImageElement from './elements/Image';
import YouTubeElement from './elements/YouTube';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';


export default function Element(props) {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };
  const { key } = element;

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'heading-three':
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case 'heading-four':
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case 'heading-five':
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case 'heading-six':
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'image':
      return (<ImageElement {...props} />);
    case 'link':
      return (<LinkElement {...props} />);
    case 'youtube':
      return (<YouTubeElement {...props} />);

    // Table support
    case 'table':
      return (
        <TableContainer component={Paper} sx={{
          maxWidth: {
            xs: '100%',
            sm: '50%',
          }
        }}>
          <Table>
            {children}
          </Table>
        </TableContainer>
      );
    case 'table-head':
      return (
        <TableHead style={style} {...attributes} key={key}>
          {children}
        </TableHead>
      );
    case 'table-body':
      return (
        <TableBody style={style} {...attributes} key={key}>
          {children}
        </TableBody>
      );
    case 'table-row':
      return (
        <TableRow style={style} {...attributes} key={key}>
          {children}
        </TableRow>
      );
    case 'table-cell':
      return (
        <TableCell style={style} {...attributes} key={key}>
          {children}
        </TableCell>
      );

    default:
      return <p style={style} {...attributes}>{children}</p>;
  }
}
